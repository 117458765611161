import { Upload } from "antd";

import { PlusOutlined } from "@ant-design/icons";

const IPRUppload = ({
  fileList,
  setFileList,
  multiple = true,
  maxCount = 5,
  uploadFiles,
  beforeUpload,
  onChange,
  setIprFile,
  setRemoveIprFile
}) => {
  const props = {
    className: "custom-file-dragger",
    name: "file",
    multiple: multiple,
    maxCount: maxCount,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    customRequest: uploadFiles,
    onChange: onChange,
    beforeUpload: beforeUpload,
  };
  const onRemove = (file) => {
    setFileList([]);
    setIprFile(null);
    setRemoveIprFile(true);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="ipr-upload"
        {...props}
        fileList={fileList}
        onRemove={onRemove}
      >
       {!fileList.length ?
          <div style={{ textAlign: "center" }}>
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <p className="ant-upload-text">Upload</p>
          </div>
          : null}
      </Upload>
    </div>
  );
};

export default IPRUppload;
