import React, { useState, useEffect, useRef } from "react";
import {
  Radio,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Select,
  Image,
  Card,
  Affix,
  Collapse,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import GenericButton from "../../components/shared/buttons/genericButton";
import UploadScanComponent from "../../components/private/patient-detail/uploadScanComponent";

import { useHistory, useLocation } from "react-router-dom";


import { connect } from "react-redux";

import { labApiTasks } from "../../models/lab";
import PatientScanUploadComponent from "../../components/private/patient-detail/patientScanUploadComponent";

// import "../../assets/styles/routes/patientDetail.scss";

const { Panel } = Collapse;

const { Title } = Typography;
const { Option } = Select;

const routes = [
  {
    path: "/clinic/scheduling",
    breadcrumbName: "Patient List",
  },
  {
    path: "first",
    breadcrumbName: "Patient Detail",
  },
];

function PatientScanUpload({ computedMatch, lab }) {
  const [patientId] = useState(computedMatch.params.patientId);
  const history = useHistory();
  const location = useLocation();
  const [orderDetails] = useState(location.state.message);
  const [scanList, setScanList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [isUploadComponentSpinning, setIsUploadComponentSpinning] = useState(
    true
  );

  useEffect(() => {
    _getScanFiles();
    console.log("get scan files");
  }, []);

  const _getScanFiles = async () => {
    // setIsProgressImagesSpinning(true);
    try {
      const scanRes = await labApiTasks.getAll(
        "lab/patient/resource/getOnboardingImages",
        { patientId, patientStage: "scan" }
      );

      setScanList(scanRes.body.results.patient_onboarding_images);
      console.log(scanRes.body.results.patient_onboarding_images, "cristiano");
      setIsUploadComponentSpinning(false);
    } catch (error) {
      //   setIsProgressImagesSpinning(false);
      setIsUploadComponentSpinning(false);

      console.log(error);
    }
  };

  const [allFilesChecked, setAllFilesChecked] = useState(false);
  console.log(computedMatch, patientId);

  const confirmAccept = async (val) => {
    const hide = message.loading("Updating Order Status", 0);

    const model = {
      order_id: orderDetails.patient_order_id,

      new_order_status_id: 4,
    };
    try {
      const res = await labApiTasks.post(
        "lab/patientlist/orders/updateStatus",

        model
      );

      message.success(res.body.msg);
      setTimeout(hide, 0);

      console.log(res, "get orders");
      // getOrders();
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);

      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
    // console.log(val);
  };
  const updateDoneState = (value) => {
    setIsDone(value)

  }

  const checkAll = async (upper, lower, bite) => {
    if (upper && lower && bite) {
      const res = await confirmAccept();
      history.push(`/lab/patient/${patientId}`);
    } else {
      message.error("Please upload all files before proceeding");
    }
    setIsDone(false);
  };

  // const checkAll=()=>{
  //   console.log("test")
  // }
  return (
    <div>
      <div>
        <Row
          style={{
            marginBottom: "3rem",
          }}
        >
          <PageHeader
            // className="site-page-header"
            onBack={() => history.push(`/lab/patient/${patientId}`)}
            title="Upload Scan Files"
            breadcrumb={{ routes }}
          />
        </Row>

        <Row
          justify="end"
          style={{
            marginBottom: "2rem",
            // width: "calc(95% - 1rem)",
            // marginLeft: "2rem",
            // paddingRight: "27px",
            paddingLeft: "20px",
            width: "100%",
          }}
        >
          <GenericButton
            onClick={() => {
              history.push(`/lab/patient/${patientId}`);
            }}
          >
            Cancel
          </GenericButton>
          <GenericButton
            customStyle={{ marginLeft: "1rem" }}
            onClick={async () => {
              setIsDone(true);
            }}
            type="primary"
          >
            Done
          </GenericButton>
        </Row>

        <Row>
          {/* <PatientScanUploadComponent
            fileList={fileList}
            patientId={patientId}
            lab={lab}
          ></PatientScanUploadComponent> */}
          <div className="upload-file-scan-container">
            {isUploadComponentSpinning && (
              <Spin style={{ marginTop: "1rem" }}></Spin>
            )}
            {!isUploadComponentSpinning && (
              <UploadScanComponent
                checkAll={checkAll}
                scanUrls={scanList}
                patientId={patientId}
                isDone={isDone}
                updateDoneState = {updateDoneState}
              />
            )}
          </div>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(PatientScanUpload);
