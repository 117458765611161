import { DeleteOutlined } from "@ant-design/icons";
import { Divider, Button, message } from "antd";
import { deleteSummaryEmailsAPI } from "api/notifications";
import { summaryRemoveMessage } from "messages/messages";
const UsersList = ({ shareList, setShareList, labId }) => {
  const handleUserDelete = async (id, email) => {
    try {
      const prevList = [...shareList];
      const updatedList = prevList.filter((obj) => obj.id !== id);
      const deleteResponse = await deleteSummaryEmailsAPI({
        clinicId: labId,
        emails: [email],
        module: "clinic",
      });
      if (deleteResponse) {
        message.success(summaryRemoveMessage);
      }
      setShareList(updatedList);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  return (
    <div className="users-shareList-container">
      {shareList.map((user, i) => {
        return (
          <div key={i} className="user-listItem">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>{user.email}</p>
              <Button
                onClick={() => handleUserDelete(user.id, user.email)}
                icon={<DeleteOutlined />}
                className="del-btn"
              ></Button>
            </div>

            <Divider style={{ margin: "15px 0px" }} />
          </div>
        );
      })}
    </div>
  );
};

export default UsersList;
