import React, { useState, useEffect } from "react";
import { Upload, Typography, Select, Card } from "antd";

import { connect } from "react-redux";

import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";

import UploadScanComponent from "./uploadScanComponent";
import UploadClinicalFiles from "./UploadClinicalFiles";

// import "../../../assets/styles/components/patientScanUploadsComponent.scss";
dayjs.extend(minMax);

const ViewScanComponent = ({ patientId, scanUrls, clinicalFiles, getImagesAndQuestionnaire, setScanUrls }) => {
  const [lastUploadedScan, setLastUploadedScan] = useState();
  const [lastUploadedClinicalScan, setLastUploadedClinicalScan] = useState();

  const checkAll = () => {
    console.log("test");
  };

  useEffect(() => {
    if (clinicalFiles && clinicalFiles.length) {
      const getAllUploadedClinicalDates = clinicalFiles.map((scanUrl) => {
        return dayjs(scanUrl.upload_date);
      });
      if (getAllUploadedClinicalDates?.length) {
        const largestDate = dayjs.max(getAllUploadedClinicalDates);
        setLastUploadedClinicalScan(largestDate.format("DD-MM-YYYY"));
      }
    }
  }, [clinicalFiles]);

  useEffect(() => {
    if (scanUrls && scanUrls.length) {
      const getAllUploadedDates = scanUrls.map((scanUrl) => {
        return dayjs(scanUrl.upload_date);
      });
      if (getAllUploadedDates?.length) {
        const largestDate = dayjs.max(getAllUploadedDates);
        setLastUploadedScan(largestDate.format("DD-MM-YYYY"));
      }
    }
  }, [scanUrls]);
  return (
    <div style={{ width: "100%" }}>
      <Card>
        <div style={{ display: "flex" }}>
          <h1 className="headerScans">Scan Files</h1>
          {lastUploadedScan ? (
            <div style={{ marginRight: "auto", paddingLeft: "10px" }}>
              <span style={{ color: "#77888b" }}>Last Updated at:</span> {lastUploadedScan}
            </div>
          ) : null}
        </div>
        <UploadScanComponent
          checkAll={checkAll}
          patientId={patientId}
          scanUrls={scanUrls}
          mode={"view"}
          setScanUrls={setScanUrls}
        />
      </Card>
      <br />
      <Card>
        <div style={{ display: "flex" }}>
          <h1 className="headerScans">{`Clinical Files ${
            clinicalFiles?.length ? "(" + clinicalFiles.length + ")" : ""
          }`}</h1>
          {lastUploadedClinicalScan ? (
            <div style={{ marginRight: "auto", paddingLeft: "50px" }}>
              <span style={{ color: "#77888b" }}>Last Updated at:</span> {lastUploadedClinicalScan}
            </div>
          ) : null}
        </div>

        <UploadClinicalFiles
          patientId={patientId}
          clinicalFiles={clinicalFiles}
          getImagesAndQuestionnaire={getImagesAndQuestionnaire}
          mode={"view"}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(ViewScanComponent);
