import React from "react";
import { Form, Row, Radio, Col, Card } from "antd";
import { removeUnderscore } from "../utils"
const IPRAlignment = ({ ipr, attachments, crowding, incisal, spacing }) => {
  return (
    <div>
      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">IPR & Attachments</h1>
        <Row>
          <Col lg={12} className="border-right">
            <h2 className="caption">IPR</h2>
            <p className="content"  style={{textTransform:"capitalize" }}>{ipr ? ipr : "--"}</p>
          </Col>
          <Col lg={12}>
            <div className="paddingLeft">
              <h2 className="caption">Attachments</h2>
              <p className="content" style={{textTransform:"capitalize" }}>{attachments  ? attachments : "--"}</p>
            </div>
          </Col>
        </Row>
      </Card>

      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Alignment</h1>
        <Row>
          <Col lg={8} className="border-right">
            <h2 className="caption">Incisal edge alignment</h2>
            <p className="content"  style={{textTransform:"capitalize" }}>{removeUnderscore(incisal)}</p>
          </Col>
          <Col lg={8} className="border-right">
            <div className="paddingLeft">
              <h2 className="caption">Crowding</h2>
              <p className="content"  style={{textTransform:"capitalize" }}>{removeUnderscore(crowding)}</p>
            </div>
          </Col>

          <Col lg={8}>
            <div className="paddingLeft">
              <h2 className="caption">Spacing</h2>
              <p className="content">{removeUnderscore(spacing)}</p>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default IPRAlignment;
