import React, { useEffect,useState } from 'react'
import { primaryColor } from "colors-config";
import PatientDetails from "./components/PatientDetails";
import "./treatment.scss";
import Footer from "./components/Footer";
import TreatmentInfo from "./components/TreatmentInfo";
import IprData from "./components/IPRData";
import AttachmentData from "./components/AttachmentData";
import axios from "axios";

const TreatmentPDF = ({patientInfo,  treatment, lowerAligners, upperAligners, chartValue, lowerAttachments,upperAttachments, alignersLabel, attachmentsLabel}) => {
  const [clinicDetails,setClinicDetails]=useState()
  const PUBLIC_API_BASE_URL = process.env.REACT_APP_CLINIC_BASE_URL;

  useEffect(()=>{

    if(patientInfo?.clinic_id){
      _getClinicInformation(patientInfo?.clinic_id)
    }

  },[patientInfo])

  const _getClinicInformation = async (clinicId) => {
    try {
      const clinicInfo = await axios.get(
        `${PUBLIC_API_BASE_URL}/clinic/populateClinicDetails?clinicId=${clinicId}`
      );
      if(clinicInfo?.data?.body?.clinic_id){
        setClinicDetails(clinicInfo?.data?.body)
      }
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="treatmentPDF-container">
      <h1 style={{color: primaryColor}}>Treatment Guide</h1>
      <PatientDetails patientInfo={patientInfo} clinic={clinicDetails} />
      <TreatmentInfo treatment={treatment}/>
      <IprData 
        lowerAligners={lowerAligners}
        upperAligners={upperAligners}
        chartValue={chartValue}
        upperAttachments={upperAttachments}
        lowerAttachments={lowerAttachments}
        alignersLabel={alignersLabel}
        />

        <AttachmentData
        chartValue={chartValue}
        upperAttachments={upperAttachments}
        lowerAttachments={lowerAttachments}
        attachmentsLabel={attachmentsLabel}
        alignersLabel={alignersLabel}
        />
      <Footer/>
    </div>
  )
}

export default TreatmentPDF
