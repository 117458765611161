import styles from "assets/styles/routes/dashboard.module.scss";
const HeaderCount = ({ icon, count, info }) => {
  return (
    <div className={styles.headerCount}>
      <div>{icon}</div>
      <div>
        <h1>{count !== null && count !== undefined ? count : '--'}</h1>
        <p>{info}</p>
      </div>
    </div>
  );
};

export default HeaderCount;
