import React, { useState, useEffect } from "react";
import { Steps, Button, Card, Divider, Row, Col, message } from "antd";
import { labApiTasks } from "../../../models/lab";
import moment from "moment";

import DeliveryUpdateModal from "../../shared/modals/deliveryUpdateModal";
import ConfirmationModal from "../../shared/modals/confirmationModal";
import OrderRemakeModal from "../../shared/modals/orderRemakeModal";

import "../../../assets/styles/components/patientOrdersStepsComponent.scss";
import { useHistory } from "react-router-dom";
import {  updateCommonLabStatusAPI } from "api/lab";

const { Step } = Steps;

const PatientOrderStepsComponent = ({
  steps,
  orderDetails,
  orderRemakeReasons,
  getOrders,
  patientId,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showDeliveryUpdateModal, setShowDeliveryUpdateModal] = useState(false);
  const [
    showReceitConfirmationModal,
    setShowReceitConfirmationModal,
  ] = useState(false);
  const [showOrderRemakeModal, setShowOrderRemakeModal] = useState(false);

  const history = useHistory();

  const confirmDeliveryUpdate = async (val) => {
    const hide = message.loading("Updating Delivery Status", 0);
    const model = {
      order_id: orderDetails.patient_order_id,
      new_order_status_id: 2,
      tracker_url: val,
    };
    try {
      const res = await updateCommonLabStatusAPI(model)

      message.success(res.body.msg);
      setTimeout(hide, 0);

      getOrders();

      console.log(res, "get orders");
    } catch (error) {
      setTimeout(hide, 0);

      console.log(error);
      if (error?.response?.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
    console.log(val, "vall");
  };

  useEffect(() => {
    // if(!orderDetails.order_tracking_link){
    //   setCurrentStep(1);
    // }
    if (orderDetails.order_tracking_link) {
      setCurrentStep(2);
    }
    if (orderDetails.impressions_received_date) {
      setCurrentStep(3);
    }
    if (orderDetails.scan_uploaded_date) {
      setCurrentStep(4);
    }
    if (
      orderDetails.order_type === "Pro-Impressions" &&
      !orderDetails.impressions_received_date
    ) {
      setCurrentStep(2);
    }
  }, [orderDetails]);

  const confirmReceipt = async () => {
    const hide = message.loading("Updating Status", 0);
    const model = {
      order_id: orderDetails.patient_order_id,
      new_order_status_id: 3,
    };
    try {
      const res = await labApiTasks.post(
        "lab/patientlist/orders/updateStatus",

        model
      );

      message.success(res.body.msg);
      setTimeout(hide, 0);

      getOrders();

      console.log(res, "get orders");
    } catch (error) {
      setTimeout(hide, 0);

      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  };

  const confirmRemake = async (val) => {
    const hide = message.loading("Updating Order Status", 0);

    const model = {
      order_id: orderDetails.patient_order_id,

      new_order_status_id: 5,
      order_remake_reason_id: val.reason,
    };
    try {
      const res = await labApiTasks.post(
        "lab/patientlist/orders/updateStatus",

        model
      );

      message.success(res.body.msg);
      setTimeout(hide, 0);

      console.log(res, "get orders");
      getOrders();
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);

      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
    // console.log(val);
  };

  const confirmAccept = async (val) => {
    const hide = message.loading("Updating Order Status", 0);

    const model = {
      order_id: orderDetails.patient_order_id,

      new_order_status_id: 4,
    };
    try {
      const res = await labApiTasks.post(
        "lab/patientlist/orders/updateStatus",

        model
      );

      message.success(res.body.msg);
      setTimeout(hide, 0);

      console.log(res, "get orders");
      getOrders();
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);

      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
    // console.log(val);
  };

  const shippingSuccessComponent = () => {
    return (
      <div>
        {/* <p> Tracking Link</p> */}
        <div>
        <a href={orderDetails.order_tracking_link}>
          {orderDetails.order_tracking_link}{" "}
        </a>
        </div>
      </div>
    );
  };

  const impressionReceivedComponent = () => {
    return (
      <div>
        <p>Received Date</p>

        <p style={{ fontWeight: "bolder" }}>
          {moment(orderDetails.impressions_received_date).format("MMM Do YYYY")}
        </p>
      </div>
    );
  };

  const onConfirm = async () => {
    history.push({
      pathname: `/lab/patient/${patientId}/scan`,
      state: { message: orderDetails },
    });
  };

  const labProcessingButtons = () => {
    return (
      <div>
        <Row>
          <Button
            style={{ display: currentStep !== 3 ? "none" : "", width: "11rem" }}
            onClick={onConfirm}
            type="primary"
          >
            Approve & Upload Scan
          </Button>
        </Row>

        <Row justify="center">
          <Button
            onClick={() => setShowOrderRemakeModal(true)}
            style={{
              display: currentStep !== 3 ? "none" : "",
              marginTop: "1rem",
            }}
          >
            Remake
          </Button>
        </Row>
      </div>
    );
  };

  const labProcessingResultComponent = () => {
    if (orderDetails.order_remake_reason_id) {
      return (
        <div style={{ wordWrap: "break-word" }}>
          <Row>
            <p
              style={{
                background: "rgba(254, 80, 80, 0.18)",
                borderRadius: "34px",
                color: "red",
                padding: "0.4rem",
              }}
            >
              Remake needed from clinic
            </p>
          </Row>
          <Row justify="center">
            <p>Remake Reason</p>
          </Row>
          <Row justify="center">
            <p
              style={{
                fontWeight: "800",
                width: "10rem",
                wordWrap: "break-word",
              }}
            >
              {orderDetails.order_remake_reason_name}
            </p>
          </Row>
        </div>
      );
    } else if (orderDetails.scan_uploaded_date) {
      return (
        <div>
          <Row>
            <p
              style={{
                background: "#E4F5EB",
                borderRadius: "34px",
                color: "green",
                padding: "0.4rem",
              }}
            >
              Approved
            </p>
          </Row>
          <Row justify="center">
            <p>Scan Uploaded</p>
          </Row>
          <Row justify="center">
            <p style={{ fontWeight: "800" }}>
              {moment(orderDetails.scan_uploaded_date).format("MMM Do YYYY")}
            </p>
          </Row>
        </div>
      );
    }
    //   return <div>
    //   <Row >
    //   <Button style={{display:currentStep !==3?"none":"",width:"11rem"}}  type="primary">Approve & Upload Scan</Button>

    //   </Row>

    //     <Row justify="center">
    //     <Button onClick={()=>setShowOrderRemakeModal(true)} style={{display:currentStep !==3?"none":"",marginTop:"1rem"}}  >Remake</Button>

    //     </Row>

    // </div>
  };

  return (
    <Card className="order-container">
      {console.log("check45:", orderDetails.patient_order_id, currentStep)}
      {showDeliveryUpdateModal && (
        <DeliveryUpdateModal
          title="Shipping Status"
          showModal={showDeliveryUpdateModal}
          onConfirmation={confirmDeliveryUpdate}
          content={"Home Impression kit shipped"}
          setShow={setShowDeliveryUpdateModal}
        ></DeliveryUpdateModal>
      )}

      {showReceitConfirmationModal && (
        <ConfirmationModal
          okText={<p>Yes</p>}
          cancelText={<p>No</p>}
          title="Impression Status"
          content=" Impressions has been received by the Lab?"
          showModal={showReceitConfirmationModal}
          onConfirmation={confirmReceipt}
          setShow={setShowReceitConfirmationModal}
        ></ConfirmationModal>
      )}

      {showOrderRemakeModal && (
        <OrderRemakeModal
          title="Remake needed from clinic"
          orderRemakeReasons={orderRemakeReasons}
          showModal={showOrderRemakeModal}
          onConfirmation={confirmRemake}
          setShow={setShowOrderRemakeModal}
        ></OrderRemakeModal>
      )}

      <Row className="order-status-container">
        <Col className="order-status-col">
          <div style={{ paddingRight: "2.5rem" }}>
            <Row>
              <p className="order-status-header">Order Type</p>
            </Row>
            <Row>
              <p className="order-statuses">{orderDetails.order_type}</p>
            </Row>
          </div>
        </Col>

        <Col className="order-status-col">
          <div style={{ paddingRight: "2.5rem" }}>
            <Row>
              <p className="order-status-header">Payment Status</p>
            </Row>
            <Row>
              <p className="order-statuses">{orderDetails.payment_status}</p>
            </Row>
          </div>
        </Col>

        <Col>
          <div style={{ paddingRight: "2.5rem" }}>
            <Row>
              <p className="order-status-header">Order Status</p>
            </Row>
            <Row>
              <p className="order-statuses">
              {orderDetails.order_type === "Pro-Impressions" && currentStep === 2 ?"-":orderDetails.order_status}</p>
            </Row>
          </div>
        </Col>
      </Row>

      <div className="steps">
        <Steps
          labelPlacement="vertical"
          current={currentStep}
          status={orderDetails.order_remake_reason_id ? "error" : ""}
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            width: "100%",
            padding: "0 1rem",
          }}
        >
          <Step
            status={orderDetails.order_type === "Pro-Impressions" && "process"}
            style={{ margin: "2rem 2rem 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[0].title}
            title={steps[0].title}
            subTitle={
              orderDetails.order_type !== "Pro-Impressions"
                ? "Order created date"
                : ""
            }
            description={
              orderDetails.order_type !== "Pro-Impressions" ? (
                <p style={{ fontWeight: "800" }}>
                  {moment(orderDetails.product_order_date).format(
                    "MMM Do YYYY"
                  )}
                </p>
              ) : (
                ""
              )
            }
          />
          <Step
            status={orderDetails.order_type === "Pro-Impressions" && "process"}
            style={{ margin: "2rem 2rem 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[1].title}
            title={steps[1].title}
            subTitle={
              orderDetails.order_type !== "Pro-Impressions" ? (
                !orderDetails.order_tracking_link ? (
                  <Button
                    onClick={() => setShowDeliveryUpdateModal(true)}
                    type="primary"
                  >
                    Confirm Shipped
                  </Button>
                ) : (
                  shippingSuccessComponent()
                )
              ) : (
                ""
              )
            }
            // description={item.description}
          />
          <Step
            style={{ margin: "2rem 2rem 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[2].title}
            title={steps[2].title}
            subTitle={
              orderDetails.impressions_received_date ? (
                impressionReceivedComponent()
              ) : (
                <Button
                  style={{ display: currentStep !== 2 ? "none" : "" }}
                  onClick={() => setShowReceitConfirmationModal(true)}
                  type="primary"
                >
                  Confirm Receipt
                </Button>
              )
            }
            // description={item.description}
          />
          <Step
            style={{ margin: "2rem 2rem 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[3].title}
            title={steps[3].title}
            subTitle={
              orderDetails.order_remake_reason_id ||
              orderDetails.scan_uploaded_date
                ? labProcessingResultComponent()
                : labProcessingButtons()
            }
            // subTitle={item.subTitle}
            // description={item.description}
          />
        </Steps>
      </div>
    </Card>
  );
};

export default PatientOrderStepsComponent;
