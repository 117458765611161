import { Button } from "antd";

const Footer = ({ handleCancel, handleOk, handleSaveDraft, treatment, draftLoader }) => {
  return (
    <div>
      <Button shape="round" onClick={handleCancel}>
        Cancel
      </Button>
      <Button loading={draftLoader} onClick={handleSaveDraft} shape="round">
        Save as Draft
      </Button>
      <Button shape="round" type="primary" onClick={handleOk}>
        {Object.keys(treatment).length ? "Update" : "Submit"}
      </Button>
    </div>
  );
};

export default Footer;
