import { Row, Col } from "antd";
import { primaryColor } from "colors-config";
import { removeUnderscore } from "components/private/PrescriptionModal/components/TeethChart/data";
import { showTeethName } from "components/shared/TeethChart/data";


const PrescriptionToothChart = ({toothStatuses, chartValue}) => {
  return (
    <div className="prescriptionPDF-card">
    <h2>Tooth status</h2>
    {toothStatuses?.length ?  toothStatuses?.map((tooth, i) => {
    return (
      <div key={i}>
        <div className="prescriptionPDF-duration">
          <div>
            <label className="treatment-label toothName" style={{backgroundColor: primaryColor}}>
              {chartValue === "fdi"
                ? tooth.toothNo
                : showTeethName(tooth.toothNo, chartValue)}
            </label>
            <p className="treatment-info">{removeUnderscore(tooth.procedure)}</p>
          </div>
          <div>
            <div>
              <label className="treatment-label">Details</label>
              <p className="treatment-info">
                {tooth.detail === "" ? "--" : tooth.detail}
              </p>
            </div>
          </div>
        </div>

        {tooth.addOns.map((addOn, i) => {
          return (
            <div key={i}>
              <h2>Additional Status</h2>
              <div className="prescriptionPDF-duration">
                <div>
                  <label className="treatment-label">Procedure</label>
                  <p className="treatment-info">
                    {removeUnderscore(addOn.procedure)}
                  </p>
                </div>
                <div>
                  <div>
                    <label className="treatment-label">Details</label>
                    <p className="treatment-info">
                      {addOn.detail === "" ? "--" : addOn.detail}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  }) : "--"}
  </div>
  )
}

export default PrescriptionToothChart

