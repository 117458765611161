import { Typography } from "antd";
import styles from "./detailInfo.module.scss";
const { Text, Title } = Typography;

const DetailInfo = ({ label, value, style, bold = false }) => {
  return (
    <div style={style}>
      <Text className={styles["label"]}>{label ? label :""}</Text>
      <Title className={bold ? styles["bold-value"]: styles["value"]} level={5}>
        {value}
      </Title>
    </div>
  );
};

export default DetailInfo;
