import { Row, Col, Form, Input, Select } from "antd";
import CountryCodeSelect from "components/shared/CountryCodeSelect/CountryCodeSelect";
import { staffRolesOptions } from "../constants";

const FormItem = Form.Item;

const StaffForm = ({ addStaffFormRef, onFinish }) => {
  const onFinishFailed = (error) => {
    // console.log(failed)
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={addStaffFormRef}
      name="add-staff-form"
      layout="vertical"
      initialValues={{
        countryCode: "+353",
      }}
    >
      <Row gutter={24}>
        <Col md={12}>
          <FormItem
            rules={[{ required: true, message: "First name is required" }]}
            name="firstName"
            label="First Name"
          >
            <Input placeholder="Enter first name"/>
          </FormItem>
        </Col>
        <Col md={12}>
          <FormItem
            rules={[{ required: true, message: "Last name is required" }]}
            name="lastName"
            label="Last Name"
          >
            <Input placeholder="Enter last name"/>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={12}>
          <FormItem
            rules={[{ required: true, type: "email", message: "Email is required" }]}
            name="email"
            label="Email"
            validateTrigger="onBlur"
          >
            <Input placeholder="Enter email"/>
          </FormItem>
        </Col>
        <Col md={12}>
          <FormItem
            rules={[{ required: true, message: "Role is required" }]}
            name="role"
            label="Role"
          >
            <Select placeholder="Choose" options={staffRolesOptions} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col md={12}>
          <FormItem
            name="phone"
            label="Phone Number"
            // validateTrigger="onBlur"
            rules={[
              {
                // required: true,
                message: "Please input your phone number!",
              },
              () => ({
                validator(_, value) {
                  const convertedValue = Number(value);
                  if (!value) {
                    return Promise.resolve();
                  } else if (
                    !isNaN(convertedValue) &&
                    (value.length === 9 || value.length === 10)
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Invalid phone number");
                },
              }),
            ]}
          >
            <Input
              addonBefore={<CountryCodeSelect name="countryCode" />}
              style={{ width: "100%" }}
              placeholder="Phone Number"
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default StaffForm;
