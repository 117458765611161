export const smileAlignPhasingInfo =
  "We are able to send smaller phases of treatment to allow you to monitor treatment more closely. This can be at any stage of treatment you wish. Please let us know if you would like your treatment phased and, in the box below, how many aligners you would like.";

export const spacingInfo =
  "If all spacing cannot be closed/spaces need to be left, please indicate your preference on where these are left.";

export const smileGeniusPhasingInfo = "Please let us know if you would like your case phased.";

export const notificationToolTip =
  "Emails are sent multiple times a day in your local timezone to help you close your action items.";

export const summaryAddMessage = "Summary email recipient added successfully";

export const summaryRemoveMessage = "Summary email recipient removed successfully";

export const alignersRequiredMessage =
  "Please enter the number of aligners for either Upper Arch or Lower Arch or both.";
export const onBoardingToolTip =
  "Invites cannot be resent or deleted when the clinic onboarding is in progress or completed";
export const verificationPendingOrOnboardingPendingTooltip =
  "Invites cannot be resent or deleted when the clinic is in this partnership status";
export const confirmPasswordErrorMessage =
  "The passwords entered do not match! Please review and enter the same passwords in both fields.";
  export const productPriceErrorMessage = "'0' is not allowed, please mark it as 'Free' instead"
  export const prescriptionProductMessage ="Products added here will appear in the clinic prescription treatment list. (Ex. Enter Canine to Canine if this is one of treatment types you offer.)"
