import React, { useState, useEffect } from "react";
import { Modal } from "antd";

const ConfirmationModal = ({ title, content, onConfirmation, showModal, setShow, ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    onConfirmation();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  return (
    <Modal
      title={title}
      open={isModalVisible}
      onOk={handleOk}
      {...props}
      onCancel={handleCancel}
      okButtonProps={{
        style: { width: "7rem", borderRadius: "30px" },
      }}
      cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
    >
      <p>{content}</p>
    </Modal>
  );
};

export default ConfirmationModal;
