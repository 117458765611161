import { Row, Card, Divider, message, Select } from "antd";
// import "../../../assets/styles/components/patient-journey/patientPrescriptionFormComponent.scss";
import UploadScanComponent from "../UploadClinicalFiles/UploadClinicalFiles";

import { useEffect, useState } from "react";
// import UploadScanComponent from "../onboarding-profile/uploadFilesComponent";
const { Option } = Select;

const ViewScanFilesComponent = ({
  clinicalFiles,
  scanUrls,
  patientId,
  treatmentOriginId,
  activeCaseId,
  getImg,
  status,
  setClinicalFilesArray,
  clinicalFilesArray,
  setStatusesState,
  updateTreatmentOrigin,
  dropdownValues,
  setScanUrls
}) => {
  const [activeTreatmentOrigin, setActiveTreatmentOrigin] =
    useState("HIK Impressions");

  useEffect(() => {
    if (status && status.patient_treatment_origin_name) {
      setActiveTreatmentOrigin(status.patient_treatment_origin_name);
    }
  }, [status]);

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  //   const handleOriginChange = (value) => {
  //     if(value === "HIK Impressions"){
  //       if (dropdownValues?.data[0].treatment_origin_name) {
  //         setActiveTreatmentOrigin(
  //           dropdownValues?.data[0].treatment_origin_name
  //         );
  //         updateTreatmentOrigin(dropdownValues?.data[0].treatment_origin_id);
  //       }
  //     }
  //     else if (value ==="Pro Impressions"){
  //       if (dropdownValues?.data[1].treatment_origin_name) {
  //         setActiveTreatmentOrigin(
  //           dropdownValues?.data[1].treatment_origin_name
  //         );
  //         updateTreatmentOrigin(dropdownValues?.data[1].treatment_origin_id);
  //       }
  //     }
  //     else{
  //       if (dropdownValues?.data[2].treatment_origin_name) {
  //         setActiveTreatmentOrigin(
  //           dropdownValues?.data[2].treatment_origin_name
  //         );
  //         updateTreatmentOrigin(dropdownValues?.data[2].treatment_origin_id);
  //       }
  //     }
  //   };
  return (
    <div className="scan-files-container">
          <div className="scanFiles-header">
            <Row className="scan-form-heading" style={{ margin: "0px" }}>
              Scan Files
            </Row>
          </div>
          <Divider />
      <div style={{ width: "100%" }}>

        <div className="scan-form-container">
          <div className="scanFiles-col">
            <UploadScanComponent
              scanUrls={scanUrls}
              setScanUrls={setScanUrls}
              patientId={patientId}
              treatmentOriginId={treatmentOriginId}
              activeCaseId={activeCaseId}
              getImg={getImg}
              setClinicalFilesArray={setClinicalFilesArray}
              clinicalFilesArray={clinicalFilesArray}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewScanFilesComponent;
