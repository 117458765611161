import { ReactComponent as Sample } from "assets/images/Sample.svg";
import { ReactComponent as Save } from "assets/images/Save.svg";
import { Button } from "antd";
import ClinicInvitesFile from "assets/files/Clinic_Invites_Sample.xlsx";
import styles from "../../labPartners.module.scss";

const FileSample = () => {
  const handleDownload = () => {
    const filePath = "../../../../../assets/files/Clinic_Invites_Sample.xlsx";
    const link = document.createElement("a");
    link.href = filePath;
    link.download = "Clinic_Invites_Sample.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={styles.sampleContainer}>
      <ul className={styles.requiredList}>
        <li>Email address is mandatory</li>
        <li>Clinician name is mandatory</li>
        <li>The email address must not have any space in between</li>
        <li>The email domain should be a valid</li>
        <li>Email Column should be at start</li>
        <li>Phone number should be 12 digits</li>
        <li>Phone number is optional</li>
      </ul>
      <div className={styles.fileHeader}>
        <h2>Example</h2>
        <a
          href={ClinicInvitesFile}
          download="Clinic_Invites_Sample"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className={styles.downloadBtn}
            icon={<Save />}
          >
            Download Sample
          </Button>
        </a>
      </div>
         <Sample/>
    </div>
  );
};

export default FileSample;
