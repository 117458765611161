import { useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
  Button,
  Checkbox,
  Popconfirm,
  Dropdown,
  Menu,
} from "antd";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";

import { DeleteOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import { PRODUCT_TYPE_PER_ALIGNER } from "constants";
import CustomEditIcon from "components/shared/Icon/EditIcon";
import DiscountForm from "./DiscountForm";
import AppToolTip from "../AppToolTip/AppToolTip";
import TreatmentProductToolTip from "components/private/TreatmentProductsTable/TreatmentProductToolTip";
const { Option } = Select;
const LabProductForm = ({
  form,
  labProducts,
  handleProductNameChange,
  onQuantityChange,
  totalSum,
  onDiscountPercentageChange,
  onDiscountAmountChange,
  onSaveDiscount,
  calculateTotal,
  openDropdownKey,
  setOpenDropdownKey,
}) => {
  return (
    <div className="dynamic-products">
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <div style={{ padding: "10px 15px" }}>
            {fields.map(({ key, name, fieldKey, ...restField }) => {
              const isFree = form.getFieldValue(["products", name, "free"]);
              const productCurrency = form.getFieldValue(["products",name, "currency"]);
              const productType = form.getFieldValue(["products", name, "quantityType"]);
              const pCurrency = form.getFieldValue(["products", name, "currency"]);
              const pPrice = form.getFieldValue(["products", name, "price"]);
              const pNetPrice = form.getFieldValue(["products", name, "netPrice"]);
              const pDiscountPrice = form.getFieldValue(["products", name, "discountAmount"]);
              return (
                <Row gutter={[16, 16]} key={key}>
                  <Col lg={7}>
                    <Form.Item
                      {...restField}
                      name={[name, "labProductId"]}
                      fieldKey={[fieldKey, "labProductId"]}
                      label="Product/Service Name"
                    >
                      <Select
                        size="large"
                        placeholder="Select Product"
                        allowClear
                        // onChange={handleProductNameChange}
                        onChange={(value) =>
                          handleProductNameChange(value, name, fieldKey)
                        }
                        showSearch
                        optionRender={(option) => {
                          return (
                            <>
                              <span className="treatment-arches-dp">
                                <span className="dp-label">
                                  {option.data.productName}
                                </span>
                                <span className="db-arches">
                                  {" "}
                                  {option.data.arches}
                                </span>
                              </span>
                            </>
                          );
                        }}
                        options={labProducts?.map((item) => {
                          return {
                            ...item,
                            label: item.productName,
                            value: item.id,
                          };
                        })}
                        filterOption={(input, option) => {
                          return (
                            option?.label
                              ?.toLowerCase()
                              .includes(input?.toLowerCase()) ||
                            option?.arches
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <Form.Item
                      {...restField}
                      name={[name, "arches"]}
                      fieldKey={[fieldKey, "arches"]}
                      label="Arches Treated"
                    >
                      <Select
                        size="large"
                        disabled
                        placeholder="Arches Treated"
                      >
                        <Option value="Upper and Lower">Upper and Lower</Option>
                        <Option value="Upper">Upper</Option>
                        <Option value="Lower">Lower</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={10}>
                    <Row>
                      {productType === PRODUCT_TYPE_PER_ALIGNER && (
                        <Col lg={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "quantity"]}
                            fieldKey={[fieldKey, "quantity"]}
                            label="Quantity"
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder="Quantity"
                              max={1000}
                              min={1}
                              onChange={(value) =>
                                onQuantityChange(value, name)
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col
                        lg={productType === PRODUCT_TYPE_PER_ALIGNER ? 11 : 17}
                      >
                        <div>
                          <div className="cost-container">
                            <label>
                              {productType === PRODUCT_TYPE_PER_ALIGNER
                                ? "Net Price per unit"
                                : "Net Price"}
                            </label>
                            {pDiscountPrice && (
                              <AppToolTip
                                title={
                                  <TreatmentProductToolTip
                                    currency={pCurrency}
                                    price={pPrice}
                                    netPrice={pNetPrice}
                                    discountPrice={pDiscountPrice}
                                  />
                                }
                              />
                            )}
                          </div>

                          <Form.Item
                            {...restField}
                            name={[name, "netPrice"]}
                            fieldKey={[fieldKey, "netPrice"]}
                            label=""
                          >
                            <InputNumber
                              placeholder="Enter price"
                              disabled
                              step={0.01}
                              precision={2}
                              addonAfter={
                                !isFree && (
                                  <Dropdown
                                    className="cost-dropdown"
                                    overlay={
                                      <Menu className="conflict-menu">
                                        <Menu.Item>
                                          <Row
                                            align="middle"
                                            gutter={[16, 16]}
                                            style={{
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <Col lg={9}>
                                              <span>Price per Unit</span>
                                            </Col>
                                            <Col lg={15}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "price"]}
                                                fieldKey={[fieldKey, "price"]}
                                                label=""
                                                style={{
                                                  margin: "0px",
                                                }}
                                              >
                                                <InputNumber
                                                className="product-price"
                                                  placeholder="Price"
                                                  min={0}
                                                  max={100}
                                                  step={0.1}
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                  disabled
                                                  suffix={
                                                    <span
                                                      style={{
                                                        color: primaryColor,
                                                      }}
                                                    >
                                                      {productCurrency}
                                                    </span>
                                                  }
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>

                                          <DiscountForm
                                            name={name}
                                            fieldKey={fieldKey}
                                            restField={restField}
                                            productCurrency={productCurrency}
                                            form={form}
                                          />
                                          <Row
                                            align="middle"
                                            gutter={[16, 16]}
                                            style={{
                                              marginTop: "10px",
                                            }}
                                          >
                                            <Col lg={9}>
                                              <b>Discount Price</b>
                                            </Col>
                                            <Col lg={15}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "discountPrice"]}
                                                fieldKey={[
                                                  fieldKey,
                                                  "discountPrice",
                                                ]}
                                                label=""
                                                style={{
                                                  margin: "0px",
                                                  textAlign: "right",
                                                }}
                                              >
                                                <InputNumber
                                                  min={0}
                                                  max={100}
                                                  step={0.1}
                                                  style={{
                                                    width: "100%",
                                                    color: "#000",
                                                  }}
                                                  className="DiscountPrice product-price"
                                                  disabled
                                                  suffix={
                                                    <b
                                                      style={{
                                                        color: "#000",
                                                      }}
                                                    >
                                                      {productCurrency}
                                                    </b>
                                                  }
                                                  // onChange={onDiscountPercentageChange}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                          <Button
                                            style={{
                                              marginTop: "10px",
                                            }}
                                            type="primary"
                                            block
                                            onClick={() => onSaveDiscount(name)}
                                          >
                                            Save
                                          </Button>
                                        </Menu.Item>
                                      </Menu>
                                    }
                                    trigger={["click"]}
                                    // visible={true}
                                    visible={openDropdownKey === key}
                                    onVisibleChange={(visible) =>
                                      visible
                                        ? setOpenDropdownKey(key)
                                        : setOpenDropdownKey(null)
                                    }
                                  >
                                    <CustomEditIcon />
                                  </Dropdown>
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={7} style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            right: "-35px",
                            top: "-5px",
                          }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "free"]}
                            fieldKey={[fieldKey, "free"]}
                            label=""
                            valuePropName="checked"
                          >
                            <Checkbox size="large" disabled>
                              Free
                            </Checkbox>
                          </Form.Item>
                        </div>

                        <Form.Item
                          {...restField}
                          name={[name, "currency"]}
                          fieldKey={[fieldKey, "currency"]}
                          label="Currency"
                        >
                          <Input size="large" placeholder="Currency" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={1}>
                    {fields.length > 1 && (
                      <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this Product/Service?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={(e) => {
                          remove(name);
                          calculateTotal();
                        }}
                        onCancel={() => {}}
                      >
                        <DeleteOutlined
                          style={{
                            marginTop: 40,
                            color: "black",
                            fontSize: "20px",
                            float: "right",
                          }}
                        />
                      </Popconfirm>
                    )}
                  </Col>
                </Row>
              );
            })}
            <Button
              disabled={fields.length === 3}
              icon={
                <AddIcon
                  style={{ fill: fields.length === 3 ? "grey" : primaryColor }}
                />
              }
              className="addOn-btn"
              onClick={() => add()}
            >
              Add Another
            </Button>
          </div>
        )}
      </Form.List>
      <div className="border-line" />
      <div className="totals">
        <Row>
          <Col offset={18} lg={6}>
            <p className="total-label">Total Amount</p>
          </Col>
          <Col offset={18} lg={6}>
            <h2 style={{ color: primaryColor }}>{totalSum}</h2>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LabProductForm;
