import PropTypes from "prop-types";
import { Button } from "antd";
import styles from "./manageStaff.module.scss";

const StaffHeader = ({ addStaffHandler }) => {
  return (
    <div className={styles["header-container"]}>
      <h3 className={styles['header-container__title']} >Roles List</h3>
      <Button onClick={addStaffHandler} shape="round" type="primary">
        Add New
      </Button>
    </div>
  );
};

StaffHeader.propTypes = {
  addStaffHandler: PropTypes.func.isRequired,
};

export default StaffHeader;
