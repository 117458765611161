import AppTable from "components/shared/AppTable/AppTable";
import styles from "../../labPartners.module.scss";
const PartnerTables = ({ columns, tableData, loading, onRow, pagination , onChange}) => {
  return (
    <div className={styles.partnersTableContainer}>
      <AppTable
        columns={columns}
        tableData={tableData}
        loading={loading}
        onRow={onRow}
        pagination={pagination}
        onChange={onChange}
      />
    </div>
  );
};

export default PartnerTables;
