import { Tabs } from "antd";
import PropTypes from "prop-types";
import ProductServicesContainer from "./ProductServicesContainer/index";
import AccountSettingsFormComponent from "./Profile";
import ManageStaff from "routes/private/Settings/ManageStaff/ManageStaff";
// import WhiteLabel from "./WhiteLabel/WhiteLabel";
// import { companyType } from "colors-config";
// import { disable } from "mixpanel-browser";

const Settings = ({
  activeKey,
  onChange,
  onProfileFormSubmit,
  labDetails,
  fileListTermsAndConditions,
  fileListForm,
  mode,
  setMode,
  profileForm,
  productAndServicesForm,
  onProductsFormSubmit,
  productsAndServices,
  productOrServiceCurrencyOptions,
  defaultCurrency,
  lab,
  productList,
  setProductList,
  setProductFormData,
  productFormData,
  whiteLabelForm,
  whiteLabelData,
  onWhiteLabelSubmit,
  loginImageFile,
  setLoginImageFile,
  splashImageFile,
  setSplashImageFile,
  treatmentImageFile,
  setTreatmentImageFile,
  setPrescriptionProductList,
  prescriptionProductList,
}) => {
  const items = [
    {
      key: "1",
      label: "Profile",
      children: (
        <AccountSettingsFormComponent
          onProfileFormSubmit={onProfileFormSubmit}
          labDetails={labDetails}
          fileListTermsAndConditions={fileListTermsAndConditions}
          fileListForm={fileListForm}
          mode={mode}
          setMode={setMode}
          profileForm={profileForm}
        />
      ),
    },
    {
      key: "2",
      label: "Services & Products",
      disabled: !lab?.labName,
      children: (
        <ProductServicesContainer
          mode={mode}
          lab={lab}
          productAndServicesForm={productAndServicesForm}
          setMode={setMode}
          onProductsFormSubmit={onProductsFormSubmit}
          productsAndServices={productsAndServices}
          productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
          defaultCurrency={defaultCurrency}
          productList={productList}
          setProductList={setProductList}
          setProductFormData={setProductFormData}
          productFormData={productFormData}
          prescriptionProductList={prescriptionProductList}
          setPrescriptionProductList={setPrescriptionProductList}
        />
      ),
    },

    {
      key: "3",
      label: "Manage Staff",
      disabled: !lab?.labName,
      children: activeKey === "3" && <ManageStaff />,
    },
    // {
    //   key: "3",
    //   label: "WhiteLabelling",
    //   disabled: !lab?.labName ,
    //   children: (
    //     <WhiteLabel whiteLabelForm={whiteLabelForm} whiteLabelData={whiteLabelData} onWhiteLabelSubmit={onWhiteLabelSubmit} mode={mode}   loginImageFile={loginImageFile}
    //     setLoginImageFile={setLoginImageFile} splashImageFile={splashImageFile} setSplashImageFile={setSplashImageFile}/>
    //   ),
    // }
  ];

  return <Tabs activeKey={activeKey} items={items} onChange={onChange} />;
};

export default Settings;

Settings.propsTypes = {
  activeTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
