import { Table } from "antd";
import EmptyTableDesign from "../EmptyTable/EmptyTable";
import style from "./appTable.module.scss";
const AppTable = ({
  tableData,
  columns,
  onChange,
  onRow,
  styles,
  loading,
  pagination,
  rowSelection,
  rowKey,
  className,
}) => {
  return (
    <div>
      <Table
        className={className}
        style={styles}
        rowKey={rowKey}
        columns={columns}
        dataSource={tableData}
        pagination={pagination}
        onChange={onChange}
        onRow={onRow}
        loading={loading}
        rowSelection={rowSelection}
        locale={{
          emptyText: (
            <div className={style.emptyMargins}>
              <EmptyTableDesign />
            </div>
          ),
        }}
      />
    </div>
  );
};

export default AppTable;
