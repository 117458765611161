import { Row, Tooltip, Modal, Button, Empty } from "antd";
import { useState } from "react";
import DeleteIcon from "../../../../../assets/images/delete_icon_treatment.svg";
import { getFormatDate } from "../utils";
import AppTag from "components/shared/AppTag/AppTag";
import { TAG_STATUS, TREATMENT_STATUS } from "../../../../../constants";

const ModifyCommentModal = ({ modifyComment }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button shape="round" onClick={() => setShowModal(true)}>
        View Modification Request
      </Button>

      <Modal
        title="Modification Request"
        footer={null}
        onCancel={() => setShowModal(false)}
        open={showModal}
      >
        {modifyComment ? modifyComment : <Empty description={"No modification comments"} />}
      </Modal>
    </>
  );
};

const StatusMessageComponent = ({
  patientInfo,
  treatment,
  setShowTreatmentSetupModal,
  setDeleteModalOpen,
  isTreatmentCreationAllowed,
  patientOrders,
  refinementData,
  setRefinementModalOpen,
  patientOrderStatus,
}) => {
  const treatmentStatusAfterApproval = (tp) => {
    if (
      tp.patientApproval &&
      patientInfo.patient_journey_status_name === TREATMENT_STATUS.TREATMENT_COMPLETED
    ) {
      return {
        status: TREATMENT_STATUS.TREATMENT_COMPLETED,
        color: TAG_STATUS.SUCCESS,
      };
    }

    if (
      tp.patientApproval &&
      patientInfo.patient_journey_status_name === TREATMENT_STATUS.TREATMENT_IN_PROGRESS
    ) {
      return {
        status: TREATMENT_STATUS.TREATMENT_IN_PROGRESS,
        color: TAG_STATUS.DEFAULT,
      };
    }
    if (tp.patientApproval) {
      return {
        status: TREATMENT_STATUS.APPROVED_FOR_PRINTING,
        color: TAG_STATUS.SUCCESS,
      };
    }

    if (tp.sendToPatient) {
      return {
        status: TREATMENT_STATUS.AWAITING_CONFIRMATION,
        color: TAG_STATUS.DEFAULT,
      };
    }

    if (refinementData || treatment.revision_plan_version) {
      return {
        status: TREATMENT_STATUS.REFINEMENT_APPROVED,
        color: TAG_STATUS.APPROVED,
      };
    }

    return {
      status: TREATMENT_STATUS.APPROVED,
      color: TAG_STATUS.APPROVED,
    };
  };

  if (patientInfo?.treatment_plan_status_id == 13) {
    return <AppTag text={TREATMENT_STATUS.CANCELLED} color={TAG_STATUS.ERROR} />;
  }
  if (treatment.approval_status === "rejected" || treatment.approval_status === "pending") {
    return (
      <Row style={{ display: "flex", flexWrap: "wrap" }} justify="space-between" align="middle">
        {treatment.approval_status === "rejected" && (
          <>
            <div style={{ marginRight: "10px" }}>
              <ModifyCommentModal modifyComment={treatment?.modifyComment || ""} />
            </div>
          </>
        )}

        {treatment.approval_status === "pending" && !treatment?.revision_plan_version && (
          <AppTag text={TREATMENT_STATUS.SUBMITTED} color={TAG_STATUS.DEFAULT} />
        )}
        {treatment.approval_status === "pending" && treatment?.revision_plan_version && (
          <AppTag text={TREATMENT_STATUS.REFINEMENT_SUBMITTED} color={TAG_STATUS.DEFAULT} />
        )}

        <span style={{ margin: "0 6px", fontSize: "12px" }}>
          {getFormatDate(
            treatment?.approval_status === "rejected"
              ? treatment?.modify_date
              : treatment?.date_created
          )}
        </span>

        <Tooltip placement="right" title={"Decline Case"}>
          <div className="status-massage-tooltip" onClick={() => setDeleteModalOpen(true)}>
            <img src={DeleteIcon} alt="Delete Icon" />
          </div>
        </Tooltip>
      </Row>
    );
  } else if (
    // treatment?.approval_status === "approved" ||
    treatment?.approved
  ) {
    return (
      <div className="status-massage-container">
        {refinementData &&
        patientOrderStatus?.status_name != "Approved for Printing" &&
        patientOrderStatus?.status_name != "Printing in Progress" &&
        patientOrderStatus?.status_name != "Awaiting confirmation" &&
        patientOrderStatus?.status_name != "Shipped" ? (
          <>
            <AppTag text={TREATMENT_STATUS.REFINEMENT_REQUESTED} color={TAG_STATUS.ERROR} />
            {/* <GenericButton
            onClick={() => setRefinementModalOpen(true)}
            type="default"
            customStyle={{
              width: "11.5rem",
              marginRight: "10px",
              borderColor: primaryColor,
              color: primaryColor,
            }}
          >
            View Refinement Details
          </GenericButton> */}
          </>
        ) : (
          <AppTag
            text={treatmentStatusAfterApproval(treatment).status}
            color={treatmentStatusAfterApproval(treatment).color}
          />
        )}

        {/* {(!patientOrders.length || refinementData) ?
          <div
            style={{
              marginLeft: "auto",
              paddingRight: "5px",
            }}
          >
            <GenericButton
              onClick={() => setShowTreatmentSetupModal(true)}
              type="primary"
              disabled={!isTreatmentCreationAllowed}
            >
              Update Treatment
            </GenericButton>
          </div> : ""} */}
        <span style={{ marginLeft: "6px" }}>{getFormatDate(treatment?.date_created)}</span>
        <Tooltip placement="right" title={"Decline Case"}>
          <div className="delete-tooltip" onClick={() => setDeleteModalOpen(true)}>
            <img src={DeleteIcon} alt="Delete Icon" />
          </div>
        </Tooltip>
      </div>
    );
  } else return null;
};

export default StatusMessageComponent;
