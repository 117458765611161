import PropTypes from "prop-types";
import { Button } from "antd";
import moment from "moment";
import { ReactComponent as EditIcon } from "assets/images/edit_icon.svg";
import { primaryColor } from "colors-config";
import styles from "./print.module.scss"


const PrintStage = ({ orderDetails, setShowPrintingConfirmationModal, setShowEditDispatchModal }) => {
  const handleButtonClick = () => {
    setShowPrintingConfirmationModal(true);
  };

  const handleEditButtonClick = () => {
    setShowEditDispatchModal(true);
  };

  const formatDate = (date) => moment(date).format("MMM Do YYYY")

  const renderPrintingInfo = (text, date) => (
    <div>
      <p>{text}</p>
      <p  className={styles["print-date"]}>{date ? formatDate(date) : "--"}</p>
    </div>
  );

  if (orderDetails?.order_delivery_status_id === 6) {
    return (
      <Button onClick={handleButtonClick} type="primary" shape="round">
        Start Printing
      </Button>
    );
  }

  if (orderDetails?.order_delivery_status_id === 7) {
    return (
      <div  className={styles["print-info-status"]}>
        {renderPrintingInfo(
          "Printing started on",
          orderDetails?.progress_started_date
        )}

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {renderPrintingInfo("Est. Dispatch Date", orderDetails?.est_date)}
          <EditIcon
            onClick={handleEditButtonClick}
            style={{
              width: "12px",
              marginTop: "2px",
              fill: primaryColor,
              cursor: "pointer",
            }}
          />
        </div>

        <Button shape="round" onClick={handleButtonClick}>
          Pause Printing
        </Button>
      </div>
    );
  }

  if (orderDetails?.order_delivery_status_id === 9) {
    return (
      <div className={styles["print-info-status"]}>
        {renderPrintingInfo(
          "Printing paused on",
          orderDetails?.progress_started_date
        )}
      <div style={{ display: "flex", alignItems: "flex-end" }}>
          {renderPrintingInfo("Est. Dispatch Date", orderDetails?.est_date)}
          <EditIcon
            onClick={handleEditButtonClick}
            style={{
              width: "12px",
              marginTop: "2px",
              fill: primaryColor,
              cursor: "pointer",
            }}
          />
        </div>        
        <Button onClick={handleButtonClick} shape="round">
          Continue Printing
        </Button>
      </div>
    );
  }

  if (
    orderDetails?.order_delivery_status_id !== 7 &&
    orderDetails?.order_delivery_status_id !== 9 &&
    orderDetails?.progress_started_date
  ) {
    return (
      <div className={styles["print-info-status"]}>
        {renderPrintingInfo(
          "Printing started on",
          orderDetails?.progress_started_date
        )}
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {renderPrintingInfo("Est. Dispatch Date", orderDetails?.est_date)}
        </div>
      </div>
    );
  }

  return null;
};

PrintStage.propTypes = {
  orderDetails: PropTypes.shape({
    order_delivery_status_id: PropTypes.number.isRequired,
    progress_started_date: PropTypes.string,
    paused_printing_date: PropTypes.string,
    estDate: PropTypes.string,
  }).isRequired,
  setShowPrintingConfirmationModal: PropTypes.func.isRequired,
};

export default PrintStage;
