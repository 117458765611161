import { Form, Checkbox, Button } from "antd";
import { primaryColor } from "colors-config";

const Footer = ({
  dataAcquisitionURL,
  termsAndConditionsURL,
  disabled
}) => {
  return (
    <div className="formFooter">
      <div className="formRowOne">
        <Form.Item
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Should accept terms and conditions")
                    ),
            },
          ]}
          name="terms_conditions"
        >
          <Checkbox disabled={disabled}>
            <span>
              Lab&nbsp;
              <a href={termsAndConditionsURL} style={{color:primaryColor}} target="_blank" rel="noreferrer">
                Terms and Conditions
              </a>
            </span>
          </Checkbox>
        </Form.Item>

        <Form.Item
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Should accept agreement")),
            },
          ]}
          name="data_consent"
          valuePropName="checked"
        >
          <Checkbox disabled={disabled}>
            <span>
              Lab declaration of&nbsp;
              <a href={dataAcquisitionURL} style={{color:primaryColor}} target="_blank" rel="noreferrer">
                Consent Data Acquisition and Processing
              </a>
            </span>
          </Checkbox>
        </Form.Item>
      </div>
      {/* <Form.Item>
        <div className="buttons-row">
          <Button
           shape="round"
          // className="cancel-btn"
          disabled={disabled}
            // onClick={() => {
            //   if (editMode !== INITIAL) {
            //     setEditMode(SUBMITTED);
            //   }
            // }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit"  disabled={disabled} shape="round" style={{marginLeft:"12px"}}>
            Submit
          </Button>
        </div>
      </Form.Item> */}
    </div>
  );
};

export default Footer;
