import { Select, Form } from "antd";
import { useSelector } from "react-redux";
import { getPatientCountryCodesList } from "services/redux/selectors/common.js";

const CountryCodeSelect = ({ disabled, size, name }) => {
  const patientCountriesList = useSelector(getPatientCountryCodesList);
  return (
    <Form.Item name={name} noStyle>
      <Select
        disabled={disabled}
        //   dropdownClassName="country-dropdown"
        //   className="country-select"
        size={size}
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        labelRender={(props) => {
          return props.label;
        }}
        options={patientCountriesList?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={(option) => {
          const { data } = option;
          return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );
};

export default CountryCodeSelect;
