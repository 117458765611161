import { companyType, defaultAppCurrency } from "colors-config";
import dayjs from "dayjs";
export const productsServiceMapper = (labProducts) => {
  return {
    productsServices: labProducts?.map((el) => {
      const product = {
        productName: el.productName,
        currencyId: el.currencyId,
        price: el.price,
        id: el.id,
        quantityType: el.quantityType,
        arches: el.arches,
        free: el.free,
      };
      return product;
    }),
  };
};

const getSmileAlignInitialProduct = () => {
  return [
    {
      productName: null,
      price: "",
      currencyId: defaultAppCurrency,
      arches: "Upper and Lower",
      quantityType: "Package",
    },
  ];
};

export const productServicesFormInitialValues = (refinementSeries = 1) => {
  let initialArray = [];
  if (companyType === 5) {
    initialArray = getSmileAlignInitialProduct();
  }
  return {
    productsServices: initialArray,
    prescriptionProducts: [
      {
        productName: "Upper and Lower",
      },
    ],
  };
};


export const removeDuplicates = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const checkTextEditorContent = (content) => {
  const tempContainer = document.createElement("div");
  tempContainer.innerHTML = content;
  const innerText = tempContainer.innerText;
  return innerText ? true : false;
};


export const getProfileButtonText = (isFirstLogin, activeTab) =>{
  let text = "Save"
  if (isFirstLogin && activeTab === "1") {
    text = "Save and Next"    
  }

  return text

}


export const passwordValidator = (_, value) => {
  if (value.length < 8) {
    return Promise.reject("Password must be at least 8 characters long");
  }
  if (!/(?=.*[a-z])/.test(value)) {
    return Promise.reject("At least 1 lowercase alphabetical character is required");
  }
  if (!/(?=.*[A-Z])/.test(value)) {
    return Promise.reject("At least 1 uppercase alphabetical character is required");
  }
  if (!/(?=.*[0-9])/.test(value)) {
    return Promise.reject("At least 1 numeric character is required");
  }
  if (!/(?=.*[!@#$%^&*()\-_=+.])/.test(value)) {
    return Promise.reject("At least 1 special character is required (! @ # $ % ^ & * ( ) - _ = + .)");
  }
  if (/[^!@#$%^&*()\-_=+a-zA-Z0-9.]/.test(value)) {
    return Promise.reject(
      "A special character that you may have entered is unsupported, please use one or more from the following list ! @ # $ % ^ & * ( ) - _ = + ."
    );
  }
  return Promise.resolve();
};


export const makeClinicAddress = (clinicInfo) => {
  const address = `${clinicInfo?.address || ""}${clinicInfo?.address && clinicInfo?.city ? "," : ""
    } ${clinicInfo?.city || ""}${clinicInfo?.county && clinicInfo?.city ? "," : ""
    } ${clinicInfo?.county || ""}${clinicInfo?.county && clinicInfo?.zipCode ? "," : ""
    } ${clinicInfo?.zipCode || ""}${clinicInfo?.country && clinicInfo?.zipCode ? "," : ""
    } ${clinicInfo?.country || ""}`;
  if (address === "" || !address.replace(/\s/g, "").length) return "-";

  return address;
};


export const getUniquePrescriptionProducts = (products) =>{
  const normalizeName = (name) => name.trim().toLowerCase();

  const treatmentNames = products.map(p => normalizeName(p.treatmentName));
  const uniqueTreatmentNames = new Set(treatmentNames);

  const allUnique = treatmentNames.length === uniqueTreatmentNames.size;

  return allUnique;
}


export const mapDashboardData = (data) => {
  const patientStageData = data.patientStageData;
  const patientStageArray = Object.entries(patientStageData);
  const keyMapping = {
    totalPatients: "Total Patients",
    treatmentSetupRequested: "Treatment Setup Requested",
    treatmentSetupSubmitted: "Treatment Plan Submitted",
    shipped: "Shipped",
    approvedForPrinting: "Approved for Printing",
    awaitingConfirmation: "Awaiting Confirmation",
    delivered: "Delivered",
    refinementRequested: "Refinement Setup Requested",
    printingInProgress: "Printing in Progress",
    refinementSubmitted: "Refinement Setup Submitted",
  };

  const urlMapping = {
    totalPatients:"/lab/patients?status=totalPatients",
    shipped: "/lab/patients?status=shipped",
    treatmentSetupRequested: "/lab/patients?status=treatmentSetupRequested",
    treatmentSetupSubmitted: "/lab/patients?status=treatmentSetupSubmitted",
    approvedForPrinting: "/lab/patients?status=approvedForPrinting",
    awaitingConfirmation: "/lab/patients?status=awaitingConfirmation",
    delivered: "/lab/patients?status=delivered",
    refinementRequested: "/lab/patients?status=refinementRequested",
    printingInProgress: "/lab/patients?status=printingInProgress",
    refinementSubmitted: "/lab/patients?status=refinementSubmitted"
  };

  const highestValue = Math.max(...Object.values(patientStageData));
  const mappedPatientStageArray = patientStageArray.map(([key, value]) => {
    const mappedKey = keyMapping[key];
    const percentage = Math.round((value / highestValue) * 100);
    const url = urlMapping[key] || '';
    return { key: mappedKey, value, percentage:  percentage > 0 ? percentage :  1, url: url};
  });
  mappedPatientStageArray.sort((a, b) => b.value - a.value);
  mappedPatientStageArray.forEach((item, index) => {
    const opacity = 1 - index * 0.1;

    item.bg = opacity > 0.3 ? opacity : 0.2;
  });

  const dashboardData = {
    ...data,
    patientStageArray: mappedPatientStageArray,
  };

  return dashboardData;
};


export const getOrderStatusFilter = (statusParams) => {
  let filter = []
  if (!statusParams) {
    return []
  }

//   [
//     "Approved for Printing",
//     "Awaiting confirmation",
//     "Delivered",
//     "Printing in Progress",
//     "Printing Paused",
//     "Refinement Plan Submitted",
//     "Refinement Requested",
//     "Shipped",
//     "TP Cancelled",
//     "TP Modification Requested",
//     "TP Setup Requested",
//     "TP Setup Submitted"
// ]

  if (statusParams === "new-treatment" || statusParams === "treatmentSetupRequested") {
    filter = "TP Setup Requested";
  } else if (statusParams == "modify-treatment" ) {
    filter = "TP Modification Requested";
  } else if (statusParams == "appliance-production" || statusParams === "approvedForPrinting") {
    filter = "Approved for Printing";
  } else if (statusParams === "shipped") {
    filter = "Shipped";
  }
  else if (statusParams === "treatmentSetupSubmitted") {
    filter = "TP Setup Submitted";
  }
  else if (statusParams === "awaitingConfirmation") {
    filter = "Awaiting confirmation";
  }
  else if (statusParams === "delivered") {
    filter = "Delivered";
  }
  else if (statusParams === "printingInProgress") {
    filter = "Printing in Progress";
  }
  else if (statusParams === "refinementSubmitted") {
    filter = "Refinement Setup Submitted"; 
  }
  else if (statusParams === "refinementRequested") {
    filter = "Refinement Setup Requested";
  }
  else {
    filter = []
  }
 

  return filter.length ? [filter] :""
};


export const getDurationStatusFilter = (statusParams) => {
  let filter = "";
  if (!statusParams) {
    return "";
  }
  if (statusParams === "early") {
    filter = "early";
  } else if (statusParams === "ontime") {
    filter = "ontime";
  } else if (statusParams === "due") {
    filter = "due";
  }  
 else if (statusParams === "late") {
    filter = "late";
  }
  else {
    filter = ""
  }

  return filter;
};

export const removeTrailingEmptyTagsFromString = (htmlString) => {
  // Parse the HTML string to a document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Function to check if an element is empty
  const isEmptyElement = (element) => {
    return (
      element.innerHTML.trim() === '' ||
      [...element.childNodes].every(
        (child) => child.nodeName === 'BR' || (child.nodeType === Node.TEXT_NODE && !child.nodeValue.trim())
      )
    );
  };

  // Function to remove trailing empty elements
  const removeTrailingEmptyTags = (parent) => {
    const children = [...parent.children];
    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      if (isEmptyElement(child)) {
        parent.removeChild(child);
      } else {
        break;
      }
    }
  };

  // Remove trailing empty tags from the body
  removeTrailingEmptyTags(doc.body);

  // Serialize the document back to a string
  return doc.body.innerHTML;
};

export const getStartDateFilter = (statusParams) => {
  let filter = "";
  let storedDates = localStorage.getItem("dashboardFilter");
  if (storedDates && statusParams) {
    storedDates = JSON.parse(storedDates).map((date) => dayjs(date))
    filter = dayjs(storedDates[0]).format("YYYY-MM-DD");
  }
  return filter;
};


export const getEndDateFilter = (statusParams) => {
  let filter = "";
  let storedDates = localStorage.getItem("dashboardFilter");
  if (storedDates && statusParams) {
    storedDates = JSON.parse(storedDates).map((date) => dayjs(date))
    filter = dayjs(storedDates[1]).format("YYYY-MM-DD");
  }
  return filter;
};


export const formatDateRange = (startDate, endDate) => {
  if (startDate && endDate) {
    return `${dayjs(startDate).format("DD MMMM, YYYY")} - ${dayjs(endDate).format("DD MMMM, YYYY")}`;
  } else {
    return "";
  }
};

export const getScanClass = () => {
  let name = "scanName";
  if (companyType === 5) {
    name += " scanNameSmilelign";
  }
  return name;
};

export const stlFilesOrder = (file) => {
  switch (file.name.toLowerCase()) {
    case "upperarch.stl":
      return 1;
    case "lowerarch.stl":
      return 2;
    case "bitescan1.stl":
      return 3;
    case "bitescan2.stl":
      return 4;
    default:
      return 5;
  }
};


export const getSTLUrl = (file, fileUrl) => {
  const withOutAccessKeyImg = fileUrl.indexOf("?");
  const processedURl = fileUrl.slice(0, withOutAccessKeyImg);
  return processedURl;
};


export const showPhasingDetails = (phasing = "", show = true) => {
  if (companyType !== 5) {
    return "--";
  }
  const value = phasing?.trim() || "--";
  return (
    <div style={{ textAlign: "left" }}>
     {show && <p style={{ marginBottom: 0 }} className="patient-question">Phasing</p>} 
      <p style={{ marginBottom: 0, marginTop:"5px" }}>{value}</p>
    </div>
  );
};

export function getOrdinal(number) {
  let num = number
  if (typeof number !== 'number') {
   num = +number
  }
  const lastDigit = num % 10;
  const secondLastDigit = Math.floor((num % 100) / 10);
  if (lastDigit === 1 && secondLastDigit !== 1) {
    return `${num}st `;
  } else if (lastDigit === 2 && secondLastDigit !== 1) {
    return `${num}nd `;
  } else if (lastDigit === 3 && secondLastDigit !== 1) {
    return `${num}rd `;
  } else {
    return `${num}th `;
  }
}

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  return (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);
}