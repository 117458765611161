import api from "./api";

export const saveTreatmentDraftService = (data) => {
  return api({
    method: "POST",
    url: `/lab/treatment-draft/save`,
    data: data,
  });
};

export const getTreatmentDraftService = (patientId) => {
  return api({
    method: "GET",
    url: `/lab/treatment-draft/get/${patientId}`,
  });
};

export const deleteTreatmentDraftService = (data) => {
  return api({
    method: "DELETE",
    url: `/lab/treatment-draft/delete`,
    data,
  });
};

export const getTreatmentPlanPrice = (patientId) => {
  return api({
    method: "GET",
    url: `/patient/treatmentSetup/price?patientId=${patientId}`,
  }).then((res) => res.data);
};

export const patientClinicUpdateStatus = (data) => {
  return api({
    method: "PUT",
    url: `/clinic/patientlist/treatmentSetup/updateStatus`,
    data,
  }).then((res) => res.data);
};

export const refinementAdditionalDetailsService = (data) => {
  return api({
    method: "POST",
    url: "/lab/refinement/additional-details",
    data,
  }).then((res) => res.data);
};

export const uploadRefinementFileService = (data) => {
  return api({
    method: "POST",
    url: "/lab/refinement/file/upload",
    data: data,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};


/**
 * Updates the status of an aligner order for a patient.
 *
 * @function startTreatmentApi
 * @param {Object} data - The data object containing the order ID and other necessary information.
 * @param {string} data.orderId - The unique identifier of the patient's order that will have its status updated.
 * 
 * @returns {Promise} - Returns a promise that resolves with the API response if the request is successful,
 *                      or rejects if there's an error.
 *
 */

export const startTreatmentApi = (data) => {
  return api({
    method: "PUT",
    url: `/patient/updateAlignerOrderStatus`,
    data: data,
  });
};


export const startRevisionTreatmentApi = (data) => {
  return api({
    method: "PUT",
    url: `/patient/startRevisionTreatment`,
    data: data,
  });
};
