import React, { useState, useRef, useEffect } from "react";
import {
  Upload,
  message,
  Input,
  Button,
  Row,
  Typography,
  Tooltip,
  Select,
} from "antd";
import {
  InboxOutlined,
  LinkOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { labApiTasks } from "../../../models/lab";
import axios from "axios";

import "../../../assets/styles/components/patientScanUploadsComponent.scss";

const { Dragger } = Upload;
const { Title } = Typography;
const { Option } = Select;

const UploadFilesComponent = ({
  setFileList,
  lab,
  patientId,
  //
  fileList,
  mode,
}) => {
  const [uploadFileList, setUploadFileList] = useState(fileList);
  const [uploadFileDataObject, setUploadFileDataObject] = useState({});
  const [selectedScanType, setSelectedScanType] = useState("BiteScan");

  const inputEl = useRef(null);
  const typeInputEl = useRef(null);

  const uploadEl = useRef(null);

  async function uploadFiles(options, linkData = null) {
    // setIsMultiStepButtonDisabled(true);

    if (linkData) {
      console.log(linkData);
      const linkPreSignRequestBody = {
        // clinic_id: lab.clinicId,
        // file_name: linkData.url,
        // resource_type: linkData.type,
        // file_type: linkData.type,

        patient_id: patientId,
        file_url: linkData.url,
        patient_upload_stage: "scan",
        resource_type: "url",
        scan_type: linkData.scanType,
      };

      try {
        const linkPreSignedResponse = await labApiTasks.post(
          "lab/patient/resource/upload/confirm",
          linkPreSignRequestBody
        );

        setUploadFileList([...uploadFileList, linkData]);
        setUploadFileDataObject({
          ...uploadFileDataObject,
          [linkData.uid]: { name: linkData.url, fileUrl: linkData.url },
        });
        // setIsMultiStepButtonDisabled(false);
      } catch (error) {
        console.error(error);
        if (error.response.data?.err?.msg) {
          message.error(error.response.data.err.msg);
        } else {
          message.error("Something went wrong. Please try again.");
        }
        // setIsMultiStepButtonDisabled(false);
      }
    }
    //  else {
    //   const { onSuccess, onError, file, onProgress } = options;
    //   const fileNameArray = file.name.split(".");
    //   fileNameArray[0] = fileNameArray[0] + "-" + new Date().valueOf();
    //   const fileName = fileNameArray.join(".");

    //   const fmData = new FormData();
    //   const config = {
    //     headers: {
    //       "content-type": file.type,
    //     },

    //     onUploadProgress: (event) => {
    //       const percent = Math.floor((event.loaded / event.total) * 100);

    //       if (percent === 100) {
    //       }
    //       onProgress({ percent: (event.loaded / event.total) * 100 });
    //     },
    //   };
    //   fmData.append("file", file);

    //   try {
    //     const preSignRequestBody = {
    //       clinic_id: lab.clinicId,
    //       file_name: fileName,
    //       resource_type: "file",
    //       file_type:
    //         file.type.split("/")[0] === "application" ? "pdf" : "image",
    //     };
    //     const preSignedResponse = await labApiTasks.post(
    //       "clinic/resource/add",
    //       preSignRequestBody
    //     );

    //     const accessUrl = preSignedResponse.body.success.accessUrl;
    //     const uploadUrl = preSignedResponse.body.success.uploadUrl;
    //     const uploadResponse = await axios.put(uploadUrl, file, config);
    //     const successFullUpload = await labApiTasks.post(
    //       "resource/upload/confirm",
    //       {
    //         clinic_id: lab.clinicId,
    //         file_url: accessUrl,
    //         file_name: fileName,
    //         file_type:
    //           file.type.split("/")[0] === "application" ? "pdf" : "image",
    //       }
    //     );

    //     setUploadFileDataObject({
    //       ...uploadFileDataObject,
    //       [file.uid]: { name: fileName, fileUrl: accessUrl },
    //     });
    //     onSuccess("Ok");

    //     // setIsMultiStepButtonDisabled(false);
    //   } catch (err) {
    //     console.log(err);

    //     onError({ err });
    //     // setIsMultiStepButtonDisabled(false);
    //   }
    // }
  }

  const props = {
    name: "file",
    multiple: false,

    customRequest: uploadFiles,

    onChange(info) {
      let fileList = [...info.fileList];

      setUploadFileList([...info.fileList]);
      setFileList([...info.fileList]);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const addLink = () => {
    const url = inputEl.current.state.value;
    console.log(typeInputEl);
    const scanType = selectedScanType;
    const linkFile = {
      name: (
        <p>
          Type:{scanType} - URL:{url}
        </p>
      ),
      status: "done",
      type: "url",
      scanType,

      url,
    };
    uploadFiles({}, linkFile);

    inputEl.current.state.value = "";
  };

  const onFileRemove = async (file) => {
    const fileId = file.uid;
    const resourceDeleteBody = {
      clinic_id: lab.clinicId,
      file_url: uploadFileDataObject[fileId].fileUrl,
    };

    try {
      const res = await labApiTasks.post(
        "clinic/resource/delete",
        resourceDeleteBody
      );
    } catch (error) {
      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  };

  const viewFile = async (file) => {
    if (file.type === "url") {
      window.open(file.url, "_blank");
    } else {
      try {
        const urlLinkRes = await labApiTasks.post("clinic/resource/get", {
          clinic_id: lab.clinicId,
          file_name: file.name,
        });
        const url = urlLinkRes.body.url.url;
        window.open(url, "_blank");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div
      className="upload-file-scan-container"
      style={{
        backgroundColor: "white",
        borderRadius: "6px",
        paddingBottom: "2rem",
      }}
    >
      <Row></Row>
      <Row justify="space-between">
        <p className="dragger-scan-title">Scan Type</p>
        <p className="url-scan-title">Add Link</p>
      </Row>

      <Input
        ref={inputEl}
        disabled={mode === "view"}
        style={{
          width: "40%",
          display: "inline-block",
          float: "right",

          margin: "0.5rem 2rem 2rem 2rem",
        }}
        placeholder="Paste link here"
        addonAfter={
          <Button
            disabled={mode === "view"}
            type="primary"
            style={{ width: "4.5rem", borderRadius: "50px" }}
            onClick={addLink}
          >
            Add
          </Button>
        }
      />
      {/* <Input
        ref={typeInputEl}
        disabled={mode === "view"}
        style={{
          width: "40%",
          display: "inline-block",
          float: "left",

          margin: "0.5rem 2rem 2rem 2rem",
        }}
        placeholder="Enter Type of scan"
      
      /> */}

      <Select
        defaultValue={selectedScanType}
        ref={typeInputEl}
        onChange={(val) => setSelectedScanType(val)}
        style={{
          width: "40%",
          display: "inline-block",
          float: "left",

          margin: "0.5rem 2rem 2rem 2rem",
        }}
      >
        <Option key={"BiteScan"} value={"BiteScan"}>
          Bite Scan
        </Option>
        <Option key={"UpperArch"} value={"UpperArch"}>
          Upper Arch
        </Option>
        <Option key={"LowerArch"} value={"LowerArch"}>
          Lower Arch
        </Option>
      </Select>

      <Dragger
        disabled={mode === "view"}
        ref={uploadEl}
        onPreview={(file) => viewFile(file)}
        listType="picture"
        iconRender={(file) => {
          if (file.type === "url") return <LinkOutlined />;
        }}
        style={{
          width: "40%",

          display: "none",
          float: "left",
          margin: " 0.5rem 2rem 2rem 2rem",
        }}
        fileList={uploadFileList}
        onRemove={onFileRemove}
        {...props}
      >
        <div style={{ display: "inline-block" }}>
          <InboxOutlined style={{ fontSize: "1rem" }} />

          <p
            style={{
              display: "inline-block",
              marginLeft: "0.5rem",
            }}
          >
            Click or drag file to this area to upload
          </p>
          <div style={{ clear: "both" }}></div>
        </div>
      </Dragger>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(UploadFilesComponent);
