import React, { useEffect, useState } from "react";

import { Row, Col } from "antd";
import PasswordHandleComponent from "../../components/shared/passwordHandleComponent";

import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { message } from "antd";
import { headerLogo } from "colors-config";

export default function ForgotPassword() {
  const history = useHistory();
  const location = useLocation();
  const [forgetPasswordCode, setForgetPasswordCode] = useState("");

  const [email, setEmail] = useState(location?.state?.message || "");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const userEmail = queryParams.get("email");

    if (userEmail) {
      setEmail(userEmail);
    }

    setForgetPasswordCode(code);
  }, [location.search]);

  useEffect(() => {
    document.body.style = "background: #f3f6fa !important;";

  }, []);

  const forgotPassword = (otp, newPassword) => {
    Auth.forgotPasswordSubmit(email, otp, newPassword)
      .then((data) => {
        console.log(data);
        history.push("/login");
        message.success("Password successfully changed");
      })
      .catch((err) => message.error(err.message));
    console.log(email);
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          height: "4.7rem",
          position: "relative",

          boxShadow: "3px 6px 8px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Row justify="start">
          <Col
            xxl={{ span: 6, offset: 1 }}
            xl={{ span: 6, offset: 1 }}
            lg={{ span: 6, offset: 1 }}
            md={24}
          >
            <Row justify="start">
              <img
                style={{
                  width: "160px",
                  height: "65px",
                  objectFit: "contain",
                  marginRight: "2.1rem",
                  marginTop: "5px",
                }}
                src={headerLogo}
                alt="logo"
              />
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ width: "75rem", margin: "10rem " }}>
        <PasswordHandleComponent
          forgetPasswordCode={forgetPasswordCode}
          forgotPassword={forgotPassword}
        ></PasswordHandleComponent>
      </div>
    </div>
  );
}
