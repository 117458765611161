
import React, { useState, useEffect, useRef } from "react";
import { Table, Radio, Row, Col, Typography, Modal, Tooltip, Dropdown, Menu, message, Spin, Form, notification, Button } from "antd";
import { ArrowLeftOutlined, MoreOutlined, DeleteOutlined, UserAddOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import moment from "moment";
import { Empty } from "antd";
import ClinicNameFilter from "components/private/ClinicNameFilter/ClinicNameFilter";
import { ShowTotalNumber } from "components/shared/ShowTotalNumber/ShowTotalNumber";
import AppButton from "components/shared/AppButton/AppButton";
import { columnTitleElement } from "utils/commonMethod";
import { useHistory } from "react-router-dom";
import { getArchivePatientList } from "api/lab";
import { connect } from "react-redux";
import FilterDataRangePicker from "components/shared/filterDataRangePicker";
import { unArchivePatient } from "api/lab";
import { companyType, primaryColor } from "colors-config";
import PatientPrescriptionForm from "components/private/PrescriptionModal/PatientPrescriptionForm";
import ViewScanFilesComponent from "components/private/PrescriptionModal/components/ViewScanFilesComponent/ViewScanFilesComponent";
import { labApiTasks } from "models/lab";
import { savePrescriptionAPI } from "api/patient";
import { handleScanFilesModal, prescriptionFilesUploader } from "utils/prescription";
import { SCAN_REQUIRED_MESSAGE } from "messages/messageController";
import ConfirmationModal from "components/shared/modals/confirmationModal";

const { Title } = Typography;
const { confirm } = Modal;

const ArchivedPatients = ({ lab }) => {
    const [paginationObj, setPaginationObj] = useState({});
    const [tableData, setTableData] = useState([]);
    const [patientsFilterData, setPatientsFilterData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paginationPage, setPaginationPage] = useState(1);
    const [paginationLimit, setPaginationLimit] = useState(50);
    const [isPatientTableSpinning, setIsPatientTableSpinning] = useState(false)
    const [clinicNamesFilter, setClinicNamesFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    const [unArchiveModal, setUnArchiveModal] = useState(false);
    const [patientDetails, setPatientDetails] = useState(null);
    const [unArchiveType, setUnArchiveType] = useState('resumecase');
    const [showPrescription, setShowPrescription] = useState(false);
    const [record, setRecord] = useState(null)
    const [clinicalFilesArray, setClinicalFilesArray] = useState([])
    const [scanUrls, setScanUrls] = useState([])
    const [clinicalFiles, setClinicalFiles] = useState([]);
    const [prescriptionForm] = Form.useForm();
    const teethChartRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [scanMessage, setScanMessage] = useState(SCAN_REQUIRED_MESSAGE);
    const [prescriptionConfirmation, setPrescriptionConfirmation] = useState(false);

    const radioLabel = (boldText, description) => {
        return (
            <div style={{ paddingTop: '50px' }}>
                <p style={{ marginBottom: 0 }}><b>{boldText}</b></p>
                <p>{description}</p>
            </div>
        )
    }

    const radioOptions = [
        {
            label: radioLabel('Start New Case +', '(Previous case will be closed by starting a new case)'),
            value: 'newcase',
        },
        {
            label: radioLabel('Resume Case', '(Previous case will be continue from where it was archived.)'),
            value: 'resumecase',
            selected: true
        }
    ]

    useEffect(() => {
        if (lab?.labId) {
            _getArchiveList(lab?.labId);
        }
    }, [startDateFilter, endDateFilter, clinicNamesFilter, lab.labId])

    useEffect(() => {
        const obj = {
            current: paginationPage,
            pageSize: paginationLimit,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 50],
            total: totalRecords,
        };

        setPaginationObj(obj);
    }, [paginationPage, paginationLimit, totalRecords]);

    const handleTableChange = (pagination, filters, sorter) => {
        setPaginationPage(pagination.current);
        setPaginationLimit(pagination.pageSize);
        if (filters && filters.clinicName?.length > 0) {
            setClinicNamesFilter(filters.clinicName.toString());
        }
        else setClinicNamesFilter("");
    };

    const getScanFiles = async (patientId) => {
        // setIsProgressImagesSpinning(true);
        try {
            const scanRes = await labApiTasks.getAll(
                "lab/patient/resource/getOnboardingImages",
                { patientId, patientStage: "scan" }
            );

            setScanUrls(scanRes.body.results.patient_onboarding_images);
        } catch (error) {
            //   setIsProgressImagesSpinning(false);

            console.log(error);
        }
    };

    const prescriptionDataMapper = (
        values,
        teethChartData,
        patientId,
        clinicId
    ) => {
        return {
            ...values,
            teethChart: teethChartData,
            patientId,
            clinicId,
            notify: false
        };
    };

    const onPrescriptionSubmit = async (values) => {
        try {
            setLoading(true);
            const patient_Id = record?.patientID;

            const prescriptionPayload = prescriptionDataMapper(
                values,
                teethChartRef.current,
                patient_Id,
                record?.clinicId
            );

            const { data } = await savePrescriptionAPI(prescriptionPayload);

            await prescriptionFilesUploader(clinicalFilesArray, patient_Id);

            await getScanFiles(patient_Id);
            notification.success({
                duration: 6,
                message: "Prescription Added",
                description: <a>View Profile</a>,
                className: "patient-created",
                onClick: () => {
                    history.push("/lab/patient/" + patient_Id);
                    window.location.reload();
                },
            });
        } catch (error) {
            console.log("error", error);
        } finally {
            setLoading(false);
            setShowPrescription(false);
            setClinicalFilesArray([]);
        }
    };

    const prescriptionSubmit = () => {
        const formValues = prescriptionForm.getFieldsValue();
        let arches = {};
        if (formValues.treatmentTypes && formValues.treatmentTypes[0]) {
            arches = formValues.treatmentTypes[0].arches || {};
        }
        if (companyType === 5) {
            arches = formValues.smilelignArches || {};
        }
        const res = handleScanFilesModal(clinicalFilesArray, scanUrls, arches)
        setScanMessage(res.message)
        if (res.test) {
            setPrescriptionConfirmation(true)
        }
        else {
            prescriptionForm.submit();
        }


        // prescriptionForm.submit()
    }

    const _getArchiveList = async (labId) => {
        setIsPatientTableSpinning(true);
        const params = {
            limit: 1000,
            page: 1,
            labId: labId ? labId : lab.labId,
            clinicName: clinicNamesFilter !== "" ? `"${clinicNamesFilter}"` : "",
            startDate: startDateFilter,
            endDate: endDateFilter,
        }

        try {

            const resData = await getArchivePatientList(params);
            const list = resData?.data?.body?.data || [];
            if (list.length > 0) {
                const dataList = list.map((dataObj, index) => {
                    return {
                        key: index,
                        dateCreated: dataObj.patientCreated
                            ? moment(dataObj.patientCreated).format("DD/MM/YYYY")
                            : "Not Available",
                        patientDetails: {
                            patientID: dataObj.patient_id,
                            patientName: dataObj?.patient_first_name + " " + dataObj?.patient_last_name,
                        },
                        clinicianDetails: {
                            clinicName: dataObj.clinic_name ? dataObj.clinic_name : "Not Available",
                            clinicLabel: dataObj.clinicLabel ? dataObj.clinicLabel : "",
                            clinicId: dataObj.clinic_id ? dataObj.clinic_id : "",
                        },
                        archiveDate: dataObj.archiveDate ? moment(dataObj.archiveDate).format("DD/MM/YYYY") : "Not Available",
                    };
                });
                setTableData(dataList);
                setPatientsFilterData(dataList);
                setTotalRecords(dataList.length);
            } else {
                setTableData([]);
                setPatientsFilterData([]);
                setTotalRecords(0);
            }
        } catch (e) {
            message.error("Failed to load patients list");
        }
        finally {
            setIsPatientTableSpinning(false);
        }
    };

    const handleDateFilter = (confirm, start = "", end = "") => {
        setStartDateFilter(start)
        setEndDateFilter(end)
        setPaginationPage(1);
        confirm({ closeDropdown: true });
    };

    const onUnArchive = async () => {
        try {
            setUnArchiveModal(false)
            setIsPatientTableSpinning(true);
            const payload = { patientID: patientDetails?.patientDetails.patientID, labId: lab?.labId, clinicId: patientDetails?.clinicianDetails?.clinicId, type: unArchiveType };
            await unArchivePatient(payload);
            await _getArchiveList();
            message.success("Patient Unarchived Successfully");
            if (unArchiveType === 'newcase') {
                setShowPrescription(true);
                setRecord({ ...payload, fullName: patientDetails?.patientDetails.patientName });
            } else {
                notification.success({
                    duration: 6,
                    message: "Patient",
                    description: <a>View Profile</a>,
                    className: "patient-created",
                    onClick: () => {
                        history.push("/lab/patient/" + patientDetails?.patientDetails.patientID);
                        window.location.reload();
                    },
                });
            }


        } catch (e) {
            message.error("Failed to Unarchive patient. Please try again later");
            setUnArchiveModal(false)
        }
    };

    const onCancelModal = () => {
        setUnArchiveModal(false)
        setPatientDetails(null)
    }

    const renderDropdownMenu = (record) => (
        <Menu
            onClick={(e) => {
                e.domEvent.stopPropagation();
                setPatientDetails(record)
                setUnArchiveModal(true);
            }}
        >
            <Menu.Item key="delete" icon={<DeleteOutlined />}>
                Unarchive Patient
            </Menu.Item>
        </Menu>
    );

    const onRadioChange = ({ target: { value } }) => {

        setUnArchiveType(value);
    };

    const showConfirm = () => {
        confirm({
            title: "Close add prescription form",
            icon: <ExclamationCircleFilled />,
            content: "Any unsaved changes will be discarded. Do you want to proceed?",
            okButtonProps: {
                style: {
                    width: "7rem",
                    background: primaryColor,
                    borderColor: primaryColor,
                    color: "#fff",
                },
            },
            cancelButtonProps: {
                style: {
                    width: "7rem",
                },
            },
            onOk() {
                setShowPrescription(false);
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };

    const onConfirmationOk = () => {
        prescriptionForm.submit();
    }

    const handleCancel = (event) => {
        showConfirm();
    };

    const modalStyles = {
        top: 20,
    };

    const contentStyles = {
        maxHeight: "calc(100vh - 300px)",
        overflowY: "auto",
    };

    const history = useHistory();
    const columns = [
        {
            width: 170,
            title: columnTitleElement("PATIENT DETAILS"),
            dataIndex: "patientDetails",
            key: "patientDetails",
            render: (data) => details(data?.patientName, data?.patientID),
            sorter: (a, b) => {
                // Convert clinicName to lowercase for case-insensitive comparison
                const nameA = a?.patientDetails?.patientName?.toLowerCase();
                const nameB = b?.patientDetails?.patientName?.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            },
        },
        {
            width: 170,
            title: columnTitleElement("CLINICIAN DETAILS"),
            dataIndex: "clinicianDetails",
            key: "clinicianDetails",
            render: (data) => details(data?.clinicName, data?.clinicLabel),
            filtered: tableData.length !== patientsFilterData.length,
            filterDropdown: ({ confirm, clearFilters }) => (
                <ClinicNameFilter
                    clearFilters={clearFilters}
                    confirm={confirm}
                    tableData={tableData}
                    setPatientsFilterData={setPatientsFilterData}
                    patientsFilterData={patientsFilterData}
                    setTotalRecords={setTotalRecords}
                />
            ),
        },
        {
            width: 170,
            title: columnTitleElement("ARCHIVE DATE"),
            dataIndex: "archiveDate",
            key: "archiveDate",
            render: (date) => moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY'),
            filtered: startDateFilter && endDateFilter,
            filterDropdown: ({ confirm, clearFilters }) => (
                <FilterDataRangePicker
                    clearFilters={clearFilters}
                    confirm={confirm}
                    handleDateFilter={handleDateFilter}
                    fromDate={startDateFilter}
                    endingDate={endDateFilter}
                />
            ),
        },
        {
            width: 80,
            title: "",
            dataIndex: "actions",
            key: "actions",
            align: "center",
            render: (value, record) => {
                return (
                    <Tooltip title={"Unarchived"}>
                        <Dropdown overlay={renderDropdownMenu(record)} trigger={["click"]}>
                            <span onClick={(e) => e.stopPropagation()}>
                                <MoreOutlined style={{ cursor: "pointer" }} />
                            </span>
                        </Dropdown>
                    </Tooltip>
                );
            },
        },
    ]
    const details = (name, id) => {
        return (
            <>
                <Row>
                    <p style={{ fontWeight: '600', marginBottom: '0' }}> {name}</p>
                </Row>
                <Row>
                    <p style={{ fontWeight: '300', marginBottom: '0' }}> {id}</p>
                </Row>
            </>
        );
    };
    let locale = {
        emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Patients to Show</span>} />
        ),
    };
    return (
        <div style={{ width: "100%" }}>
            <Row style={{ justifyContent: "space-between" }}>
                <Col style={{ textAlign: "start" }}>
                    <div className="headerListPatient">
                        <ArrowLeftOutlined onClick={() => { history.push("/lab/patients") }} />
                        <Title level={3}>Archive Patients</Title>
                        {patientsFilterData && (
                            <ShowTotalNumber total={patientsFilterData?.length} className={"patientListLength"} />
                        )}
                    </div>
                </Col>
            </Row>
            <Spin spinning={isPatientTableSpinning}>
                <Table
                    scroll={{
                        y: 800,
                    }}
                    style={{ width: "100%", overflow: "hidden", marginTop: "0.5rem" }}
                    columns={columns}
                    dataSource={patientsFilterData}
                    pagination={paginationObj}
                    onChange={handleTableChange}
                    hideOnSinglePage
                    locale={locale}
                />
            </Spin>
            {unArchiveModal && <Modal
                title={
                    <div>
                        <Title level={5} style={{ margin: "0px" }}>
                            Unarchive Patient with
                        </Title>
                    </div>
                }
                open={unArchiveModal}
                onCancel={onCancelModal}
                footer={[
                    <AppButton onClick={() => onCancelModal()} type="default">
                        Cancel
                    </AppButton>,
                    <AppButton
                        onClick={() => onUnArchive()}
                        type="primary"
                        htmlType="submit"
                    >
                        Yes, Confirm
                    </AppButton>,
                ]}
            >
                <div style={{ marginTop: '-45px' }}>
                    <Radio.Group style={{ display: 'flex', justifyContent: "space-between" }} onChange={onRadioChange} value={unArchiveType} options={radioOptions} />
                </div>
            </Modal>}
            {showPrescription && <Modal
                style={modalStyles}
                className="addPatientModal"
                title={
                    <div className="titleSpan">
                        <UserAddOutlined style={{ fontSize: "35px" }} />{" "}
                        <Title level={5} style={{ margin: "0px" }}>
                            {`${record?.fullName} Prescription`}
                        </Title>
                    </div>
                }
                width={1500}
                open={showPrescription}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="cancel"
                        onClick={handleCancel}
                        shape="round"
                        style={{ width: "7rem", borderRadius: "30px" }}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        shape="round"
                        onClick={prescriptionSubmit}
                        loading={loading}
                        style={{ width: "7rem", borderRadius: "30px" }}
                    >
                        Submit
                    </Button>,
                ]}
            >
                <Spin spinning={loading}>
                    <div className="prescription-modal-container" style={contentStyles}>
                        <ViewScanFilesComponent
                            setClinicalFilesArray={setClinicalFilesArray}
                            clinicalFilesArray={clinicalFilesArray}
                            clinicalFiles={clinicalFiles}
                            scanUrls={scanUrls}
                            setScanUrls={setScanUrls}
                            treatmentOriginId={3}
                            activeCaseId={1}
                        />
                        <PatientPrescriptionForm
                            prescriptionForm={prescriptionForm}
                            clinicId={
                                record?.clinicId
                            }
                            labName={lab?.labName}
                            labId={lab?.labId}
                            teethChartRef={teethChartRef}
                            onPrescriptionSubmit={onPrescriptionSubmit}
                        />
                    </div>
                </Spin>
            </Modal>}
            {prescriptionConfirmation &&
                <ConfirmationModal
                    title="Are you sure you want to submit without relevant scans?"
                    content={scanMessage}
                    showModal={prescriptionConfirmation}
                    setShow={setPrescriptionConfirmation}
                    onConfirmation={onConfirmationOk}
                    okText="Submit"
                ></ConfirmationModal>
            }
        </div>
    );
}
const mapStateToProps = (state) => ({
    lab: state.lab,
});
export default connect(mapStateToProps)(ArchivedPatients);