import { Row, Col } from "antd";
import IPRInfo from "components/private/IPRInfo/IPRInfo";
const IprData = ({
  lowerAligners,
  upperAligners,
  chartValue,
  lowerAttachments,
  upperAttachments,
  alignersLabel,
}) => {
  return (
    <div>
      <Row align="top" justify="space-between">
        <Col lg={4}>
          <div className="aligner-component" style={{ padding: "0.5rem" }}>
            <Row>
              <p className="patient-response" style={{ marginTop: "0px" }}>
                No. of IPR Sessions
              </p>
            </Row>
            <Row>
              <p className="patient-question" >{alignersLabel.length}</p>
            </Row>
          </div>
        </Col>
        <Col lg={4} offset={10}>
          <div className="aligner-component" style={{ padding: "0.5rem" }}>
            <Row>
              <p className="patient-response" style={{ marginTop: "0px", width:"100px", textTransform:"uppercase"}}>
                {chartValue}
              </p>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="aligner-component" style={{marginTop:"1rem", background:"#fff", border:"none", padding:"0px"}}>
        <IPRInfo
          lowerAligners={lowerAligners}
          upperAligners={upperAligners}
          chartValue={chartValue}
          upperAttachments={upperAttachments}
          lowerAttachments={lowerAttachments}
        />      
      </div>
    </div>
  );
};

export default IprData;
