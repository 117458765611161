import { Upload, message } from "antd";

import { ReactComponent as Pdf } from "assets/images/Pdf.svg";
import { ReactComponent as Image } from "assets/images/Image.svg";
import {
  UploadOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const LabInvoiceUpload = ({
  resourceFileList,
  setResourceFileList,
  multiple = true,
  maxCount = 5,
  beforeUpload,
  onChange,
  orderDetails,
  setRemoveInvoiceFiles
}) => {

  // console.log("...orderDetails.shippingFiles", orderDetails.shippingFiles)
  const props = {
    // className: "custom-file-dragger",
    name: "file",
    multiple: true,
    maxCount: maxCount,
  };
  const onRemove = (delFile) => {
    const allFiles = [...resourceFileList];
    const filtered = allFiles.filter((file) => file.uid !== delFile.uid);
    setResourceFileList(filtered);
    message.success(`${delFile.name} removed successfully`);
  };

  const renderFileIcon = (file) => {
    if (["image/jpeg", "image/png"].includes(file.type || file?.meta?.mimeType)) {
      return <Image />
    } else {
      return <Pdf />
    }
  };

  const deleteInvoiceHandler = (name, fileId, id) => {
    // Handle file delete logic
  //  console.log("fileId", fileId)
   const allFiles = [...resourceFileList]
  //  console.log("allFiles", allFiles)
   const filteredList = allFiles.filter((file) => (file.uid !== fileId) || (file.id !== id))
  //  console.log("filteredList", filteredList)
     setResourceFileList(filteredList);
    if(id){
      setRemoveInvoiceFiles(prevDeletedIds => [...prevDeletedIds, id]);
    }
    

    message.success(`File ${name} deleted successfully.`);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <div className="uploader-file-list">
        <Upload
          name="files"
          listType="picture-card"
          className="order-uploader"
          multiple={true}
          fileList={resourceFileList}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
          showUploadList={false} // Hide default upload list
        >
          <div style={{ margin: "8px 8px 0px 8px" }}>
            <UploadOutlined />
            <p className="scan-upload-text" style={{margin:"0px"}}>
              Click or drag file to this area to upload
            </p>
          </div>
        </Upload>

        <div className="uploaded-files" style={{overflowX : resourceFileList.length > 3 ? "scroll" :"hidden" }}>
          {resourceFileList.map((file, i) => (
            <div key={i} className="resource">
              <div className="resource-wrapper">
                <div className="icons">
                  {
                    file.url && (
                      <a href={file.url} target="_blank" rel="noreferrer" key={i}>
                      <DownloadOutlined  style={{
                         marginRight: "1rem",
                         fontSize: "20px",
                       }}/>
                       </a>
                    )
                  }
        
                  <DeleteOutlined
                    onClick={() => deleteInvoiceHandler(file.name, file.uid, file.id)}
                    style={{ marginRight: "1rem", fontSize: "20px" }}
                  />
                </div>
              </div>
              {renderFileIcon(file)}
              <p>{file.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* <Upload
        name="avatar"
        listType="picture-card"
        className="order-uploader"
        multiple={true}
        {...props}
        fileList={resourceFileList}
        beforeUpload={(file) => {
          console.log("file", file);
          const allFiles = [...resourceFileList];
          allFiles.push(file);
          setResourceFileList(allFiles);
          return false;
        }}
        onRemove={onRemove}
        // onChange={(info) => onChangeHandler(info)}
      >
        <div style={{ margin: "14px 0px 16px" }}>
          <UploadOutlined />

          <p className="ant-upload-text scan-upload-text">
            Click or drag file to this area to upload
          </p>
        </div>
      </Upload> */}
    </div>
  );
};

export default LabInvoiceUpload;
