import { Form, Input, } from "antd";

const FormItem = Form.Item;

const ResendInviteForm = ({ resendInviteFormRef, onFinish }) => {
  const onFinishFailed = (error) => {
    // console.log(failed)
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={resendInviteFormRef}
      name="resend-invite-form"
      layout="vertical"
    >
      <FormItem
        rules={[{ required: true, type: "email", message: "email is required" }]}
        name="email"
        label="Email"
        validateTrigger="onBlur"
      >
        <Input style={{ width: "55%" }} />
      </FormItem>
    </Form>
  );
};

export default ResendInviteForm;
