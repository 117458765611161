import { INVOICES_LIST_FIRST } from "constants";
import { INVOICES_LIST_ALL } from "constants";
import moment from  "moment"
import { calculateProductsTotal, getProductServiceName, getProductServiceNames, getProductServiceTotalQuantity } from "utils/treatment";

export const createInvoicesTableData = (invoiceList) => {
    return invoiceList.map((dataObj, index) => {
      return {
        key: index,
        shippingFiles: dataObj?.shippingFiles || [],
        invoiceNo: dataObj.patient_order_id
          ? dataObj.patient_order_id
          : "Not Available",
        invoiceDate: dataObj.product_order_date
          ? moment(dataObj.product_order_date).format("DD/MM/YYYY")
          : "Not Available",
        patientId: dataObj.patient_id,
        clinicName: dataObj.clinic_name ? dataObj.clinic_name : "Not Available",
        productServiceName: getProductServiceName(dataObj),
        productServiceNames:  getProductServiceNames(dataObj),
        paymentStatus: dataObj.payment_status_name
          ? dataObj.payment_status_name
          : "Not Available",
        amount: dataObj.product_price
          ? dataObj.product_price + " " + dataObj.currency_name
          : "Not Available",
        quantity: getProductServiceTotalQuantity(dataObj),
        labProductPrice: dataObj.free
          ? `${dataObj.currency_name} 0 (Free)`
          : dataObj.labProductPrice
          ? dataObj.labProductPrice
          : "Not Available",
        labProductTotalPrice: calculateProductsTotal(dataObj?.labProductList)
      };
    });
  };


 export const fileDownloadHandler = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export const getDefaultInvoiceState = (params) =>{
  if (!params) {
    return INVOICES_LIST_ALL
  }
  if (params === INVOICES_LIST_ALL) {
    return INVOICES_LIST_ALL
  }
  return INVOICES_LIST_FIRST
  }