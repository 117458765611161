import { useEffect, useState } from "react";
import { Form, message } from "antd";
import AppModal from "components/shared/AppModal/AppModal";
import ResendInviteForm from "./ResendInviteForm";
import { handleApiService } from "../services";
import { updateLabStaff } from "api/staff";
const ResendInviteModal = ({
  labId,
  showResendInviteModal,
  setShowResendInviteModal,
  selectedRowRef,
  fetchData,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [resendInviteFormRef] = Form.useForm();

  useEffect(() => {
    resendInviteFormRef.setFieldValue("email", selectedRowRef.current.email);
  }, [selectedRowRef, resendInviteFormRef]);

  const onFinish = (values) => {
    handleApiService(
      updateLabStaff,
      { labId, staffId: selectedRowRef.current.id, data: { email: values.email } },
      setConfirmLoading,
      () => {
        fetchData();
        message.success("new invite sent to the staff member");
        resendModalCloseHandler();
      }
    );
  };

  const resendModalCloseHandler = () => setShowResendInviteModal(false);

  const onOk = () => {
    resendInviteFormRef.submit();
  };

  const resendInviteProps = {
    resendInviteFormRef,
    onFinish,
  };

  return (
    <AppModal
      onOk={onOk}
      okText="Send Email"
      width={500}
      open={showResendInviteModal}
      onCancel={resendModalCloseHandler}
      confirmLoading={confirmLoading}
      title="Resend Invite"
    >
      <ResendInviteForm {...resendInviteProps} />
    </AppModal>
  );
};

export default ResendInviteModal;
