import { getCountriesCodes, getCountries } from "api/common";
import { countriesAndCodes } from "services/redux/actions/labActions";

export const fetchCountriesAndCodes = () => {
  return (dispatch) => {
    Promise.all([getCountriesCodes(), getCountries()])
      .then((res) => {
        const [codeResponse, countriesResponse] = res;

        dispatch(
          countriesAndCodes({
            codes: codeResponse?.data?.body?.countryCodes || [],
            countries: countriesResponse.data.body.countries || [],
          })
        );
      })
      .catch((err) => console.log(err));
  };
};


