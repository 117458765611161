import React, { useState, useEffect, useRef } from "react";

import { Modal, Input, Form,Select} from "antd";
const {Option} = Select

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 8,
      offset: 2,
    },
    lg: {
      span: 8,
      offset: 0,
    },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
  },
  wrapperCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 24,
      offset: 0,
    },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
  },
};

const OrderRemakeModal = ({
  title,

  onConfirmation,
  showModal,
  setShow,
  orderRemakeReasons
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    console.log(inputRef.current);
    setIsModalVisible(false);
    setShow(false);
    form.submit()
    
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };
  const onFinish = (val) =>{
      console.log(val)
      onConfirmation(val);
  }

  return (
    <>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        

        <Form
        form={form}
          className="login-form"
          {...formItemLayout}
          layout={"vertical"}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{reason:orderRemakeReasons[0]?.order_remake_reason_id}}
        >
          <Form.Item
            name="reason"
           
            label="Remake Reason" 
          >
<Select >
        {orderRemakeReasons.map((reason) => {
          return (
            <Option key={reason.order_remake_reason_id} value={reason.order_remake_reason_id}>
              {reason.order_remake_reason_name}
            </Option>
          );
        })}
      </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OrderRemakeModal;
