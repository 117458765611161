import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Drawer, Radio, message } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { UserOutlined, UserSwitchOutlined, MessageOutlined, BellOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import { staffDropDowSelector } from "services/redux/selectors/labStaff.selector";
import Activity from "./Activity/Activity";
import LabChat from "./LabChat/LabChat";
import styles from "./chatWindow.module.scss";
import { activitySocket, getActivitiesServices, socket as LabChatSocket } from "api/chat";
import { getCommentsAPI } from "services/api/common";
import { imagesTypes } from "../../../../constants";
import { ACTIVITY_TYPES, chatOptions } from "./constants";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
const components = {
  1: Activity,
  2: LabChat,
};

const getTimeDifference = (dateTimeString) => {
  const now = dayjs();
  const pastTime = dayjs(dateTimeString);

  const diffInSeconds = now.diff(pastTime, "seconds");
  const diffInHours = now.diff(pastTime, "hours");

  if (diffInSeconds < 60) {
    return "just now";
  }

  if (diffInHours >= 24) {
    return pastTime.format("YYYY-MM-DD");
  }

  return pastTime.from(now);
};

const ChatDrawer = ({ setOpen, open, patientId, subUser, clinicInfo, labInfo, setUnreadCount }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [activityLoader, setActivityLoader] = useState(false);
  const [activities, setActivities] = useState([]);
  const [activityLoadMore, setActivityLoadMore] = useState(false);
  const staffMembers = useSelector(staffDropDowSelector);
  const activityScrollRef = useRef(null);
  const [labMessages, setLabMessages] = useState([]);
  const labChatScrollRef = useRef(null);
  const [chatSpinner, setChatSpinner] = useState(false);
  const [labChatText, setLabChatText] = useState("");
  const [activityText, setActivityText] = useState("");

  const chatInfo = useMemo(
    () => ({ lab_id: labInfo.labId, clinic_id: clinicInfo.clinic_id, patient_id: patientId }),
    [labInfo.labId, clinicInfo.clinic_id, patientId]
  );

  const getStaffMember = useCallback(
    (id) => {
      return staffMembers.find((el) => el.id === Number(id));
    },
    [staffMembers]
  );

  const activityMapper = useCallback(
    (activities = []) => {
      return activities?.map((activityContent) => {
        const { type } = activityContent;
        const staffData = getStaffMember(activityContent.staffId);
        const content = {
          ...activityContent,
          showAssignee: true,
          assigneeName: staffData ? staffData.name : "",
          iconBackgroundColor: "",
          activityDate: getTimeDifference(activityContent.createdAt),
        };
        if (activityContent.type === ACTIVITY_TYPES.USER_COMMENT) {
          content.icon = <UserOutlined />;
        } else if (activityContent.type === ACTIVITY_TYPES.ASSIGN_PATIENT) {
          content.icon = <UserSwitchOutlined />;
          content.showAssignee = false;
        } else if (type === ACTIVITY_TYPES.LAB_TO_CLINIC_CHAT) {
          content.icon = <MessageOutlined />;
          content.assigneeName = `<div> <b>${content.assigneeName}</b> message to <b>clinic</b></div>`;
          content.iconBackgroundColor = primaryColor;
        } else if (type === ACTIVITY_TYPES.CLINIC_TO_LAB_CHAT) {
          content.icon = <MessageOutlined />;
          content.assigneeName = `<div> Received message from <b>clinic</b></div>`;
          content.iconBackgroundColor = primaryColor;
        } else {
          content.showAssignee = false;
          content.icon = <BellOutlined />;
          content.iconBackgroundColor = primaryColor;
        }
        return content;
      });
    },
    [getStaffMember]
  );

  const getActivities = useCallback(
    async (patientId, offset, limit, initial = false) => {
      try {
        setActivityLoader(true);

        const response = await getActivitiesServices(patientId, offset, limit);
        setActivities((prev) => {
          if (initial) {
            return activityMapper(response.data.data.activities);
          }

          return [...prev, ...activityMapper(response.data.data.activities)];
        });
        setActivityLoadMore(response.data.data.hasMore);
      } catch (error) {
        console.log(error);
      } finally {
        setActivityLoader(false);
      }
    },
    [activityMapper]
  );

  useEffect(() => {
    if (open && patientId) getActivities(patientId, "", "", true);
  }, [patientId, getActivities, open]);

  const activityMessageListener = useCallback(
    (data) => {
      setActivities((prev) => [
        ...activityMapper([{ ...data, id: Date.now(), createdAt: dayjs().toString() }]),
        ...prev,
      ]);
    },
    [activityMapper]
  );

  const handleMessageCount = useCallback(() => {
    if (!open) {
      setUnreadCount((prev) => prev + 1);
    }
  }, [open, setUnreadCount]);

  useEffect(() => {
    const activityListener = (data) => {
      handleMessageCount();
      activityMessageListener(data);
    };
    if (patientId) {
      activitySocket.emit("joinRoom", patientId);
      activitySocket.on("lab:activity:client", activityListener);
      // console.log("Activity socket listener added");
    }

    return () => {
      activitySocket.off("lab:activity:client", activityListener);
      activitySocket.emit("leaveRoom", patientId);
    };
  }, [patientId, handleMessageCount, activityMessageListener]);

  function isImage(mimeType) {
    // Extract the file extension from the MIME type
    const extension = mimeType.split("/")[1];

    // Check if the extension exists in the allowedExtensions array
    return imagesTypes.includes(extension);
  }

  const commentMetaMapping = useCallback((comment) => {
    const date = dayjs(comment.created_date).format("DD-MM-YYYY hh:mm A");
    const commentCopy = { ...comment, created_date: date };

    if (!comment?.metaData) {
      return commentCopy;
    }
    const metaData = JSON.parse(comment.metaData);

    if (!metaData.mimetype) {
      return commentCopy;
    }
    if (isImage(metaData.mimetype)) {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "image",
        metaData,
      };
    } else {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "file",
        metaData,
      };
    }
  }, []);
  const scrollToBottom = () => {
    setTimeout(() => {
      const chatWindow = labChatScrollRef.current;
      if (chatWindow?.scrollHeight > 0) {
        chatWindow.scrollTop = chatWindow?.scrollHeight;
      }
    }, 100);
  };

  const getLabChat = useCallback(
    async (senderId, receiverId, patientId) => {
      setChatSpinner(true);
      try {
        const response = await getCommentsAPI({
          senderId: senderId,
          receiverId: receiverId,
          patientId,
        });

        const mappedComments = response?.data?.body?.data?.map((comment) => {
          const mappedComment = commentMetaMapping(comment);

          return mappedComment;
        });

        setLabMessages(mappedComments);
        if (response?.data?.body?.result?.length && response?.data?.body?.result[0]?.dentist_name) {
          // setClinicName(response.data.body.result[0].dentist_name);
        }

        scrollToBottom();
      } catch (error) {
        message.error(error);
      } finally {
        setChatSpinner(false);
      }
    },
    [commentMetaMapping]
  );

  useEffect(() => {
    if (chatInfo && open) {
      getLabChat(chatInfo.lab_id, chatInfo.clinic_id, chatInfo.patient_id);
    }
  }, [chatInfo, getLabChat, open]);

  const onMessage = useCallback(
    (comment) => {
      // comment.module === "clinic" &&
      //   notificationApi.info({
      //     message: "New Message from clinic",
      //   });
      // }

      const mappedComment = commentMetaMapping(comment);
      setLabMessages((prevMessages) => [...prevMessages, mappedComment]);
      scrollToBottom();
    },
    [commentMetaMapping]
  );

  useEffect(() => {
    // Define the listener function
    const handleMessage = (data) => {
      handleMessageCount();
      onMessage(data);
    };
    if (chatInfo) {

      LabChatSocket.emit("joinRoom", chatInfo);
      LabChatSocket.on("message", handleMessage);

      // console.log("chat socket connected");
    }

    return () => {
      // console.log("chat socket unmounted");
      LabChatSocket.off("message", handleMessage);
      LabChatSocket.emit("leaveRoom", chatInfo);
    };
  }, [handleMessageCount, onMessage, chatInfo]);

  const onClose = () => {
    setOpen(false);
  };

  const onTabChange = (e) => {
    const { value } = e.target;
    setSelectedTab(value);
  };

  return (
    <Drawer
      title={
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Radio.Group
            options={chatOptions}
            onChange={onTabChange}
            value={selectedTab}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
      }
      onClose={onClose}
      width={610}
      open={open}
    >
      {open && (
        <div className={styles["chat-container"]}>
          {selectedTab === 1 ? (
            <Activity
              activityLoader={activityLoader}
              activityLoadMore={activityLoadMore}
              loadMoreActivities={getActivities}
              activityScrollRef={activityScrollRef}
              staffMembers={staffMembers}
              activities={activities}
              setActivities={setActivities}
              subUser={subUser}
              activitySocket={activitySocket}
              patientId={patientId}
              editorInput={activityText}
              setEditorInput={setActivityText}
            />
          ) : (
            <LabChat
              scrollToBottom={scrollToBottom}
              labChatScrollRef={labChatScrollRef}
              chatSpinner={chatSpinner}
              labMessages={labMessages}
              chatInfo={chatInfo}
              subUser={subUser}
              labInfo={labInfo}
              clinicInfo={clinicInfo}
              editorInput={labChatText}
              setEditorInput={setLabChatText}
            />
          )}
        </div>
      )}
    </Drawer>
  );
};

export default ChatDrawer;
