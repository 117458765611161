import { useState } from "react";
import { Form, message , Typography} from "antd";
import AppModal from "components/shared/AppModal/AppModal";
import StaffForm from "./StaffForm";
import { createStaff } from "api/staff";
import { UserAddOutlined } from "@ant-design/icons";
const { Title } = Typography;
const AddStaffModal = ({ labId, showAddStaffModal, setShowAddStaffModal, fetchData }) => {
  const [addStaffFormRef] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const staffModalCloseHandler = () => {
    setShowAddStaffModal(false);
  };
  const onOk = () => {
    addStaffFormRef.submit();
  };

  const onFinish = async (values) => {
    console.log("values", values);

    try {
      setConfirmLoading(true);
      await createStaff(labId, values);
      await fetchData();
      message.success("Staff member created successfully");
      staffModalCloseHandler();
    } catch (error) {
      message.error("Operational failed. Please try again");
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
    <AppModal
      title={
        <div className="titleSpan">
          <UserAddOutlined style={{ fontSize: "20px" }} />{" "}
          <Title level={5} style={{ margin: "0px" }}>
          Add New Role
          </Title>
        </div>
      }
      onOk={onOk}
      okText="Send Invite"
      width={650}
      open={showAddStaffModal}
      onCancel={staffModalCloseHandler}
      confirmLoading={confirmLoading}
    >
      <StaffForm onFinish={onFinish} addStaffFormRef={addStaffFormRef} />
    </AppModal>
  );
};

export default AddStaffModal;
