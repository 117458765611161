import React, { useState, useEffect, useRef } from "react";
import { Tag, notification, Form, Modal, Breadcrumb, message } from "antd";
import { useHistory } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { connect, useDispatch, useSelector } from "react-redux";

import { prescriptionDataMapper } from "../../../components/private/patient-detail/newPrescriptionForm/utils";
import ProcessingLabPatientDetailComponent from "../../../components/private/patient-detail/processingLabPatientDetailComponent";
import PatientsDetail from "../../../components/private/patientsDetail";
// import CommentModal from "../../../components/shared/modals/CommentModal";
import LabChat from "components/private/patient-detail/labChat/LabChat";
import { useParams } from "react-router-dom";
import SLA_COLORS from "../../../utils/slaColors";
import { makePatientAddress, patientDetailBreadcrumb } from "utils/Patient";
import ChatWindow from "./ChatWindow/ChatWindow";
import { labApiTasks } from "models/lab";

import "../../../assets/styles/routes/patientDetail.scss";
import { changeCommentBoxState } from "services/redux/actions/labActions";
import { getPatientPrescriptionDetails } from "components/private/patient-detail/PatientTreatment/api";
import { getLabOrdersAPI, getPatientList } from "api/lab";
import { getClinicInfoService } from "api/clinic";
import AppTag from "components/shared/AppTag/AppTag";
import { TAG_STATUS } from "constants";
import { primaryColor } from "colors-config";
import { getPatientDetails } from "api/patient";
// import Breadcrumb from "../../../node_modules/antd/es/breadcrumb/Breadcrumb";
import { socket, activitySocket } from "api/chat";
import SelectAssignee from "components/shared/SelectAssignee/SelectAssignee";
import { staffDropDowSelector } from "services/redux/selectors/labStaff.selector";
import { getStaffMemberByPatientId, assignStaffMember } from "api/staff";

function PatientDetail(props) {
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const labStaffMembers = useSelector(staffDropDowSelector);

  const [patientAssignee, setPatientAssignee] = useState({});
  const [modal, modalContextHolder] = Modal.useModal();
  const [prescriptionForm] = Form.useForm();
  const formDataObjectRef = useRef(null);
  const { computedMatch, lab, location } = props;
  const params = useParams();

  const { patientId } = params;
  // const [patientId] = useState(computedMatch.params.patientId);
  const [patientInfoState, setPatientInfoState] = useState();
  const [clinicInfo, setClinicInfo] = useState();
  const [currentAlignerState, setCurrentAlignerState] = useState();
  const [totalAlignerState, setTotalAlignerState] = useState();
  const [patientOrderStatus, setPatientOrderStatus] = useState();
  const [patientOrders, setPatientOrders] = useState([]);
  const [orderRemakeReasons, setOrderRemakeReasons] = useState([]);

  const [imageStageTypes, setImageStageTypes] = useState({});
  const [scanUrls, setScanUrls] = useState([]);
  const [clinicalFiles, setClinicalFiles] = useState([]);
  const [treatmentSetup, setTreatmentSetup] = useState([]);
  const [treatmentId, setTreatmentId] = useState([]);
  const patientAddressRef = useRef("");

  const [treatmentPrescription, setTreatmentPrescription] = useState({});

  const history = useHistory();
  const [isChildSpinning, setIsChildSpinning] = useState(false);
  // const [showCommentsModal, setShowCommentsModal] = useState(lab.openCommentBox);

  const [alignerStageDet, setAlignerStageDet] = useState();
  const [assigneeLoading, setAssigneeLoading] = useState(false);

  const [clinicDetails, setClinicDetails] = useState();

  useEffect(() => {
    socket.connect();
    activitySocket.connect();
    // console.log("socket", socket);
    return () => {
      socket.disconnect();
      activitySocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (patientId) {
      (async () => {
        const response = await getStaffMemberByPatientId({ patientId });
        // console.log("response", response);
        setPatientAssignee(response.data.data);
        //  setAssigneeId(response.data.data.)
      })();
    }
  }, [patientId]);

  useEffect(() => {
    setIsChildSpinning(true);
    _getOrders();
    _getImagesAndQuestionnaire();
    _getPatientInformation();
    if (lab && lab.labServiceId && (lab.labServiceId === 2 || lab.labServiceId === 3)) {
      _getTreatmentSetupAndPrescription();
      _getPrescription();
    }
  }, [lab.labServiceId, lab.labId, patientId]);

  useEffect(() => {
    if (lab.labId && treatmentSetup) {
      _getTreatmentId();
    }
  }, [treatmentId, lab.labId, lab.labServiceId, patientId]);

  const getImageUrls = async (imageDetail, imageTypeObject) => {
    try {
      const res = await labApiTasks.post(`lab/patient/resource/getOnboardingImagesURL`, {
        file_name: imageDetail.resource_url,
      });

      const imageObject = {
        imageUrl: res.body.url.url,
        imageStageId: imageDetail.patient_image_uploaded_stage_id,
        imageStageName: imageDetail.patient_image_uploaded_stage_name,
        imageViewType: imageDetail.patient_image_view,
        imageDeleteRefUrl: imageDetail.resource_url,
        rowId: imageDetail.row_id,
      };

      if (imageTypeObject[imageDetail.patient_image_uploaded_stage_id]) {
        imageTypeObject[imageDetail.patient_image_uploaded_stage_id] = [
          ...imageTypeObject[imageDetail.patient_image_uploaded_stage_id],
          imageObject,
        ];
      } else {
        imageTypeObject[imageDetail.patient_image_uploaded_stage_id] = [imageObject];
      }

      setImageStageTypes({ ...imageTypeObject });
    } catch (error) {
      console.log(error);
    }
  };

  const createImageStageTypes = (imageDetails) => {
    const imageStageTypeObject = {};
    imageDetails.forEach((imageDetail) => {
      getImageUrls(imageDetail, imageStageTypeObject);
    });
  };

  const _getPatientInformation = async () => {
    try {
      const patientInfo = await getPatientDetails({
        patientId,
      });

      setPatientInfoState(patientInfo.data.body[0]);
      patientAddressRef.current = makePatientAddress(patientInfo.data.body[0]);
      _getClinicInformation(patientInfo.data.body[0]?.clinic_id);
    } catch (error) {
      console.log(error);
    }
  };

  const _getClinicInformation = async (clinic_id) => {
    try {
      const clinicInfo = await getClinicInfoService(clinic_id);
      setClinicInfo(clinicInfo.data.body);
    } catch (error) {
      console.log(error);
    }
  };

  const _getTreatmentId = async () => {
    try {
      const currentAligner = await labApiTasks.getAll("lab/patientlist/showCurrentAligner", {
        patientId,
        labId: lab.labId,
        labTreatmentId: treatmentId,
      });
      setCurrentAlignerState(currentAligner?.body?.current_aligner_number);
      setTotalAlignerState(currentAligner?.body?.total_aligners);
      // console.log(
      //   "current aligner number:",
      //   currentAligner.body.current_aligner_number
      // );
    } catch (error) {
      console.log(error);
    }
  };
  // Fetching Image Urls only for Progress Image Card

  const _getImagesAndQuestionnaire = async () => {
    // setIsProgressImagesSpinning(true);
    try {
      const scanRes = await labApiTasks.getAll("lab/patient/resource/getOnboardingImages", {
        patientId,
        patientStage: "scan",
      });
      const clinicalRes = await labApiTasks.getAll("lab/patient/resource/getOnboardingImages", {
        patientId,
        patientStage: "clinical",
      });
      //   const patientQuestionnaireRes = await labApiTasks.getAll(
      //     "clinic/patient/getRespForQuestionnaire",
      //     { patientId }
      //   );
      //   console.log(patientQuestionnaireRes, "res2");
      //   setIsProgressImagesSpinning(false);

      //   setPatientQuestionnaireResponses(patientQuestionnaireRes.body);
      setClinicalFiles(clinicalRes?.body?.results?.patient_onboarding_images);
      setScanUrls(scanRes.body.results.patient_onboarding_images);
    } catch (error) {
      //   setIsProgressImagesSpinning(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (lab.labId) {
      checkPatientDetails();
    }
  }, [lab.labId]);

  const checkPatientDetails = async () => {
    const statusRes = await getPatientList({
      labId: lab.labId,
      search: patientId,
      limit: 1,
      page: 1,
    });
    if (statusRes?.data?.body?.data?.length) {
      setPatientOrderStatus(statusRes?.data?.body.data[0]);
    } else {
      console.log("no data");
      history.push("/lab/patients");
      return;
    }
  };

  const _getOrders = async () => {
    try {
      const res = await getLabOrdersAPI({ patientId, labId: lab.labId });

      const remakeReasonsRes = await labApiTasks.getAll("patientlist/orders/getRemakeReasons");

      setOrderRemakeReasons(remakeReasonsRes.body.data);

      setPatientOrders(res.body?.data);
      setIsChildSpinning(false);

      // message.success(res.body?.msg);
    } catch (error) {
      setIsChildSpinning(false);

      console.log(error);
      // message.error("Something didn't go right. Please try again!");
    }
  };

  const _getTreatmentSetupAndPrescription = async () => {
    try {
      const treatmentRes = await labApiTasks.getAll("lab/patientlist/treatmentSetup/get", {
        patientId,
      });
      setTreatmentSetup(treatmentRes.body.treatment_setup);
      setTreatmentId(treatmentRes.body?.treatment_setup[0]?.lab_treatment_id);

      // console.log("Treatment setup id:", treatmentSetup);
    } catch (error) {
      console.log(error, "errror");
    }
  };

  const _getPrescription = async () => {
    try {
      const { data } = await getPatientPrescriptionDetails(patientId);

      const finalObj = prescriptionDataMapper(data?.prescription);
      setTreatmentPrescription(finalObj);
      // console.log("finalObj", finalObj)
      formDataObjectRef.current = finalObj;
      prescriptionForm.setFieldsValue(finalObj);
    } catch (error) {
      console.log(error, "errror");
    }
  };

  const reassignPatient = async (value) => {
    try {
      setAssigneeLoading(true);
      const response = await assignStaffMember({
        patientId,
        staffId: value,
        labStaffId: patientAssignee?.id || "",
      });

      setPatientAssignee(response.data.data);
      message.success(`Patient ${value ? "assigned" : "un assigned"} successfully`);
    } catch (error) {
      console.log(error);
    } finally {
      setAssigneeLoading(false);
    }
  };

  return (
    <div>
      {modalContextHolder}
      {notificationContextHolder}

      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/lab/patients")}
        title="Patient Detail"
        breadcrumb={<Breadcrumb routes={patientDetailBreadcrumb} />}
        extra={[
          <div key="uniqueKey-header" className="page-header-patient-container">
            <SelectAssignee
              loading={assigneeLoading}
              options={[{ label: "Unassign", value: 0 }, ...labStaffMembers]}
              value={patientAssignee?.staffId || null}
              onChange={reassignPatient}
            />
            {patientOrderStatus?.status_name && (
              <div>
                {/* <Tag
                        style={{
                          fontFamily: '"Mulish", sans-serif',
                          fontWeight: 600,
                          fontSize: "13px",
                          padding: "6px",
                          borderRadius: "15px",
                          width: "auto",
                          marginTop: "20px",
                          height: "35px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        {patientOrderStatus?.status_name}
                      </Tag> */}
                <AppTag text={patientOrderStatus?.status_name} type={TAG_STATUS.DEFAULT} />
              </div>
            )}
            {patientOrderStatus?.sla?.totalDays && (
              <div style={{ marginLeft: "10px" }}>
                <Tag
                  className="sla-tag"
                  style={{
                    borderRadius: "24px",
                    padding: "0.4rem 1rem",
                    color:
                      patientOrderStatus?.sla?.colorCode != SLA_COLORS.CYAN ? "#FFF" : "#05A2BD",
                  }}
                  color={patientOrderStatus?.sla?.colorCode}
                >
                  {patientOrderStatus?.sla?.totalDays} DAY
                  {patientOrderStatus?.sla?.totalDays > 1 ? "S" : ""}
                </Tag>
              </div>
            )}
          </div>,
        ]}
      />

      <div
        style={{
          paddingBottom: "20px",
          paddingLeft: "20px",
          width: "100%",
        }}
      >
        <PatientsDetail
          currentAlignerNumber={currentAlignerState}
          patientInfo={patientInfoState}
          clinicDetails={clinicDetails}
          totalAlignerState={totalAlignerState}
          clinicInfo={clinicInfo}
          getPatientInformation={_getPatientInformation}
        />
      </div>
      <ProcessingLabPatientDetailComponent
        modal={modal}
        patientAddressRef={patientAddressRef}
        clinicInfo={clinicInfo}
        setTreatmentPrescription={setTreatmentPrescription}
        prescriptionForm={prescriptionForm}
        formData={treatmentPrescription}
        getTreatmentSetupAndPrescription={_getTreatmentSetupAndPrescription}
        labType={lab.labServiceId}
        labId={lab.labId}
        setTreatmentSetup={setTreatmentSetup}
        isChildSpinning={isChildSpinning}
        treatmentSetup={treatmentSetup}
        patientOrders={patientOrders}
        setPatientOrders={setPatientOrders}
        scanUrls={scanUrls}
        setScanUrls={setScanUrls}
        getImagesAndQuestionnaire={_getImagesAndQuestionnaire}
        clinicalFiles={clinicalFiles}
        imageStageTypes={imageStageTypes}
        alignerStageDet={alignerStageDet}
        getOrders={_getOrders}
        orderRemakeReasons={orderRemakeReasons}
        patientId={patientId}
        treatmentId={treatmentId}
        setTreatmentId={setTreatmentId}
        createImageStageTypes={(data) => createImageStageTypes(data)}
        activeTab={location?.state?.currentActiveTab}
        patientInfoState={patientInfoState}
        clinic={clinicInfo}
        currentOrderMenuSelected={
          location?.state?.currentOrderTypeMenuSelected
            ? location?.state?.currentOrderTypeMenuSelected
            : "aligners"
        }
        getPatientInformation={_getPatientInformation}
        patientOrderStatus={patientOrderStatus}
      />
      <ChatWindow
        openCommentBox={lab.openCommentBox}
        patientInfoState={patientInfoState}
        lab={lab}
        patientId={patientId}
        primaryColor={primaryColor}
        notificationApi={notificationApi}
        clinicInfo={clinicInfo}
      />
      {/* <LabChat
        openCommentBox={lab.openCommentBox}
        patientInfoState={patientInfoState}
        lab={lab}
        patientId={patientId}
        primaryColor={primaryColor}
        notificationApi={notificationApi}
      /> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(PatientDetail);
