import { Row, Col, Progress } from "antd";
import HeaderCount from "./HeaderCount";
import styles from "assets/styles/routes/dashboard.module.scss";
import { Link } from "react-router-dom";

import { ReactComponent as PatientsIcon } from "assets/images/PatientsIcon.svg";
import { primaryColor } from "colors-config";
const StageChart = ({ patientStageData, patientStageArray }) => {
  return (
    <div className={styles.stageContainer} style={{ flex: 1 }}>
      <h1>Patients at each stage</h1>
      <Row gutter={[12, 12]}>
        <Col lg={5}>
          <div className={styles.headerBg}>
            <HeaderCount
              icon={<PatientsIcon />}
              count={patientStageData?.totalPatients}
              info="Patients"
            />
          </div>
        </Col>
      </Row>
      <div className={styles.patientsProgress}>
        <div className={styles.line} />
        {patientStageArray?.map((obj, i) => {
          return (
            <div className={styles.patientProgress} key={i}>
                <Link to={obj.url}>
                  <Row gutter={[24, 24]} align="middle">
                    <Col lg={2}>
                      <span style={{ color: primaryColor }}>{obj.value}</span>
                    </Col>
                    <Col lg={7}>
                      <p>{obj.key}</p>
                    </Col>
                    <Col lg={15}>
                      <div>
                        <Progress
                          //  style={{width:obj.percentage < 5 ? "5%" : `${obj.percentage}%`}}
                          className={styles.customProgress}
                          style={{ opacity: obj.bg }}
                          percent={obj.percentage}
                          strokeColor={primaryColor}
                          showInfo={false}
                          strokeLinecap="square"
                        />
                      </div>
                    </Col>
                  </Row>
                </Link>

              <div className={styles.line} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StageChart;
