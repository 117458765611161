import ApiCore from "../services/api/utilities/core";
import axios from "axios";

export const labAxiosInstance = axios.create();

// console.log(labAxiosInstance.defaults.headers.Authorization);

const configurationObject = {
  getAll: true,
  getSingle: true,
  getClinic: true,
  post: true,
  put: true,
  patch: true,
  delete: true,
  isAuthRequired: true,

  //   plural: plural,
  //   single: single,
};

export const labApiTasks = new ApiCore(
  { ...configurationObject },
  labAxiosInstance
);



// labApiTasks.massUpdate = () => {};
