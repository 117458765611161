import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Modal, Row, Button, Col, Typography, Image, Spin, message, Upload } from "antd";
import TextEditor from "components/shared/TextEditor/TextEditor";
import {
  UserOutlined,
  SendOutlined,
  ReloadOutlined,
  UploadOutlined,
  FileOutlined,
} from "@ant-design/icons";
import "../../../assets/styles/modals/CommentModal.scss";
import { getCommentsAPI } from "services/api/common";
import { socket, uploadChatFile, activitySocket } from "api/chat";
import { imagesTypes, CHAT_FILE_SIZE } from "../../../constants";
import { checkTextEditorContent, removeTrailingEmptyTagsFromString } from "utils/Lab.js";
import styles from "./commentModal.module.scss";
import { subUserSelector } from "services/redux/selectors/lab.selectors";
import { primaryColor } from "colors-config";
const { Text } = Typography;

const CommentModal = ({
  showCommentsModal,
  toggleCommentModal,
  clinicId,
  lab,
  patientId,
  disableCommentBox,
  setUnreadCount,
  notificationApi,
}) => {
  const chatInfo = useMemo(
    () => ({ lab_id: lab.labId, clinic_id: clinicId, patient_id: patientId }),
    [lab, clinicId, patientId]
  );
  const subUser = useSelector(subUserSelector);
  const [inputText, setInputText] = useState("");
  const [senderId, setSenderId] = useState("");
  const [recieverId, setRecieverId] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [commentHistory, setCommentHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function isImage(mimeType) {
    // Extract the file extension from the MIME type
    const extension = mimeType.split("/")[1];

    // Check if the extension exists in the allowedExtensions array
    return imagesTypes.includes(extension);
  }

  const commentMetaMapping = (comment) => {
    const date = dayjs(comment.created_date).format("DD-MM-YYYY hh:mm A");
    const commentCopy = { ...comment, created_date: date };

    if (!comment?.metaData) {
      return commentCopy;
    }
    const metaData = JSON.parse(comment.metaData);

    if (!metaData.mimetype) {
      return commentCopy;
    }
    if (isImage(metaData.mimetype)) {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "image",
        metaData,
      };
    } else {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "file",
        metaData,
      };
    }
  };

  const onMessage = (comment) => {
    // console.log("message", comment);

    if (!showCommentsModal && comment?.module !== "lab") {
      setUnreadCount((prevCount) => {
        return prevCount + 1;
      });

      // comment.module === "clinic" &&
      //   notificationApi.info({
      //     message: "New Message from clinic",
      //   });
    }

    const mappedComment = commentMetaMapping(comment);
    setCommentHistory((prevMessages) => [...prevMessages, mappedComment]);
    scrollToBottom();
  };

  useEffect(() => {
    //console.log("connected", socket);
    socket.emit("joinRoom", chatInfo);
    socket.on("message", onMessage);

    return () => {
      socket.off("message", () => {
        console.log("off socket");
      });
    };
  }, [chatInfo]);

  // console.log("socket", socket);

  useEffect(() => {
    if (clinicId && lab.labId) {
      setRecieverId(clinicId);
      setSenderId(lab.labId);
      lab.clinicNames?.forEach((clinic) => {
        if (clinic.clinic_id === clinicId) setClinicName(clinic.clinic_name);
      });

      if (showCommentsModal) getCommentHistory();
    }
  }, [showCommentsModal, clinicId, lab.labId]);

  const getCommentHistory = async () => {
    setIsLoading(true);
    try {
      const response = await getCommentsAPI({
        senderId: senderId,
        receiverId: recieverId,
        patientId,
      });

      const mappedComments = response.data.body.data.map((comment) => {
        const mappedComment = commentMetaMapping(comment);

        return mappedComment;
      });

      setCommentHistory(mappedComments);
      if (response?.data?.body?.result?.length && response?.data?.body?.result[0]?.dentist_name) {
        setClinicName(response.data.body.result[0].dentist_name);
      }

      scrollToBottom();
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessage = (message) => {
    const hasInnerText = checkTextEditorContent(message);
    message = removeTrailingEmptyTagsFromString(message);
    if (hasInnerText) {
      socket.emit(
        "chatMessage",
        {
          meta: {
            receiver_id: chatInfo.clinic_id,
            sender_id: chatInfo.lab_id,
            patient_id: chatInfo.patient_id,
            module: "lab",
            staffId: subUser.id,
          },

          message,
        },
        (response) => {
          if (response && response?.status === "ok") {
            setInputText("");
            scrollToBottom();
          } else {
            message.error("server not responding! try again");
          }
        }
      );
    }
  };

  const sendHandler = () => {
    const stringText = inputText.toString();
    if (!stringText) {
      return;
    }
    sendMessage(stringText);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const chatWindow = document.getElementById("chatWindow");
      if (chatWindow?.scrollHeight > 0) {
        chatWindow.scrollTop = chatWindow?.scrollHeight;
      }
    }, 100);
  };

  const onFileChange = async (file) => {
    if (file?.size > CHAT_FILE_SIZE) {
      return message.error("One or more files should not be greater than 150MB.");
    }
    const data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    data.append("sender", "lab");
    data.append("lab_id", chatInfo.lab_id);
    data.append("clinic_id", chatInfo.clinic_id);
    data.append("patient_id", chatInfo.patient_id);

    data.append("receiver", "clinic");
    try {
      setIsLoading(true);
      await uploadChatFile(data);
    } catch (err) {
      console.log("err");
      message.error("failed to send file");
    } finally {
      setIsLoading(false);
    }

    return false;
  };

  const footer = (
    <div style={{ display: "flex", alignItems: "end", justifyContent: "start" }}>
      <TextEditor
        imageCallback={onFileChange}
        placeholder="Write your message here"
        editorInput={inputText}
        setEditorInput={setInputText}
      />

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div style={{ width: "100%", marginLeft: "1rem", textAlign: "left" }}>
          <Upload
            multiple
            customRequest={() => {}}
            showUploadList={false}
            beforeUpload={onFileChange}
          >
            <Button shape="circle" icon={<UploadOutlined />} />
          </Upload>
        </div>
        <Button
          type="primary"
          shape="circle"
          size="large"
          style={{ marginLeft: "12px" }}
          onClick={sendHandler}
          icon={<SendOutlined rotate={315}  style={{ fill: primaryColor}} />}
        />
      </div>
    </div>
  );
  const title = (
    <Row>
      <Col>
        <Button icon={<UserOutlined />} shape="circle" size="large" />
      </Col>
      <Col span={14} style={{ marginLeft: "10px", marginTop: "9px" }}>
        <Row>
          <Text style={{ fontSize: "16px" }}>{clinicName}</Text>
        </Row>
      </Col>
      <Col>
        <Button
          style={{ right: "-4rem", border: "none" }}
          icon={<ReloadOutlined />}
          onClick={getCommentHistory}
        />
      </Col>
    </Row>
  );

  function urlify(text) {
    const urlRegex = /https?:\/\/[^\s<>"']+(?![^<>]*>)/g;
    return text.replace(urlRegex, function (url, b, c) {
      var url2 = c == "www." ? "http://" + url : url;
      return '<a class="hyperlinksChat" href="' + url2 + '" target="_blank">' + url + "</a>";
    });
  }

  const replaceNewLine = (comment_description) => {
    if (comment_description) {
      comment_description = comment_description.replaceAll("\n", "<br>");
    }
    return comment_description;
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    link.click();
    link.remove();
  };
  const showContent = (commentData) => {
    if (commentData?.type === "image") {
      return <Image width={200} src={commentData.comment_description} />;
    }

    if (commentData?.type === "file") {
      return (
        <div
          title={commentData.metaData.name}
          onClick={() => handleDownload(commentData.comment_description, commentData.metaData.name)}
          className={styles["file-container"]}
        >
          <FileOutlined style={{ fontSize: "25px" }} />
          <span className={styles["file-name"]}>{commentData.metaData.name}</span>
        </div>
      );
    }

    return (
      <div
        className={styles["no-margin"]}
        dangerouslySetInnerHTML={{
          __html: urlify(replaceNewLine(commentData.comment_description)),
        }}
      />
    );
  };

  return (
    <Modal
      className="commentModal"
      open={showCommentsModal}
      onCancel={toggleCommentModal}
      style={{ marginRight: "33rem" }}
      width={450}
      footer={footer}
      closable={false}
      title={title}
    >
      <Spin spinning={isLoading}>
        <div id="chatWindow" style={{ height: "17.5rem", overflow: "auto" }}>
          {commentHistory?.map((data) => {
            return (
              <div className={data.module === "lab" ? "senderComment" : "receiverComment"}>
                <Row className={data.module === "lab" ? "senderHistory" : "receiverHistory"}>
                  {showContent(data)}
                </Row>
                <Row style={{ float: "right", fontSize: "10px" }}>
                  <span>{data.created_date}</span>
                </Row>

                <br />
              </div>
            );
          })}
        </div>
      </Spin>
    </Modal>
  );
};

export default CommentModal;
