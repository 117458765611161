export const ACTIVITY_TYPES = {
  ASSIGN_PATIENT: "ASSIGN_PATIENT",
  USER_COMMENT: "USER_COMMENT",
  CLINIC_TO_LAB_CHAT: "CLINIC_TO_LAB_CHAT",
  LAB_TO_CLINIC_CHAT:"LAB_TO_CLINIC_CHAT"
};


export const chatOptions = [
  {
    label: "Activity",
    value: 1,
  },

  {
    label: "Clinic",
    value: 2,
  },
];
