import { Select } from "antd";
import styles from "./styles.module.scss";
import { UserOutlined } from "@ant-design/icons";

const SelectAssignee = ({ options, value, onChange,loading, labelProps="name" }) => {
  return (
    <div className={styles["assignee-wrapper"]}>
      <Select
        showSearch
        optionLabelProp={labelProps}
        optionFilterProp={labelProps}
        dropdownStyle={{ width: "250px" }}
        placeholder={
          <span>
            <UserOutlined style={{color:"black",marginRight:"5px"}} />
            <span>Unassigned</span>
          </span>
        }
        onChange={onChange}
        value={value}
        style={{width: "160px",}}
        className={value ? styles["assignee-ValueWrapper"]: styles["assignee-emptyWrapper"]}
        onClick={(e) => e.stopPropagation()}
        options={options}
        loading={loading}
      />
    </div>
  );
};

export default SelectAssignee;
