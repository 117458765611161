import clearX from "./assets/images/clearx-banner.jpg";
import smileBanner from "./assets/images/Registration-Webpage-2.png";
import smileNavBarLogo from "./assets/images/smile-genius-logo.png";
import clearXNavLogo from "./assets/images/clear-x-logo-black.png";
import smileAlignLoginPage from "./assets/images/smilealign-login-page.jpg";
import smileAlignLogo from "./assets/images/smile-align-logo.png";
import iasLoginPage from "./assets/images/ias-login-page.png";
import iasLogo from "./assets/images/ias-logo.png";
import aquaLogo from "./assets/images/aquaaligner_logo.jpg";
import aquaLoginPage from "./assets/images/aqua_signup.jpg";
// "#00b96b"
//"#1F3332"
//"#41ACA4"
let primaryColor = "";
let secondaryColor = "";
let logoColor = "";
let LogInPageLogo = "";
let headerColor = "";
let sidebarColor = "";
let defaultActiveColor = "";
let sibarHoverColor = "";
let headerButtonBackgroundColor = "";
let headerButtonColor = "";
let tagBackgroundColor = "";
let tagTextColor = "";
let headerLogo = "";
let navBarTextColor = "";
let totalNumberBackgroundColor = "";
let totoalNumberTextColor = "";
let buttonColor = "";
let companyName = "";
let smileTermsUrl = "https://www.smilegeniusdental.com/terms";
let smilePrivacyUrl = "https://www.smilegeniusdental.com/privacy-policy";

let logoWidth = '11.1rem';
let logoMarginLeft = '1rem';
let defaultAppCurrency = 1;
let prefix = '+353';
let country = 'Ireland';

const companyType = Number(process.env.REACT_APP_COMPANY_TYPE);
if (companyType === 4) {
  primaryColor = "#1F3332";
  secondaryColor = "#fff";
  logoColor = "#41ACA4";
  LogInPageLogo = clearX;
  headerColor = "#0a1715";
  sidebarColor = "#0a1715";
  sibarHoverColor = "#41ACA4";
  defaultActiveColor = "#41ACA4";
  headerButtonColor = "#41ACA4";
  headerButtonBackgroundColor = "#fff";
  tagBackgroundColor = "#1F3332";
  tagTextColor = "#fff";
  headerLogo = clearXNavLogo;
  navBarTextColor = "#fff";
  totalNumberBackgroundColor = "#fff";
  totoalNumberTextColor = "#1F3332";
  buttonColor = "#fff";
  companyName = "Clearx Classic";
  logoWidth = '11.1rem';
  logoMarginLeft = '1rem';
} else if (companyType === 5) {
  defaultAppCurrency = 5;
  LogInPageLogo = smileAlignLoginPage;
  primaryColor = "#DE8754";
  secondaryColor = "#fff";
  logoColor = "#41ACA4";
  headerColor = "#ffffff";
  sidebarColor = "#DE8754";
  sibarHoverColor = "#ffffff";
  defaultActiveColor = "#ffffff";
  headerButtonColor = "#ffffff";
  headerButtonBackgroundColor = "#DE8754";
  tagBackgroundColor = "#f0f0f0";
  tagTextColor = "#DE8754";
  headerLogo = smileAlignLogo;
  navBarTextColor = "rgba(0,0,0,.85)";
  totalNumberBackgroundColor = "#DE8754";
  totoalNumberTextColor = "#fff";
  buttonColor = "#ffffff";
  companyName = "Smilelign";
  logoWidth = 'auto';
  logoMarginLeft = '0.5rem';
  prefix = '+44';
  country = 'United Kingdom';
}
else if (companyType === 6) {
  defaultAppCurrency = 5;
  LogInPageLogo = iasLoginPage;
  primaryColor = "#539ad1";
  secondaryColor = "#fff";
  logoColor = "#41ACA4";
  headerColor = "#ffffff";
  sidebarColor = "#539ad1";
  sibarHoverColor = "#ffffff";
  defaultActiveColor = "#ffffff";
  headerButtonColor = "#ffffff";
  headerButtonBackgroundColor = "#539ad1";
  tagBackgroundColor = "#f0f0f0";
  tagTextColor = "#539ad1";
  headerLogo = iasLogo;
  navBarTextColor = "rgba(0,0,0,.85)";
  totalNumberBackgroundColor = "#539ad1";
  totoalNumberTextColor = "#fff";
  buttonColor = "#ffffff";
  companyName = "IAS Academy";
  logoWidth = 'auto';
  logoMarginLeft = '1rem';
  prefix = '+44';
  country = 'United Kingdom';
} 
else if (companyType === 7) {
  defaultAppCurrency = 8;
  primaryColor = "#00bff2";
  secondaryColor = "#fff";
  logoColor = "#f205e2";
  LogInPageLogo = aquaLoginPage;
  headerColor = "#fff";
  sidebarColor = "#00c6e9";
  defaultActiveColor = "#e6ffff";
  sibarHoverColor = "#fff";
  headerButtonColor = "#fff";
  headerButtonBackgroundColor = "#00c6e9";
  tagBackgroundColor = "#DDFAFF";
  tagTextColor = "#00c6e9";
  headerLogo = aquaLogo;
  navBarTextColor = "rgba(0,0,0,.85)";
  totalNumberBackgroundColor = "#DDFAFF";
  totoalNumberTextColor = "#00c6e9";
  buttonColor = "#fff";
  companyName = "Aqua Aligner";
  logoWidth = '11.1rem';
  logoMarginLeft = '1rem';
  prefix = '+971';
  country = 'United Arab Emirates';
}
else {
  primaryColor = "#00c6e9";
  secondaryColor = "#fff";
  logoColor = "#f205e2";
  LogInPageLogo = smileBanner;
  headerColor = "#fff";
  sidebarColor = "#00c6e9";
  defaultActiveColor = "#e6ffff";
  sibarHoverColor = "#fff";
  headerButtonColor = "#fff";
  headerButtonBackgroundColor = "#00c6e9";
  tagBackgroundColor = "#DDFAFF";
  tagTextColor = "#00c6e9";
  headerLogo = smileNavBarLogo;
  navBarTextColor = "rgba(0,0,0,.85)";
  totalNumberBackgroundColor = "#DDFAFF";
  totoalNumberTextColor = "#00c6e9";
  buttonColor = "#fff";
  companyName = "Smile Genius";
  logoWidth = '11.1rem';
  logoMarginLeft = '1rem';
}

export {
  primaryColor,
  secondaryColor,
  logoColor,
  LogInPageLogo,
  headerColor,
  sidebarColor,
  defaultActiveColor,
  sibarHoverColor,
  headerButtonColor,
  headerButtonBackgroundColor,
  tagBackgroundColor,
  tagTextColor,
  headerLogo,
  navBarTextColor,
  totalNumberBackgroundColor,
  totoalNumberTextColor,
  buttonColor,
  companyName,
  smileTermsUrl,
  smilePrivacyUrl,
  companyType,
  logoWidth,
  logoMarginLeft,
  defaultAppCurrency,
  prefix,
  country
};
