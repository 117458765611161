import { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  Menu,
  Form,
  Input,
  Select,
  Button,
  message,
  Divider,
} from "antd";
import PropTypes from "prop-types";
import { DeleteOutlined } from "@ant-design/icons";

import { ReactComponent as Teeth18 } from "assets/images/teeths/teeth18.svg";
import { ReactComponent as Teeth17 } from "assets/images/teeths/teeth17.svg";
import { ReactComponent as Teeth16 } from "assets/images/teeths/teeth16.svg";
import { ReactComponent as Teeth15 } from "assets/images/teeths/teeth15.svg";
import { ReactComponent as Teeth14 } from "assets/images/teeths/teeth14.svg";
import { ReactComponent as Teeth13 } from "assets/images/teeths/teeth13.svg";
import { ReactComponent as Teeth12 } from "assets/images/teeths/teeth12.svg";
import { ReactComponent as Teeth11 } from "assets/images/teeths/teeth11.svg";

import { ReactComponent as Teeth21 } from "assets/images/teeths/teeth21.svg";
import { ReactComponent as Teeth22 } from "assets/images/teeths/teeth22.svg";
import { ReactComponent as Teeth23 } from "assets/images/teeths/teeth23.svg";
import { ReactComponent as Teeth24 } from "assets/images/teeths/teeth24.svg";
import { ReactComponent as Teeth25 } from "assets/images/teeths/teeth25.svg";
import { ReactComponent as Teeth26 } from "assets/images/teeths/teeth26.svg";
import { ReactComponent as Teeth27 } from "assets/images/teeths/teeth27.svg";
import { ReactComponent as Teeth28 } from "assets/images/teeths/teeth28.svg";

import { ReactComponent as Teeth48 } from "assets/images/teeths/teeth48.svg";
import { ReactComponent as Teeth47 } from "assets/images/teeths/teeth47.svg";
import { ReactComponent as Teeth46 } from "assets/images/teeths/teeth46.svg";
import { ReactComponent as Teeth45 } from "assets/images/teeths/teeth45.svg";
import { ReactComponent as Teeth44 } from "assets/images/teeths/teeth44.svg";
import { ReactComponent as Teeth43 } from "assets/images/teeths/teeth43.svg";
import { ReactComponent as Teeth42 } from "assets/images/teeths/teeth42.svg";
import { ReactComponent as Teeth41 } from "assets/images/teeths/teeth41.svg";

import { ReactComponent as Teeth31 } from "assets/images/teeths/teeth31.svg";
import { ReactComponent as Teeth32 } from "assets/images/teeths/teeth32.svg";
import { ReactComponent as Teeth33 } from "assets/images/teeths/teeth33.svg";
import { ReactComponent as Teeth34 } from "assets/images/teeths/teeth34.svg";
import { ReactComponent as Teeth35 } from "assets/images/teeths/teeth35.svg";
import { ReactComponent as Teeth36 } from "assets/images/teeths/teeth36.svg";
import { ReactComponent as Teeth37 } from "assets/images/teeths/teeth37.svg";
import { ReactComponent as Teeth38 } from "assets/images/teeths/teeth38.svg";
import { ReactComponent as PlusOutlined } from "assets/images/plusOutlined.svg";
import { ReactComponent as PlusTwo } from "assets/images/plusTwo.svg";
import { ReactComponent as PlusOutlinedGray } from "assets/images/plusOutlinedGray.svg";
import { companyType, primaryColor } from "colors-config";
import { hasAttachmentType } from "utils/ipr";

const { Option } = Select;
const { TextArea } = Input;

const svgComponents = {
  18: Teeth18,
  17: Teeth17,
  16: Teeth16,
  15: Teeth15,
  14: Teeth14,
  13: Teeth13,
  12: Teeth12,
  11: Teeth11,
  21: Teeth21,
  22: Teeth22,
  23: Teeth23,
  24: Teeth24,
  25: Teeth25,
  26: Teeth26,
  27: Teeth27,
  28: Teeth28,
  48: Teeth48,
  47: Teeth47,
  46: Teeth46,
  45: Teeth45,
  44: Teeth44,
  43: Teeth43,
  42: Teeth42,
  41: Teeth41,
  31: Teeth31,
  32: Teeth32,
  33: Teeth33,
  34: Teeth34,
  35: Teeth35,
  36: Teeth36,
  37: Teeth37,
  38: Teeth38,

  // ... add other mappings for different IDs
};

const ToothSvg = ({ id }) => {
  const SvgComponent = svgComponents[+id];
  return SvgComponent ? <SvgComponent /> : <div>No SVG for ID {id}</div>;
};

const Tooth = ({
  teeth,
  chartValue,
  index,
  handleAlignerChange,
  type,
  alignersArray,
  teethStructure,
  setTeethStructure,
  attachmentIndex,
  setAttachmentsArray,
  attachmentProductList,
  setAttachmentProductList,
  lowerAttachments,
  upperAttachments
}) => {
  const [form] = Form.useForm();
  const inputRef = useRef();
  const [productName, setProductName] = useState("");

  const addItem = () => {
    if (productName.trim() !== "") {
    const prevProducts = [...attachmentProductList]
    const prevNames = prevProducts.map((obj) => obj.attachmentName)
    if (prevNames.includes(productName)) {
      return message.error("Attachment name already exists")
    }
      setAttachmentProductList([
        ...attachmentProductList,
        { attachmentName: productName, value: productName },
      ]);
      setProductName("");
      inputRef.current.focus();
    }
  };


  const onAttachmentDeleteClick = (e,itemName) =>{
    e.stopPropagation();
    const attachments = [...upperAttachments, ...lowerAttachments]
    const result = hasAttachmentType(attachments, itemName);
    if (result) {
      return message.error("Attachment type is already used")
    }
    const prevProducts = [...attachmentProductList]
    const FilterProducts = prevProducts.filter((obj) => obj.attachmentName !== itemName)
    setAttachmentProductList(FilterProducts);
    // const value = form.getFieldValue("attachmentType")
  //  console.log("value", value)
  //  if (value) {
  //   form.setFieldsValue({
  //     attachmentType: value
  //   })
  //  }

  }

  function getTeethValuesWithTrue(array) {
    const teethValues = [];
    array.forEach((item) => {
      if (item.alignerValues) {
        item.alignerValues.forEach((attachment) => {
          if (attachment.value === true && attachment.teeth !== null) {
            teethValues.push(attachment.teeth);
          }
        });
      }
    });
    return teethValues;
  }

  useEffect(() => {
    let previousTooths = [];
    if (alignersArray.length) {
      previousTooths = getTeethValuesWithTrue(alignersArray);
      if (previousTooths.length) {
        const structure = [...teethStructure];

        const updatedStructure = structure.map((Item) => {
          const matchingTooth = previousTooths.find(
            (tooth) => tooth == Item.id
          );
          return {
            ...Item,
            selected: matchingTooth ? true : false,
          };
        });

        setTeethStructure(updatedStructure);
      }
    }
  }, []);

  const onFinish = (values) => {
    // console.log("Success:", values);
    // console.log("alignersArray", alignersArray);
    // console.log("value, index, type", index, type, alignersArray);
    // console.log("attachmentIndex", attachmentIndex);

    if (attachmentIndex == null) {
      return message.error("No aligner session is selected");
    }
    if (type !== attachmentIndex.type) {
      return message.error("No aligner is selected");
    }
    const newData = [...alignersArray];
    const attachmentObj = {
      detail: values.detail,
      attachmentType: values.attachmentType,
      teeth: values.toothNo,
      value: true,
    };

    const myIndex = attachmentIndex.value;
    newData[myIndex]["alignerValues"][index] = attachmentObj;
    setAttachmentsArray(newData);

    const structure = [...teethStructure];
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === Number(values.toothNo)) {
        return {
          ...tooth,
          selected: true,
          visible: false,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);

    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleDetailCancel = (id) => {
    const structure = [...teethStructure];
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === id) {
        return {
          ...tooth,
          visible: false,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);
    form.resetFields();
  };

  const handDropDown = (visible) => {
    if (!visible) {
      const structure = [...teethStructure];
      const updatedTeths = structure.map((tooth) => {
        return {
          ...tooth,
          visible: false,
        };
      });
      setTeethStructure(updatedTeths);
      form.resetFields();
    }
  };

  const handleSelectedTooth = (id) => {
    form.setFieldsValue({
      toothNo: id,
    });
    const structure = [...teethStructure];
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === id) {
        return {
          ...tooth,
          visible: !tooth.visible,
        };
      }
      return { ...tooth, visible: false };
    });
    setTeethStructure(updatedTeths);
  };
  

  return (
    <>
      <div
        className={`${
          teeth.selected ? "teeth-structure active-teeth" : "teeth-structure"
        } ${
          companyType === 5
            ? "teeth-structure-smilealign"
            : ""
        }
        ${
          companyType === 6
            ? "teeth-structure-ias"
            : ""
        }
        ${
          companyType === 7
            ? "teeth-structure-aqua"
            : ""
        }
        `}
      >
        {<ToothSvg id={teeth.showName} />}

        <Dropdown
          visible={teeth.visible}
          onVisibleChange={(visible) => handDropDown(visible)}
          overlay={
            <Menu>
              <Menu.Item key="tooth">
                <Form
                  form={form}
                  name={teeth.name}
                  initialValues={{}}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="statusForm"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Form.Item
                      label=""
                      name="toothNo"
                      style={{ visibility: "hidden" }}
                    >
                      <Input disabled />
                    </Form.Item>
                    <p
                      className="tootName"
                      style={{ backgroundColor: primaryColor }}
                    >
                      {chartValue === "fdi"
                        ? teeth.showName
                        : chartValue === "universal"
                        ? teeth.universalName
                        : teeth.palmerName}
                    </p>
                  </div>

                  <Form.Item
                    label=""
                    name="attachmentType"
                    rules={[
                      {
                        required: true,
                        message: "Please select attachment Type",
                      },
                    ]}
                  >
                    <Select
                    className="lab-product-selectbox"
                      placeholder="Select Attachment Type"
                      size="large"
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: "8px 0",
                              }}
                            />
                            <div
                              style={{
                                padding: "0 0px 5px",
                                display: "flex",
                                alignItems: "center",
                                gap: "7px",
                              }}
                            >
                              <Input
                                style={{ width: "100%" }}
                                placeholder="Enter name"
                                ref={inputRef}
                                value={productName}
                                // onChange={onNameChange}
                                onChange={(e) =>
                                  e.target.value.length <= 50 &&
                                  setProductName(e.target.value)
                                }
                                // onKeyDown={(e) => e.stopPropagation()}
                              />
                              <Button
                                type="text"
                                icon={<PlusOutlined style={{fill: primaryColor}}/>}
                                onClick={addItem}
                                style={{ color: primaryColor, display:"flex", alignItems:"center", padding:"none" , width:"107px"}}
                              >
                                Add item
                              </Button>
                            </div>
                          </>
                        );
                      }}
                      options={attachmentProductList?.map((item, i) => {
                        return {
                          value: item.attachmentName,
                          label: (
                            <>
                              <div className="lab-product-selectbox-item">
                                {item.attachmentName}
                                <DeleteOutlined
                                          className="delete-icon"
                                          onClick={(e) =>
                                            onAttachmentDeleteClick(e,item.attachmentName)
                                          }
                                        />
                              </div>
                            </>
                          ),
                        };
                      })}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    label=""
                    name="detail"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter detail!",
                    //   },
                    // ]}
                  >
                    <TextArea placeholder="Detail" rows={3} />
                  </Form.Item>
                  <div className="statusForm-btns">
                    <Form.Item>
                      <Button
                        type="danger"
                        onClick={() => handleDetailCancel(teeth.id)}
                        className="cancel-btn"
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="save-btn"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <div
            className={
              alignersArray.length === 0
                ? "attachment-plus"
                : teeth.selected
                ? `attachment-plus attachment-plus${type} attachment-two`
                : `attachment-plus attachment-plus${type}`
            }
            onClick={(e) =>
              !alignersArray.length ? null : handleSelectedTooth(teeth.id)
            }
          >
            {alignersArray.length === 0 ? (
              <PlusOutlinedGray />
            ) : teeth.selected ? (
              <PlusTwo />
            ) : (
              <PlusOutlined style={{ fill: primaryColor }} />
            )}
          </div>
        </Dropdown>

        <span style={{ top: "42%" }}>
          {chartValue === "fdi"
            ? teeth.showName
            : chartValue === "universal"
            ? teeth.universalName
            : teeth.palmerName}
        </span>
      </div>
    </>
  );
};

Tooth.propTypes = {
  //   handleAlignerChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  chartValue: PropTypes.string.isRequired,
};

export default Tooth;
