import AppTag from "components/shared/AppTag/AppTag";
import Actions from "./Actions";
import { isAdmin } from "utils/auth";
export const makeStaffColumns = (staffActionHandler, subUser) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

      render: (text) => {
        return <span style={{ margin: 0, textTransform: "capitalize" }}>{text}</span>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",

      render: (_, record) => {
        return <span>{`${record.countryCode} ${record.phone}`}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "displayRole",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "displayStatus",
      align: "center",
      key: "displayStatus",
      render: (text) => {
        return <AppTag text={text} />;
      },
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, row) => {
        if (!isAdmin(subUser.role)) {
          return null;
        }
        return <Actions row={row} staffActionHandler={staffActionHandler} />;
      },
    },
  ];
};
