import { useEffect } from "react";
import {
  Dropdown,
  Menu,
  Select,
  Form,
  Button,
  Input,
  Tooltip,
  message,
} from "antd";
import { removeUnderscore } from "components/private/patient-detail/newPrescriptionForm/utils/index";
import { companyType } from "colors-config";

const { Option } = Select;
const { TextArea } = Input;

const Teeths = ({
  teethStructure,
  toothStatuses,
  setTeethStructure,
  setToothStatuses,
  chartValue,
  disabled,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const structure = [...teethStructure];
    const updatedStructure = structure.map((Item) => {
      const matchingTooth = toothStatuses?.find(
        (tooth) => tooth.toothNo == Item.name
      );
      return {
        ...Item,
        selected: matchingTooth ? true : false,
        previous: matchingTooth ? true : false,
        procedure: matchingTooth ? matchingTooth?.toothProcedure : "",
        detail: matchingTooth ? matchingTooth?.detail : "",
      };

    });
    setTeethStructure(updatedStructure);
  }, [toothStatuses]);

  const handleSelectedTooth = (id) => {
    // console.log("selected", id);
    const filteredStatus = toothStatuses?.filter((tooth) => tooth.id === id);
    if (filteredStatus?.length > 0) {
      message.info("Tooth is already selected add additional data");
    } else {
      form.setFieldsValue({
        toothNo: id,
      });
      const structure = [...teethStructure];
      const updatedTeths = structure.map((tooth) => {
        if (tooth.id === id) {
          return {
            ...tooth,
            selected: tooth.selected ? true : false,
            visible: !tooth.visible,
          };
        }
        return { ...tooth, visible: false };
      });
      setTeethStructure(updatedTeths);
    }
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    const toothStatusObj = {
      toothNo: values.toothNo,
      detail: values.detail,
      procedure: values.procedure,
      addOns: [],
      isExtra: false,
      isAdditional: false,
    };
    const newStatus = [...toothStatuses, toothStatusObj];
    setToothStatuses(newStatus);
    const structure = [...teethStructure];
    // console.log("structure", structure);
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === Number(values.toothNo)) {
        return {
          ...tooth,
          selected: true,
          visible: false,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);
    form.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleDetailCancel = (id) => {
    const structure = [...teethStructure];
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === id) {
        return {
          ...tooth,
          visible: false,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);
  };

  const handleAddTooth = () => {
    const toothStatusObj = {
      procedure: "crown",
      detail: "",
      toothNo: null,
      isExtra: true,
      isAdditional: false,
      addOns: [],
    };
    const newStatus = [...toothStatuses, toothStatusObj];
    setToothStatuses(newStatus);
  };

  const handDropDown = (visible) => {
    if (!visible) {
      const structure = [...teethStructure];
      const updatedTeths = structure.map((tooth) => {
        return {
          ...tooth,
          visible: false,
        };
      });
      setTeethStructure(updatedTeths);
    }
  };
  let tooth;
  return (
    <>
      <div className="teethRow-container">
        <div className="teethRow-bgOne" />
        <div className="teethRow-one">
          {teethStructure.map((teeth, i) => {
            return (
              <Tooltip
                placement="topLeft"
                title={
                  <div>
                    {teeth.previous ? (
                      <div>
                        <p>{removeUnderscore(teeth?.procedure)}</p>
                        <p>{teeth.detail}</p>
                      </div>
                    ) : (
                      `Tooth No: ${
                        chartValue === "fdi"
                          ? teeth.showName
                          : chartValue === "universal"
                          ? teeth.universalName
                          : teeth.palmerName
                      }`
                    )}
                  </div>
                }
                key={i}
              >
                <Dropdown
                  visible={teeth.visible}
                  onVisibleChange={(visible) => handDropDown(visible)}
                  overlay={
                    <Menu>
                      <Menu.Item key="tooth">
                        <Form
                          form={form}
                          name={teeth.name}
                          initialValues={{}}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          className="statusForm"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Form.Item
                              label=""
                              name="toothNo"
                              style={{ visibility: "hidden" }}
                            >
                              <Input disabled />
                            </Form.Item>
                            <p className="tootName">
                              {chartValue === "fdi"
                                ? teeth.showName
                                : chartValue === "universal"
                                ? teeth.universalName
                                : teeth.palmerName}
                            </p>
                          </div>

                          <Form.Item
                            label=""
                            name="procedure"
                            rules={[
                              {
                                required: true,
                                message: "Please select procedure!",
                              },
                            ]}
                          >
                            <Select placeholder="Select Procedure" size="large">
                              <Option value="tooth_status">Tooth Status</Option>
                              <Option value="missing">Missing</Option>
                              <Option value="extraction_planned">
                                Extraction Planned
                              </Option>
                              <Option value="crown">Crown</Option>
                              <Option value="veneer">Veneer</Option>
                              <Option value="Inlay_Onlay">Inlay / Onlay</Option>
                              <Option value="bridge">Bridge</Option>
                              <Option value="implant">Implant</Option>
                              <Option value="deciduous">Deciduous</Option>
                              <Option value="impacted">Impacted</Option>
                              <Option value="other">Other</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label=""
                            name="detail"
                            rules={[
                              {
                                required: true,
                                message: "Please enter detail!",
                              },
                            ]}
                          >
                            <TextArea placeholder="Detail" rows={3} />
                          </Form.Item>
                          <div className="statusForm-btns">
                            <Form.Item>
                              <Button
                                type="danger"
                                onClick={() => handleDetailCancel(teeth.id)}
                                className="cancel-btn"
                              >
                                Cancel
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="save-btn"
                              >
                                Save
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <div
                   className={`${teeth.selected
                      ? "teeth-structure active-teeth"
                      : "teeth-structure"} ${companyType==5?"teeth-structure-smilealign":""}
                      ${companyType===6?"teeth-structure-ias":""}
                      ${companyType===7?"teeth-structure-aqua":""}
                      `}
                    onClick={(e) =>
                      disabled ? null : handleSelectedTooth(teeth.id)
                    }
                  >
                    {teeth.content}
                    <span>
                      {chartValue === "fdi"
                        ? teeth.showName
                        : chartValue === "universal"
                        ? teeth.universalName
                        : teeth.palmerName}
                    </span>
                  </div>
                </Dropdown>
              </Tooltip>
            );
          })}
          <div className="tooth-sides">
            <h2>Right</h2>
            <h2>Left</h2>
          </div>
        </div>
        <div className="teethRow-bgTwo"></div>
      </div>
    </>
  );
};

export default Teeths;
