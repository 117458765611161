import React, { useState, useEffect, useRef } from "react";

import { Col, Row, Upload, message, Typography, Modal, Anchor, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { labApiTasks } from "../../../models/lab";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete_icon.svg";
import { DownloadIcon } from "components/shared/Icon/index";
import { StlViewer } from "react-stl-file-viewer";
import { primaryColor } from "colors-config";
// import STLViewer from 'stl-viewer'
import axios from "axios";
import { useHistory } from "react-router-dom";
// import { DownloadOutlined } from "@ant-design/icons";
import { confirmScanFileAPI, downloadS3File, uploadScanFileAPI } from "api/patient";
import { deleteScanService } from "api/prescription";

import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";
import { getScanClass } from "utils/Lab";

const { Dragger } = Upload;
const { Link } = Anchor;
const { Text } = Typography;

const UploadScanComponent = ({
  scanUrls,
  patientId,
  checkAll,
  mode,
  isDone,
  updateDoneState,
  setScanUrls,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalUrl, setModalUrl] = useState("");
  const [isUpperFileUploaded, setIsUpperFileUploaded] = useState(false);
  const [isLowerFileUploaded, setIsLowerFileUploaded] = useState(false);
  const [isBiteFileUploaded, setIsBiteFileUploaded] = useState(false);
  const [isBiteFileUploaded2, setIsBiteFileUploaded2] = useState(false);
  const history = useHistory();
  // const [selectedFile, setSelectedFle] = useState();
  // const [scanType, setScanType] = useState("UpperArch");
  const [patient_Id] = useState(patientId);
  const [upperArchFile, setUpperArchFile] = useState([]);
  const [lowerArchFile, setLowerArchFile] = useState([]);
  const [biteScanFile, setBiteScanFile] = useState([]);
  const [biteScanFile2, setBiteScanFile2] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [volume, setvolume] = useState(0);
  const [scanFile, setScanFile] = useState({
    currentScanFileKey: "",
  });
  const [scanFileObj, setScanFileObj] = useState({
    UpperArch: "",
    LowerArch: "",
    BiteScan: "",
    BiteScan2: "",
  });
  const [scanFileOption, setScanFileOption] = useState({
    UpperArch: "",
    LowerArch: "",
    BiteScan: "",
    BiteScan2: "",
  });
  // const downloadScanFile = async (title, filename) => {
  //   const imageUrl = await getImageUrl(filename);
  //   var withOutAccessKeyImg = imageUrl.url.indexOf("?");
  //   var processedURl = imageUrl.url.slice(0, withOutAccessKeyImg);
  //   window.open(processedURl);
  // };

  const downloadScanFile = async (title, scanData) => {
    if (scanData?.url) {
      // handleDownload(title, scanData.url);
      download(scanData);
    }
  };

  const download = async (file) => {
    const fileName = file.resource_url?.split("/")[1];
    if (fileName) {
      window.open(downloadS3File(fileName));
    }
  };

  const handleDownload = (fileName, url) => {
    // Create a blob from the file content

    // Create an anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Set the file name for download
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    document.body.removeChild(a);
  };

  useEffect(() => {
    setUpperArchFile(scanUrls.filter((item) => item.patient_image_view === "UpperArch"));
    setLowerArchFile(scanUrls.filter((item) => item.patient_image_view === "LowerArch"));
    setBiteScanFile(scanUrls.filter((item) => item.patient_image_view === "BiteScan"));

    setBiteScanFile2(scanUrls.filter((item) => item.patient_image_view === "BiteScan2"));

    if (modalUrl !== "") {
      setIsModalVisible(true);
    }
  }, [
    isUpperFileUploaded,
    isLowerFileUploaded,
    isBiteFileUploaded,
    modalUrl,
    isBiteFileUploaded2,
    scanUrls,
  ]);

  useEffect(() => {
    if (isDone) {
      if (scanFileObj.UpperArch !== "" && scanFileObj.LowerArch !== "") {
        uploadFilesToDb();
      } else {
        message.error("Please upload all files before proceeding");
      }
      updateDoneState(false);
    }
  }, [isDone]);

  const showModal = (title, file) => {
    const extension = getFileType(file.resource_url);
    if (extension == "stl") {
      getImageUrl(file.resource_url);
      setModalTitle(title);
    } else {
      downloadScanFile(title, file);
    }
  };
  const getImageUrl = async (filename) => {
    const requestBody = {
      file_name: filename,
    };
    try {
      const response = await labApiTasks.post(
        "lab/patient/resource/getOnboardingImagesURL",
        requestBody
      );
      const url = response.body.url;

      var withOutAccessKeyImg = url.url.indexOf("?");
      var processedURl = url.url.slice(0, withOutAccessKeyImg);
      setModalUrl(processedURl);
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalTitle("");
    setModalUrl("");
  };

  const uploadFileTask = (fileName, options) => {
    return new Promise(async (resolve) => {
      const { onSuccess, onError, file, onProgress, filename } = options;
      const fmData = new FormData();
      const config = {
        headers: {
          "content-type": "model/stl",
        },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          if (percent === 100) {
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("file", file);
      try {
        const preSignRequestBody = {
          // clinic_id: clinic.clinicId,
          patient_id: patient_Id,
          file_name: fileName,
          resource_type: "file",
          file_type: "stl",
        };
        const preSignedResponse = await labApiTasks.post(
          "patient/resource/add",
          preSignRequestBody
        );
        const accessUrl = preSignedResponse.body.success.accessUrl;
        const uploadUrl = preSignedResponse.body.success.uploadUrl;
        const uploadResponse = await axios.put(uploadUrl, file, config);
        const successFullUpload = await labApiTasks.post("lab/patient/resource/upload/confirm", {
          patient_id: patient_Id,
          file_url: accessUrl,
          patient_upload_stage: "scan",
          resource_type: "url",
          scan_type: filename,
        });
        onSuccess("Ok");
        return resolve(true);
      } catch (error) {
        console.log(error);
        onError({ error });
        return resolve(false);
      }
    });
  };
  const uploadFilesToDb = async () => {
    setIsSpinning(true);
    let uploadCount = 0;
    for (let key in scanFileObj) {
      if (scanFileObj[key] !== "") {
        const uploadStatus = await uploadFileTask(scanFileObj[key], scanFileOption[key]);
        if (uploadStatus) {
          uploadCount = uploadCount + 1;
        }
      }
    }
    if (uploadCount === 3) {
      checkAll(true, true, true);
    } else {
      checkAll(false, true, true);
    }
    setIsSpinning(false);
  };

  async function uploadFiles(options) {
    const { onSuccess, onError, file, onProgress, filename } = options;
    const fileNameArray = file.name.split(".");
    fileNameArray[0] = fileNameArray[0] + "-" + file.uid;
    const fileName = fileNameArray.join(".");
    const fmData = new FormData();
    // console.log(fileName)
    const config = {
      headers: {
        "content-type": file.type,
      },

      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);

        if (percent === 100) {
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {
      const preSignRequestBody = {
        // clinic_id: clinic.clinicId,
        patient_id: patient_Id,
        file_name: fileName,
        resource_type: "file",
        file_type: file.type,
        content_type: file.type,
      };
      const preSignedResponse = await uploadScanFileAPI(preSignRequestBody);
      const accessUrl = preSignedResponse?.data.body?.success.accessUrl;
      const uploadUrl = preSignedResponse?.data?.body.success.uploadUrl;
      const uploadResponse = await axios.put(uploadUrl, file, config);
      console.log("uploadResponse", uploadResponse, accessUrl, uploadUrl);

      const successFullUpload = await confirmScanFileAPI({
        patient_id: patient_Id,
        file_url: accessUrl,
        patient_upload_stage: "scan",
        resource_type: "url",
        scan_type: filename,
        case_id: 1,
      });
      const scanRes = await axios.get(
        `${
          process.env.REACT_APP_CLINIC_BASE_URL
        }/clinic/patient/resource/getOnboardingImages?patientId=${patient_Id}&patientStage=scan&caseId=${1}`
      );
      setScanUrls(scanRes?.data?.body?.results?.patient_onboarding_images);
      onSuccess("Ok");
    } catch (error) {
      console.log(error);

      onError({ error });
    }
  }
  const onDeleteConfirmation = async (data) => {
    const deleteData = {
      patient_id: data?.patient_id,
      file_name: data?.resource_url,
    };
    console.log("data", data);
    const scanResModel = { patientId: data.patient_id, patientStage: "scan", caseId: data.case_id };
    const { patient_id, case_id } = data;

    await deleteScanService(deleteData);

    const scanRes = await axios.get(
      `${process.env.REACT_APP_CLINIC_BASE_URL}/clinic/patient/resource/getOnboardingImages?patientId=${patient_id}&patientStage=scan&caseId=${case_id}`
    );
    setScanUrls(scanRes?.data?.body?.results?.patient_onboarding_images);
    message.success("Resource Deleted Successfully");
  };

  const deleteScan = async (data) => {
    const deletScanConfig = createConfirmConfig({
      title: "Delete Scan File",
      content: "Are you sure you want to delete the scan file?",
      onOk: () => onDeleteConfirmation(data),
      onCancel: () => {},
      okText: "Confirm",
    });

    modal.confirm(deletScanConfig);
  };

  const properties = {
    // accept: ".stl",
    multiple: false,
    maxCount: 1,
    customRequest: uploadFiles,
  };

  const onChangeHandler = (info, type) => {
    const { status } = info.file;
    // setSelectedFle(info.file);
    // setScanType(event.target.id);
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      if (type === "UpperArch") setIsUpperFileUploaded(true);
      else if (type === "LowerArch") setIsLowerFileUploaded(true);
      else if (type === "BiteScan") setIsBiteFileUploaded(true);
      else setIsBiteFileUploaded2(true);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setIsUpperFileUploaded(false);
      setIsLowerFileUploaded(false);
      setIsBiteFileUploaded(false);
      setIsBiteFileUploaded2(false);
    }
  };

  const getFileType = (fileName) => {
    const value = fileName.substring(fileName.lastIndexOf(".") + 1);
    return value;
  };

  return (
    <div>
      {contextHolder}
      <Spin spinning={isSpinning}>
        <Row>
          <Col className="scan-container">
            <div>
              <div className="scan-subheader required">Upper Arch</div>
              {upperArchFile.length > 0 ? (
                <div>
                  <div
                    className="NewClassAnchor"
                    style={{ textAlign: "start" }}
                  >
                    <span
                      className={getScanClass()}
                      onClick={() => showModal("UpperArch", upperArchFile[0])}
                    >
                      UpperArch.{getFileType(upperArchFile[0].resource_url)}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <DownloadIcon
                        onClick={() =>
                          downloadScanFile("UpperArch", upperArchFile[0])
                        }
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deleteScan(upperArchFile[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Dragger
                    name="UpperArch"
                    className="scan-uploader"
                    onChange={(info) => onChangeHandler(info, "UpperArch")}
                    {...properties}
                  >
                    <div>
                      <div className="icon-margin">
                        <UploadOutlined />
                      </div>

                      <div className="scan-upload-text">
                        Click or drag file to this area to upload
                      </div>
                    </div>
                  </Dragger>
                </div>
              )}
            </div>
          </Col>
          <Col className="scan-container">
            <div>
              <div className="scan-subheader required">Lower Arch</div>
              {lowerArchFile.length > 0 ? (
                <div>
                  <div
                    className="NewClassAnchor"
                    style={{ textAlign: "start" }}
                  >
                    <span
                    className={getScanClass()}
                      onClick={() => showModal("LowerArch", lowerArchFile[0])}
                    >
                      LowerArch.{getFileType(lowerArchFile[0].resource_url)}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <DownloadIcon
                        onClick={() =>
                          downloadScanFile("LowerArch", lowerArchFile[0])
                        }
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deleteScan(lowerArchFile[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Dragger
                    name="LowerArch"
                    className="scan-uploader"
                    onChange={(info) => onChangeHandler(info, "LowerArch")}
                    {...properties}
                  >
                    <div>
                      <div className="icon-margin">
                        <UploadOutlined />
                      </div>

                      <div className="scan-upload-text">
                        Click or drag file to this area to upload
                      </div>
                    </div>
                  </Dragger>
                </div>
              )}
            </div>
          </Col>
          <Col className="scan-container">
            <div>
              <div className="scan-subheader">&nbsp;&nbsp;Bite Scan</div>
              {biteScanFile.length > 0 ? (
                <div>
                  <div
                    className="NewClassAnchor"
                    style={{ textAlign: "start" }}
                  >
                    <span
                     className={getScanClass()}
                      onClick={() => showModal("BiteScan", biteScanFile[0])}
                    >
                      BiteScan.{getFileType(biteScanFile[0].resource_url)}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <DownloadIcon
                        onClick={() =>
                          downloadScanFile("BiteScan", biteScanFile[0])
                        }
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deleteScan(biteScanFile[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Dragger
                    name="BiteScan"
                    className="scan-uploader"
                    onChange={(info) => onChangeHandler(info, "BiteScan")}
                    {...properties}
                  >
                    <div>
                      <div className="icon-margin">
                        <UploadOutlined />
                      </div>

                      <div className="scan-upload-text">
                        Click or drag file to this area to upload
                      </div>
                    </div>
                  </Dragger>
                </div>
              )}
            </div>
          </Col>
          <Col className="scan-container">
            <div>
              <div className="scan-subheader">Bite Scan2</div>
              {biteScanFile2.length > 0 ? (
                <div>
                  <div
                    className="NewClassAnchor"
                    style={{ textAlign: "start" }}
                  >
                    <span
                      className={getScanClass()}
                      onClick={() => showModal("BiteScan2", biteScanFile2[0])}
                    >
                      BiteScan2{getFileType(biteScanFile2[0].resource_url)}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <DownloadIcon
                        onClick={() =>
                          downloadScanFile("BiteScan2", biteScanFile2[0])
                        }
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deleteScan(biteScanFile2[0]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Dragger
                    name="BiteScan2"
                    className="scan-uploader"
                    onChange={(info) => onChangeHandler(info, "BiteScan2")}
                    {...properties}
                  >
                    <div>
                      <div className="icon-margin">
                        <UploadOutlined />
                      </div>

                      <div className="scan-upload-text">
                        Click or drag file to this area to upload
                      </div>
                    </div>
                  </Dragger>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Spin>
      {isModalVisible && (
        <Modal
          title={modalTitle}
          open={isModalVisible}
          footer={null}
          // onOk={handleCancel}
          onCancel={handleCancel}
        >
          <StlViewer
            width={450}
            height={400}
            url={modalUrl}
            groundColor="rgb(255, 255, 255)"
            objectColor="rgb(137, 137, 137)"
            skyboxColor="rgb(255, 255, 255)"
            gridLineColor="rgb(0, 0, 0)"
            lightColor="rgb(255, 255, 255)"
            volume={setvolume}
          />
        </Modal>
      )}
    </div>
  );
};

export default UploadScanComponent;
