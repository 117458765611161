import dayjs from "dayjs";

export const getStatusNames = () => {
  return [
    {
      text: "Email sent to clinician",
      value: "ONBOARDING_REQUESTED",
    },
    {
      text: "Verification pending",
      value: "VERIFICATION_PENDING",
    },
    {
      text: "Onboarding pending",
      value: "ONBOARDING_PENDING",
    },
    {
      text: "Onboarding in progress",
      value: "ONBOARDING_IN_PROGRESS",
    },
    {
      text: "Onboarding completed",
      value: "ONBOARDING_COMPLETED",
    },
  ];
};

export const createPartnersTableData = (partneredClinics) => {
  if (!partneredClinics?.length) {
    return [];
  }
  return partneredClinics?.map((dataObj, index) => {
    return {
      key: index,
      clinicDetails: dataObj,
      orders: dataObj.lab_order_count,
      clinicName: dataObj.clinic_name,
      clinician_name: dataObj.clinician_name,
      status: dataObj.lab_partnership_status,
      email: dataObj.clinic_email,
      mobile: dataObj.country_code_name + " " + dataObj.clinic_contact,
      hours: getClinicTime(dataObj),
    };
  });
};

export const onBoardingMapper = (arr) => {
  return arr.map((clinic) => {
    return {
      ...clinic,
      mobile: clinic.phone ? `${clinic.countryCode}-${clinic.phone}` : "--", 
      actions: { id: clinic.id },
    };
  });
};

export const getClinicTime = (clinicDetails) => {
  const today =  dayjs().format("YYYY-MM-DD");
  if (clinicDetails?.clinic_start_time) {
    return (
      dayjs(today + " " + clinicDetails?.clinic_start_time).format(
        "HH:mm"
      ) +
      " - " +
      dayjs(today + " " + clinicDetails?.clinic_end_time).format("HH:mm")
    );
  }
  return "Not available";
};

export const getAddClinicButtonText = (clinic) =>{
  let text = "Send Invite"
  if (clinic) {
  text ="Update"   
  }
  return text
}


export const getAddClinicHeaderText = (clinic, upload) => {
  if (upload) {
    return "Upload File";
  } else if (clinic) {
    return "Resend Invite";
  } else {
    return "Add New Clinician";
  }
};


export const calculateErrorCount = (data) => {
  let errorCount = 0;

  data.forEach((item) => {
    if (item.isRequiredEmail) {
      errorCount++;
    }
    if (item.inValidEmail) {
      errorCount++;
    }
    if (item.emailExists) {
      errorCount++;
    }
    if (item.duplicate) {
      errorCount++;
    }
    if (item.isRequiredClinicName) {
      errorCount++;
    }
    if (item.isMinLengthCilinicName) {
      errorCount++;
    }
    if (item.isMaxLengthCilinicName) {
      errorCount++;
    }
    if (item.isRequiredClinicianName) {
      errorCount++;
    }
    if (item.isMinLengthCilinicianName) {
      errorCount++;
    }
    if (item.isMaxLengthCilinicianName) {
      errorCount++;
    }
    if (item.isMinLengthAddress) {
      errorCount++;
    }
    if (item.isMaxLengthAddress) {
      errorCount++;
    }
    if (item.isMinLengthCity) {
      errorCount++;
    }
    if (item.isMaxLengthCity) {
      errorCount++;
    }
    if (item.isMinLengthCounty) {
      errorCount++;
    }
    if (item.isMaxLengthCounty) {
      errorCount++;
    }
    // if (item.inValidZipCode) {
    //   errorCount++;
    // }
    if (item.isMinLengthZipCode) {
      errorCount++;
    }
    if (item.isMaxLengthZipCode) {
      errorCount++;
    }
    if (item.isNotAlphabets) {
      errorCount++;
    }
    if (item.isRequiredCountry) {
      errorCount++;
    }
    if (item.inValidCountryCode) {
      errorCount++;
    }
    if (item.isMaxLengthCode) {
      errorCount++;
    }
    if (item.inValidPhone) {
      errorCount++;
    }
    if (item.isMinLengthPhone) {
      errorCount++;
    }
    if (item.isMaxLengthPhone) {
      errorCount++;
    }
  });

  return errorCount;
};


export const getInvitesClinicButtonText = (errors,) =>{
  let text = "Send Invite"
  if (errors !==0 ) {
  text = "Ignore & Send Invite"   
  }
  return text
}


export const getSuccessButtonText = (data) =>{
  let recordCount = 0;
  data.forEach(item => {
    if (!(item.isRequiredEmail || item.inValidEmail || item.emailExists || item.duplicate) 
    && !(item.isRequiredClinicName || item.isMinLengthCilinicName || item.isMaxLengthCilinicName) 
    && !(item.isRequiredClinicianName || item.isMinLengthCilinicianName || item.isMaxLengthCilinicianName) 
    && !(item.isMinLengthAddress || item.isMaxLengthAddress) 
    && !(item.isMinLengthCity || item.isMaxLengthCity) 
    && !(item.isMinLengthCounty || item.isMaxLengthCounty) 
    && !(item.isMinLengthZipCode || item.isMaxLengthZipCode) 
    && !(item.isNotAlphabets || item.isRequiredCountry) 
    && !(item.inValidCountryCode || item.isMaxLengthCode) 
    && !(item.inValidPhone || item.isMinLengthPhone || item.isMaxLengthPhone)) {
      recordCount++;
    }
  })
  return recordCount
}

export const getCountryErrorTitle = (record) => {
  let title = ""
  if(record.isNotAlphabets){
    title = "Country name should only contain alphabetic characters. "
  }
  else if(record.isRequiredCountry){
    title = "Country is required"
  }
  return title;
}


export const getClinicNameErrorTitle = (record) => {
  let title = ""
  if (record.isRequiredClinicName) {
    title = "Clinic name is required"
  }
  else if (record.isMinLengthCilinicName) {
    title = "Clinic name must be at least 3 characters long."
  }
  else if (record.isMaxLengthCilinicName) {
    title = "Clinic name must not exceed 100 characters."
  }
  return title
}

export const getClinicianNameErrorTitle = (record) =>{
  let title = ""
  if (record.isRequiredClinicianName) {
    title = "Clinician name is required"
  }
  else if (record.isMinLengthCilinicianName) {
    title = "Clinician name must be at least 3 characters long."
  }
  else if (record.isMaxLengthCilinicianName) {
    title = "Clinician name must not exceed 70 characters."
  }
  return title
}

export const getAddressLineErrorTitle = (record) => {
  let title = ""
  if (record.isMinLengthAddress) {
    title = "Address must be at least 3 characters long."
  }
  else if (record.isMaxLengthAddress) {
    title = "Address must not exceed 255 characters."
  }
  return title
}

export const getCityErrorTitle = (record) => {
  let title = ""
  if (record.isMinLengthCity) {
    title = "City must be at least 3 characters long."
  }
  else if (record.isMaxLengthCity) {
    title = "City must not exceed 100 characters."
  }
  return title
}

export const getCountyErrorTitle = (record) => {
  let title = ""
  if (record.isMinLengthCounty) {
    title = "County/Province/State must be at least 1 character long."
  }
  else if (record.isMaxLengthCounty) {
    title = "County/Province/State must not exceed 50 characters."
  }
  return title
}

export const getZipCodeErrorTitle = (record) => {
  let title = ""
  if (record.isMinLengthZipCode) {
    title = "Post/Zip/EIR Code must be at least 3 digits long."
  }
  else if (record.isMaxLengthZipCode) {
    title = "Post/Zip/EIR Code must not exceed 15 digits."
  }
  return title
}

export const getInvalidCountryCodeErrorTitle = (record) =>{
  let title =""
    if(record.inValidCountryCode){
    title = "Country code is invalid."
   }
   else if(record.isMaxLengthCode){
    title = "Country code must not exceed 3 digits."
   }
   else if(record.inValidPhone){
    title = "Phone no is invalid."
   }
   else if(record.isMinLengthPhone){
    title = "Phone no must be at least 5 digits long."
   }
   else if(record.isMaxLengthPhone){
    title = "Phone no must not exceed 12 digits."
   }
  return title
}


export const getEmailErrorTitle = (emailExists, invalidEmail, duplicate, required=false) =>{
  let title =""
   if (emailExists) {
    title = "Email already exists"
  }
  else if(invalidEmail){
    title = "Email is invalid"
   }
  else if(duplicate){
    title = "Email is duplicated"
   }
   else if(required){
    title = "Email is required"
   }
   return title
}

export const getQueryParamFilterStatus = (status) =>{
  if (!status ) return [];
  let filter = []
  if (status === "email-sent-to-clinic") {
   filter = ["ONBOARDING_REQUESTED"]
  }
  else if(status === "verification-pending"){
    filter = ["VERIFICATION_PENDING"]
  } 
  else if(status === "onboarding-pending"){
    filter = ["ONBOARDING_PENDING"]
  }
  // else if(status === "partnered"){
  //   filter = ["Partnered"]
  // }
  else{
    filter = []
  }
  return filter
 }

