import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Checkbox,
  Typography,
  message,
  Spin,
} from "antd";
import errorModal from "../shared/modals/errorModal";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { Auth } from "aws-amplify";
// import Title from "antd/lib/skeleton/Title";
const { Title } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 12,
  },
};

export default function SignUpConfirmation({ username }) {
  const history = useHistory();
  const [isSubmitButtonSpinning, setIsSubmitButtonSpinning] = useState(false);

  async function confirmSignUp({ code }) {
    try {
      const res = await Auth.confirmSignUp(username, code);
      message.success("User succesfully confirmed");
      setIsSubmitButtonSpinning(false);

      history.push("/login");
    } catch (error) {
      errorModal(error.message.includes("1 validation error detected: Value") ? "Invalid verification code, please try again.":error.message);
      setIsSubmitButtonSpinning(false);

      console.log("error confirming sign up", error);
    }
  }
  const onFinish = (values) => {
    if (values.code.trim().length === 0) {
      message.error("Confirmation code cannot be empty spaces. Try again!");
    } else {
      setIsSubmitButtonSpinning(true);

      confirmSignUp(values);
    }
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ marginTop: "30%" }}>
      {/* <Title>sdfdf</Title> */}
      <Row justify="center" align="middle">
        <Col span={12}>
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Enter the code sent to your email to confirm your registration
          </p>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            layout={"vertical"}
            style={{ width: "100%" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {/* <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

            <Form.Item
              label="Security Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please input the code !",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Spin spinning={isSubmitButtonSpinning}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {/* {userConfirmed && (
        <Row justify="center">
          <p style={{ fontWeight: "bold" }}>
            Confirmation succes. Click{" "}
            <Link
              style={{
                color: "#00c6e9",
              }}
              to="/login"
            >
              here
            </Link>{" "}
            to login.{" "}
          </p>
        </Row>
      )} */}
    </div>
  );
}
