import dayjs from "dayjs";

export const getTreatmentChecks = (treatmentPlan) => {
  return treatmentPlan?.map((i) => ({
    ...i,
    label: `${i?.revision_plan_version ? "Refinement" : "Treatment"} Plan Version ${
      i?.revision_plan_version ? i?.revision_plan_version : i?.treatment_plan_version
    }.0`,
    value: i?.treatment_plan_version,
  }));
};

export const getFormatDate = (date) => dayjs(date).format("DD-MM-YYYY");

export const getTreatmentStatus = (treatmentPrescription) =>
  Object.keys(treatmentPrescription).length > 0;


