import DOMPurify from "dompurify";
import { Avatar } from "antd";
import styles from "./activity.module.scss";

const sanitizedContent = (html) => {
  const cleanHTML = DOMPurify.sanitize(html);

  return (
    <div className={styles["activity__text"]} dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};

const DisplayActivity = ({ content }) => {
  return (
    <div className={styles["activity-pill"]}>
      <div className={styles["activity__icon"]}>
        <Avatar style={{ backgroundColor: content.iconBackgroundColor }} icon={content.icon} />
      </div>
      <div className={styles["activity__description"]}>
        <div className={styles["activity__text-container"]}>
          {content.showAssignee && (
            <span style={{ fontWeight: "bold" }}>
              {sanitizedContent(content?.assigneeName || "")}
            </span>
          )}
          <span>{sanitizedContent(content?.activity || "")}</span>
        </div>

        <span className={styles["activity__time"]}>{content.activityDate}</span>
      </div>
    </div>
  );
};

export default DisplayActivity;
