/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect,useState } from 'react'
import {  Row } from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { useHistory, Link, useLocation,useParams  } from "react-router-dom";
import ClinicInformation from '../../components/private/clinicInfo';
import axios from "axios";

const PUBLIC_API_BASE_URL = process.env.REACT_APP_CLINIC_BASE_URL;

function clinicDetails() {
  const history = useHistory();
  const location = useLocation();
  const { clinicId } = useParams()
  const [clinicDetails,setClinicDetails]=useState()

  const routes = [
    {
      path: "/lab/partners",
      breadcrumbName: "Clinic List",
    },
    {
      path: "first",
      breadcrumbName: "Clinic Details",
    },
  ];
  

  useEffect(()=>{

    if(clinicId){
      _getClinicInformation(clinicId)
    }

  },[clinicId])

  const _getClinicInformation = async (clinicId) => {
    try {
      const clinicInfo = await axios.get(
        `${PUBLIC_API_BASE_URL}/clinic/populateClinicDetails?clinicId=${clinicId}`
      );
      if(clinicInfo?.data?.body?.clinic_id){
        setClinicDetails(clinicInfo.data.body)
      }
    } catch (error) {
      console.log(error);
    }
  }

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={"/lab/partners"}>{route.breadcrumbName}</Link>
    );
  }
  console.log("clinicDetails",clinicDetails);
  return (
    <>
      <Row
        style={{
          marginBottom: "3rem",
        }}
      >
        <PageHeader
          className="site-page-header"
          onBack={() => history.push("/lab/partners")}
          title="Clinic Detail"
          breadcrumb={{ routes, itemRender }}
        />
      </Row>
      <Row
        style={{
          marginTop: "-40px",
          paddingBottom: "20px",
          marginLeft: "20px",
          width: "94%",
        }}
      >
        <ClinicInformation
          clinicInfo={location?.state?.details}
          clinicDetails={clinicDetails}
        />
      </Row>
    </>

  )
}

export default clinicDetails