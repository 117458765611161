import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Select, Divider } from "antd";
import TeethInfo from "./TeethInfo";

import Teeths from "./Teeths/Teeths";
import { FDI_CHART } from "./data";

import "./teethChart.scss";

const { Option } = Select;

const TeethChart = forwardRef(({ disabled, teethChartData,chartValue,setChartValue  }, ref) => {
  const [toothStatuses, setToothStatuses] = useState([]);
  const [teethStructure, setTeethStructure] = useState(FDI_CHART);


  useImperativeHandle(ref, () => {
    return {
      setTeethData(data) {
        setToothStatuses(data?.length ? data : []);
      },
      toothStatuses,
    };
  });

  useEffect(() => {
    setToothStatuses(teethChartData);
  }, [teethChartData]);

  const handleStructureChange = (value) => {
    setChartValue(value);
  };

  const header = (
    <>
      <div className="chart-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1>Teeth Chart</h1>
        </div>
        <Select
          placeholder="Select option"
          className="header-select"
          onChange={handleStructureChange}
          value={chartValue}
        >
          <Option value="fdi">FDI</Option>
          <Option value="universal">Universal</Option>
          <Option value="palmer">Palmer</Option>
        </Select>
      </div>
      <Divider />
    </>
  );

  return (
    <div className="teethChart-container">
      {header}
      <Teeths
        chartValue={chartValue}
        disabled={disabled}
        teethStructure={teethStructure}
        toothStatuses={toothStatuses}
        setTeethStructure={setTeethStructure}
        setToothStatuses={setToothStatuses}
      />
      <TeethInfo
        disabled={disabled}
        chartValue={chartValue}
        toothStatuses={toothStatuses}
        setToothStatuses={setToothStatuses}
        teethStructure={teethStructure}
        setTeethStructure={setTeethStructure}
      />
    </div>
  );
});

export default TeethChart;
