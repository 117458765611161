import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import IPRCard from "components/shared/IPRCard/IPRCard";
import { primaryColor } from "colors-config";

const PatientTreatmentProgressComponent = ({ aligners }) => {
  const [alignersData, setAlignersData] = useState([]);
  const [currentSelected, setCurrentSelected] = useState();

  useEffect(() => {
    if (aligners) {
      setAlignersData(aligners.body.data);
      setCurrentSelected(aligners.body.current_aligner);
    }
  }, [aligners]);


 
  function getOrdinal(number) {
    let num = number
    if (typeof number !== 'number') {
     num = +number
    }
    const lastDigit = num % 10;
    const secondLastDigit = Math.floor((num % 100) / 10);
    if (lastDigit === 1 && secondLastDigit !== 1) {
      return `${num}st`;
    } else if (lastDigit === 2 && secondLastDigit !== 1) {
      return `${num}nd`;
    } else if (lastDigit === 3 && secondLastDigit !== 1) {
      return `${num}rd`;
    } else {
      return `${num}th`;
    }
  }
  const createTreatmentData = (calendar) => {
    return (
      <div className="treatment-progress-card-body-container">
        <Row gutter={24}>
          <Col className="patient-start-date-title" span={12}>
            <Row>
              <span style={{ fontWeight: "600", color: "#77888B" }}>
                Start date
              </span>
            </Row>
            <Row>
              <CalendarOutlined
                className="calendar aligner-date"
                style={{
                  fontSize: "20px",
                  color: primaryColor,
                }}
              />
              <div
                style={{
                  fontWeight: "700",
                  paddingLeft: "5px",
                }}
              >
                {dayjs(calendar.start_date).format("DD/MM/YYYY")}
              </div>
            </Row>
          </Col>
          <Col className="patient-end-date-title" span={12}>
            <span style={{ fontWeight: "600", color: "#77888B" }}>
              End date
            </span>
            <Row>
              <CalendarOutlined
                className="aligner-date"
                style={{
                  fontSize: "20px",
                  color: primaryColor,
                }}
              />
              <div
                style={{
                  fontWeight: "700",
                  paddingLeft: "5px",
                }}
              >
                {dayjs(calendar.end_date).format("DD/MM/YYYY")}
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };
  let iprSessionCount=0;
  const getSessionTitle=(count,type)=>{
    const iprCount = getOrdinal(count);
    if(type=="BOTH"){
      return `${iprCount} IPR + ${iprCount} Attachment Session`;
    }
    else if(type=="IPR"){
      return `${iprCount} IPR Session`;
    }
    else if(type=="ATTACHMENT"){
      return `${iprCount} Attachment Session`;
    }
    else return "";
  }
  return (
    <div>
      <Card
        className="treatmemt-in-progress-card"
        style={{
          width: "100%",
          minHeight: "50rem",
          maxHeight: "50rem",
          textAlign: "left",
          boxShadow: "0px 10px 16px rgba(0, 0, 0, 0.02)",
          borderRadius: "8px",
          overflow: "auto",
        }}
        title="Treatment In Progress"
      >
        <Row gutter={[8, 8]}>
          {alignersData?.map((calendar, index) => {
            if (calendar.jawType) {
              iprSessionCount++;
              return (
                <Col lg={12} key={index}>
                  <IPRCard
                    title={getSessionTitle(iprSessionCount, calendar.type)}
                    subTitle={`Before ${calendar.stage_name}`}
                    status={calendar.completed}
                    type={calendar.type}
                  />
                </Col>
              );
            } else {
              return (
                <Col lg={12} key={index}>
                  <Card
                    hoverable
                    className={`${currentSelected == calendar.aligner_id
                      ? "treatment-progress-sub-cards-selected"
                      : "treatment-progress-sub-cards"} ${process.env.REACT_APP_COMPANY_TYPE==5?"treatment-progress-sub-cards-smilealign":""}`}
                  
                    style={{
                      height: "100%",
                    }}
                    title={
                      <div className="header-container">
                        {/* <div>{"Aligner" + " " + calendar.aligner_count}</div> */}
                        <div>{  calendar.stage_name}</div>
                      </div>
                    }
                  >
                    {createTreatmentData(calendar)}
                  </Card>
                </Col>
              );
            }
          })}
        </Row>
        {alignersData?.length == 0 && (
          <div style={{ paddingTop: "1.5rem" }} className="padding-class">
            <Row className="questionnaire-responses-component ">
              No Treatments have been added yet.
            </Row>
          </div>
        )}
      </Card>
    </div>
  );
};

export default PatientTreatmentProgressComponent;
