import { Checkbox } from "antd";
import PropTypes from "prop-types";
import { getAlignerNumberBackground} from "utils/ipr";

const AttachmentSessionList = ({ alignersLabel, onAlignerLabelClicked }) => {
  function getOrdinal(number) {
    let num = number
    if (typeof number !== 'number') {
     num = +number
    }
  
    const lastDigit = number % 10;
    const secondLastDigit = Math.floor((number % 100) / 10);
  
    if (lastDigit === 1 && secondLastDigit !== 1) {
      return `${num}st`;
    } else if (lastDigit === 2 && secondLastDigit !== 1) {
      return `${num}nd`;
    } else if (lastDigit === 3 && secondLastDigit !== 1) {
      return `${num}rd`;
    } else {
      return `${num}th`;
    }
  }
  return (
    <div style={{marginBottom:"15px", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-end"}}>
      {alignersLabel.map((obj, index) => {
        return (
          <div key={index} className="ipr-check-row">
            <Checkbox checked={obj.checked} onClick={()=>onAlignerLabelClicked(index)}></Checkbox>
            <p className="patient-question" style={{ margin: "0 8px 0px 5px", minWidth:"290px" }}>
            {/* {`${getOrdinal(index + 1)} Attachment Session (Before Aligner ${obj.jawType === "upper" ? `U-${obj.aligner_count}` : `L-${obj.aligner_count}`})`} */}
            {getOrdinal(index + 1)} Attachment Session (Before Aligner {obj.aligner_count})
            </p>
            <div className={`ipr-circle`} 
              style={{
                background: `${getAlignerNumberBackground(
                  obj.aligner_count
                )} `
              }}
            />
          </div>
        )
      })}
    </div>
  );
};

export default AttachmentSessionList;

AttachmentSessionList.propTypes = {
  alignersLabel: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      aligner_count: PropTypes.number.isRequired,
    })
  ).isRequired,
};
