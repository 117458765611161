import React from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { primaryColor } from 'colors-config';

const CustomColumnSorter = ({ name, columnKey, tableSorting, onSort, }) => {
  const isAscActive = tableSorting?.name === columnKey && tableSorting?.type === "ASC";
  const isDescActive = tableSorting?.name === columnKey && tableSorting?.type === "DESC";

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      {name}
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "0px" }}>
        <CaretUpOutlined
          style={{
            fontSize: "13px",
            color: isAscActive ? primaryColor : "rgba(0, 0, 0, 0.29)",
            cursor: "pointer"
          }}
          onClick={() => onSort(name, columnKey, "ASC")}
        />
        <CaretDownOutlined
          style={{
            fontSize: "13px",
            color: isDescActive ? primaryColor : "rgba(0, 0, 0, 0.29)",
            cursor: "pointer",
            marginTop: "-3px"
          }}
          onClick={() => onSort(name, columnKey, "DESC")}
        />
      </div>
    </div>
  );
};

export default CustomColumnSorter;
