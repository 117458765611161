export const FORM_CONSTANTS = {
  //Smaller versions
 UPPER:"upper",
 LOWER:"lower",
 UPPER_AND_LOWER:"Upper and Lower",
 RETAINER:"retainer",
 WHITENING_TRAY :"whitening",
 RETAINER_AND_WHITENING_TRAY:"retainer_and_whitening",
 NO_RETAINER_AND_WHITENING_TRAY:"no",
 COMMENT:"comment",
//as done in db previously
 MAINTAIN:"maintain",
 IMPROVE:"improve",
 AS_NEEDED:"as needed",
 ANTERIOR:"Anterior",
 POSTERIOR:"Posterior",
 NO:"no",
 YES:"yes",
 GINGIVAL:"gingival",
 INCISAL:"incisal",
 EXPANDING:"expanding",
 PROCLINING:"proclining",
 BETWEEN_CENTRALS:"between_centrals",
 DISTAL_TO_LATERALS:"distal_to_laterals",
 DISTAL_TO_CANINES :"distal_to_canines",
 EQUALLY_AROUND_lATERALS:"equally_around_laterals",
 THIN:"thin",
 THICK:"thick",
 NORMAL:"normal",
 LEFT:"left",
 RIGHT:"right",
 BOTH:"both",
 CLASS_ONE: "class_one",
 CLASS_TWO:"class_two",
 CLASS_THREE:"class_three",
 DIVISION_ONE: "division_one",
 DIVISION_TWO:"division_two",
 DIVISION_THREE:"division_three",
 CLASS_2:"Class 2"
}
