import { useState, useRef } from "react";
import { Button, Row, Divider, Col, Radio } from "antd";
import {
  DownloadOutlined,
} from "@ant-design/icons";
import { useReactToPrint} from "react-to-print";
import IPRInfo from "../IPRInfo/IPRInfo";
import IprSessionList from "./IprSessionList/IprSessionList";
import { ReactComponent as DownArrow } from "assets/images/DownArrow.svg";
import TeethType from "../TeethType/TeethType";
import "./iprDetail.scss";
import FileList from "./FileList/FileList";
import AttachmentShowInfo from "./AttachmentDetail/AttachmentShowInfo";
import AttachmentRow from "./AttachmentDetail/AttachmentRow";
import AttachmentsInfo from "../IPRInfo/AttachmentsInfo";
import TreatmentPDF from "../TreatmentPDF/TreatmentPDF";
import { isSafari } from "utils/Lab";
import IprModal from "./IprModal/IprModal";
import AppButton from "components/shared/AppButton/AppButton";


const IPRDetail = ({
  alignersLabel,
  lowerAligners,
  upperAligners,
  setAlignersLabel,
  fullUpperAligners,
  setLowerAligners,
  fullLowerAligners,
  setUpperAligners,
  treatment,
  attachmentsLabel,
  onAttachmentsLabelClicked,
  lowerAttachments,
  upperAttachments,
  patientInfo
}) => {
  const [openIprGuideModal, setOpenIprGuideModal] = useState(false);
  const [chartValue, setChartValue] = useState("fdi");
  const [value, setValue] = useState("ipr");
  const componentRef = useRef();

  const handleStructureChange = (value) => {
    setChartValue(value);
  };

  // const header = (
  //   <>
  //     <div className="chart-header">
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <h1>Treatment Guide</h1>
  //       </div>
  //       <TeethType
  //         teethTypeName={chartValue}
  //         onTeethTypeChange={handleStructureChange}
  //       />
  //     </div>
  //     <Divider />
  //   </>
  // );

  const onAlignerLabelClicked = (index) => {
    // console.log("fullUpperAligners", fullUpperAligners)
    let uniqueSessions = [...alignersLabel];
    uniqueSessions[index].checked = !uniqueSessions[index].checked;
    // console.log("uniqueSessions", uniqueSessions);
    setAlignersLabel(uniqueSessions);
    let fullUpperAlignersTemp = [];
    let fullLowerAlignersTemp = [];
    fullUpperAligners.forEach((obj) => {
      const findAligner = uniqueSessions.find(
        (uObj) => uObj.aligner_count == obj.aligner_count
      );
      if (findAligner && findAligner.checked) {
        fullUpperAlignersTemp.push(obj);
      }
    });

    fullLowerAligners.forEach((obj) => {
      const findAligner = uniqueSessions.find(
        (uObj) => uObj.aligner_count == obj.aligner_count
      );
      if (findAligner && findAligner.checked) {
        fullLowerAlignersTemp.push(obj);
      }
    });
    setUpperAligners(fullUpperAlignersTemp);
    setLowerAligners(fullLowerAlignersTemp);
  };

  const detailOptions = [
    {
      label: "IPR",
      value: "ipr",
    },
    {
      label: "Attachment",
      value: "attachment",
    },
  ];

  const onChange4 = ({ target: { value } }) => {
    setValue(value);
  };

  const handleDownload = useReactToPrint({
    content: () => {
      document
        .querySelectorAll(".treatment-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "block";
          }
        });
      return componentRef.current;
    },
    onAfterPrint: () => {
      document
        .querySelectorAll(".treatment-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "none";
          }
        });
    },
  });
  const getPageStyles = () => {
    return `
      @media print {
       
        @page {
          margin: 25px !important;
           padding: 0 !important;
           size: A4;
        }
          
        .teethChart-container .teethRow-container .teethRow-one svg {
          width: 38px !important;
        }

        .ipr-session-list-labels{
        margin-left:auto;}
        

          ${isSafari() ? 

            `
             html, body {
       margin: 0 !important;
           padding: 0 !important;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
      .aligner-component .ipr-check-row{
          display:flex;}
       
    
  `:''
          }
      }
    `;
  };

  const showModal = () => {
    setOpenIprGuideModal(true);
  };


  return (
    <div>
        <style>{getPageStyles()}</style>  
        
        {openIprGuideModal && (
          <IprModal
            alignersLabel={alignersLabel}
            chartValue={chartValue}
            handleStructureChange={handleStructureChange}
            treatment={treatment}
            openIprGuideModal={openIprGuideModal}
            setOpenIprGuideModal={setOpenIprGuideModal}
            lowerAligners={lowerAligners}
            upperAligners={upperAligners}
            onAlignerLabelClicked={onAlignerLabelClicked}
            upperAttachments={upperAttachments}
            lowerAttachments={lowerAttachments}
            attachmentsLabel={attachmentsLabel}
            onAttachmentsLabelClicked={onAttachmentsLabelClicked}
          />
        )}
      <Row
        style={
          {
            //   marginLeft: "0.5rem",
            //   marginBottom: "1.5rem",
            //   marginTop: "-0.5rem",
            //   padding: "0px 10px",
          }
        }
      >
        {alignersLabel.length || attachmentsLabel.length ? (
          <div style={{ padding: "1.5rem" }} className="aligner-component">
            <Row align="middle" justify="space-between">
              <Col>
              <Radio.Group
              className="heading-radio"
              options={detailOptions}
              onChange={onChange4}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
              </Col>
              <Col>
              <Row align="middle">
                  <AppButton
                    onClick={showModal}
                    shape="round"
                    type="primary"
                    className="guideBtn"
                  >
                    View Treatment Guide
                  </AppButton>
                  <div    
                onClick={() => handleDownload()} 
               className="download-btn">
                  Download  <DownloadOutlined /> 
                  </div>
                </Row>
          
              </Col>
            </Row>
            {value == "ipr" ? (
              <>
                <Col lg={24}>
                  <div>
                    <br/>
                    {/* <Col lg={24}>
                      <p
                        className="patient-question"
                        style={{ textAlign: "left" }}
                      >
                       Treatment Guide PDF
                      </p>
                      <FileList
                        iprFile={treatment?.iprDocument}
                      />
                    </Col> */}
                  </div>
                </Col>
                <Row align="top">
                  <Col lg={10}>
                    <div>
                      <Row>
                        <p
                          className="patient-question"
                          style={{ marginTop: "0px" }}
                        >
                          No. of IPR sessions
                        </p>
                      </Row>
                      <Row>
                        <p className="patient-response">
                          {alignersLabel.length}
                        </p>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={14}>
                    <IprSessionList
                      alignersLabel={alignersLabel}
                      onAlignerLabelClicked={onAlignerLabelClicked}
                    />
                  </Col>
                </Row>
                <div className="chart-header">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <h1>Treatment Guide</h1> */}
                  </div>
                  <TeethType
                    teethTypeName={chartValue}
                    onTeethTypeChange={handleStructureChange}
                  />
                </div>
                <Divider />
                <IPRInfo
                  lowerAligners={lowerAligners}
                  upperAligners={upperAligners}
                  chartValue={chartValue}
                  upperAttachments={upperAttachments}
                  lowerAttachments={lowerAttachments}
                />
              </>
            ) : (
              <>
                <div className="chart-header">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <h1>Treatment Guide</h1> */}
                  </div>
                  <TeethType
                    teethTypeName={chartValue}
                    onTeethTypeChange={handleStructureChange}
                  />
                </div>
                <Divider />
                <AttachmentShowInfo
                  alignersLabel={attachmentsLabel}
                  onAlignerLabelClicked={onAttachmentsLabelClicked}
                  upperAttachments={upperAttachments}
                  lowerAttachments={lowerAttachments}
                  chartValue={chartValue}
                />

                <AttachmentsInfo
                  lowerAligners={lowerAligners}
                  upperAligners={upperAligners}
                  chartValue={chartValue}
                  upperAttachments={upperAttachments}
                  lowerAttachments={lowerAttachments}
                />

                <AttachmentRow
                  alignersLabel={attachmentsLabel}
                  onAlignerLabelClicked={onAttachmentsLabelClicked}
                  upperAttachments={upperAttachments}
                  lowerAttachments={lowerAttachments}
                  chartValue={chartValue}
                />
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </Row>

      <div
        ref={componentRef}
        className="treatment-pdf"
        style={{ display: "none" }}
        id="printablediv"
      >
        <TreatmentPDF
          patientInfo={patientInfo}
          treatment={treatment}
          lowerAligners={lowerAligners}
          upperAligners={upperAligners}
          chartValue={chartValue}
          upperAttachments={upperAttachments}
          lowerAttachments={lowerAttachments}
          alignersLabel={alignersLabel}
          attachmentsLabel={attachmentsLabel}
          onAlignerLabelClicked={onAttachmentsLabelClicked}
        />
      </div>
    </div>
  );
};

export default IPRDetail;
