import AppTag from "components/shared/AppTag/AppTag";
import { TAG_STATUS } from "../../../../../constants";
import styles from "../style.module.scss";

const Header = ({ refinementData, treatment, isDraft }) => {
  const title = refinementData
    ? "Add Refinement Setup"
    : Object.keys(treatment).length
    ? "Update  Treatment Setup"
    : "Add Treatment Setup";

  return (
    <div className={styles.headerContainer}>
      <span> {title}</span>

      {isDraft && <AppTag text="Saved as Draft" color={TAG_STATUS.ERROR} />}
    </div>
  );
};

export default Header;
