import { useRef, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import TextEditor from "components/shared/TextEditor/TextEditor";
import ScanFiles from "components/private/SanFiles/ScanFiles";
import ClinicalFiles from "./ClinicalFiles";
import {
  refinementAdditionalDetailsService,
  uploadRefinementFileService,
  startTreatmentApi,
  startRevisionTreatmentApi,
} from "api/treatment";
import { checkTextEditorContent } from "utils/Lab";
import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";
import styles from "./styles.module.scss";

const RefinementAdditionalDetails = ({
  refinementAdditionalDetailModalVisible,
  setRefinementAdditionalDetailModalVisible,
  labId,
  clinicId,
  patientId,
  treatmentPlanId,
  setShowTreatmentSetupModal,
  getRefinementDetails,
  treatmentPlans,
  patientOrders,
  alignerOrders,
  refinements,
  aligners,
  getData,
  patientInfo
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [editorInput, setEditorInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const clinicalFilesRef = useRef(null);

  const scanFilesRef = useRef(null);
  const closeModal = () => setRefinementAdditionalDetailModalVisible(false);
  const getConfirmOrderConfig = () => {
    const resendPasswordConfig = createConfirmConfig({
      title: "Are you sure you want to continue?",
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      onOk: closeModal,
      onCancel: () => {},
      okText: "Ok",
      zIndex: 1001, // Ensure this is higher than the default zIndex of modals
    });

    return resendPasswordConfig;
  };

  const onCancel = () => {
    if ((editorInput && editorInput !== "<p><br></p>") || isChanged) {
      return modal.confirm(getConfirmOrderConfig());
    }
    closeModal();
  };

  const makeFileData = (file, treatmentRefinementId, fileName) => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("patientId", patientId);
    formData.append("treatmentRefinementId", treatmentRefinementId);
    formData.append("treatmentPlanId", treatmentPlanId);
    formData.append("clinicId", clinicId);

    if (fileName) {
      formData.append("scanName", fileName);
    }
    return formData;
  };

  const onOk = async () => {
    const isContent = checkTextEditorContent(editorInput.toString());
    try {
      setLoader(true);
      if (patientInfo.clinic_patient_journey_status_id != 15) {
        const order = patientOrders.find((order) => order.lab_treatment_id === treatmentPlanId);
        if (refinements?.length) {
          const currentAlignerData = aligners.data.find(
            (aligner) => aligner.aligner_id === aligners.current_aligner
          );
          const apiReq = {
            order_id: order.patient_order_id,
            treatmentId: treatmentPlanId,
            upperAligner: currentAlignerData.upperAligner,
            lowerAligner: currentAlignerData.lowerAligner,
          };

          await startRevisionTreatmentApi(apiReq);
        } else {
          await startTreatmentApi({ order_id: order.patient_order_id });
        }
      }

      const response = await refinementAdditionalDetailsService({
        labId: labId,
        clinicId: clinicId,
        patientId: patientId,
        treatmentPlanId: treatmentPlanId,
        details: isContent ? editorInput.toString() : ""
      });

      const { id: treatmentRefinementId } = response.data;

      const promises = [];

      if (clinicalFilesRef.current?.clinicalFiles?.length) {
        clinicalFilesRef.current.clinicalFiles.forEach((file) => {
          const formData = makeFileData(file.originFileObj, treatmentRefinementId);
          const promise = uploadRefinementFileService(formData);
          promises.push(promise);
        });
      }

      if (scanFilesRef.current?.scanFiles) {
        const keys = Object.keys(scanFilesRef.current.scanFiles);
        keys.forEach((key) => {
          const fileData = scanFilesRef.current.scanFiles[key];
          if (fileData.length) {
            const formData = makeFileData(fileData[0].originFileObj, treatmentRefinementId, key);
            const promise = uploadRefinementFileService(formData);
            promises.push(promise);
          }
        });
      }

      await Promise.all(promises);

      closeModal();
      await getRefinementDetails(patientId);
      await treatmentPlans();
      setShowTreatmentSetupModal(true);
      getData();
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const setModalOkText = () => {
    return (editorInput && editorInput !== "<p><br></p>") || isChanged
      ? "Save & Next"
      : "Skip & Next";
  };

  return (
    <Modal
      title="Refinement Details (Optional)"
      open={refinementAdditionalDetailModalVisible}
      width={600}
      onOk={onOk}
      confirmLoading={loader}
      onCancel={onCancel}
      okText={setModalOkText()}
      okButtonProps={{ style: { borderRadius: "30px" } }}
      cancelButtonProps={{ style: { borderRadius: "30px" } }}
    >
      {contextHolder}
      <div>
        <div className={styles.subText}>Additional Details</div>
        <TextEditor editorInput={editorInput} setEditorInput={setEditorInput} hideToolbar />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <ClinicalFiles setIsChanged={setIsChanged} ref={clinicalFilesRef} />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <ScanFiles setIsChanged={setIsChanged} ref={scanFilesRef} />
      </div>
    </Modal>
  );
};

RefinementAdditionalDetails.propTypes = {
  refinementAdditionalDetailModalVisible: PropTypes.bool.isRequired,
  setRefinementAdditionalDetailModalVisible: PropTypes.func.isRequired,
  labId: PropTypes.string.isRequired,
  clinicId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  treatmentPlanId: PropTypes.string.isRequired,
  setShowTreatmentSetupModal: PropTypes.func.isRequired,
};

export default RefinementAdditionalDetails;
