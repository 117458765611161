import { useEffect, useState } from "react";
import { Form, Input, Select, Row, Col, Divider } from "antd";
import { companyType, country, prefix } from "colors-config";
import { useSelector } from "react-redux";
import {
  getCountriesSelector,
  // getCodesSelector,
  getPatientCountryCodesList,
} from "services/redux/selectors/common";
import { removeDuplicates } from "utils/Lab";

const { Option } = Select;
const AddClinicForm = ({ addClinicForm, onAddClinicSubmit, setTouched }) => {
  // const countryCodes = useSelector(getCodesSelector);
  const patientCountriesList = useSelector(getPatientCountryCodesList);
  const [countryList, setCountryList] = useState([]);

  // useEffect(() => {
  //   if (countryCodes?.length) {
  //     setCountryList(removeDuplicates(countryCodes, "country_code_name"));
  //   }
  // }, [countryCodes]);

  useEffect(() => {
    const obj = {
      countryCode: prefix,
      country: country,
    };
    addClinicForm.setFieldsValue(obj);
  }, []);

  const prefixSelector = (
    <Form.Item name="countryCode" noStyle>
      <Select
        dropdownClassName="country-dropdown"
        className="country-select"
        size="large"
        defaultValue={prefix}
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        labelRender={(props) => {
          return props.label;
        }}
        options={patientCountriesList?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={(option) => {
          const { data } = option;
          return (
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}
            >
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );

  return (
    <Form
      form={addClinicForm}
      layout="vertical"
      name="form_in_modal"
      className="addPatient-form"
      onFinish={onAddClinicSubmit}
      onFieldsChange={(changedValue, allFields) => {
        setTouched(true);
      }}
    >
      <Row gutter={[16, 16]}>
        <Col lg={24}>
        {" "}
          <Form.Item
            name="clinicName"
            rules={[
              {
                required: true,
                message: "Please enter clinicianName",
              },
              {
                max: 100,
                message: "Clinician Name cannot be more than 100 characters",
              },
              {
                min: 3,
                message: "Clinician Name must be at least 3 characters",
              },
            ]}
            label="Clinician Name"
          >
            <Input size="large" placeholder="Enter Clinician Name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
          <Col lg={24}>
            <Form.Item
              name="clinicEmail"
              rules={[
                {
                  type: "email",
                  message: "Invalid email address",
                },
                {
                  required: true,
                  message: "Please enter Clinic E-mail Address!",
                },
              ]}
              label="Email ID"
            >
              <Input size="large" placeholder="Enter Email ID" />
            </Form.Item>
          </Col>
      </Row>
      <Row>
      <Col lg={24}>
          <Form.Item
            className="inputTypeClass"
            name="phone"
            label="Phone Number"
            rules={[
              () => ({
                validator(_, value) {
                  const convertedValue = Number(value);
                  if (!value) {
                    return Promise.resolve();
                  } else if (
                    !isNaN(convertedValue) &&
                    (value.length === 10 || value.length === 9)
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Input must be a valid number");
                },
              }),
            ]}
          >
            <Input
              size="large"
              addonBefore={prefixSelector}
              className="inputTypeClass"
              style={{
                width: "100%",
              }}
              placeholder="Phone Number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      {/* <Row gutter={[16, 16]}>
        
        <Col lg={12}>
          <Form.Item
            name="clinicName"
            rules={[
              {
                required: true,
                message: "Please enter clinic name!",
              },
              {
                max: 100,
                message: "Clinic Name cannot be more than 100 characters",
              },
              {
                min: 3,
                message: "Clinic Name must be at least 3 characters",
              },
            ]}
            label="Clinic Name"
          >
            <Input size="large" placeholder="Enter Clinic Name" />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            name="clinicEmail"
            rules={[
              {
                type: "email",
                message: "Invalid email address",
              },
              {
                required: true,
                message: "Please enter Clinic E-mail Address!",
              },
            ]}
            label="Email"
          >
            <Input size="large" placeholder="Enter Clinic Email Address" />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={[16, 16]}> 
        <Col lg={24}>
        <Form.Item
            name="clinicianName"
            rules={[
              {
                required: true,
                message: "Please enter clinic name!",
              },
              {
                max: 100,
                message: "Clinic Name cannot be more than 100 characters",
              },
              {
                min: 3,
                message: "Clinic Name must be at least 3 characters",
              },
            ]}
            label="Clinic Name"
          >
            <Input size="large" placeholder="Enter Clinic Name" />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]}>
        <Col lg={12}>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              { required: true, message: "Country is required" },
              {
                pattern: /^[a-zA-Z ]*$/,
                message: "Please enter only alphabets",
              },
            ]}
            className="inline-form-right"
          >
            <Select
              showSearch
              placeholder="Select Country"
              defaultActiveFirstOption={false}
              showArrow={false}
              optionFilterProp="children"
              labelRender={(props) => {
                return props.title;
              }}
              filterOption={(input, option) => {
                const lowerCasedInput = input.toLowerCase();
                return option.name.toLowerCase().includes(lowerCasedInput);
              }}
              notFoundContent={null}
              options={patientCountriesList?.map((country, i) => {
                return {
                  ...country,
                  value: country.name,
                  title: country.name,
                  label: (
                    <>
                      <span className="emoji-font">{country.emoji}</span>
                      {` ${country.name}`}
                    </>
                  ),
                  key: i,
                };
              })}
            ></Select>
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            className="inputTypeClass"
            name="phone"
            label="Phone Number"
            rules={[
              () => ({
                validator(_, value) {
                  const convertedValue = Number(value);
                  if (!value) {
                    return Promise.resolve();
                  } else if (
                    !isNaN(convertedValue) &&
                    (value.length === 10 || value.length === 9)
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Input must be a valid number");
                },
              }),
            ]}
          >
            <Input
              size="large"
              addonBefore={prefixSelector}
              className="inputTypeClass"
              style={{
                width: "100%",
              }}
              placeholder="Phone Number"
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Form.Item name="addressLine" label="Clinic's Address Line">
            <Input size="large" placeholder="Enter address line" />
          </Form.Item>
        </Col>
        
      </Row>
      {/* <Col lg={12}>
          <Form.Item name="city" label="Clinic's City">
            <Input size="large" placeholder="Enter city" />
          </Form.Item>
        </Col> */}
      <Row gutter={[16, 16]}>
      <Col lg={12}>
          <Form.Item name="city" label="Clinic's City">
            <Input size="large" placeholder="Enter city" />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item name="zipCode" label="Clinic's Post/Zip/EIR Code">
            <Input size="large" placeholder="Enter Post/Zip/EIR Code" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
      <Col lg={12}>
          <Form.Item name="county" label="Clinic's County/Province/State">
            <Input size="large" placeholder="Enter County/Province/State" />
          </Form.Item>
        </Col>
        <Col lg={12}>
        <Form.Item
            label="Country"
            name="country"
            rules={[
              { required: true, message: "Country is required" },
              {
                pattern: /^[a-zA-Z ]*$/,
                message: "Please enter only alphabets",
              },
            ]}
            className="inline-form-right"
          >
            <Select
              showSearch
              placeholder="Select Country"
              defaultActiveFirstOption={false}
              showArrow={false}
              optionFilterProp="children"
              labelRender={(props) => {
                return props.title;
              }}
              filterOption={(input, option) => {
                const lowerCasedInput = input.toLowerCase();
                return option.name.toLowerCase().includes(lowerCasedInput);
              }}
              notFoundContent={null}
              options={patientCountriesList?.map((country, i) => {
                return {
                  ...country,
                  value: country.name,
                  title: country.name,
                  label: (
                    <>
                      <span className="emoji-font">{country.emoji}</span>
                      {` ${country.name}`}
                    </>
                  ),
                  key: i,
                };
              })}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddClinicForm;
