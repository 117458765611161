import { Link } from "react-router-dom";
import {Card} from "antd"
import styles from "assets/styles/routes/dashboard.module.scss";
import { ReactComponent as Arrow } from "assets/images/Arrow.svg";
const TaskTime = ({ treatmentTaskTimeData }) => {
  return (
    <Card style={{ flex: 1 }}>
    <div className={styles.taskContainer} >
      <h1>Treatment Task Time</h1>
      <div className={styles.taskList}>
        <Link to="/lab/patients?status=ontime">
          <div className={styles.taskListItem}>
            <h2>
              {treatmentTaskTimeData?.onTime !== null &&
              treatmentTaskTimeData?.onTime !== undefined
                ? treatmentTaskTimeData?.onTime
                : "--"}{" "}
            </h2>
            <div className={styles.taskListName}>
              <p>On Time</p>
              <Arrow />
            </div>

            <div className={`${styles.time} ${styles.line}`} />
          </div>
        </Link>
        <Link to="/lab/patients?status=due">
          <div className={styles.taskListItem}>
            <h2>
              {treatmentTaskTimeData?.due !== null &&
              treatmentTaskTimeData?.due !== undefined
                ? treatmentTaskTimeData?.due
                : "--"}{" "}
            </h2>
            <div className={styles.taskListName}>
              <p>Due</p>
              <Arrow />
            </div>
            <div className={`${styles.due} ${styles.line}`} />
          </div>
        </Link>
        <Link to="/lab/patients?status=early">
          <div className={styles.taskListItem}>
            <h2>
              {treatmentTaskTimeData?.early !== null &&
              treatmentTaskTimeData?.early !== undefined
                ? treatmentTaskTimeData?.early
                : "--"}{" "}
            </h2>
            <div className={styles.taskListName}>
              <p>Early</p>
              <Arrow />
            </div>
            <div className={`${styles.early} ${styles.line}`} />
          </div>
        </Link>
        <Link to="/lab/patients?status=late">
          <div className={styles.taskListItem}>
            <h2>
              {treatmentTaskTimeData?.late !== null &&
              treatmentTaskTimeData?.late !== undefined
                ? treatmentTaskTimeData?.late
                : "--"}{" "}
            </h2>
            <div className={styles.taskListName}>
              <p>Late</p>
              <Arrow />
            </div>
            <div className={`${styles.late} ${styles.line}`} />
          </div>
        </Link>
      </div>
    </div>
    </Card>
  
  );
};

export default TaskTime;
