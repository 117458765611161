import styles from "./styles.module.scss";

export const createConfirmConfig = ({ title, content, onOk, onCancel, okText, cancelText }) => {
  const okButtonProps = {
    className: styles["roundButton"],
  };

  const cancelButtonProps = {
    className: styles["roundButton"],
  };
  
  return {
    title,
    content,
    closable: true,
    okButtonProps,
    cancelButtonProps,
    onOk: onOk,
    onCancel: onCancel,
    okText,
    cancelText
  };
};
