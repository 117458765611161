import { useState, useEffect } from "react";
import { Modal, Typography, Form, message, Tooltip } from "antd";
import AddClinicForm from "../AddClinicForm/AddClinicForm";
import {
  checkClinicEmailExists,
  downloadOnBoardingErrorFile,
  editOnBoardingClinic,
  saveOnBoardingClinic,
  saveOnBoardingClinicFile,
} from "api/lab";
import {
  onBoardingMapper,
  getAddClinicButtonText,
  getAddClinicHeaderText,
  calculateErrorCount,
  getInvitesClinicButtonText,
  getSuccessButtonText
} from "../../utils";
import { getClinicInvitesColumns, getOnBoardingTableColumns } from "../PartnerTables/TableColumns";
import { PARTNER_TABS } from "constants";
import { showConfirmationModal } from "components/shared/ShowConfirmModal/index";
import AppButton from "components/shared/AppButton/AppButton";
import UploadFile from "../UploadCsvFile/UploadFile";
import FileSample from "../UploadCsvFile/FileSample";
import InvitesTable from "../InvitesTable/InvitesTable";

const { Title } = Typography;

const AddClinicModal = ({
  showClinicModal,
  setShowClinicModal,
  labId,
  onBoardingClinics,
  setOnBoardingClinics,
  setTableData,
  resendClinic,
  setResendClinic,
  setTableColumns,
  onResend,
  onDelete,
  showCsvUpload,
  setShowCsvUpload,
  fetchData,
  tablePagination,
  setTablePagination,
  onBoardingStatusFilter,
  tableData
}) => {
  const [addClinicForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [clinicEntries, setClinicEntries] = useState([]);

  useEffect(() => {
    if (resendClinic) {
      addClinicForm.setFieldsValue(resendClinic);
    }
  }, []);

  const closeModal = () => {
    setShowClinicModal(false);
    addClinicForm.resetFields();
    setResendClinic(null);
    setShowCsvUpload(false);
  };

  const handleAddClinicCancel = () => {
    if (showCsvUpload && fileList) {
      showConfirmationModal(
        () => {
          closeModal();
        },
        "Close CSV upload",
        "Any unsaved changes will be discarded. Do you want to proceed?"
      );
    } else if (touched) {
      showConfirmationModal(
        () => {
          closeModal();
        },
        "Close add clinic form",
        "Any unsaved changes will be discarded. Do you want to proceed?"
      );
    } else {
      closeModal();
    }
  };

  const registerClinic = async (apiPayload) => {
    const { clinicEmail } = apiPayload;
    try {
      await checkClinicEmailExists(clinicEmail);
      return;
    } catch (error) {
      throw error;
    }
  };

  const handleSaveError = (error) => {
    let errorMessage;
    if (error.response && error.response.data) {
      if (error?.response?.data?.body?.err?.msg) {
        errorMessage = "Clinic email already exists!";
      }
      if (error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message;
      }
    } else {
      errorMessage = "Something went wrong";
    }
    message.error(errorMessage);
  };

  const saveClinic = async (apiPayload) => {
    try {
      setLoading(true);
      await registerClinic(apiPayload);
      await saveOnBoardingClinic([apiPayload]);

      // if (selectedTable === PARTNER_TABS.REGISTERED) {
        const paginationCopy = { ...tablePagination };
        paginationCopy.current = 1;
        fetchData(paginationCopy, onBoardingStatusFilter.length ? onBoardingStatusFilter : "");

        setTablePagination((prev) => {
          return {
            ...prev,
            current: 1,
          };
        });
        // setTableColumns(columns);
      // }

      message.success("Clinic invite send successfully");

      setShowClinicModal(false);
    } catch (error) {
      console.log(error);
      handleSaveError(error);
    } finally {
      setLoading(false);
    }
  };

  const editClinic = async (apiPayload) => {
    const payload = {
      ...apiPayload,
      id: resendClinic.id,
    };

    try {
      setLoading(true);
      const { data } = await editOnBoardingClinic(payload);

      const clinics = [...tableData];
      const updatedClinics = clinics.map((clinic) => {
        if (clinic.id === resendClinic.id) {
          return {
            ...clinic,
            clinicName: apiPayload.clinicName,
            clinicEmail: apiPayload.clinicEmail,
            country: apiPayload.country,
            countryCode: apiPayload.countryCode,
            phone: apiPayload.phone,
            
          };
        }
        return {
          ...clinic,
        };
      });
      const finalData = onBoardingMapper(updatedClinics);
      setOnBoardingClinics(finalData);
      setTableData(finalData);
      setResendClinic(null);
      addClinicForm.resetFields();

      if (data) {
        message.success("Clinic Details Updated");
        message.success(`Invite resent for ${apiPayload.clinicName}`);
      }
      setShowClinicModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onAddClinicSubmit = async (values) => {
    const apiPayload = {
      ...values,
      labId: labId,
    };
    if (resendClinic) {
      return editClinic(apiPayload);
    }
    saveClinic(apiPayload);
  };

  const uploadFiles = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      onSuccess(file);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const { data } = await saveOnBoardingClinicFile(formData, config);
      const fileData = data?.data;
      if (!fileData.length) {
        message.error("CSV file must contain at least one record");
      }
      setClinicEntries(fileData);
      setFileList(file);
    } catch (error) {
      console.log("error", error);
      onError({ err: error });
      if (error?.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    addClinicForm.submit();
  };

  const handleDownloadErrors = async () => {
    try {
      setLoading(true);
      
      const fieldsToFilterOut = ["isMinLengthAddress","isMaxLengthAddress","isMinLengthCity","isMaxLengthCity","isMinLengthCounty","isMaxLengthCounty","isMaxLengthZipCode","isMinLengthZipCode"];
      const finalData = clinicEntries.map((invite) => {
        const updatedInvite = {};
        for (const key in invite) {
          if (!fieldsToFilterOut.includes(key)) {
            if (typeof invite[key] === 'boolean' && invite[key]) {
              updatedInvite[key] = "Yes";
            } else {
              updatedInvite[key] = invite[key];
            }
          }
        }
        return updatedInvite;
      });
      const { data } = await downloadOnBoardingErrorFile(finalData);
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "exported_data.csv";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      console.log("File parameters are not correct");
    } finally {
      setLoading(false);
    }
  };

  const handleUploadAgain = () => {
    setClinicEntries([]);
  };

  const saveClinicInvites = async () => {
    try {
      setLoading(true);
      const filtered = clinicEntries.filter(
        (invite) =>
          !(
            invite.inValidPhone ||
            invite.isMinLengthPhone ||
            invite.isMaxLengthPhone ||
            invite.inValidCountryCode ||
            invite.isMaxLengthCode ||
            invite.isNotAlphabets ||
            invite.isRequiredCountry ||
            invite.inValidEmail ||
            invite.duplicate ||
            invite.emailExists ||
            invite.isRequiredEmail ||
            invite.isRequiredClinicName ||  
            invite.isMinLengthCilinicName || 
            invite.isMaxLengthCilinicName || 
            invite.isRequiredClinicianName ||
            invite.isMinLengthCilinicianName ||
            invite.isMaxLengthCilinicianName ||
            invite.isMinLengthAddress ||
            invite.isMaxLengthAddress ||
            invite.isMinLengthCity ||
            invite.isMaxLengthCity ||
            invite.isMinLengthCounty ||
            invite.isMaxLengthCounty ||
            invite.isMinLengthZipCode ||
            invite.isMaxLengthZipCode
          )
      );

      if (!filtered.length) {
        return message.error("File parameters are incorrect");
      }
      const invitesMapper = filtered.map((invite) => {
        return {
          labId: labId,
          clinicEmail: invite.email,
          phone: invite.phone.toString(),
          clinicName: invite.clinicianName,
          country: invite.country,
          countryCode: "+" + invite.countryCode.toString(),
          clinicianName: invite.clinicName,
          addressLine: invite.addressLine,
          county: invite.county,
          zipCode: invite.zipCode,
          city: invite.city
        };
      });
      const { data } = await saveOnBoardingClinic(invitesMapper);
      const addedClinics = data?.data;
      const clinics = [...addedClinics, ...tableData];
      const finalData = onBoardingMapper(clinics);
      setOnBoardingClinics(finalData);
      const columns = getOnBoardingTableColumns(onResend, onDelete, finalData);
      // if (selectedTable === PARTNER_TABS.REGISTERED) {
        setTableData(finalData);
        // setTableColumns(columns);
      // }
      if (data) {
        message.success("Clinic invites sent successfully");
      }
      setShowClinicModal(false);
      setShowCsvUpload(false);
    } catch (error) {
      console.log(error);
      message.error(
        error.response.data.message === "clinicEmail must be unique"
          ? "clinic Email already exists. Try another email"
          : "File parameters are not correct"
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title={
        <div>
          <Title level={5} style={{ margin: "0px" }}>
            {getAddClinicHeaderText(resendClinic, showCsvUpload)}
          </Title>
        </div>
      }
      width={showCsvUpload ? 1300 : 750}
      open={showClinicModal}
      onCancel={handleAddClinicCancel}
      footer={
        clinicEntries.length
          ? [
              calculateErrorCount(clinicEntries) !== 0 ? (
                <div style={{ float: "left" }}>
                  <Tooltip title="It will download a CSV file." placement="top">
                    <span></span>
                    <AppButton onClick={handleDownloadErrors} type="default">
                      Download Errors
                    </AppButton>
                  </Tooltip>
                </div>
              ) : null,
              <AppButton onClick={handleUploadAgain} type="default">
                Upload Again
              </AppButton>,
              <AppButton onClick={saveClinicInvites} type="primary" loading={loading} htmlType="submit" disabled={getSuccessButtonText(clinicEntries) === 0}>
                {getInvitesClinicButtonText(calculateErrorCount(clinicEntries))}
              </AppButton>,
            ]
          : showCsvUpload
          ? null
          : [
              <AppButton onClick={handleAddClinicCancel} type="default">
                Cancel
              </AppButton>,
              <AppButton onClick={handleFormSubmit} loading={loading} htmlType="submit" type="primary">
                {getAddClinicButtonText(resendClinic)}
              </AppButton>,
            ]
      }
    >
      {showCsvUpload ? (
        <>
          {clinicEntries.length ? (
            <InvitesTable
              columns={getClinicInvitesColumns()}
              tableData={clinicEntries}
              loading={loading}
              fileList={fileList}
              // onRow={onRow}
              // pagination={pagination}
            />
          ) : (
            <>
              <UploadFile fileList={fileList} setFileList={setFileList} uploadFiles={uploadFiles} />
              <FileSample />
            </>
          )}
        </>
      ) : (
        <AddClinicForm
          addClinicForm={addClinicForm}
          onAddClinicSubmit={onAddClinicSubmit}
          setTouched={setTouched}
        />
      )}
    </Modal>
  );
};

export default AddClinicModal;
