
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Tooltip } from "antd";
import {
  totalNumberBackgroundColor,
  totoalNumberTextColor,
} from "colors-config";
import dayjs from "dayjs";
import { getAddressLineErrorTitle, getCityErrorTitle, getClinicianNameErrorTitle, getClinicNameErrorTitle, getCountryErrorTitle, getCountyErrorTitle, getEmailErrorTitle, getInvalidCountryCodeErrorTitle, getStatusNames, getZipCodeErrorTitle } from "../../utils";
import { onBoardingToolTip, verificationPendingOrOnboardingPendingTooltip } from "messages/messages";
import styles from "../../labPartners.module.scss";
import { onBoardingConstants, ONBOARDING_STATUS } from "../../../../../constants";
import { ONBOARDING_REQUESTED } from "constants";
import { showConfirmationModal } from "components/shared/ShowConfirmModal/index";
import FilterDataRangePicker from "components/shared/filterDataRangePicker";
import CustomColumnSorter from "./components/CustomColumnSorter";

export const UserName = ({ name }) => {
  return (
    <div className={styles.userName}>
      <UserOutlined className={styles.userIcon} />
      <p>{name}</p>
    </div>
  );
};

const getOnBoardStatus = (status) => {
  return onBoardingConstants[status];
};

const getActionsTooltip = (status) => {
  if (status === ONBOARDING_STATUS.VERIFICATION_PENDING || status === ONBOARDING_STATUS.ONBOARDING_PENDING) {
    return verificationPendingOrOnboardingPendingTooltip;
  } else if (status !== ONBOARDING_STATUS.ONBOARDING_REQUESTED) {
    return onBoardingToolTip;
  } else {
    return '';
  }
}

const getDefaultFilter = (status) =>{
  let filterArr = []
  if(!status) return filterArr
  if (status === "email-sent-to-clinic") {
    filterArr = ['ONBOARDING_REQUESTED']
  }
  else if(status === "verification-pending"){
    filterArr = ['VERIFICATION_PENDING']
  }
  else if(status === "onboarding-pending"){
    filterArr = ['ONBOARDING_PENDING']
  }
  return filterArr

}

export const getOnBoardingTableColumns = (onResend, onDelete, data, status, setStartDateFilter, setEndDateFilter, startDateFilter, endDateFilter, setFromDashboardRun, onBoardingStatusFilter, tableSorting, setTableSorting, queryParamStatus, history) => {
  const renderDropdownMenu = (record) => (
    <Menu
      onClick={({ key }) => {
        if (key === "delete") {
          showConfirmationModal(
            () => {
              onDelete(record.id, data);
            },
            "Delete Invite",
            "Are you sure you want to delete this invite?",
            "Cancel", 
            "Proceed"
          );
        } else {
          onResend(record, data);
        }
      }}
    >
      <Menu.Item
        key="resend"
        icon={<EditOutlined />}
        disabled={record.onboarding_status !== ONBOARDING_REQUESTED}
      >
        Resend Invite
      </Menu.Item>
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        disabled={record.onboarding_status !== ONBOARDING_REQUESTED}
      >
        Delete Invite
      </Menu.Item>
    </Menu>
  );

  const handleDateOrderedFilter = (confirm, start = "", end = "") => {
    setStartDateFilter(start)
    setEndDateFilter(end)
    if (start === "" && end === "") {
      setFromDashboardRun(3)
    }
    confirm({ closeDropdown: true });
    if(queryParamStatus){
      history.push({
        pathname: "/lab/partners",
        search: "",
      });
    }
  };

  const handleSort = (columnName, key, order) => {
    if (tableSorting?.name === key && tableSorting?.type === order) {
      setTableSorting({ name: null, type: null });
    } else {
      setTableSorting({ name: key, type: order });
    }
  };
   return [
    {
      title: <CustomColumnSorter
          name="Clinician Name"
          columnKey="clinicianName"
          tableSorting={tableSorting}
          onSort={handleSort}
        />
      ,
      dataIndex: "clinicName",
      key: "clinicName",
      render: (name) => <UserName name={name} />,
    },
    {
      title: <CustomColumnSorter
      name="Clinic Name"
      columnKey="clinicName"
      tableSorting={tableSorting}
      onSort={handleSort}
    />,
      dataIndex: "clinicianName",
      key: "clinicianName",
      render: (name) => name,
    },
    {
      width: 240,
      title: "Partnership Status",
      dataIndex: "onboarding_status",
      key: "onboarding_status",
      render: (status) => (
        <p
          className="register-patient-status"
          style={{
            color: totoalNumberTextColor,
            backgroundColor: totalNumberBackgroundColor,
          }}
        >
          {getOnBoardStatus(status)}
        </p>
      ),
      filters: getStatusNames(),
      filterMultiple: true,
      filteredValue: onBoardingStatusFilter,
      onFilter: (value, record) => {
        return record?.onboarding_status?.indexOf(value) === 0;
      },
    },
    {
      width: 160,
     title: "Date",
     dataIndex: "updatedAt",
     key: "updatedAt",
     render: (updatedAt) => (
       <span>{updatedAt ? dayjs(updatedAt).format("DD/MM/YYYY") : "-"}</span>
     ),
      filtered: (startDateFilter && endDateFilter),
     filterDropdown: ({ confirm, clearFilters }) => (
       <FilterDataRangePicker
         clearFilters={clearFilters}
         confirm={confirm}
         handleDateFilter={handleDateOrderedFilter}
         fromDate={startDateFilter}
         endingDate={endDateFilter}
       />
     ),
   },
    {
      title:<CustomColumnSorter
      name="Email"
      columnKey="clinicEmail"
      tableSorting={tableSorting}
      onSort={handleSort}
    />,
      dataIndex: "clinicEmail",
      key: "clinicEmail",
      // render: (email) => <span>{email}</span>,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      // render: (mobile, record) => {
      // return  <span>{mobile}</span>}
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (value, record) => {
        return (
          <Tooltip
            title={getActionsTooltip(record.onboarding_status)}
          >
            <Dropdown overlay={renderDropdownMenu(record)} trigger={["click"]}>
              <span>
                <MoreOutlined style={{ cursor: "pointer" }} />
              </span>
            </Dropdown>
          </Tooltip>
        );
      },
    },
  ];
};

const getRequestDate = (clinicDetails) => {
  if (clinicDetails?.requested_date) {
    return dayjs(clinicDetails?.requested_date).format("DD/MM/YYYY");
  }
  return "-";
};

export const getPartnersRequestColumns = () => {
  return [
    {
      title: "Clinic Name",
      dataIndex: "clinicName",
      key: "clinicName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
    },
    {
      title: "Requested Date",
      dataIndex: "hours",
      key: "request_date",
      render: (text) => getRequestDate(text),
    },
    {
      // title: 'Requests',
      dataIndex: "requests",

      key: "requests",
    },
  ];
};

export const getPartnerInvitesColumns = () => {
  return [
    {
      title: "Clinic Names",
      dataIndex: "clinicName",
      key: "clinicName",
      className: "test",
      render: (name) => <UserName name={name} />,
    },
    {
      title: "Partnership Status",
      dataIndex: "onboarding_status",
      key: "onboarding_status",
      render: (status) => (
        <p
          className="register-patient-status"
          style={{
            color: totoalNumberTextColor,
            backgroundColor: totalNumberBackgroundColor,
          }}
        >
          Email send to clinic
        </p>
      )
    },

    {
      title: "Email",
      dataIndex: "clinicEmail",
      key: "clinicEmail",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
  ]
}

export const getPartnerColumns = (onPartnerStatusFilter) => {
  return [
    {
      title: "Clinician Name",
      dataIndex: "clinicName",
      key: "clinicName",
      className: "test",
      render: (name) => <UserName name={name} />,
    },
    {
      title: "Clinic Name",
      dataIndex: "clinician_name",
      key: "clinician_name",
      className: "test",
      render: (name) => <p>{name}</p>,
    },
    {
      title: "Partnership Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <p
            className="register-patient-status"
            style={{
              color: totoalNumberTextColor,
              backgroundColor: totalNumberBackgroundColor,
            }}
          >
            {/* {status} */}
            {getOnBoardStatus(status)}
          </p>
        )
      },
      // filters: getStatusNames(),
      // filterMultiple: true,
      // filteredValue: onPartnerStatusFilter,
      // onFilter: (value, record) => {
      //   return record?.onboarding_status?.indexOf(value) === 0;
      // },
      sorter: (a, b) => (getOnBoardStatus(a.status) > getOnBoardStatus(b.status) ? -1 : 1),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
  ];
};

export const ErrorField = ({text}) =>{
  return (
    <div style={{color:"#FF0000"}}>
      <p>{text ? text : "--"}</p>
    </div>
  )
}



export const getClinicInvitesColumns = () => {
  return [
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      render: (value, record) => {
        console.log('Email Address',record)
        return <Tooltip title={getEmailErrorTitle(record.emailExists, record.inValidEmail, record.duplicate, record.isRequiredEmail)}>
        <span></span>
        {(record.inValidEmail || record.emailExists || record.duplicate || record.isRequiredEmail) ? <ErrorField text={value} /> : <p>{value}</p>}
        </Tooltip>
      },
    },
    {
      title: "Clinician Name",
      dataIndex: "clinicianName",
      key: "clinicianName",
      render: (value, record) => {
        return <Tooltip title={getClinicianNameErrorTitle(record)}> <span></span> 
        {(record.isRequiredClinicianName || record.isMinLengthCilinicianName || record.isMaxLengthCilinicianName)?<ErrorField text={value}/>:<p>{value}</p>}</Tooltip>;
      },
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (value, record) => {
        return <>{(!!record.countryCode && !!record.phone) ? (<Tooltip title={getInvalidCountryCodeErrorTitle(record)}> <span></span>
          {(record.inValidPhone || record.isMinLengthPhone || record.isMaxLengthPhone || record.inValidCountryCode || record.isMaxLengthCode) ?
            <ErrorField text={`${record.countryCode}-${record.phone}`} /> : <p>{`${record.countryCode}-${record.phone}`}</p>}
        </Tooltip>) : <p>--</p>}</>
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (value, record) => {
        return <Tooltip title={getCountryErrorTitle(record)}> <span></span> 
          {(record.isNotAlphabets || record.isRequiredCountry) ? <ErrorField text={value}/> : <p>{value}</p>}
        </Tooltip>
      },
    },
    {
      title: "Clinic Name",
      dataIndex: "clinicName",
      key: "clinicName",
      render: (value, record) => {
        return <Tooltip title={getClinicNameErrorTitle(record)}> <span></span> 
        {(record.isRequiredClinicName || record.isMinLengthCilinicName || record.isMaxLengthCilinicName)?<ErrorField text={value}/> : <p>{value}</p>}
        </Tooltip>;
      },
    },
    {
      title: "Clinic's Address Line",
      dataIndex: "addressLine",
      key: "addressLine",
      render: (value, record) => {
        return <Tooltip title={getAddressLineErrorTitle(record)}> <span></span> 
        {(record.isMinLengthAddress || record.isMaxLengthAddress )?<ErrorField text={value}/>:<p>{value}</p>}</Tooltip>;
      },
    },
    {
      title: "Clinic's City",
      dataIndex: "city",
      key: "city",
      render: (value, record) => {
        return <Tooltip title={getCityErrorTitle(record)}> <span></span> 
        {(record.isMinLengthCity || record.isMaxLengthCity )?<ErrorField text={value}/>:<p>{value}</p>}</Tooltip>;
      },
    },
    {
      title: "Clinic's County/Province/State",
      dataIndex: "county",
      key: "county",
      render: (value, record) => {
        return <Tooltip title={getCountyErrorTitle(record)}> <span></span> 
        {(record.isMinLengthCounty || record.isMaxLengthCounty )?<ErrorField text={value}/>:<p>{value}</p>}</Tooltip>;
      },
    },
    {
      title: "Clinic's Post/Zip/EIR Code",
      dataIndex: "zipCode",
      key: "zipCode",
      render: (value, record) => {
        return <Tooltip title={getZipCodeErrorTitle(record)}> <span></span> 
        {(record.isMinLengthZipCode || record.isMaxLengthZipCode)?<ErrorField text={value}/>:<p>{value}</p>}</Tooltip>;
      },
    },
  ];
};

