import { useState } from "react";
import { Modal, Spin, Typography } from "antd";
import { ReactComponent as User } from "assets/images/User.svg";
import UsersList from "./components/UsersList";
import ShareInput from "./components/ShareInput";
const { Title } = Typography;

const NotificationShareModal = ({
  showShareModal,
  setShowShareModal,
  shareList,
  setShareList,
  labId
}) => {
  const [loading, setLoading] = useState(false);

  const handleShareResourceCancel = () => {
    setShowShareModal(false);
  };

  return (
    <Spin spinning={loading}>
      <Modal
        className="notificationShareModal"
        title={
          <div className="titleSpan">
            <Title level={5} style={{ margin: "0px" }}>
              Share with
            </Title>
          </div>
        }
        width={600}
        open={showShareModal}
        onCancel={handleShareResourceCancel}
        footer={null}
      >
        <ShareInput shareList={shareList} setShareList={setShareList} labId={labId} />
        {shareList.length ? (
          <UsersList shareList={shareList} setShareList={setShareList} labId={labId} />
        ) : (
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <User />
          </div>
        )}
      </Modal>
    </Spin>
  );
};

export default NotificationShareModal;
