import { useState } from "react";
import { Modal, Button, Row, Col } from "antd";
import { ReactComponent as Pdf } from "assets/images/Pdf.svg";
import { ReactComponent as Image } from "assets/images/Image.svg";
import { ReactComponent as RightArrow } from "assets/images/RightArrow.svg";
import { ReactComponent as LeftArrow } from "assets/images/LeftArrow.svg";
import {
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import FileViewModal from "components/shared/FileViewModal/FileViewModal";

const LabInvoiceModal = ({
  showInvoiceModal,
  setShowInvoiceModal,
  orderInfo,
  setOrderInfo,
}) => {
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [iframeFile, setIframeFile] = useState(null);

  const handleOk = () => {
    setShowInvoiceModal(false);
  };

  const handleCancel = () => {
    setShowInvoiceModal(false);
    setOrderInfo(null);
  };

  const onDownloadAllClick = () => {
    orderInfo.shippingFiles.forEach((file) => {
      downloadHandler(file);
    });
  };

  const showIframeModalHandler = (file) => {
    console.log(file);
    setShowIframeModal(true);
    setIframeFile(file);
  };
  const downloadHandler = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleIframeCancel = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };
  const handleIframeOk = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  // Handle showing the previous file
  const showPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : orderInfo.shippingFiles.length - 1
    );
  };

  // Handle showing the next file
  const showNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < orderInfo.shippingFiles.length - 1 ? prevIndex + 1 : 0
    );
  };

  const currentFile = orderInfo.shippingFiles[currentIndex];
  return (
    <div>
      <Modal
        width={700}
        title="Order Invoice"
        open={showInvoiceModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        footer={null}
      >
        <div className="order-invoice-modal">
          {orderInfo?.shippingFiles?.length ? (
            <Row>
              <Col lg={6} offset={18}>
                <div
                  onClick={() => onDownloadAllClick()}
                  className="download-allBtn"
                >
                  <DownloadOutlined /> Download All
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <div>
            {orderInfo?.shippingFiles.length ? (
              <div className="carousel-container">
                <div className="resource-container">
                <div className="resource">
                  <div className="resource-wrapper">
                    <div className="icons">
                      <a
                        href={currentFile.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadOutlined
                          style={{ marginRight: "1rem", fontSize: "20px" }}
                          onClick={() => downloadHandler(currentFile)}
                        />
                      </a>
                      <EyeOutlined
                        onClick={() => showIframeModalHandler(currentFile)}
                        style={{ marginRight: "1rem", fontSize: "20px" }}
                      />
                    </div>
                  </div>
                  {["image/jpeg", "image/png"].includes(
                    currentFile.meta.mimeType
                  ) ? (
                    <Image />
                  ) : (
                    <Pdf />
                  )}
                  <p>{currentFile.name}</p>
                </div>
                </div>
              
                <Row justify="space-between">
                  <Col lg={8}>
                    <div className="arrow-icon">
                      <LeftArrow
                        onClick={showPrevious}
                      
                      />
                    </div>
                  </Col>
                  <Col lg={8} style={{textAlign:"center"}}>
                    <p>
                      {currentIndex + 1} / {orderInfo.shippingFiles.length}
                    </p>
                  </Col>
                  <Col lg={8} >
                    <div className="arrow-icon" style={{ float:"right"}}>
                      <RightArrow
                        onClick={showNext}
                     
                      />
                    </div>
                  </Col>
                </Row>
            
              </div>
            ) : (
              <div>
                <h1 style={{fontSize:"16px"}}>No Shipping Files</h1>
              </div>
            )}
          </div>
          {showIframeModal && (
            <FileViewModal
              isModalVisible={showIframeModal}
              handleOk={handleIframeOk}
              handleCancel={handleIframeCancel}
              iframeFile={iframeFile}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default LabInvoiceModal;
