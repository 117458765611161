import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Tooltip,
} from "antd";
import { useHistory } from "react-router-dom";
import MultiStepButton from "./buttons/multiStepButton";
import "../../assets/styles/components/passwordHandleComponent.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import { passwordValidator } from "utils/Lab";
import { confirmPasswordErrorMessage } from "messages/messages";


const { Title } = Typography;

export default function PasswordHandleComponent({ mode, forgotPassword ,forgetPasswordCode}) {
  const [form] = Form.useForm();
  const [otp, setOtp] = useState();
  const [newPassword, setNewPassword] = useState();
  const history = useHistory();

  useEffect(() => {
    if (forgetPasswordCode) {
      setOtp(forgetPasswordCode);

      form.setFieldValue('code',forgetPasswordCode)
    }
  }, [forgetPasswordCode,form]);

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        forgotPassword(otp, newPassword);
      })
      .catch((info) => {
        console.log("Hello");
      });
  };
  return (
    <div className="forgot-password-container ">
      <Row
        justify="start"
        style={{ backgroundColor: "white", borderRadius: "12px 12px 0 0" }}
      >
        <Col
          span={24}
          style={{ paddingLeft: "2rem", borderBottom: "1px solid lightgray" }}
        >
          <div className="forgot-password-title-container ">
            <Title
              level={2}
              style={{
                fontFamily: "'Poppins', sans-serif",
                marginBottom: "0.1rem",
              }}
            >
              {mode !== "change" ? "Forgot Password" : ""}
            </Title>

            <p
              style={{
                fontSize: "1rem",
                fontWeight: "300",
              }}
            >
              {mode !== "change"
                ? "Let’s create a new password for your account"
                : ""}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        {" "}
        <Form
          style={{
            width: "100%",
            borderRadius: "6px",
          }}
          name="complex-form"
          layout={"vertical"}
          //   onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <div className="forgot-password-form">
            <Form.Item
              label="Please enter the verification code sent to your email"
              name="code"
              //   className="inline-form-left"
              rules={[{ required: true, message: "Security Code is required" }]}
              style={{
                //width: "calc(50% - 1.5rem)",
                marginRight: "0.4rem",
                marginTop: "2rem",

                textAlign: "left",
              }}
            >
              <Input disabled={Boolean(forgetPasswordCode)} style={{ width: "calc(50% - 1.5rem)" }} />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  New Password&nbsp;
                  <Tooltip
                    overlayClassName="registerInfo"
                    title={
                      <ul style={{ padding: "5px 10px" }}>
                        <li>At least 1 lowercase alphabetical character</li>
                        <li>At least 1 uppercase alphabetical character</li>
                        <li>At least 1 numeric character</li>
                        <li>At least 1 special character</li>
                        <li>Minimum 8 characters</li>
                      </ul>
                    }
                  >
                    <InfoCircleOutlined
                      style={{
                        color: primaryColor,
                        marginLeft: "0.5rem",
                      }}
                    />
                  </Tooltip>
                </span>
              }
              name="newPassword"
              //   className="inline-form-left"
              rules={[
                { required: true, message: "Password is required" },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/,
                  message: "Invalid password",
                },
                {
                  validator: passwordValidator,
                },
              ]}
              hasFeedback
              style={{
                width: "calc(50% - 1.5rem)",
                marginRight: "0.4rem",
                marginTop: "2rem",

                textAlign: "left",
              }}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              //   className="inline-form-left"
              rules={[
                {
                  required: true,
                  message: "Password confirmation is required",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      setOtp(getFieldValue("code"));
                      setNewPassword(getFieldValue("newPassword"));

                      return Promise.resolve();
                    }

                    return Promise.reject(
                      confirmPasswordErrorMessage
                    );
                  },
                }),
              ]}
              hasFeedback
              dependencies={["newPassword"]}
              style={{
                width: "calc(50% - 1.5rem)",
                marginRight: "0.4rem",
                marginTop: "2rem",

                textAlign: "left",
              }}
            >
              <Input.Password />
            </Form.Item>
          </div>
          <div style={{ backgroundColor: "#F3F6FA", marginTop: "1rem" }}>
            <MultiStepButton
              align={"right"}
              type={"primary"}
              extraStyle={{ marginLeft: "2rem" }}
              onClick={submit}
              //   onClick={() => onNext()}
              //   disabled={isMultiStepButtonDisabled}
            >
              Save Changes
            </MultiStepButton>
            <MultiStepButton
              align={"right"}
              onClick={() => history.push("/login")}
              //   disabled={isMultiStepButtonDisabled}
            >
              Cancel
            </MultiStepButton>
          </div>
        </Form>
      </Row>
    </div>
  );
}
