import { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Spin, Divider,Form,message,Switch } from "antd";

import { ReactComponent as Users } from "assets/images/Users.svg";

import NotificationShareModal from "../NotificationShareModal/NotificationShareModal";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import { getSummaryEmailsAPI , getSummaryNotificationsAPI, updateNotificationsAPI} from "api/notifications";
import { notificationToolTip ,} from "messages/messages";

const NotificationSettingsModal = ({
  openSettingsModal,
  onNotificationCancel,
  labId,
}) => {
  const [notificationForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareList, setShareList] = useState([]);

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  useEffect(() => {
    const fetchDurations = async () => {
      try {
        const apiArray = [
          getSummaryEmailsAPI(labId),
         getSummaryNotificationsAPI(labId)
        ];
        const response = await Promise.all(apiArray)
        // const { data } = await getSummaryEmailsAPI(labId);
        // const res = await getSummaryNotificationsAPI(labId)
        if (response) {
        const  emailsList = response[0]?.data?.emails
          const finalResponse={
            summaryEmail: response[1]?.data.settings?.summaryEmail
          }
          setShareList(emailsList);
          notificationForm.setFieldsValue(finalResponse);
        }
  
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDurations();
  }, []);

  const onFinish = async (values) => {
    console.log("values", values)
    const apiPayload = { labId, settings: {...values} };
    try {
      setLoading(true);
       const { data } = await updateNotificationsAPI(apiPayload, labId);
      if (data) {
        message.success("Notifications settings updated");
      }
      onNotificationCancel();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      {showShareModal && (
        <NotificationShareModal
          showShareModal={showShareModal}
          setShowShareModal={setShowShareModal}
          shareList={shareList}
          setShareList={setShareList}
          labId={labId}
        />
      )}

      <Modal
        open={openSettingsModal}
        title="Notification Settings"
        onCancel={onNotificationCancel}
        // onOk={onNotificationsSubmit}
        width={850}
        footer={[
          <Button
            key="cancel"
            shape="round"
            onClick={onNotificationCancel}
            style={{ width: "7rem" }}
          >
            Cancel
          </Button>,
          <Button
          shape="round"
            style={{ width: "7rem" }}
            key="submit"
            type="primary"
            onClick={() => notificationForm.submit()}
          >
            Save
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <div className="notifications-modal-container">
          <Row justify="end" align="middle" className="header-row">
              <Col lg={4} offset={12}>
                <p>Email</p>
              </Col>
            </Row>
            <Divider style={{ margin: "10px 0" }} />

            <Form
              name="notification-form"
              // layout={"vertical"}
              form={notificationForm}
              onFinish={onFinish}
            >
              <Row justify="start" align="middle">
              <Col lg={20}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label className="form-label">Lab Summary Notification</label>
                  <AppToolTip
                    title={notificationToolTip}
                    iconStyles={{ marginLeft: "10px" }}
                  ></AppToolTip>
                  <Button
                    shape="round"
                    type="primary"
                    style={{
                      marginLeft: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleShareClick}
                    icon={<Users />}
                  >
                    Share
                  </Button>
                </div>
              </Col>
                <Col lg={4}>
                  <div className="notification-columnTwo">
                    <Form.Item name="summaryEmail" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <span className="onLabel">On</span>
                  </div>
                </Col>

              </Row>
            </Form>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default NotificationSettingsModal;
