import { useState, useEffect } from "react";
import { Button, Dropdown, Menu } from "antd";
import { v4 as uuidv4 } from "uuid";
import { UPPER_JAW, LOWER_JAW } from "./data";
import AttachmentTooth from "./AttachmentTooth";
import { ReactComponent as AddIcon } from "../../../assets/images/teeths/addIcon.svg";

import { primaryColor } from "colors-config";
import AttachmentAligners from "./AttachmentAligners";
import AttachmentInfo from "./AttachmentInfo";

const Attachments = ({
  upperLength,
  lowerLength,
  upperAttachments,
  setUpperAttachments,
  lowerAttachments,
  setLowerAttachments,
  chartValue,
  attachmentProductList,
  setAttachmentProductList,
  updateAlignersErrorsArray,
}) => {
  const [attachmentIndex, setAttachmentIndex] = useState(null);
  const [upperAlignerOptions, setUpperAlignerOptions] = useState([]);
  const [lowerAlignerOptions, setLowerAlignerOptions] = useState([]);
  const [upperStructure, setUpperStructure] = useState(UPPER_JAW);
  const [lowerStructure, setLowerStructure] = useState(LOWER_JAW);
  const [alignersLabel, setAlignersLabel] = useState([]);

  const generateAlignerArray = (num, type) => {
    const result = [];

    // const prefix = type === "upper" ? "U" : "L";
    const prefix = type === "upper" ? "Before Aligner " : "Before Aligner ";

    for (let i = 1; i <= num; i++) {
      result.push({
        label: `${prefix}${i}`,
        key: i,
      });
    }

    return result;
  };

  useEffect(() => {
    // if (upperAligners.length && lowerAligners.length) {
    const mergeAligners = upperAttachments.concat(lowerAttachments);
    mergeAligners.sort((a, b) => {
      return a.aligner_count - b.aligner_count;
    });
    let uniqueSessions = uniqByKeepLast(mergeAligners, (obj) => obj.aligner_count);
    uniqueSessions.map((obj, index) => {
      obj.session = index + 1;
      return obj;
    });
    setAlignersLabel(uniqueSessions);
    // }
  }, [upperAttachments, lowerAttachments]);

  const uniqByKeepLast = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };

  useEffect(() => {
    const upperOptions = generateAlignerArray(upperLength, "upper");
    setUpperAlignerOptions(upperOptions);
    const lowerOptions = generateAlignerArray(lowerLength, "lower");
    setLowerAlignerOptions(lowerOptions);
  }, [lowerLength, upperLength]);

  const filteredOptions = (type) => {
    // let filteredOptions;
    // let names;
    // let totalOptions;
    // if (type === "upper") {
    //   names = upperAttachments.map((item) => item.name);
    //   totalOptions = upperAlignerOptions;
    // } else {
    //   names = lowerAttachments.map((item) => item.name);
    //   totalOptions = lowerAlignerOptions;
    // }

    // let filtered = totalOptions.filter((option) => !names.includes(option.key));
    // filteredOptions = filtered.map((aligner) => {
    //   return <Menu.Item key={aligner.key}>{aligner.label}</Menu.Item>;
    // });
    // return filteredOptions;

    let filteredOptions,
      totalOptions,
      targetJaw = [];
    if (type === "upper") {
      targetJaw = upperAttachments;
      totalOptions = upperAlignerOptions;
    } else {
      targetJaw = lowerAttachments;
      totalOptions = lowerAlignerOptions;
    }

    const alignerNumbersArray = targetJaw.map((item) => item.aligner_count);

    let filtered = totalOptions.filter((option) => !alignerNumbersArray.includes(option.key));
    filteredOptions = filtered.map((aligner) => {
      return <Menu.Item key={aligner.key}>{aligner.label}</Menu.Item>;
    });
    return filteredOptions;
  };

  const handleMenuClick = (key, type) => {
    let aligners;
    let newData;
    const UId = uuidv4();
    if (type === "upper") {
      aligners = [...upperAttachments];
    } else {
      aligners = [...lowerAttachments];
    }
    const newAligners = aligners.map((aligner) => {
      return {
        ...aligner,
        selected: false,
      };
    });
    const prefix = type === "upper" ? "U" : "L";
    newAligners.unshift({
      name: `${prefix}${key}`,
      selected: true,
      uniqueId: UId,
      aligner_count: key,
      alignerValues: Array.from({ length: 16 }, (_, index) => ({
        value: false,
        teeth: null,
        detail: "",
        attachmentType: "",
      })),
    });

    if (type === "upper") {
      newData = newAligners.sort((a, b) => b.aligner_count - a.aligner_count);
      newData = newData.reverse().map((obj, index) => {
        obj.index = index + 1;
        return obj;
      });
      setUpperAttachments(newData.reverse());
      setLowerAttachments((prev) =>
        prev.map((aligner) => {
          return { ...aligner, selected: false };
        })
      );
    } else {
      newData = newAligners.sort((a, b) => a.aligner_count - b.aligner_count);
      setLowerAttachments(newData);
      setUpperAttachments((prev) =>
        prev.map((aligner) => {
          return { ...aligner, selected: false };
        })
      );
    }
    const index = newData.findIndex((obj) => obj.uniqueId === UId);
    setAttachmentIndex({
      type,
      activeAligner: UId,
      value: index,
    });
  };

  return (
    <div>
      <div className="teethChart-container" style={{ padding: "0px 35px" }}>
        <Dropdown
          disabled={!upperLength || upperLength === upperAttachments.length}
          overlay={
            <Menu
              className={upperLength > 6 ? "scroll-ipr-chart ipr-chart-drop" : "ipr-chart-drop"}
              onClick={(e) => handleMenuClick(+e.key, "upper")}
              mode="horizontal"
              selectable={true}
            >
              <Menu.Item key="" disabled>
                Before:
              </Menu.Item>
              {filteredOptions("upper")}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button icon={<AddIcon style={{ fill: primaryColor }} />} className="addOn-btn">
            Add Attachment Session
          </Button>
        </Dropdown>
        <AttachmentAligners
          //  secondAligners={lowerAttachments}
          alignersArray={upperAttachments}
          setUpperAttachments={setUpperAttachments}
          setLowerAttachments={setLowerAttachments}
          setAttachmentIndex={setAttachmentIndex}
          attachmentIndex={attachmentIndex}
          alignerType="upper"
          alignersLabel={alignersLabel}
          teethStructure={upperStructure}
          setTeethStructure={setUpperStructure}
          updateAlignersErrorsArray={updateAlignersErrorsArray}
        />

        <div className="teethRow-container" style={{ marginTop: "4px" }}>
          <div className="teethRow-bgOne" />
          <div className="teethRow-one ipr-row">
            {upperStructure.map((teeth, index) => {
              return (
                <div key={index}>
                  <AttachmentTooth
                    // setAttachmentIndex={setAttachmentIndex}
                    attachmentIndex={attachmentIndex}
                    teethStructure={upperStructure}
                    setTeethStructure={setUpperStructure}
                    alignersArray={upperAttachments}
                    setAttachmentsArray={setUpperAttachments}
                    type="upper"
                    index={index}
                    teeth={teeth}
                    chartValue={chartValue}
                    attachmentProductList={attachmentProductList}
                    setAttachmentProductList={setAttachmentProductList}
                    upperAttachments={upperAttachments}
                    lowerAttachments={lowerAttachments}
                    // handleAlignerChange={handleAlignerChange}
                  />
                </div>
              );
            })}
            <div className="tooth-sides">
              <h2>Right</h2>
              <h2>Left</h2>
            </div>
            {lowerStructure.map((teeth, index) => {
              return (
                <div key={index}>
                  <AttachmentTooth
                    teethStructure={lowerStructure}
                    setTeethStructure={setLowerStructure}
                    alignersArray={lowerAttachments}
                    type="lower"
                    index={index}
                    teeth={teeth}
                    chartValue={chartValue}
                    setAttachmentIndex={setAttachmentIndex}
                    setAttachmentsArray={setLowerAttachments}
                    attachmentIndex={attachmentIndex}
                    attachmentProductList={attachmentProductList}
                    setAttachmentProductList={setAttachmentProductList}
                    // handleAlignerChange={handleAlignerChange}
                  />
                </div>
              );
            })}
          </div>
          <div className="teethRow-bgTwo"></div>
        </div>
        <AttachmentAligners
          setUpperAttachments={setUpperAttachments}
          alignersArray={lowerAttachments}
          setLowerAttachments={setLowerAttachments}
          setAttachmentIndex={setAttachmentIndex}
          attachmentIndex={attachmentIndex}
          alignerType="lower"
          alignersLabel={alignersLabel}
          teethStructure={lowerStructure}
          setTeethStructure={setLowerStructure}
          updateAlignersErrorsArray={updateAlignersErrorsArray}
        />

        <Dropdown
          disabled={!lowerLength || lowerLength === lowerAttachments.length}
          overlay={
            <Menu
              className={lowerLength > 6 ? "scroll-ipr-chart ipr-chart-drop" : "ipr-chart-drop"}
              onClick={(e) => handleMenuClick(+e.key, "lower")}
              mode="horizontal"
              selectable={true}
            >
              <Menu.Item key="" disabled>
                Before:
              </Menu.Item>
              {filteredOptions("lower")}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button icon={<AddIcon style={{ fill: primaryColor }} />} className="addOn-btn">
            Add Attachment Session
          </Button>
        </Dropdown>
      </div>
      <AttachmentInfo
        setUpperAttachments={setUpperAttachments}
        attachmentsArray={upperAttachments}
        setLowerAttachments={setLowerAttachments}
        attachmentIndex={attachmentIndex}
        teethStructure={upperStructure}
        setTeethStructure={setUpperStructure}
        attachmentType="upper"
        chartValue={chartValue}
        attachmentProductList={attachmentProductList}
        setAttachmentProductList={setAttachmentProductList}
        updateAlignersErrorsArray={updateAlignersErrorsArray}
      />

      <AttachmentInfo
        setUpperAttachments={setUpperAttachments}
        attachmentsArray={lowerAttachments}
        setLowerAttachments={setLowerAttachments}
        attachmentIndex={attachmentIndex}
        teethStructure={lowerStructure}
        setTeethStructure={setLowerStructure}
        attachmentType="lower"
        chartValue={chartValue}
        attachmentProductList={attachmentProductList}
        setAttachmentProductList={setAttachmentProductList}
        updateAlignersErrorsArray={updateAlignersErrorsArray}
      />
    </div>
  );
};

export default Attachments;
