import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, Image, message } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import { StlViewer } from "react-stl-file-viewer";
import "../../../assets/styles/components/UploadClinicalFiles.scss";
import { downloadS3File } from "api/patient";
import dayjs from "dayjs";
import { primaryColor } from "colors-config";

const UploadClinicalFiles = ({
  clinicalFiles,
  patientId,
  activeCaseId = 1,
  getImagesAndQuestionnaire,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  let [deleteFileOpen, setDeleteFileOpen] = useState(false);
  let [deleteFileData, setDeleteFileData] = useState();
  useEffect(() => {
    const updatedClinicalFiles = clinicalFiles.filter(
      (f) => f.resource_type !== "video" && f.resource_type !== "application"
    );
    updatedClinicalFiles.push(
      ...clinicalFiles
        .filter((f) => f.resource_type === "video")
        .map((obj) => ({
          ...obj,
          thumbUrl: require("../../../assets/images/video-icon.svg").default,
        }))
    );
    updatedClinicalFiles.push(
      ...clinicalFiles
        .filter((f) => f.resource_type === "application" && !f.resource_url.includes(".zip"))
        .map((obj) => ({
          ...obj,
          thumbUrl: require("../../../assets/images/doc-icon.svg").default,
        }))
    );
    updatedClinicalFiles.push(
      ...clinicalFiles
        .filter((f) => f.resource_type === "application" && f.resource_url.includes(".zip"))
        .map((obj) => ({ ...obj, thumbUrl: require("../../../assets/images/zip_icon.png") }))
    );
    const finalFileList = updatedClinicalFiles.map((obj) => ({
      ...obj,
      uid: obj.url,
      name: obj?.patient_image_view?.split("-")[0],
    }));

    const sortedArray = finalFileList
      .sort((a, b) => dayjs(a.upload_date).diff(dayjs(b.upload_date)))
      .reverse();
    setFileList(sortedArray);
  }, [clinicalFiles]);
  // console.log(fileList)
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (file.resource_type === "application") {
      window.open(file.url, "_blank");
      return false;
    }
    if (file.resource_type === "video") {
      setPreviewOpen(true);
      setFile(file);
      setType("video");
      return false;
    }

    let imageType = "image";
    if (file.resource_url.includes(".stl")) {
      imageType = "stl";
    }

    setType(imageType);

    setFile(file);
    setPreviewOpen(true);
    setPreviewTitle("Preview");
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const deleteClinicalFile = async (data) => {
    const deleteData = {
      patient_id: data?.patient_id,
      file_name: data?.resource_url,
    };
    await axios
      .post(`${process.env.REACT_APP_CLINIC_BASE_URL}/patient/resource/delete`, deleteData)
      .then((res) => {
        message.success("Resource Deleted Successfully");
        setFileList(fileList.filter((obj) => obj.resource_url !== data.resource_url));
        getImagesAndQuestionnaire();
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
    </div>
  );
  async function uploadFiles(options) {
    if (activeCaseId) {
      const { onSuccess, onError, file, onProgress, filename } = options;
      const fileNameArray = file.name.split(".");
      fileNameArray[0] = fileNameArray[0] + "-" + new Date().valueOf();
      const fileName = fileNameArray.join(".");
      const fmData = new FormData();
      const config = {
        headers: {
          "content-type": file.type,
        },

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          if (percent === 100) {
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("file", file);
      try {
        const preSignRequestBody = {
          patient_id: patientId,
          file_name: fileName,
          resource_type: "file",
          file_type: file.type,
          content_type: file.type,
        };
        // console.log(preSignRequestBody)
        // return false
        await axios
          .post(`${process.env.REACT_APP_CLINIC_BASE_URL}/patient/resource/add`, preSignRequestBody)
          .then(async (res) => {
            const accessUrl = res.data.body.success.accessUrl?.split("?")[0];
            await axios.put(res.data.body.success.uploadUrl, file, config);
            await axios.post(
              `${process.env.REACT_APP_CLINIC_BASE_URL}/clinic/patient/resource/upload/confirm`,
              {
                patient_id: patientId,
                file_url: accessUrl,
                patient_upload_stage: "clinical",
                resource_type: file?.type?.split("/")[0],
                scan_type: filename,
                case_id: activeCaseId,
              }
            );
          });
        onSuccess("Ok");
        getImagesAndQuestionnaire();
      } catch (error) {
        console.log(error);

        onError({ error });
      }
    } else message.error("Cannot upload scan files for this case");
  }
  const properties = {
    customRequest: uploadFiles,
  };

  const perview = () => {
    return (
      <>
        {file?.patient_image_view?.split("-")[0]}
        <DownloadOutlined
          style={{
            color: primaryColor,
            marginTop: "0.2rem",
            marginLeft: "0.2rem",
          }}
          onClick={() => {
            window.open(downloadS3File(file?.resource_url?.split("/")[1]));
          }}
        />
      </>
    );
  };

  const handleFileOk = () => {
    deleteClinicalFile(deleteFileData);
    setDeleteFileOpen(false);
  };

  const handleFileCancel = () => {
    setDeleteFileOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <Upload
        className="clinic-file-upload"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        // onRemove={e => deleteClinicalFile({patient_id : e.patient_id, resource_url: e.resource_url})}
        onRemove={(e) => {
          setDeleteFileOpen(true);
          setDeleteFileData({ patient_id: e.patient_id, resource_url: e.resource_url });
          return false;
        }}
        {...properties}
        itemRender={(originNode, file) => {
          return (
            <div className="file-container" style={{ minWidth: "100px" }}>
              {originNode}
              {!file?.status && (
                <div
                  style={{
                    textAlign: "center",
                    textTransform: "capitalize",
                    overflowWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <b style={{ margin: "0px", fontSize: "13px", whiteSpace: "nowrap" }}>
                    {file.name}
                  </b>
                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {dayjs(file.upload_date).format("DD-MM-YYYY")}
                  </p>
                </div>
              )}
            </div>
          );
        }}
      >
        <div>
          <div style={{ margin: "14px 0 16px 0" }}>
            <UploadOutlined />
          </div>

          <div className="scan-upload-text">Click or drag file to this area to upload</div>
        </div>
      </Upload>

      <Modal
        open={deleteFileOpen}
        title={"Delete File"}
        onOk={handleFileOk}
        onCancel={handleFileCancel}
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
      {previewOpen && (
        <Modal
          className="uploadfileContainermodal"
          open={previewOpen}
          title={perview()}
          footer={null}
          onCancel={handleCancel}
        >
          {type === "video" ? (
            <ReactPlayer url={file?.url} height="250px" width={"450px"} controls />
          ) : type === "stl" ? (
            <StlViewer
              width={480}
              height={248}
              orbitControls
              shadows
              url={file?.url}
              objectColor="gray"
              volume={() => {}}
            />
          ) : (
            <Image
              alt="example"
              style={{
                width: "100%",
              }}
              src={file?.url}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
export default UploadClinicalFiles;
