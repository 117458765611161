import { ReactComponent as Teeth18 } from "assets/images/teeths/teeth18.svg";
import { ReactComponent as Teeth17 } from "assets/images/teeths/teeth17.svg";
import { ReactComponent as Teeth16 } from "assets/images/teeths/teeth16.svg";
import { ReactComponent as Teeth15 } from "assets/images/teeths/teeth15.svg";
import { ReactComponent as Teeth14 } from "assets/images/teeths/teeth14.svg";
import { ReactComponent as Teeth13 } from "assets/images/teeths/teeth13.svg";
import { ReactComponent as Teeth12 } from "assets/images/teeths/teeth12.svg";
import { ReactComponent as Teeth11 } from "assets/images/teeths/teeth11.svg";

import { ReactComponent as Teeth21 } from "assets/images/teeths/teeth21.svg";
import { ReactComponent as Teeth22 } from "assets/images/teeths/teeth22.svg";
import { ReactComponent as Teeth23 } from "assets/images/teeths/teeth23.svg";
import { ReactComponent as Teeth24 } from "assets/images/teeths/teeth24.svg";
import { ReactComponent as Teeth25 } from "assets/images/teeths/teeth25.svg";
import { ReactComponent as Teeth26 } from "assets/images/teeths/teeth26.svg";
import { ReactComponent as Teeth27 } from "assets/images/teeths/teeth27.svg";
import { ReactComponent as Teeth28 } from "assets/images/teeths/teeth28.svg";

import { ReactComponent as Teeth48 } from "assets/images/teeths/teeth48.svg";
import { ReactComponent as Teeth47 } from "assets/images/teeths/teeth47.svg";
import { ReactComponent as Teeth46 } from "assets/images/teeths/teeth46.svg";
import { ReactComponent as Teeth45 } from "assets/images/teeths/teeth45.svg";
import { ReactComponent as Teeth44 } from "assets/images/teeths/teeth44.svg";
import { ReactComponent as Teeth43 } from "assets/images/teeths/teeth43.svg";
import { ReactComponent as Teeth42 } from "assets/images/teeths/teeth42.svg";
import { ReactComponent as Teeth41 } from "assets/images/teeths/teeth41.svg";

import { ReactComponent as Teeth31 } from "assets/images/teeths/teeth31.svg";
import { ReactComponent as Teeth32 } from "assets/images/teeths/teeth32.svg";
import { ReactComponent as Teeth33 } from "assets/images/teeths/teeth33.svg";
import { ReactComponent as Teeth34 } from "assets/images/teeths/teeth34.svg";
import { ReactComponent as Teeth35 } from "assets/images/teeths/teeth35.svg";
import { ReactComponent as Teeth36 } from "assets/images/teeths/teeth36.svg";
import { ReactComponent as Teeth37 } from "assets/images/teeths/teeth37.svg";
import { ReactComponent as Teeth38 } from "assets/images/teeths/teeth38.svg";

export const teethCollection = {
  18: Teeth18,
  17: Teeth17,
  16: Teeth16,
  15: Teeth15,
  14: Teeth14,
  13: Teeth13,
  12: Teeth12,
  11: Teeth11,
  21: Teeth21,
  22: Teeth22,
  23: Teeth23,
  24: Teeth24,
  25: Teeth25,
  26: Teeth26,
  27: Teeth27,
  28: Teeth28,
  48: Teeth48,
  47: Teeth47,
  46: Teeth46,
  45: Teeth45,
  44: Teeth44,
  43: Teeth43,
  42: Teeth42,
  41: Teeth41,
  31: Teeth31,
  32: Teeth32,
  33: Teeth33,
  34: Teeth34,
  35: Teeth35,
  36: Teeth36,
  37: Teeth37,
  38: Teeth38,
};
