import React, { useState, useEffect, useRef } from "react";

import { Modal, Input, Form,Select} from "antd";
const {Option} = Select

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 8,
      offset: 2,
    },
    lg: {
      span: 8,
      offset: 0,
    },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
  },
  wrapperCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 24,
      offset: 0,
    },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
  },
};

const UpdatePaymentStatusModal = ({
  title,

  onConfirmation,
  showModal,
  setShow,
  paymentStatusList,
  selectedOrder
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    console.log(inputRef.current);
    setIsModalVisible(false);
    setShow(false);
    form.submit()
    
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };
  const onFinish = (val) =>{
      console.log(val)
      onConfirmation(val);
  }

  return (
    <>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        

        <Form
        form={form}
        //   className="login-form"
          {...formItemLayout}
          layout={"vertical"}
          name="paymentStatusModal"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{paymentStatus:selectedOrder.paymentStatus}}
        >
          <Form.Item
            name="paymentStatus"
            label='Select the status'
           
           
          >
<Select >
        {paymentStatusList.map((status) => {
          return (
            <Option key={status.payment_status_name} value={status.payment_status_name}>
              {status.payment_status_name}
            </Option>
          );
        })}
      </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatePaymentStatusModal;




