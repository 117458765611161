import React, { useState } from "react";
import { Col, Row, Typography, Modal, Button, Spin, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import GenericButton from "../../../../shared/buttons/genericButton";
import { primaryColor } from "colors-config";
import { labApiTasks } from "../../../../../models/lab";
import ViewScanFilesComponent from "../../../PrescriptionModal/components/ViewScanFilesComponent/ViewScanFilesComponent";
import { getScanFileCheck } from "utils/Patient";
import { prescriptionFilesUploader } from "utils/prescription";
import { scanFiles } from "constants";
import { useEffect } from "react";
import UploadScanComponent from "components/private/PrescriptionModal/components/UploadClinicalFiles/UploadClinicalFiles";
import AppButton from "components/shared/AppButton/AppButton";


const { Title } = Typography;
const { confirm } = Modal;

const NoTreatmentSetupView = ({
  patientInfo,
  treatment,
  setShowTreatmentSetupModal,
  setDeleteModalOpen,
  isTreatmentCreationAllowed,
  setTreatmentSetup,
  IprProps,
  iprList,
  treatmentSetup,
  scanUrls,
  setScanUrls
}) => {

  const [showScanFilesModal, setScanFilesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clinicalFilesArray, setClinicalFilesArray] = useState([]);
  // const [scanUrls, setScanUrls] = useState([]);

  const getscanFiles = async() => {
    const scanRes = await labApiTasks.getAll(
      "lab/patient/resource/getOnboardingImages",
      { patientId: patientInfo.patient_id, patientStage: "scan" }
    );
    setScanUrls(scanRes.body.results.patient_onboarding_images)
  }


  // useEffect(()=>{
  //   if(patientInfo)
  //   {
  //     getscanFiles()
  //   }
  // },[patientInfo])

  const checkTreatmentSetup = async () => {
    if (scanUrls.length && isRequiredScanUploaded()) {
      setShowTreatmentSetupModal(true)
    } else {
      setScanFilesModal(true)
    }
  }

  const showConfirm = () => {
    confirm({
      title: "Close upload scan files form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
        },
      },
      onOk() {
        setScanFilesModal(false);
        setClinicalFilesArray([]);
        // setScanUrls([])
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const isRequiredScanUploaded = () => {
    const scanArray = scanUrls?.map((el) => el.patient_image_view) || [];
    const uploadArray = clinicalFilesArray.map((el) => el.filename) || [];

    const uniqueArray = [...new Set([...scanArray, ...uploadArray])];
    const scanFileCheck = getScanFileCheck(scanFiles);
    uniqueArray.forEach((filename) => {
      if (filename === scanFiles.UpperArch) {
        scanFileCheck[scanFiles.UpperArch] = true;
      }
      if (filename === scanFiles.LowerArch) {
        scanFileCheck[scanFiles.LowerArch] = true;
      }
    });

    const allTrue = Object.values(scanFileCheck).every((value) => value);
    return allTrue;
  }

  const handleSubmit = async (values) => {
    if (!isRequiredScanUploaded()) {
      return message.error("Please upload scan files before proceeding");
    }

    try {
      setLoading(true);
      await prescriptionFilesUploader(clinicalFilesArray, patientInfo.patient_id)
      setShowTreatmentSetupModal(true)
      setScanFilesModal(false)
    } catch (error) {
      message.error(error)
    } finally {
      getscanFiles()
      setLoading(false);
      setScanFilesModal(false)
      setClinicalFilesArray([]);
    }
  };

  const modalStyles = {
    top: 20,
  };

  return (
    <div className="no-treatment-plan-container">
      <Spin spinning={loading}>
        <Row
          justify="space-between"
          align="middle"
          className="no-treatment-plan"
        >
          <Col className="no-treatment-plan-header">
            <Title level={5} className="no-treatment-plan-title">
              {" "}
              Treatment Plan
            </Title>
          </Col>
          <Col>
            <AppButton
              type="primary"
              shape="round"
              onClick={() => checkTreatmentSetup()}
              disabled={!isTreatmentCreationAllowed}
            >
              Add Treatment Setup
            </AppButton>
          </Col>
        </Row>
        <Row className="empty-plan-component">
          No Treatment Plan has been added yet.
        </Row>
        {showScanFilesModal && (
          <Modal
            className="addPatientModal"
            open={showScanFilesModal}
            onCancel={() =>
              clinicalFilesArray.length > 0
                ? showConfirm()
                : setScanFilesModal(false)
            }
            title="Add Scan Files"
            width={1010}
            footer={[
              null,
              <AppButton
                key="submit"
                type="primary"
                shape="round"
                loading={loading}
                onClick={handleSubmit}
                style={{ width: "auto", borderRadius: "30px" }}
              >
                Add Treatment Setup
              </AppButton>,
            ]}
          >
            <Spin spinning={loading}>
              <Row>
                <UploadScanComponent
                  setClinicalFilesArray={setClinicalFilesArray}
                  clinicalFilesArray={clinicalFilesArray}
                  scanUrls={scanUrls}
                  setScanUrls={setScanUrls}
                  treatmentOriginId={3}
                  activeCaseId={1}
                />
              </Row>
            </Spin>
          </Modal>
        )}
      </Spin>
    </div>
  );
};

export default NoTreatmentSetupView;
