import { Row, Col, Button, Form, Input, message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { updateSummaryEmailsAPI } from "api/notifications";
import { useState } from "react";
import { summaryAddMessage } from "messages/messages";

const ShareInput = ({ shareList, setShareList, labId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const prevList = [...shareList];
      const updatedList = [...prevList, { email: values.email, id: uuidv4() }];
      const addResponse = await updateSummaryEmailsAPI({
        clinicId: labId,
        emails: [values.email],
        module: "lab",
      });
      if (addResponse) {
        message.success(summaryAddMessage);
      }
      setShareList(updatedList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      form.resetFields();
    }
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col lg={20}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Invalid email address",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              label=""
            >
              <Input size="large" placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                loading={loading}
              >
                Share
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ShareInput;
