import React from "react";

import { Card, Typography, Button } from "antd";
import {ReactComponent as Wave} from "assets/images/wave.svg";
import { useHistory } from "react-router-dom";
import { companyName, companyType, primaryColor } from "colors-config";

const { Title } = Typography;

export default function WelcomePageComponent() {
  const history = useHistory();
  return (
    <Card
      style={{
        width: "80%",
        margin: "0 auto",
        borderRadius: "12px",
      }}
    >
      <div style={{display:"flex", alignItems:"center", justifyContent:"center",     minHeight: "80vh",}}>
    <div>
    <Wave
        style={{
          marginTop: "7%",
          marginBottom: "1rem",
          width:'100px',
          height:'100px'
        }}
        fill={primaryColor}
        alt="email logo"
      />
      <Title
        level={2}
        style={{
          fontFamily: "'Poppins', sans-serif",
          width: "100%",

          margin: "0  auto 0 auto",
        }}
      >
        Welcome To {companyName}!
      </Title>
      <p style={{ fontWeight: "525", fontSize: "20px", color: "#77888B" }}>
        Let’s set your profile first.
      </p>

      <Button
        type="primary"
        style={{ width: "10rem", height: "2.5rem",margin:"1rem 0px" }}
        onClick={() => history.push("/onboarding")
      
        }
          shape="round"
      >
        {" "}
        Let's Go
      </Button>
    </div>
      </div>
     
    </Card>
  );
}
