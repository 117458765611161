import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
const tenantId = process.env.REACT_APP_SMILE_TENENT_ID;

// Create an Axios instance with custom configuration
const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_CLINIC_BASE_URL, // Set your API base URL
  timeout: 10000, // Set a timeout for requests (in milliseconds)
  headers: {
    "Content-Type": "application/json", // Set common headers
  },
});

apiInstance.interceptors.request.use(
  (config) => {
 
    config.headers["tenantId"] = tenantId;
    config.headers["timezone"] = dayjs.tz.guess();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Set the authentication token as a default header
// const authToken = 'YOUR_AUTH_TOKEN_HERE'; // Replace with your actual authentication token
// apiInstance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

export default apiInstance;
