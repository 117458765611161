import { ReactComponent as DownloadIcon } from "assets/images/download.svg";
import ReactToPrint from "react-to-print";
import { primaryColor } from "colors-config";
import { Select } from "antd";
import { useReactToPrint } from "react-to-print";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Option } = Select;

const Header = ({
  formData,
  patientInfoState,
  printFormRef,
  selectedVersion,
  onVersionChange,
  setOpenPrescriptionModal,
  companyType,
  prescriptionPdfRef
}) => {
  const onEditPrescription = () => {
    setOpenPrescriptionModal(true);
  };

  const handleDownload = useReactToPrint({
    options: {
      unit: "in",
      format: [8.5, 11], // Letter size
      orientation: "portrait",
      margin: 1, // Set 1 inch margin on all sides
    },
    pageStyle: "@page { margin: 10mm; }",
    content: () => {
      document
        .querySelectorAll(".print-part-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "block";
          }
        });
      return prescriptionPdfRef.current;
    },
    onAfterPrint: () => {
      document
        .querySelectorAll(".print-part-pdf")
        .forEach(function (container) {
          if (container) {
            container.style.display = "none";
          }
        });
    },
  });

  return (
    <div className="prescription-header">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>Prescription</h1>
        <Select
          disabled={!selectedVersion}
          value={selectedVersion}
          onChange={onVersionChange}
          placeholder="No Previous Version"
          notFoundContent={<p>No Previous Version</p>}
          style={{ margin: "0 14px", width: "140px", textAlign: "left" }}
        >
          {formData?.prescriptionVersions?.map((version) => {
            return (
              <Option value={version.value} key={version.value}>
                {version.label}
              </Option>
            );
          })}
        </Select>
        <span className="version-date">
          {formData?.createdAt ? dayjs(formData?.createdAt).format("DD-MM-YYYY") : ""}
        </span>
      </div>
      <div>
        {/* {companyType === 5 && patientInfoState?.createdFrom === "lab" && (
          <EditOutlined
            style={{
              marginRight: "15px",
              fontSize: "26px",
              color: primaryColor,
            }}
            onClick={onEditPrescription}
          />
         )} */}
        {/* <ReactToPrint
          trigger={() => {
            return (
              <a>
                <DownloadIcon
                  stroke={primaryColor}
                  // style={{ fill: primaryColor }}
                />
              </a>
            );
          }}
          content={() => printFormRef.current}
        /> */}
          <div
            // onClick={() => _getPrescriptionPdf(formData.prescription_id)}
            onClick={() => handleDownload()} 
            style={{
              color: primaryColor,
              cursor: "pointer",
              marginLeft: "15px",
            }}
          >
            Download
          </div>
      </div>
    </div>
  );
};

export default Header;
