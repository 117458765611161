import { Form, Input, Select, Row, Col, message, Card } from "antd";
// import { dentistClinicApiTasks } from "../../../../models/dentistClinic";

const Duration = ({ duration, detail }) => {
  return (
    <div>
      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Duration</h1>
        <Row gutter={[8, 8]}>
          <Col lg={24}>
            <h2 className="caption">Duration per Aligner</h2>
            <p className="content">{duration}</p>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col lg={24}>
            <div>
              <h2 className="caption">Prescription Details</h2>
              <p className="content">{detail === "" ? "--" : detail}</p>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Duration;
