import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Spin, message, Input, Radio } from "antd";
import TitleComponent from "../../components/shared/titleComponent";
import { labApiTasks } from "../../models/lab";
import { connect } from "react-redux";
import { getLabOrdersList } from "api/lab";
import {
  totalNumberBackgroundColor,
  totoalNumberTextColor,
} from "colors-config";
import dayjs from "dayjs";
import { DownloadOutlined } from "@ant-design/icons";
import "assets/styles/components/invoiceList.scss";
import { INVOICES_LIST_ALL } from "constants";
import { INVOICES_LIST_FIRST } from "constants";
import AllInvoices from "./InvoicesList/AllInvoices";
import FirstInvoices from "./InvoicesList/FirstInvoices";
import { getDefaultInvoiceState } from "utils/invoices";
import { downloadAllOrderLabInvoices, downloadFirstOrderLabInvoices } from "api/invoices";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function InvoiceList({ lab }) {
  const query = useQuery();
  const queryParamStatus = query.get("status");
  const [invoiceList, setInvoiceList] = useState([]);
  const [isInvoiceTableSpinning, setIsInvoiceTableSpinning] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [counter, setCounter] = useState(0);
  const [fromFirstDate, setFromFirstDate] = useState("");
  const [endFirstDate, setEndFirstDate] = useState("");
  const [selectedTable, setSelectedTable] = useState(
    getDefaultInvoiceState(queryParamStatus)
  );
  const [firstInvoiceCounter,setFirstInvoiceCounter] = useState(0)

  useEffect(() => {
    if (lab.labId && (searchKey.length >= 3 || searchKey === "")) {
      _getPaymentStatuses();
      _getPaymentInvoices(true);
    }
  }, [
    lab.labId,
    paginationPage,
    totalRecords,
    paginationLimit,
    searchKey,
    fromDate,
    endDate,
  ]);

  const _getPaymentStatuses = async () => {
    try {
      const statusRes = await labApiTasks.getAll("lab/order/getStatus");
      setPaymentStatusList(statusRes.body.order_Status);
      //   console.log(lab.clinicId);
    } catch (error) {
      message.error("Failed to load patients list");
    }
  };

  const _getPaymentInvoices = async (isFirstTime = false) => {
    if (isFirstTime) {
      setIsInvoiceTableSpinning(true);
    }
    try {
      // const orderRes = await labApiTasks.getAll("lab/orders", );
      let start = "";
      let end = "";
      let storedDates = localStorage.getItem("dashboardFilter");
      if (storedDates && queryParamStatus) {
        storedDates = JSON.parse(storedDates).map((date) => dayjs(date));
        start = storedDates[0].format("YYYY-MM-DD");
        end = storedDates[1].format("YYYY-MM-DD");
        setFromDate(start);
        setEndDate(end);
      }

      const orderRes = await getLabOrdersList({
        labId: lab.labId,
        limit: paginationLimit,
        page: paginationPage,
        search: searchKey,
        startDate: queryParamStatus ? start : fromDate,
        endDate: queryParamStatus ? end : endDate,
      });
      // console.log("orderResorderRes", orderRes.body)
      setInvoiceList(orderRes.body.data);
      setTotalRecords(
        searchKey || fromDate || storedDates
          ? orderRes.body.total_records
          : orderRes.body.total_records
      );
      setIsInvoiceTableSpinning(false);
      setCounter(
        searchKey || fromDate || storedDates
          ? orderRes.body.matching_records
          : orderRes.body.total_records
      );
    } catch (error) {
      setIsInvoiceTableSpinning(false);
      message.error("Failed to load patients list");
    }
  };

  const confirmUpdatePaymentStatus = async (orderId, paymentStatusId) => {
    const hide = message.loading("Updating payment status", 0);
    const model = {
      patient_order_id: orderId,
      payment_status_id: paymentStatusId,
    };

    try {
      const res = await labApiTasks.put("lab/order/paymentStatusUpdate", model);
      _getPaymentInvoices();
      setTimeout(hide, 0);
      message.success(res.body.msg);
    } catch (error) {
      setTimeout(hide, 0);

      message.error("Failed to update status. Please try again!");
    }
  };

  const onSearch = (searchKey) => {
    setSearchKey(searchKey.trim());
    setPaginationPage(1);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationPage(pagination.current);
    setPaginationLimit(pagination.pageSize);
    // if (filters && filters.orderStatus?.length > 0)
    //   setJourneyStatus(filters.journeyStatus);
  };

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromDate(start);
    setEndDate(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };
  const onRadioChange = (e) => {
    const { value } = e.target;
    setSelectedTable(value);
  };

  const downloadInvoiceHandler = async () => {
    try {
      if(selectedTable==="all"){
        window.open(downloadAllOrderLabInvoices( {
          labId:lab.labId,
          startDate: fromDate,
          endDate: endDate,
          search: searchKey,
          download:true,
          page:1,
          limit:10000
        }));
      }
      else{
        window.open(downloadFirstOrderLabInvoices(lab.labId, {
          start: fromFirstDate,
          end: endFirstDate,
          searchKey: searchKey,
        }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="invoice-list-container">
      <Row justify="space-between">
        <Col style={{ paddingLeft: "24px" }}>
          <div className="headerListPatient">
            <TitleComponent titleText={"Invoices List"}></TitleComponent>
            {/* <div
              className="patientListLength"
              style={{
                color: totoalNumberTextColor,
                backgroundColor: totalNumberBackgroundColor,
              }}
            >
              {searchKey.length > 1 ? invoiceList.length : counter}
            </div> */}
          </div>
        </Col>
        <Col>
          <Input.Search
            placeholder="Search by Patient id or Invoice No"
            onSearch={onSearch}
            allowClear={true}
            enterButton={true}
            size="large"
            style={{
              width: 331,
              border: "none",
            }}
          />
        </Col>
      </Row>
      <Row align="middle" justify="space-between" className="radio-row">
        <Col>
          <Radio.Group
            buttonStyle="solid"
            value={selectedTable}
            onChange={onRadioChange}
          >
            <Radio.Button value={INVOICES_LIST_ALL}>All Invoices ({totalRecords})</Radio.Button>
            <Radio.Button value={INVOICES_LIST_FIRST}>
              1st Order Invoices ({firstInvoiceCounter})
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <div className="download-btn" onClick={downloadInvoiceHandler}>
            <DownloadOutlined /> Download.xlsx
          </div>
        </Col>
      </Row>
      <Spin spinning={isInvoiceTableSpinning}>
        <div
          style={{
            display: selectedTable === INVOICES_LIST_ALL ? "block" : "none",
          }}
        >
          <AllInvoices
            handleTableChange={handleTableChange}
            handleDateFilter={handleDateFilter}
            totalRecords={totalRecords}
            paginationPage={paginationPage}
            confirmUpdatePaymentStatus={confirmUpdatePaymentStatus}
            onSearch={onSearch}
            paymentStatusList={paymentStatusList}
            paginationLimit={paginationLimit}
            invoiceList={invoiceList}
            fromDate={fromDate}
            endDate={endDate}
          />
        </div>
        <div
          style={{
            display: selectedTable !== INVOICES_LIST_ALL ? "block" : "none",
          }}
        >
          <FirstInvoices
            labId={lab.labId}
            paymentStatusList={paymentStatusList}
            confirmUpdatePaymentStatus={confirmUpdatePaymentStatus}
            searchKey={searchKey}
            fromDate={fromFirstDate}
            endDate={endFirstDate}
            setFromFirstDate={setFromFirstDate}
            setEndFirstDate={setEndFirstDate}
            setFirstInvoiceCounter={setFirstInvoiceCounter}
            queryParamStatus={queryParamStatus}
          />
        </div>
        {/* {selectedTable === INVOICES_LIST_ALL ? (
          <AllInvoices
            handleTableChange={handleTableChange}
            handleDateFilter={handleDateFilter}
            totalRecords={totalRecords}
            paginationPage={paginationPage}
            confirmUpdatePaymentStatus={confirmUpdatePaymentStatus}
            onSearch={onSearch}
            paymentStatusList={paymentStatusList}
            paginationLimit={paginationLimit}
            invoiceList={invoiceList}
            fromDate={fromDate}
            endDate={endDate}
          />
        ) : (
          <FirstInvoices labId={lab.labId} paymentStatusList={paymentStatusList} confirmUpdatePaymentStatus={confirmUpdatePaymentStatus} searchKey={searchKey}   fromDate={fromDate}
          endDate={endDate}/>
        )} */}
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(InvoiceList);
