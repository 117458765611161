import { Upload, Button } from "antd";
import "./treatmentUpload.scss";

const TreatmentUpload = ({
  fileList,
  setFileList,
  multiple = true,
  maxCount = 5,
  uploadFiles,
  beforeUpload,
  onChange,
  setTreatmentFile,
  setRemoveIprFile,
  treatmentFile,
}) => {
  const props = {
    className: "treatment-upload",
    name: "file",
    multiple: false,
    maxCount: 1,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    customRequest: uploadFiles,
    onChange: onChange,
    beforeUpload: beforeUpload,
  };
  const onRemove = (file) => {
    setTreatmentFile(null);
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="treatment-upload"
      {...props}
      fileList={[]}
      onRemove={onRemove}
      disabled={treatmentFile ? true : false}
    >
      <Button
        type="primary"
        shape="round"
        // style={{ marginRight: "20px" }}
        disabled={treatmentFile ? true : false}
      >
        Upload Manually
      </Button>
    </Upload>
  );
};

export default TreatmentUpload;
