import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import {removeUnderscore} from "../utils"
import { FORM_CONSTANTS } from "../constants";

const Occlusion = ({
  dentalClass,
  dentalDivision,
  dentalSide,
  skeletalClass,
  skeletalDivision,
  skeletalSide,
  parafunctionDetails,
}) => {
  const [skeletal, setSkeletal] = useState(false);
  const [dental, setDental] = useState(false);

  useEffect(() => {
  
    if (skeletalClass === FORM_CONSTANTS.CLASS_2) {
      setSkeletal(true);
    }
    if (dentalClass === FORM_CONSTANTS.CLASS_2) {
      setDental(true);
    }
  }, [skeletalClass,dentalClass ]);

  return (
    <div>
      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Occlusion</h1>
        <Row gutter={[8, 8]} align="bottom">
          <Col lg={6} className="border-right">
            <h2 className="caption">Skeletal</h2>
            <p className="content">
              {removeUnderscore(skeletalClass)}
            </p>
          </Col>
          {skeletal && (
            <Col lg={6} className="border-right">
              <div className="paddingLeft">
                <h2 className="caption">Division</h2>
                <p className="content">
                  {removeUnderscore(skeletalDivision)}
                </p>
              </div>
            </Col>
          )}
            <Col lg={6}>
              <div className="paddingLeft">
                <h2 className="caption">Side</h2>
                <p className="content">
                  {removeUnderscore(skeletalSide)}
                </p>
              </div>
            </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: "15px" }} align="bottom">
          <Col lg={6} className="border-right">
            <h2 className="caption">Dental</h2>
            <p className="content">{removeUnderscore(dentalClass)}</p>
          </Col>
          {dental && (
            <Col lg={6} className="border-right">
              <div className="paddingLeft">
                <h2 className="caption">Division</h2>
                <p className="content">
                  {removeUnderscore(dentalDivision)}
                </p>
              </div>
            </Col>
          )}
            <Col lg={6}>
              <div className="paddingLeft">
                <h2 className="caption">Side</h2>
                <p className="content">
                  {removeUnderscore(dentalSide)}
                </p>
              </div>
            </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
          <Col lg={24}>
            <h2 className="caption">Parafunction/Deviatoin</h2>
            <p className="content">
              {parafunctionDetails?.length > 1 ? parafunctionDetails : "--"}
            </p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Occlusion;
