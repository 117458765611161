import { createSelector } from "reselect";

const getLab = (state) => state.lab;

export const userRoleSelector = createSelector(
  [getLab],
  (lab) => lab.role 
);

export const userNameSelector = createSelector(getLab, (lab) => {
  if (lab?.subUser) {
    return `${lab.subUser.firstName} ${lab.subUser.lastName}`;
  }

  return lab.labName;
});

export const subUserSelector = createSelector(getLab, (lab) => {
  if (lab?.subUser) {
    return lab?.subUser;
  }

  return {};
});

const getStaffState=(state)=>state.staff

export const staffMembersSelectors = createSelector(getStaffState,(staff) => staff.members);
