import axios from "axios";
import api from "../../../../api/api";
import { labApiTasks } from "../../../../models/lab";
const BASE_URL = process.env.REACT_APP_BASE_URL;

  export const postTreatmentWithMedia = (data, config) => {
    return api({
      method: "POST",
      url: "/lab/lab/patientlist/treatmentSetup",
      data,
      ...config
    });
  };


export const cancelTreatmentAPI = (patientId) =>
  axios.post(`${BASE_URL}/patient/cancelTreatment`, {
    patient_id: patientId,
  });

export const deleteIPRGuideAPI = (treatment) =>
  labApiTasks.post("lab/patientlist/treatmentSetup/deleteIprGuide ", {
    lab_treatment_id: treatment?.lab_treatment_id,
  });

export const addIprGuideAPI = (treatment, url) =>
  labApiTasks.post("lab/patientlist/treatmentSetup/addIprGuide", {
    lab_treatment_id: treatment?.lab_treatment_id,
    ipr_guide_link: url,
  });

export const uploadIprGuideAPI = (uploadUrl, file, config) =>
  axios.put(uploadUrl, file, config);
export const addResourceAPI = (preSignRequestBody) =>
  labApiTasks.post("lab/resource/add", preSignRequestBody);

export const getRefinementData = (patientId) => {
  return api({
    method: "GET",
    url: "/clinic/refinement/getDetails/" + patientId,
  });
};

export const getPatientPrescriptionDetails = (patientId) => {
  return api({
    method: "GET",
    url: `/clinic/prescription/patient/get?patientId=${patientId}&caseId=${1}`,
  });
};

export const getPrescriptionByVersion = (prescriptionId, version) => {
  return api({
    method: "GET",
    url: `/clinic/prescription/getByvesion/${prescriptionId}/${version}`,
  });
};