import { message } from "antd";

export const handleApiService = async (apiCall, data, setLoader, callback) => {
  setLoader(true);
  try {
    const response = await apiCall(data);
    callback(response.data);
  } catch (error) {
    console.log("error", error);
    message.error("API operation failed");
  } finally {
    setLoader(false);
  }
};
