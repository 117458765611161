import React, { useState, useEffect } from "react";

import { Modal, Row } from "antd";
import moment from "moment";

const OlderPlansModal = ({ title, wholeTreatmentPlan, showModal, setShow }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [treatmentPlans, setTreatmentPlans] = useState([]);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);
  useEffect(() => {
    if (wholeTreatmentPlan) {
      setTreatmentPlans(wholeTreatmentPlan);
    }
  }, [wholeTreatmentPlan]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  return (
    <>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { display: "none  " } }}
      >
        {treatmentPlans.map((plans, index) =>
          typeof plans[0] !== "undefined" ? (
            <Row
              style={
                plans[0]?.treatment_plan_version == treatmentPlans?.length
                  ? { display: "none" }
                  : {}
              }
            >
              <p>Version {plans[0]?.treatment_plan_version}:</p>
              <a
                style={{ marginLeft: "50px" }}
                href={plans[0]?.treatment_plan_link}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
              <p style={{ marginLeft: "50px" }}>
                {moment(plans[0]?.date_created).format("ddd DD/MM/YYYY hh:mm:ss")}
              </p>
            </Row>
          ) : (
            <div></div>
          )
        )}
      </Modal>
    </>
  );
};

export default OlderPlansModal;
