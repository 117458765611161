import React, { useState } from "react";
import { Row, DatePicker, Space, Button } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const FilterDataRangePicker = ({ clearFilters, confirm, handleDateFilter,fromDate,endingDate}) => {
  const [isFilterSet, setIsFilterSet] = useState((fromDate && endingDate) ? true : false);
  const [startDate, setStartDate] = useState(fromDate ? dayjs(fromDate) : null);
  const [endDate, setEndDate] = useState(endingDate ? dayjs(endingDate) : null);

  const dateChangeHandler = (e) => {
    
    if (e) {
      setIsFilterSet(true);
      setStartDate(e[0]);
      setEndDate(e[1]);
    }
    else{
      setStartDate(null);
      setEndDate(null);
      setIsFilterSet(false);
    }
  
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setStartDate(null);
    setEndDate(null);
    setIsFilterSet(false);
    handleDateFilter(confirm);
    // this.setState({ searchText: "" });
  };
  const disabledDate = (current) => {
    // Disable dates from today and onwards
    return current && current >= dayjs().endOf("day");
  };
  return (
    <div style={{ padding: 8 }}>
      <Row>
        <RangePicker
          onChange={dateChangeHandler}
          value={[startDate, endDate]}
          disabledDate={disabledDate}
        />
      </Row>
      <Space
        style={{ marginTop: 6, justifyContent: "space-between", width: "100%" }}
      >
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90, border: "none" }}
          disabled={!isFilterSet}
        >
          Reset
        </Button>
        <Button
          onClick={() =>{   
            handleDateFilter(
              confirm,
              startDate ? startDate.format("YYYY-MM-DD"):"",
              endDate?endDate.format("YYYY-MM-DD"):""
            )
          }
          }
          size="small"
          type="primary"
          shape="round"
          style={{ width:"70px", height:"30px" }}
        >
          Ok
        </Button>
      </Space>
    </div>
  );
};

export default FilterDataRangePicker;
