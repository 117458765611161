import apiInstance from "./instance";

export const searchPatientsAPI = (searchKey, labId) => {
  return apiInstance({
    method: "GET",
    url: "/lab/patient/search",
    params: {
      searchKey,
      labId,
    },
  });
};
