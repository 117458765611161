import { Link } from "react-router-dom";
import {Card} from "antd"
import styles from "assets/styles/routes/dashboard.module.scss";
import { ReactComponent as Arrow } from "assets/images/Arrow.svg";
import { ReactComponent as Order } from "assets/images/Order.svg";
import { ReactComponent as OrderFirst } from "assets/images/OrderFirst.svg";
const OrdersTime = ({ orderData }) => {;
  return (
    <Card style={{ marginBottom:"1rem" }}>
     <div className={styles.taskContainer} >
      <h1>Orders</h1>
      <div className={styles.taskList}>
        <Link to="/lab/billing?status=all">
          <div className={styles.taskListItem}>
            <h2>
             {orderData?.allOrders}
            </h2>
            <div className={styles.taskListName}>
              <p>All Orders</p>
              <Arrow />
            </div>
            <div className={`${styles.orderLine} ${styles.line}`} />
          </div>
        </Link>
        <Order/>

      </div>
      <div className={styles.taskList}>
        <Link to="/lab/billing?status=first">
          <div className={styles.taskListItem}>
            <h3>
            {orderData?.firstOrder}
            </h3>
            <div className={styles.taskListName}>
              <p style={{minWidth:"150px"}}>1st Order Invoices</p>
              <Arrow />
            </div>

            <div className={`${styles.firstLine} ${styles.line}`} />
          </div>
        </Link>
        <OrderFirst/>

      </div>
    </div>
    </Card>
  
  );
};

export default OrdersTime;
