import dayjs from "dayjs";
import { Tooltip } from "antd";

export const checkLastActiveStatus = () => {
  const lastActiveTime = localStorage.getItem("lastActiveTime");

  if (lastActiveTime) {
    const lastActiveTimeDate = dayjs(lastActiveTime);
    const lastActiveDiff = dayjs().diff(lastActiveTimeDate, "minute");
    if (lastActiveDiff > 60) {
      return true;
    }
  }
  return false;
};

export const renderTreatmentTypes = (companyType, createdAt) => {
  let returnValue = true;
  let oldPrescription = false;
  if (companyType === 1) {
    returnValue = false;
  }
  if (companyType === 5) {
    returnValue = true;
  }

  if (companyType === 6 || companyType === 7) {
    if (createdAt) {
      const comparisonDate = "2024-07-02";
      const parsedDateToCheck = dayjs(createdAt);
      const parsedComparisonDate = dayjs(comparisonDate);
      const isBeforeOrSame =
        parsedDateToCheck.isBefore(parsedComparisonDate, "day") ||
        parsedDateToCheck.isSame(parsedComparisonDate, "day");
      if (isBeforeOrSame) {
        returnValue = false;
        oldPrescription = true;
      } else {
        returnValue = true;
      }
    } else {
      returnValue = true;
    }
  }

  return { show: returnValue, old: oldPrescription };
};

export const columnTitleElement = (value) => {
  return (
    <Tooltip title={value}>
      <p className="appTable-column-title">{value}</p>
    </Tooltip>
  );
};

export const noop = () => {};


export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const disableFutureDates = (current) =>{
  return current && current >= dayjs().endOf("day");
}

export const disablePastDates = (current) => {
  return current && current < dayjs().startOf("day");
};
