import { CalendarOutlined } from "@ant-design/icons";
// import { primaryColor } from "colors-config";


export const CalenderIcon = ({style}) => {
  return (
    <CalendarOutlined
      className="calendar"
      style={{
        fontSize: "20px",
        marginTop: "2%",
        color: "#00c6e9", 
      }}
    />
  );
};
