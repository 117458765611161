export const LOWER_JAW = [
  {
    name: "48",
    universalName: "17",
    palmerName: "LR8",
    showName: "48",
    selected: false,

    id: 48,
    visible: false,
  },
  {
    name: "47",
    universalName: "18",
    palmerName: "LR7",
    showName: "47",
    selected: false,

    id: 47,
    visible: false,
  },
  {
    name: "46",
    universalName: "36",
    palmerName: "LR6",
    showName: "46",
    selected: false,

    id: 46,
    visible: false,
  },
  {
    name: "45",
    universalName: "35",
    palmerName: "LR5",
    showName: "45",
    selected: false,

    id: 45,
    visible: false,
  },
  {
    name: "44",
    universalName: "34",
    palmerName: "LR4",
    showName: "44",
    selected: false,
    id: 44,
    visible: false,
  },
  {
    name: "43",
    universalName: "33",
    palmerName: "LR3",
    showName: "43",
    selected: false,
    id: 43,
    visible: false,
  },
  {
    name: "42",
    universalName: "32",
    palmerName: "LR2",
    showName: "42",
    selected: false,
    id: 42,
    visible: false,
  },
  {
    name: "41",
    universalName: "31",
    palmerName: "LR1",
    showName: "41",
    selected: false,

    id: 41,
    visible: false,
  },
  {
    name: "31",
    universalName: "30",
    palmerName: "LL1",
    showName: "31",
    selected: false,

    id: 31,
    visible: false,
  },
  {
    name: "32",
    universalName: "29",
    palmerName: "LL2",
    showName: "32",
    selected: false,

    id: 32,
    visible: false,
  },
  {
    name: "33",
    universalName: "28",
    palmerName: "LL3",
    showName: "33",
    selected: false,

    id: 33,
    visible: false,
  },
  {
    name: "34",
    universalName: "27",
    palmerName: "LL4",
    showName: "34",
    selected: false,

    id: 34,
    visible: false,
  },
  {
    name: "35",
    universalName: "26",
    palmerName: "LL5",
    showName: "35",
    selected: false,

    id: 35,
    visible: false,
  },
  {
    name: "36",
    universalName: "25",
    palmerName: "LL6",
    showName: "36",
    selected: false,

    id: 36,
    visible: false,
  },
  {
    name: "37",
    universalName: "24",
    palmerName: "LL7",
    showName: "37",
    selected: false,
    id: 37,
    visible: false,
  },
  {
    name: "38",
    universalName: "23",
    palmerName: "LL8",
    showName: "38",
    selected: false,
    id: 38,
    visible: false,
  },
];

export const UPPER_JAW = [
  {
    name: "18",
    universalName: "1",
    palmerName: "UR8",
    showName: "18",
    selected: false,
    id: 18,
    visible: false,
  },
  {
    name: "17",
    universalName: "2",
    palmerName: "UR7",
    showName: "17",
    selected: false,
    id: 17,
    visible: false,
  },
  {
    name: "16",
    universalName: "3",
    palmerName: "UR6",
    showName: "16",
    selected: false,
    id: 16,
    visible: false,
  },
  {
    name: "15",
    universalName: "4",
    palmerName: "UR5",
    showName: "15",
    selected: false,

    id: 15,
    visible: false,
  },
  {
    name: "14",
    universalName: "5",
    palmerName: "UR4",
    showName: "14",
    selected: false,

    id: 14,
    visible: false,
  },
  {
    name: "13",
    universalName: "6",
    palmerName: "UR3",
    showName: "13",
    selected: false,

    id: 13,
    visible: false,
  },
  {
    name: "12",
    universalName: "7",
    palmerName: "UR2",
    showName: "12",
    selected: false,

    id: 12,
    visible: false,
  },
  {
    name: "11",
    universalName: "8",
    palmerName: "UR1",
    showName: "11",
    selected: false,

    id: 11,
    visible: false,
  },
  {
    name: "21",
    universalName: "9",
    palmerName: "UL1",
    showName: "21",
    selected: false,

    id: 21,
    visible: false,
  },
  {
    name: "22",
    universalName: "10",
    palmerName: "UL2",
    showName: "22",
    selected: false,

    id: 22,
    visible: false,
  },
  {
    name: "23",
    universalName: "11",
    palmerName: "UL3",
    showName: "23",
    selected: false,

    id: 23,
    visible: false,
  },
  {
    name: "24",
    universalName: "12",
    palmerName: "UL4",
    showName: "24",
    selected: false,

    id: 24,
    visible: false,
  },
  {
    name: "25",
    universalName: "13",
    palmerName: "UL5",
    showName: "25",
    selected: false,

    id: 25,
    visible: false,
  },
  {
    name: "26",
    universalName: "14",
    palmerName: "UL6",
    showName: "26",
    selected: false,
    id: 26,
    visible: false,
  },
  {
    name: "27",
    universalName: "15",
    palmerName: "UL7",
    showName: "27",
    selected: false,
    id: 27,
    visible: false,
  },
  {
    name: "28",
    universalName: "16",
    palmerName: "UL8",
    showName: "28",
    selected: false,
    id: 28,
    visible: false,
  },
];


export const showTeethName = (toothNo, structure,type) => {
  if (toothNo) {
    let filtered;
    if (type==="upper") {
       filtered = UPPER_JAW.filter((item) => item.name == toothNo);
    }
    else{
      filtered = LOWER_JAW.filter((item) => item.name == toothNo);
    }

  let name = toothNo;
  if (structure === "universal") {
    name = filtered[0].universalName;
  } else {
    name=  filtered[0].palmerName;
  }
   return name
  }
  else{
    return ""
  }
};
