import {useEffect,useState} from "react"
import { UPPER_JAW, LOWER_JAW } from "./data";
import Aligners from "./IPRAligners";
import Tooth from "./Tooth/Tooth";


const IPRInfo = ({ lowerAligners, upperAligners, chartValue , upperAttachments, lowerAttachments}) => {
  const [upperStructure, setUpperStructure] = useState(UPPER_JAW);
  const [lowerStructure, setLowerStructure] = useState(LOWER_JAW);


  function getTeethValuesWithTrue(array) {
    const teethValues = [];
    array.forEach((item) => {
      if (item.alignerValues) {
        item.alignerValues.forEach((attachment) => {
          if (attachment.value === true && attachment.teeth !== null) {
            teethValues.push(attachment.teeth);
          }
        });
      }
    });
    return teethValues;
  }


  // useEffect(() => {
  //   let previousTooths = [];
  //   const mergedArray =  upperAttachments.length ?  [...upperAttachments] : []
  //     previousTooths = getTeethValuesWithTrue(mergedArray);
  //       const structure = [...UPPER_JAW];
  //       const updatedStructure = structure.map((Item) => {
  //         const matchingTooth = previousTooths.find(
  //           (tooth) => tooth == Item.id
  //         );
  //         return {
  //           ...Item,
  //           selected: matchingTooth ? true : false,
  //         };
  //       });
  //       setUpperStructure(updatedStructure);
  // }, [upperAttachments]);

  // useEffect(() => {
  //   let previousTooths = [];
  //   const mergedArray = lowerAttachments.length ? [...lowerAttachments] : []
  //   // if (mergedArray.length) {
  //     previousTooths = getTeethValuesWithTrue(mergedArray);
  //     // if (previousTooths.length) {
  //       const structure = [...lowerStructure];
  //       const updatedStructure = structure.map((Item) => {
  //         const matchingTooth = previousTooths.find(
  //           (tooth) => tooth == Item.id
  //         );
  //         return {
  //           ...Item,
  //           selected: matchingTooth ? true : false,
  //         };
  //       });

  //       setLowerStructure(updatedStructure);
  //     // }
  //   // }
  // }, [lowerAttachments]);


  return (
    <div className="ipr-detail-container">
      <div className="teethChart-container" style={{ padding: "20px 0px", }}>
       <Aligners alignersObj={upperAligners} alignerType="upper" />
        <div className="teethRow-container" style={{ marginTop: "0px" }}>
          <div className="teethRow-bgOne" />
          <div className="teethRow-one ipr-row">
            {upperStructure.map((tooth) => {
              return (
                <div key={tooth.id}>
                  <Tooth type="upper" tooth={tooth} chartValue={chartValue} />
                </div>
              );
            })}
            <div className="tooth-sides">
              <h2>Right</h2>
              <h2>Left</h2>
            </div>
            {lowerStructure.map((tooth) => {
              return (
                <div key={tooth.id}>
                  <Tooth type="lower" tooth={tooth} chartValue={chartValue} />
                </div>
              );
            })}
          </div>
          <div className="teethRow-bgTwo"></div>
        </div>
        <Aligners alignersObj={lowerAligners} alignerType="lower"/>
      </div>
    </div>
  );
};

export default IPRInfo;
