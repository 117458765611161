import { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import UploadFile from "components/shared/UploadFile/index";
import { UploadOutlined } from "@ant-design/icons";
import { Row, Col, message } from "antd";
import { scansArray } from "utils/Patient.js";
import "./scanFile.scss"

const ScanFiles = forwardRef((props, ref) => {
  const { setIsChanged } = props;

  const [upperArch, setUpperArch] = useState([]);
  const [lowerArch, setLowerArch] = useState([]);
  const [biteScan1, setBiteScan1] = useState([]);
  const [biteScan2, setBiteScan2] = useState([]);

  useEffect(() => {
    if (upperArch.length || lowerArch.length || biteScan1.length || biteScan2.length) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [upperArch, lowerArch, biteScan1, biteScan2]);

  useImperativeHandle(ref, () => ({
    scanFiles: {
      upperArch,
      lowerArch,
      biteScan1,
      biteScan2,
    },
    scansArray: scansArray,
  }));

  const onChange =
    (id) =>
    ({ fileList }) => {
      let setter = null;

      if (id === 1) {
        setter = setUpperArch;
      }

      if (id === 2) {
        setter = setLowerArch;
      }
      if (id === 3) {
        setter = setBiteScan1;
      }
      if (id === 4) {
        setter = setBiteScan2;
      }

      setter(
        fileList.slice(-1) // Keep only the last file
      );
    };

  const beforeUpload = (file) => {
    console.log(file);
    const isSTL = file.type === "model/stl" || file.name.endsWith(".stl");
    if (!isSTL) {
      message.error("You can only upload STL files!");
    }
    return isSTL;
  };

  const fileList = (id) => {
    if (id === 1) {
      return upperArch;
    }

    if (id === 2) {
      return lowerArch;
    }
    if (id === 3) {
      return biteScan1;
    }
    if (id === 4) {
      return biteScan2;
    }

    return [];
  };

  return (
    <div className="scanFiles-refinement">
      <div>
        <b>Scan Files</b>
      </div>
      <Row gutter={[32, 0]}>
        {scansArray.map((scanItem) => {
          return (
            <Col md={6} key={scanItem.id}>
              <UploadFile
                beforeUpload={beforeUpload}
                // accept={".stl"}
                onChange={onChange(scanItem.id)}
                multiple={false}
                fileList={fileList(scanItem.id)}
                className="scan-file-wrapper"
                uploadFiles={({ file, onSuccess, onError }) => {
                  onSuccess(true);
                }}
                children={
                  <>
                    {" "}
                    <UploadOutlined style={{ fontSize: "12px" }} />
                    <p style={{ fontSize: "8px" }} className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </>
                }
              />
              <div className="subText">{scanItem.label}</div>
              
            </Col>
          );
        })}
      </Row>
    </div>
  );
});

export default ScanFiles;
