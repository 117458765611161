import { Select } from "antd";
import React from "react";

const { Option } = Select;

const SelectAlignerListComponent = ({
  setImages,
  onSelectImageStageTypeChange,
  list,
  defaultValue,
}) => {
  return (
    <Select
      style={{ width: 175 }}
      onChange={(info) => {
        setImages([]);
        onSelectImageStageTypeChange(info);
      }}
      defaultValue={defaultValue}
    >
      {list.patient_image_upload_stages &&
        list.patient_image_upload_stages.map((item) => {
          return (
            <Option
              key={item.patient_image_uploaded_stage_id}
              value={item.patient_image_uploaded_stage_id}
            >
              {item.patient_image_uploaded_stage_name}
            </Option>
          );
        })}
    </Select>
  );
};

export default SelectAlignerListComponent;
