import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Select, Divider, Collapse, Form, message } from "antd";
import TeethInfo from "./TeethInfo";
import { ReactComponent as DownArrow } from "assets/images/DownArrow.svg";

import Teeths from "./Teeths/Teeths";
import { FDI_CHART } from "./data";

import "./teethChart.scss";
import { primaryColor } from "colors-config";

// import { updateClinicTeethChartStructure } from "api/prescription";

const { Option } = Select;
const { Panel } = Collapse;

const TeethChart = forwardRef(
  (
    { country, disabled, clinicId, teethStructureValue, teethChartRef },
    ref
  ) => {
    const [toothStatuses, setToothStatuses] = useState([]);
    const [teethStructure, setTeethStructure] = useState(FDI_CHART);
    const [chartValue, setChartValue] = useState("fdi");
    const [teethStructureLoading, setTeethStructureLoading] = useState(false);

    // useImperativeHandle(ref, () => {
    //   return {
    //     setTeethData(data) {
    //       setToothStatuses(data?.length ? data : []);
    //     },
    //     toothStatuses,
    //   };
    // });

    useEffect(() => {
      if (teethStructureValue) {
        // console.log("teethStructureValue",teethStructureValue);
        setChartValue(teethStructureValue);
      }
    }, [teethStructureValue]);

    useEffect(() => {
      // console.log(teethChartData)
      if (teethChartRef?.current?.length) {
        setToothStatuses(teethChartRef.current);
      }
      else{
        setToothStatuses([]);
      }
    }, [teethChartRef.current]);

    const handleStructureChange = async (value) => {
      setChartValue(value);
      // try {
      //   setTeethStructureLoading(true);
      //   await updateClinicTeethChartStructure(clinicId, { teethStructure: value })

      // } catch (error) {
      //   message.error("Error occured in updating")
      // }
      // finally {
      //   setTeethStructureLoading(false)
      // }
    };

    useEffect(() => {
      if (
        chartValue === "palmer" ||
        country === "USA" ||
        country === "Canada " ||
        country === "Jamaica"
      ) {
        setTeethStructure("palmer");
      }
    }, [country]);

    const header = (
      <>
        <div className="chart-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1>Teeth Chart</h1>
          </div>
          <Select
            placeholder="Select option"
            className="header-select"
            onChange={handleStructureChange}
            value={chartValue}
            disabled={disabled}
            loading={teethStructureLoading}
          >
            <Option value="fdi">FDI</Option>
            <Option value="universal">Universal</Option>
            <Option value="palmer">Palmer</Option>
          </Select>
        </div>
        <Divider />
      </>
    );

    const getItems = () => [
      {
        key: "1",
        label: header,
        children: (
          <Teeths
          // teethChartDraftRef={teethChartDraftRef}
          chartValue={chartValue}
          teethStructure={teethStructure}
          toothStatuses={toothStatuses}
          setTeethStructure={setTeethStructure}
          setToothStatuses={setToothStatuses}
          teethChartRef={teethChartRef}
        />
        ),
      },
    ];

    return (
      <div className="teethChart-container">
        <Collapse
          collapsible="icon"
          bordered={false}
           header={header}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
          <div
            style={{
              transform: !isActive ? "rotate(270deg)" : "rotate(360deg)",
              transition: "all 0.3s",
            }}
          >
            <DownArrow style={{fill:primaryColor}}/>
          </div>
        )}
        items={getItems()}
        >
          {/* {header}
          <Panel key="1">
            <Teeths
              // teethChartDraftRef={teethChartDraftRef}
              chartValue={chartValue}
              teethStructure={teethStructure}
              toothStatuses={toothStatuses}
              setTeethStructure={setTeethStructure}
              setToothStatuses={setToothStatuses}
              teethChartRef={teethChartRef}
            />
          </Panel> */}
        </Collapse>

        <TeethInfo
          teethChartRef={teethChartRef}
          // teethChartDraftRef={teethChartDraftRef}
          chartValue={chartValue}
          toothStatuses={toothStatuses}
          setToothStatuses={setToothStatuses}
          teethStructure={teethStructure}
          setTeethStructure={setTeethStructure}
        />
      </div>
    );
  }
);

export default TeethChart;
