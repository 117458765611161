import { Row, Col } from "antd";

const PrescriptionAttachments = ({ prescriptionPDFObj } ) => {
  return (
    <div className="prescriptionPDF-card ">
      <h2>IPR & Attachments</h2>
      <div className="prescriptionPDF-duration">
        <div>
          <div className="border-right">
            <label className="treatment-label">IPR</label>
            <p className="treatment-info">{prescriptionPDFObj?.ipr}</p>
          </div>
        </div>
        <div>
          <div>
            <label className="treatment-label">Attachments</label>
            <p className="treatment-info">{prescriptionPDFObj?.attachments}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionAttachments;
