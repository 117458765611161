import { Row, Col, Card } from "antd";
import { memo } from "react";
import { removeUnderscore } from "../utils";
import { primaryColor } from "colors-config";

const Aligners = ({ treatmentTypes, disabled,phasing,labProductDetails }) => {
  // console.log("treatmentTypes", treatmentTypes);

  return (
    <>
      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Treatment Type</h1>
        {treatmentTypes?.length &&
          treatmentTypes.map((treatment, i) => {
            return (
              <div key={i} style={{ position: "relative" }}>
                <div className="num-btn" style={{ backgroundColor: primaryColor}}>{i + 1}</div>
                <Row style={{ paddingLeft: "25px" }}>
                  <Col lg={12} className="border-right">
                    <h2 className="caption">Aligner</h2>
                    <p className="content">
                      {removeUnderscore(treatment.aligner)}
                    </p>
                  </Col>

                  <Col lg={12}>
                    <div className="paddingLeft">
                      <h2 className="caption">Arches Treated</h2>
                      <p className="content">
                        {removeUnderscore(treatment.arches)}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row
                  gutter={[8, 8]}
                  style={{ paddingLeft: "25px", marginTop: "20px" }}
                >
                  <Col lg={12} className={treatment.whitening !== "no" ? "border-right" : ""}>
                    <h2 className="caption">Whitening</h2>
                    <p className="content">
                      {removeUnderscore(treatment.whitening)}
                    </p>
                  </Col>

                  {treatment.whitening !== "no" ? (
                    <Col lg={6}>
                      <div className="paddingLeft">
                        <h2 className="caption">Retainer Arches</h2>
                        <p className="content">
                          {removeUnderscore(treatment.whitening_arches)}
                        </p>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            );
          })}
        {process.env.REACT_APP_COMPANY_TYPE == 5 &&
       <>
          {labProductDetails ?
            <Row
              gutter={[8, 8]}
              style={{ paddingLeft: "25px", marginTop: "20px" }}
            >
              <Col lg={12}>
                <h2 className="caption">Product</h2>
                <p className="content">
                  {labProductDetails}
                </p>
              </Col>
            </Row>
             : ""}
          {phasing ?
            <Row
              gutter={[8, 8]}
              style={{ paddingLeft: "25px", marginTop: "20px" }}
            >
              <Col lg={12}>
                <h2 className="caption">Phasing</h2>
                <p className="content" style={{textTransform:'capitalize'}}>
                  {phasing}
                </p>
              </Col>
            </Row>
            : ""
          }
       </>
        }
      </Card>
    </>
  );
};

export default memo(Aligners);
