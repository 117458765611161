import { useState, useEffect } from "react";
import { Modal, Button, message, Typography, Spin, Select } from "antd";

import { ReactComponent as User } from "assets/images/User.svg";
import { shareResourceWithClinics } from "api/resource";
const { Title } = Typography;
const { Option } = Select;
const ResourceShareModal = ({
  showShareModal,
  setShowShareModal,
  lab,
  shareId,
  resources,
  SetResources,
  sharedList,
  partneredClinics,
  sharedClinics,
  setPartneredClinics,
}) => {

  const [clinicList, setClinicList] = useState(sharedList);
  const [loading, setLoading] = useState(false);

  const checkedAll = clinicList?.find((obj) => obj === "all");

  useEffect(() =>{
    const checkedAll = clinicList?.find((obj) => obj === "all");
    if (checkedAll) {
      disableClinics()
    }

  },[sharedList])

  const disableClinics = () => {
    const clinics = [...partneredClinics];
    const updatedClinics = clinics.map((clinic) => {
      if (clinic.value === "all") {
        return clinic;
      } else
        return {
          ...clinic,
          disabled: true,
        };
    });
    setPartneredClinics(updatedClinics);
  };



  const enableClinics = () => {
    const clinics = [...partneredClinics];
    const updatedClinics = clinics.map((clinic) => {
      return {
        ...clinic,
        disabled: false,
      };
    });
    setPartneredClinics(updatedClinics);
  };

  const allClinicsChecked = (value) => {
    const clinics = [...partneredClinics];
    const clinicIds = clinics.map((clinic) => clinic.value);
    const filteredIds = clinicIds.filter((id) => id !== "all");
    if (value.length >= filteredIds.length) {
      return true;
    }
    return false;
  };

  const handleChange = (value) => {    
    let shareList = [];
    // console.log("value", value);
    const getAllClinic = value.find((obj) => obj == "all");
    // console.log("getAllClinic", getAllClinic)
    // console.log("partneredClinics", partneredClinics)
    // if(getAllClinic && value.length>1 && value[value.length-1]!='all'){
    const allChecked = allClinicsChecked(value);
    if (getAllClinic) {
      disableClinics();
      shareList = ["all"];
    } else if (allChecked) {
      disableClinics();
      shareList = ["all"];
    } else {
      enableClinics();
      shareList = value;
    }
    setClinicList(shareList);
  };

  const handleShareResourceCancel = () => {
    setShowShareModal(false);
    enableClinics();
  };

  const handleSubmit = async () => {
    let msg = "Resource has been successfully shared";
    let shareResourcePayload = {};
    const checkedAll = clinicList.find((obj) => obj === "all");
    if (checkedAll) {
      shareResourcePayload = {
        labId: lab.labId,
        id: shareId,
        clinics: [],
        allClinic: true,
      };
      msg = "Resource has been successfully shared wih all clinics";
    } else {
      const prevResources = [...resources];
      const prevClinic = prevResources.find(
        (resource) => resource.id === shareId
      );
      const prevClinicsList = prevClinic.clinics;
      const newClinicsList = clinicList.map((clinic) => {
        let obj = {
          clinicId: clinic,
        };
        if (prevClinicsList?.length) {
          const getPreviousClinic = prevClinicsList?.find(
            (obj) => obj.clinicId == clinic
          );
          if (getPreviousClinic) {
            obj.id = getPreviousClinic.id;
          }
        }
        return obj;
      });
      shareResourcePayload = {
        labId: lab.labId,
        id: shareId,
        clinics: newClinicsList,
      };
      msg = "Resource sharing settings updated";
    }
    setLoading(true);

    try {
      const { data } = await shareResourceWithClinics(shareResourcePayload);
      const shareList = data?.data;
      const prevResources = [...resources];
      let updatedResources = prevResources.map((resource) => {
        if (resource.id == shareId) {
          resource.shareList.allClinic = checkedAll ? true : false;
          resource.shareList.clinics = checkedAll ? [] : shareList;
        }
        return resource;
      });
      SetResources(updatedResources);
      message.success(msg);
    } catch (error) {
      console.log("error", error);
    } finally {
      enableClinics();
      setLoading(false);
      setShowShareModal(false);
    }
  };
  return (
    <div>
      <Spin spinning={loading}>
        <Modal
          className="addResourceModal"
          title={
            <div className="titleSpan">
              <Title level={5} style={{ margin: "0px" }}>
                Share with
              </Title>
            </div>
          }
          width={600}
          open={showShareModal}
          onCancel={handleShareResourceCancel}
          footer={[
            <Button
              key="cancel"
              onClick={handleShareResourceCancel}
              shape="round"
              style={{ width: "7rem", borderRadius: "30px" }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              shape="round"
              onClick={handleSubmit}
              loading={loading}
              style={{ width: "7rem", borderRadius: "30px" }}
            >
              Save
            </Button>,
          ]}
        >
          <div style={{ padding: "0 0 5rem 0" }}>
            <label>Select Clinicians</label>
            <Select
              mode="multiple"
              style={{
                width: "100%",
                marginTop: "10px",
              }}
              size="large"
              placeholder="Select clinician"
              value={clinicList}
              onChange={handleChange}
              optionLabelProp="label"
              options={partneredClinics}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            {checkedAll ? (
              <div className="clinics-sharedList">
                <div className="shared-clinic">All Clinicians</div>
              </div>
            ) : sharedClinics.length ? (
              <div className="clinics-sharedList">
                {sharedClinics.map((clinic, i) => {
                  return (
                    <div key={i} className="shared-clinic">
                      {clinic?.clinicDetails?.clinic_name}
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <User />
            </div>
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default ResourceShareModal;
