import api from "./api";

const getPatientAlignerTimelineAPI = (params) => {
  return api({
    method: "GET",
    url: `/clinic/aligner/timeline/get`,
    params,
  }).then((res) => res.data);
};



export { getPatientAlignerTimelineAPI };
