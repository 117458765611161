import { Card, Row, Col } from "antd";
import { ReactComponent as SessionIcon } from "assets/images/sessionIcon.svg";
import { ReactComponent as PendingIcon } from "assets/images/pending.svg";
import { ReactComponent as CompleteIcon } from "assets/images/complete.svg";
import { ReactComponent as AttachmentSessionIcon } from "assets/images/attachment-icon.svg";
const IPRCard = ({ title, subTitle, status,type }) => {
  return (
    <Card hoverable className="ipr-session-card">
      <Row justify="end">
        <Col lg={12}>
          <h1>{title}</h1>
          <h2>{subTitle}</h2>
          {status === 1 ? (
            <div style={{ marginTop: "10px" }}>
              <div className="status">
                <CompleteIcon />
                <span>Completed</span>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "10px" }}>
              <div className="status">
                <PendingIcon />
                <span>Pending</span>
              </div>
            </div>
          )}
        </Col>
        <Col lg={12}>
          <div className="session-icon">
            
            {type != "IPR" ?
              <AttachmentSessionIcon />
              :
              <SessionIcon />
            }
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default IPRCard;
