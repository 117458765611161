import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "assets/images/HomeIcon.svg";
import { ReactComponent as PendingIcon } from "assets/images/PendingIcon.svg";
import { ReactComponent as VerifyIcon } from "assets/images/VerifyIcon.svg";
import { ReactComponent as EmailSentIcon } from "assets/images/EmailSentIcon.svg";
import styles from "assets/styles/routes/dashboard.module.scss";
import HeaderCount from "./HeaderCount";
import { primaryColor } from "colors-config";
const Header = ({ clinicData }) => {
  const partnerParams = new URLSearchParams({ status: "partnered" }).toString();
  const emailSentParams = new URLSearchParams({
    status: "email-sent-to-clinic",
  }).toString();
  const verificationPendingParams = new URLSearchParams({
    status: "verification-pending",
  }).toString();
  const onBoardingPendingParams = new URLSearchParams({
    status: "onboarding-pending",
  }).toString();
  const partnerLinkWithParams = `/lab/partners`;
  const emailLinkWithParams = `/lab/partners?${emailSentParams}`;
  const verificationPendingLinkWithParams = `/lab/partners?${verificationPendingParams}`;
  const onBoardingPendingLinkWithParams = `/lab/partners?${onBoardingPendingParams}`;

  return (
    <div className={styles.headerContainer}>
      <Row gutter={[12, 12]}>
        <Col lg={6}>
          <Card hoverable style={{height:"100%"}}>
            <Link to={partnerLinkWithParams}>
              <div className={styles.headerBg}>
                <HeaderCount

                  icon={<HomeIcon style={{fill:primaryColor}} />}
                  count={clinicData?.clinicPartnered}
                  info="Clinicians Partnered"
                />
              </div>
            </Link>
          </Card>
        </Col>
        <Col lg={18}>
            <div className={styles.headerBg}>
              <Row align="middle" gutter={[12,12]}>
                <Col lg={8}>
                <Card hoverable style={{height:"100%"}}>
                <Link to={emailLinkWithParams}>
                    <HeaderCount
                      icon={<EmailSentIcon />}
                      count={clinicData?.emailSentToClinic}
                      info="Email sent to clinician"
                    />
                  </Link>
                </Card>
                 
                </Col>
                <Col lg={8}>
                <Card hoverable style={{height:"100%"}}>
                <Link to={verificationPendingLinkWithParams}>
                <HeaderCount
                    icon={<VerifyIcon />}
                    count={clinicData?.verificationPending}
                    info="Verification Pending"
                  />
                  </Link>
                </Card>
               

        
                </Col>
                <Col lg={8}>
                <Card hoverable style={{height:"100%"}}>
                <Link to={onBoardingPendingLinkWithParams}>
                <HeaderCount
                    icon={<PendingIcon />}
                    count={clinicData?.onboardingPending}
                    info="Onboarding Pending"
                  />
                  </Link>
                </Card>
               
          
                </Col>
              </Row>
            </div>
      
        </Col>
      </Row>
    </div>
  );
};

export default Header;
