import { useEffect, useRef, useState } from "react";
import { Input, Row, Col, Button, Popover, Checkbox, List } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { getAssociatedClinics } from "api/clinic";
import { secondaryFilterFilters } from "constants/notifications";
import { useLocation } from "react-router-dom";
import styles from "./notifications.module.scss";

const Search = ({
  labId,
  setChecked,
  setClinicIds,
  setFilterVal,
  open,
  setOpen,
  checked,
  setSecondaryFilter,
}) => {
  const [input, setInput] = useState("");

  const clinicDataRef = useRef(null);

  const [options, setOptions] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);

  const [filterListChecked, setFilterListChecked] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
   useEffect(() => {
     if (queryParams) {
       const typeParams=queryParams.get("type") ;
       if(typeParams=='comment'){
         setFilterListChecked(["comment"]);
       }
     }
   }, []);

  const onChange = (e) => {
    const value = e.target.value.trim();
    setInput(value);

    if (!value) {
      return setOptions(clinicDataRef.current);
    }

    const searchStr = value.toLowerCase();
    const filteredClinics = options.filter((clinic) => {
      const phoneNumber = clinic.country_code_name + clinic.clinic_contact;
      return (
        clinic.clinic_id.toLowerCase().includes(searchStr) ||
        clinic.clinic_name.toLowerCase().includes(searchStr) ||
        clinic.clinic_email.toLowerCase().includes(searchStr) ||
        phoneNumber.includes(searchStr)
      );
    });
    setOptions(filteredClinics);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const getLabClinic = async () => {
    try {
      const clinicData = await getAssociatedClinics({
        status: "Partnered",
        labId,
        limit: 1000,
        page: 1,
        search: "",
      });

      let clinics = [];

      if (clinicData?.data?.body?.data) {
        clinics = clinicData.data.body.data;
      }

      clinicDataRef.current = clinics;

      setOptions(clinics);
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  useEffect(() => {
    if (labId) getLabClinic();
  }, [labId]);

  const clinicListRender = () => {
    return (
      <div>
        <Row>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={checked}
            onChange={(checkedValues) => {
              setChecked(checkedValues);
            }}
          >
            <List
              style={{ height: "250px", width: "270px", overflowY: "auto" }}
              bordered
              size="small"
              dataSource={options}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.clinic_id}>
                    {" "}
                    {item.clinic_name}
                  </Checkbox>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        </Row>
        <Row className="clinic-filter-actions">
          <Button
            type="link"
            onClick={() => {
              setFilterVal("ALL");
              setClinicIds([]);
              setChecked([]);
              setOpen(false);
            }}
          >
            Reset
          </Button>
          <Button
            style={{
              marginLeft: "auto",
              padding: "0px 7px",
              height: "24px",
              fontSize: "14px",
              borderRadius: "3px",
            }}
            type="primary"
            onClick={() => {
              setFilterVal("Clinic");
              setClinicIds(checked);
              setOpen(false);
            }}
          >
            OK
          </Button>
        </Row>
      </div>
    );
  };

  const onFilterIconClick = () => {
    console.log("click");
  };

  const closeFilter = () => {
    setOpenFilter(false);
  };

  const onFilterReset = () => {
    setFilterListChecked([]);
    setSecondaryFilter([]);
    closeFilter();
  };

  const onFilterOk = () => {
    setSecondaryFilter(filterListChecked);
    closeFilter();
  };
  const filterListContent = () => {
    return (
      <div>
        <Row>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={filterListChecked}
            onChange={(checkedValues) => {
              setFilterListChecked(checkedValues);
            }}
          >
            <List
              style={{ height: "250px", width: "270px", overflowY: "auto" }}
              bordered
              size="small"
              dataSource={secondaryFilterFilters}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        </Row>
        <Row className="clinic-filter-actions">
          <Button type="link" onClick={onFilterReset}>
            Reset
          </Button>
          <Button
            style={{
              marginLeft: "auto",
              padding: "0px 7px",
              height: "24px",
              fontSize: "14px",
              borderRadius: "3px",
            }}
            type="primary"
            onClick={onFilterOk}
          >
            OK
          </Button>
        </Row>
      </div>
    );
  };

  return (
    <div style={{ margin: "20px 0", width: "80%" }}>
      <Row>
        <Col span={24} offset={0}>
          <div className={styles["search-bar-container"]}>
            <Popover
              overlayClassName="clinic-list-popover"
              align="bottom"
              content={clinicListRender}
              visible={open}
              onVisibleChange={handleOpenChange}
              trigger="click"
            >
              <Input.Search
                // onFocus={onSearchFocus}
                onChange={onChange}
                value={input}
                size="large"
                placeholder="search by clinic name, email, phone..."
                style={{width: "330px"}}
              />
            </Popover>
            <div style={{ marginLeft: "10px" }}>
              <Popover
                overlayClassName="clinic-list-popover"
                align="bottom"
                content={filterListContent}
                visible={openFilter}
                onVisibleChange={(val) => setOpenFilter(val)}
                trigger="click"
              >
                <FilterOutlined
                  onClick={onFilterIconClick}
                  styles={{
                    fontSize: "16px",
                  }}
                />
              </Popover>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Search;
