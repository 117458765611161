import api from "./api";

export const createStaff = (labId, data) => {
  return api({
    method: "POST",
    url: `/lab/labStaff/${labId}`,
    data,
  });
};

export const getLabStaff = (labId) => {
  return api({
    method: "GET",
    url: `/lab/labStaff/${labId}`,
  });
};

export const updateLabStaff = ({ labId, staffId, data }) => {
  return api({
    method: "PUT",
    url: `/lab/labStaff/${labId}/${staffId}`,
    data,
  });
};

export const updateLabStaffStatus = ({ staffEmail }) => {
  return api({
    method: "PUT",
    url: `/lab/labStaff/updateStatus/${staffEmail}`,
  });
};

export const deleteLabStaff = ({ labId, staffId }) => {
  return api({
    method: "DELETE",
    url: `/lab/labStaff/${labId}/${staffId}`,
  });
};

export const validateStaffEmail = ({ labId, staffEmail }) => {
  return api({
    method: "GET",
    url: `/lab/labStaff/validate/${labId}/${staffEmail}`,
  });
};

export const assignStaffMember = (data) => {
  return api({
    method: "POST",
    url: "/lab/staffPatient/assign",
    data,
  });
};

export const getStaffMemberByPatientId = ({ patientId }) => {
  return api({
    method: "GET",
    url: `/lab/staffPatient/getAssignedStaff/${patientId}`,
  });
};
