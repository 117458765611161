export const confrmTreamentPlanVerbiage = {
  title: (refinement) => `${refinement ? "Refinement" : "Treatment"} Update Confirmation`,
  content: (refinement) => `Do you want to maintain the same ${refinement ? "refinement" : "treatment"} plan version or create a new version?`,
  okText: (treatment, totalTreatmentLength) => {
    const { revision_plan_version, treatment_plan_version } = treatment;
    const versionNumber = revision_plan_version ? revision_plan_version : treatment_plan_version;

    return `Create New Version ${totalTreatmentLength + 1}.0`;
  },
  cancelText: (treatment) => {
    const { revision_plan_version, treatment_plan_version } = treatment;
    const versionNumber = revision_plan_version ? revision_plan_version : treatment_plan_version;

    return `Update Current Version ${versionNumber}.0`;
  },
};

export const labProductAssociationVerbiage = {
  title: "Confirmation",
  content:
    "Are you sure that you do not want to associate any lab product with this treatment plan before you share this with your clinic?",
};
