import api from "./api";

export const saveResource = (data,config) => {
  return api({
    method: "POST",
    url: `/lab/resources/save`,
    data: data,
    ...config
  });
};


export const editResource = (resourceId,labId, data, config) => {
  return api({
    method: "PATCH",
    url: `/lab/resources/update/${resourceId}/${labId}`,
    data: data,
    ...config
  });
};


export const shareResourceWithClinics = (data) => {
    return api({
      method: "POST",
      url: `/lab/resources/share`,
      data: data,
    });
  };



export const getResources = (labId) => {
  return api({
    method: "GET",
    url: `/lab/resources/get/${labId}`,
  });
};


export const deleteResource = (resourceId,labId) => {
    return api({
      method: "DELETE",
      url: `/lab/resources/delete/${resourceId}/${labId}`,
    });
  };


  export const searchLabResources = (labId,params) => {
    return api({
      method: "GET",
      url: `/lab/resources/lab/search/${labId}`,
      params
    });
  };
