import { Row, Col } from "antd";
import AttachmentSessionList from "./AttachmentSessionList";
import { showTeethName } from "../../Attachments/data";
import { getAlignerNumberBackground} from "utils/ipr";

const AttachmentShowInfo = ({
  alignersLabel,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
  chartValue,
}) => {


  return (
    <div className="attachmentInfo-container">
      <p
        className="patient-question"
        style={{ marginTop: "0px", textAlign: "left" }}
      >
        Attachment Details
      </p>

      {upperAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row
                      gutter={[16, 16]}
                      align="middle"
                      style={{
                        marginTop: "10px",
                        justifyContent: "space-between",
                        alignItems:"baseline"
                      }}
                    >
                      <Col lg={4} sm={24}>
                        <p
                           style={{
                            background: `${getAlignerNumberBackground(
                              attachment.session
                            )} `
                          }}
                          className={`toothName`}
                        >
                          {chartValue === "fdi"
                            ? attachmentValue.teeth
                            : showTeethName(
                                attachmentValue.teeth,
                                chartValue,
                                "upper"
                              )}
                        </p>
                      </Col>
                      <Col lg={8}>
                        <p className="attachment-response">
                          {attachmentValue.attachmentType}
                        </p>
                      </Col>
                      <Col lg={12}>
                        <p className="attachment-response">
                          {attachmentValue.detail ? attachmentValue.detail : "--"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
      {lowerAttachments.map((attachment, i) => {
        return (
          <div key={i}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row
                      gutter={[16, 16]}
                      align="middle"
                      style={{
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col lg={4} sm={24}>
                        <p
                            style={{
                              background: `${getAlignerNumberBackground(
                                attachment.session
                              )} `
                            }}
                            className={`toothName`}
                        >
                          {chartValue === "fdi"
                            ? attachmentValue.teeth
                            : showTeethName(
                                attachmentValue.teeth,
                                chartValue,
                                "lower"
                              )}
                        </p>
                      </Col>
                      <Col lg={8}>
                        <p className="attachment-response">
                          {attachmentValue.attachmentType}
                        </p>
                      </Col>
                      <Col lg={12}>
                        <p className="attachment-response">
                        {attachmentValue.detail ? attachmentValue.detail : "--"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentShowInfo;
