import api from "./api";
const baseUrl = process.env.REACT_APP_CLINIC_BASE_URL;

export const getFirstOrderLabInvoices = (labId, params) => {
    return api({
      method: "GET",
      url: `/lab/billing/invoiceFirst/${labId}`,
      params: params,
    });
  };


  export const downloadFirstOrderLabInvoices = (labId, params) => {
    let str=new URLSearchParams(params).toString();
    return  baseUrl+`/lab/billing/invoiceFirstDownload/${labId}?${str}`;
  };

  export const downloadAllOrderLabInvoices = ( params) => {
    let str=new URLSearchParams(params).toString();
    return baseUrl+`/lab/lab/orders?${str}`;
  };