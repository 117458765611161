import React from "react";
import { Button } from "antd";

const GenericButton = ({
  content,
 
  type,
  children,
  customStyle,
  
 

  ...props
}) => {
  return (
    <Button
      type={type}
      style={{
        borderRadius: "20px",
        width: "10rem",
        height: "2.5rem",
        ...customStyle,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default GenericButton;
