import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
  Collapse,
  Divider,
  Button,
  Radio,
  Spin,
  message,
} from "antd";


import ReactToPrint from "react-to-print";
import { DownOutlined } from "@ant-design/icons";
import IprGuide from "components/shared/IprGuide/IprGuide";
import IprUpload from "components/shared/IprGuide/IPRUpload/IPRUpload";
import { ExclamationCircleFilled, CloseOutlined } from "@ant-design/icons";
import { getProducts, getAttachmentProducts, uploadAppMediaApi } from "api/lab";
import Attachments from "components/private/Attachments/Attachments";
import { primaryColor, companyType } from "colors-config";
import TextEditor from "components/shared/TextEditor/TextEditor";
import SmileAlignPrescriptionDetails from "components/private/SmileGeniusPrescriptionDetails/SmileGeniusPrescriptionDetails";
import SmileGeniusPrescriptionDetails from "components/private/SmileGeniusPrescriptionDetails/SmileGeniusPrescriptionDetails";
import { validateTreatmentPlan } from "utils/ipr";
import { alignersRequiredMessage } from "messages/messages";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import styles from "./style.module.scss";
import {
  confrmTreamentPlanVerbiage,
  labProductAssociationVerbiage,
} from "./ConfirmationModals/Verbiage";
import { getTreatmentDraftService, saveTreatmentDraftService } from "api/treatment";
import TreatmentUpload from "components/shared/TreatmentUpload/TreatmentUpload";
import { MAX_FILE_SIZE } from "constants";
import { init } from "mixpanel-browser";
import {
  calculateFormDiscountPrice,
  calculateProductsTotal,
  initialProductsFormData,
  labProductsFormMapper,
} from "utils/treatment";
import LabProductForm from "components/shared/LabProductForm/LabProductForm";

const { Option } = Select;
const { TextArea } = Input;

const TreatmentSetupModal = ({
  onConfirmation,
  showModal,
  setShow,
  treatment,
  patientId,
  labId,
  getTreatmentSetupAndPrescription,
  refinementData,
  selectedTreatment,
  prescriptionObj,
  wholeTreatmentPlan,
  clinicId,
  refinements,
  clinicInfo
}) => {
  const [modal, contextModal] = Modal.useModal();
  const [draftLoader, setDraftLoader] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [messageApi, contextMessage] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const printFormRef = useRef();
  const [value, setValue] = useState("ipr");
  //   const [isModalVisible, setIsModalVisible] = useState(false);
  const [upperAligners, setUpperAligners] = useState([]);
  const [lowerAligners, setLowerAligners] = useState([]);
  const [upperAttachments, setUpperAttachments] = useState([]);
  const [lowerAttachments, setLowerAttachments] = useState([]);
  const [upperLength, setUpperLength] = useState();
  const [lowerLength, setLowerLength] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [labProducts, setLabProducts] = useState([]);
  const [chartValue, setChartValue] = useState("fdi");
  // const [disableAttachment, setDisableAttachment] = useState(false);
  const [iprFile, setIprFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [originalTreatmentData, setOriginalTreatmentData] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [removeIprFile, setRemoveIprFile] = useState(false);
  const [detailText, setDetailText] = useState("");
  const [phasing, setPhasing] = useState("");
  const [attachmentProductList, setAttachmentProductList] = useState([
    {
      attachmentName: "button",
      value: "Button",
    },
    {
      attachmentName: "Hook",
      value: "hook",
    },
    {
      attachmentName: "Bracket",
      value: "bracket",
    },
  ]);
  const [treatmentFile, setTreatmentFile] = useState(null);
  const [totalSum, setTotalSum] = useState("0.00");
  const [selectedLabProductName, setSelectedLabProductName] = useState();
  const [openDropdownKey, setOpenDropdownKey] = useState(null);
  const [form] = Form.useForm();

  const setValuesFromDraft = (draftData) => {
    const formValues = treatmentDataMapper(draftData);
    // setDisableAttachment(formValues.attachments);
    form.setFieldsValue(formValues);
    setUpperLength(formValues.upperAlignersNo);
    setLowerLength(formValues.lowerAlignersNo);
    setDetailText(formValues.details);

    setPhasing(draftData?.phasing);

    setUpperAttachments(draftData.attachmentData.upperJaw);
    setLowerAttachments(draftData.attachmentData.lowerJaw);

    setUpperAligners(draftData.iprData.upperJaw);
    setLowerAligners(draftData.iprData.lowerJaw);
    
    form.setFieldValue("products",draftData.treatmentLabProductList);

  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await Promise.all([
          getAttachmentProducts(labId),
          getProducts(labId),
          getTreatmentDraftService(patientId),
        ]);

        const [labAttachmentRes, labProductsRes, draftRes] = response;

        if (draftRes.data.data) {
          const data = JSON.parse(draftRes.data.data.treatment);
          setValuesFromDraft(data);
          setIsDraft(true);
          const sum = calculateProductsTotal(data?.treatmentLabProductList);
          setTotalSum(sum);
        }

        if (labAttachmentRes?.data?.labAttachments?.list) {
          setAttachmentProductList(labAttachmentRes?.data?.labAttachments?.list);
        }
        if (labProductsRes?.data?.labProducts) {
          const tempLabProducts = labProductsRes.data.labProducts;
          setLabProducts(tempLabProducts);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    })();
  }, [labId, patientId, treatment]);
  // console.log("refinementData", refinementData);
  const treatmentDataMapper = (formData, firstRefinement = false) => {
    if (firstRefinement) {
      const finalObj = {
        upperAlignersNo: "",
        lowerAlignersNo: "",
        attachments: "",
        details: "",
        planUrl: "",
        iprDocument: [],
        lab_treatment_id: formData?.lab_treatment_id,
        labProductId: null,
        quantity: 0,
        arches: null,
        price: "",
        total: "",
        free: false,
        products: initialProductsFormData(),
      };
      return finalObj;
    }
    const quantity = formData.quantity ? formData.quantity : 1;
    const finalObj = {
      upperAlignersNo: formData?.num_of_upper_aligners,
      lowerAlignersNo: formData?.num_of_lower_aligners,
      // attachments: formData?.attachments ? true : false,
      details: formData?.additionalDetails,
      planUrl: formData?.treatment_plan_link,
      iprDocument: formData?.iprDocument || [],
      lab_treatment_id: formData?.lab_treatment_id,
      products: formData?.labProductList?.length
        ? labProductsFormMapper(formData?.labProductList)
        : initialProductsFormData(),
      labProductId: formData.labProductId ? formData.labProductId : null,
      quantity: quantity,
      arches: formData.labProductData?.arches ? formData.labProductData?.arches : null,
      price: formData?.labProductData?.free
        ? formData.labProductData.currency + " " + "0"
        : formData?.labProductData
        ? formData?.labProductData.price
        : "",
      total: formData?.labProductData?.free
        ? formData.labProductData.currency + " " + "0"
        : formData?.labProductData && quantity
        ? formData.labProductData.currency + " " + formData?.labProductData.price * quantity
        : "",
      free: formData?.labProductData?.free ? formData?.labProductData?.free : false,
    };
    return finalObj;
    // }
  };

  const IprMapper = (aligners, type) => {
    let final;
    const prefix = type === "upper" ? "U" : "L";
    const mappedAligners = aligners.map((aligner) => {
      return {
        ...aligner,
        name: `${prefix}${aligner.aligner_count}`,
        uniqueId: aligner.id,
      };
    });

    if (type === "upper") {
      final = mappedAligners.sort((a, b) => b.aligner_count - a.aligner_count);
    } else {
      final = mappedAligners.sort((a, b) => a.aligner_count - b.aligner_count);
    }

    return final;
  };

  const handleIPR = (tp, firstRefinement = false) => {
    if (!firstRefinement && tp?.iprData?.length) {
      let upperAligners = tp?.iprData.filter((aligner) => aligner.jawType === "upper");
      const lowerAligners = tp?.iprData.filter((aligner) => aligner.jawType === "lower");
      upperAligners = upperAligners.reverse().map((obj, index) => {
        obj.index = index + 1;
        return obj;
      });
      const upperFinal = IprMapper(upperAligners.reverse(), "upper");
      const lowerFinal = IprMapper(lowerAligners, "lower");
      setUpperAligners(upperFinal);
      setLowerAligners(lowerFinal);
    }
  };

  const handleAttachments = (tp, firstRefinement = false) => {
    if (!firstRefinement && tp?.attachmentData?.length) {
      let upperAligners = tp.attachmentData.filter((aligner) => aligner.jawType === "upper");
      const lowerAligners = tp.attachmentData.filter((aligner) => aligner.jawType === "lower");
      upperAligners = upperAligners.reverse().map((obj, index) => {
        obj.index = index + 1;
        return obj;
      });
      const upperFinal = IprMapper(upperAligners.reverse(), "upper");
      const lowerFinal = IprMapper(lowerAligners, "lower");

      setUpperAttachments(upperFinal);
      setLowerAttachments(lowerFinal);
    }
  };

  useEffect(() => {
    if (wholeTreatmentPlan?.length) {
      // console.log("hasRefinement", refinements, refinementData);

      const hasRefinement = refinements?.length || false;
      const firstRefinement = Boolean(refinementData) && !hasRefinement;
      // console.log("first refinement", Boolean(refinementData) && !hasRefinement);
      setShowDownload(true);
      const findTreatmentIndex = wholeTreatmentPlan.findIndex(
        (obj) => obj.treatment_plan_version === selectedTreatment.treatment_plan_version
      );
      const formObject = wholeTreatmentPlan[findTreatmentIndex > -1 ? findTreatmentIndex : 0];

      if (formObject) {
        if (formObject.labProductData) {
          setSelectedProduct(formObject.labProductData);
        }

        const formValues = treatmentDataMapper(formObject, firstRefinement);
        const sum = calculateProductsTotal(formObject?.labProductList);
        // setDisableAttachment(formValues.attachments);
        setOriginalTreatmentData(formValues);
        form.setFieldsValue(formValues);
        setUpperLength(formValues.upperAlignersNo);
        setLowerLength(formValues.lowerAlignersNo);
        setTotalSum(sum);
        if (formObject?.planType === "FILE") {
          setTreatmentFile(formObject?.treatment_media);
        }
        if (firstRefinement) {
          form.setFieldsValue({
            products: initialProductsFormData(), // initial value for the form
          });
          setTotalSum(0);
          setTreatmentFile(null);
        }

        if (formValues?.details) {
          setDetailText(firstRefinement ? "" : formValues.details);
        }

        if (formObject?.phasing) {
          setPhasing(firstRefinement ? "" : formObject.phasing);
        }

        // if (!refinementData && treatmentPlansState?.data?.body?.treatmentFile) {
        if (formObject?.iprDocument) {
          setFileList(firstRefinement ? [] : [formObject?.iprDocument]);
        }

        handleIPR(formObject, firstRefinement);
        handleAttachments(formObject, firstRefinement);
      }
    } 
    else{
      form.setFieldsValue({
        products: initialProductsFormData(),
      });
    }
  }, [wholeTreatmentPlan]);

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    const touched = form.isFieldsTouched();
    if (touched) {
      return showConfirmCancel();
    }
    // setIsModalVisible(false);
    setShow(false);
    setTreatmentFile(null);
  };

  const updateAlignersErrorsArray = (fieldName) => {
    form.setFields([{ name: fieldName, errors: [] }]);
  };

  const showConfirm = (values) => {
    modal.confirm({
      title: labProductAssociationVerbiage.title,
      icon: <ExclamationCircleFilled />,
      content: labProductAssociationVerbiage.content,
      okButtonProps: {
        className: styles["btn"],
      },
      cancelButtonProps: {
        className: styles["btn"],
      },
      onOk() {
        if (treatment && treatment?.approval_status === "pending") {
          confirmTreatmentPlanVersion(values);
        } else {
          saveTreatmentPlan({ ...values, newVersion: true });
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const confirmTreatmentPlanVersion = (values) => {
    let totalTreatmentLength = wholeTreatmentPlan?.length ? wholeTreatmentPlan.length : 1;
    const findRefinementTreatment = wholeTreatmentPlan.find((obj) => obj.revision_plan_version);
    let refinementCheck = false;
    if (findRefinementTreatment) {
      totalTreatmentLength = wholeTreatmentPlan.filter((obj) => obj.revision_plan_version).length;
      refinementCheck = true;
    }

    modal.confirm({
      title: confrmTreamentPlanVerbiage.title(refinementCheck),
      icon: <ExclamationCircleFilled />,
      content: confrmTreamentPlanVerbiage.content(refinementCheck),
      okText: confrmTreamentPlanVerbiage.okText(treatment, totalTreatmentLength),
      cancelText: confrmTreamentPlanVerbiage.cancelText(treatment),
      width: 550,
      closable: true,
      onCancel() {},
      footer: [
        <div className={styles.footerContainer}>
          <Button
            key="cancel"
            className={styles["btn"]}
            onClick={() => saveTreatmentPlan({ ...values, newVersion: false })}
          >
            {confrmTreamentPlanVerbiage.cancelText(treatment)}
          </Button>
          <Button
            key="ok"
            type="primary"
            className={styles["btn"]}
            onClick={() => saveTreatmentPlan({ ...values, newVersion: true })}
          >
            {confrmTreamentPlanVerbiage.okText(treatment, totalTreatmentLength)}
          </Button>
        </div>,
      ],
    });
  };

  const getPayload = (values) => {
    return {
      ...values,
      attachmentNames: attachmentProductList,
      quantity: values.quantity ? values.quantity : null,
      iprData: {
        upperJaw: upperAligners,
        lowerJaw: lowerAligners,
      },
      attachmentData: {
        upperJaw: upperAttachments,
        lowerJaw: lowerAttachments,
      },
      phasing,
      details: detailText,
      revision: false,
      update: checkTreatmentUpdateForSmile(values),
      old_lab_treatment_id: originalTreatmentData?.lab_treatment_id,
      removeIprFile,
      treatmentLabProductList: values.products,
    };
  };
  const uploadTreatmentFile = async (file, labTreatmentId) => {
    // console.log("order_id", order_id)
    try {
      let formData = new FormData();

      formData.append("file", file);
      formData.append("labTreatmentId", labTreatmentId);
      formData.append("patientId", patientId);
      formData.append("clinicId", clinicId);
      formData.append("uploadedBy", "lab");
      formData.append("model", "treatment");
      const { data } = await uploadAppMediaApi(formData);
      if (data) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveTreatmentPlan = async (values) => {
    if (!values.planUrl?.trim() && !treatmentFile) {
      return message.error("Please add a Treatment Link or File");
    }
    if (treatmentFile && !values.planUrl) {
      if (treatmentFile?.size > MAX_FILE_SIZE) {
        return message.error(`${treatmentFile.name} is larger than 150MB and cannot be uploaded.`);
      }
    }

    if (!validateTreatmentPlan(values)) {
      return message.error(alignersRequiredMessage);
    }
    let checkUpperEmpty = [];
    let checkLowerEmpty = [];
    upperAligners.forEach((aligner) => {
      const valueCheck = aligner.alignerValues.some((item) => item.value);

      if (!valueCheck) {
        checkUpperEmpty.push(false);
      }
    });
    lowerAligners.forEach((aligner) => {
      const valueCheck = aligner.alignerValues.some((item) => item.value);

      if (!valueCheck) {
        checkLowerEmpty.push(false);
      }
    });
    if (checkUpperEmpty.some((obj) => !obj)) {
      message.error("Kindly add atleast one value in Upper Aligners.");
      return;
    }

    if (checkLowerEmpty.some((obj) => !obj)) {
      message.error("Kindly add atleast one value in Lower Aligners.");
      return;
    }

    const apiPayload = getPayload(values);

    if (treatmentFile?.id) {
      apiPayload.fileId = treatmentFile?.id;
    }
    const hide = message.loading("Creating treatment plan", 0);
    try {
      if (refinementData) {
        apiPayload.revision = true;
      }

      if(treatment?.iprDocument){
        apiPayload.manualTreatmentFileId = treatment?.iprDocument?.id
      }
      apiPayload.clinicId = clinicId;
      apiPayload.clinicInfo = clinicInfo;
      const res = await onConfirmation(
        apiPayload,
        patientId,
        labId,
        getTreatmentSetupAndPrescription,
        iprFile
      );
      if (treatmentFile && !treatmentFile.id) {
        const uploadedRes = await uploadTreatmentFile(treatmentFile, res?.body?.labTreatmentId);
        // console.log("uploadedResuploadedRes", uploadedRes)
      }
      message.success("Treatment Plan submitted successfully");
      getTreatmentSetupAndPrescription();
      setShow(false);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(hide, 0);
    }
  };

  const checkTreatmentUpdateForSmile = (values) => {
    if (originalTreatmentData) {
      if (
        originalTreatmentData.attachments != values.attachments ||
        originalTreatmentData.lowerAlignersNo != values.lowerAlignersNo ||
        originalTreatmentData.planUrl != values.planUrl ||
        originalTreatmentData.upperAlignersNo != values.upperAlignersNo ||
        originalTreatmentData.labProductId != values.labProductId ||
        originalTreatmentData.quantity != values.quantity
      ) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };
  function checkLabProductId(products) {
    if (!products.length) {
      return true;
    }
    return products.find((product) => !product.labProductId);
  }

  const onFinish = (values) => {
    const hasNullLabProductId = checkLabProductId(values.products);
    if (values?.products?.length > 1) {
      if (hasNullLabProductId) {
        return message.error("Please select a product or delete empty one");
      }
    } else {
      if (hasNullLabProductId) {
        return showConfirm(values);
      }
    }
    if (
      treatment &&
      (treatment.approval_status === "pending" ||
        (treatment.approval_status !== "approved" && treatment.refinement_applied))
    ) {
      return confirmTreatmentPlanVersion(values);
    }

    return saveTreatmentPlan({ ...values, newVersion: true });
  };

  const handleStructureChange = (value) => {
    setChartValue(value);
  };

  const onChange = (info) => {
    // setIprFile(info.file);
  };

  const onChangeRadio = (e) => {
    const { value } = e.target;
    setValue(value);
    // if (value === "attachment" && !disableAttachment) {
    //   return message.info("Please enable attachments in the form ");
    // }
    // if (value === "ipr") {

    // }
    // if (disableAttachment) {
    //   setValue(value);
    // }
  };

  useEffect(() => {
    if (iprFile) {
      setFileList([iprFile]);
    }
  }, [iprFile, selectedLabProductName]);

  const beforeUpload = (file) => {
    setIprFile(file);
    return false;
  };

  const handleUpperChange = (value) => {
    // if (typeof value === "number") {
    //   setUpperLength(value);
    // }
  };

  const handleLowerChange = (value) => {};

  const validateLowerAligners = (_, value) => {
    if (value === null || value === undefined) {
      return Promise.reject("Aligner no is required");
    }
    if (value === 0) {
      setLowerLength(value);
      Promise.resolve();
    }
    // if (refinementData) {
    //   setLowerLength(value);
    //   return Promise.resolve();
    // }
    if (lowerAttachments.length) {
      const counts = lowerAttachments.map((obj) => obj.aligner_count);

      const maxAligner = Math.max(...counts);

      if (value < maxAligner) {
        message.error(
          "The Aligner no is already added, please delete the attachment session first."
        );
        return Promise.reject(
          "The Aligner no is already added, please delete the attachment session first."
        );
      }
    }
    if (lowerAligners.length) {
      const counts = lowerAligners.map((obj) => obj.aligner_count);

      const maxAligner = Math.max(...counts);

      if (value < maxAligner) {
        message.error("The aligner no is already added delete the ipr session");
        return Promise.reject("The aligner no does not match the ipr session");
      }
    }

    if (typeof value === "number") {
      setLowerLength(value);
      return Promise.resolve();
    }
  };

  const validateUpperAligners = (_, value) => {
    if (value === null || value === undefined) {
      return Promise.reject("Aligner no is required");
    }
    if (value === 0) {
      setUpperLength(value);
    }
    // if (refinementData) {
    //   setUpperLength(value);
    //   return Promise.resolve();
    // }
    if (upperAttachments.length) {
      const counts = upperAttachments.map((obj) => obj.aligner_count);
      const maxAligner = Math.max(...counts);

      if (value < maxAligner) {
        message.error(
          "The Aligner no is already added, please delete the attachment session first."
        );
        return Promise.reject(
          "The Aligner no is already added, please delete the attachment session first."
        );
      }
    }
    if (upperAligners.length) {
      const counts = upperAligners.map((obj) => obj.aligner_count);
      const maxAligner = Math.max(...counts);
      if (value < maxAligner) {
        message.error("The aligner no is already added delete the ipr session");
        return Promise.reject("The aligner no does not match the ipr session");
      }
    }
    if (typeof value === "number") {
      setUpperLength(value);
      return Promise.resolve();
    }
  };

  const calculateTotal = () => {
    const values = form.getFieldsValue().products || [];
   const sum = calculateProductsTotal(values)
    setTotalSum(sum); 
  };
  
  

  const header = (
    <>
      <div className="chart-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Divider />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "-20px",
          }}
        >
          <div className="header-select-container" id="printablediv">
            {showDownload && (
              <ReactToPrint
                trigger={() => {
                  return (
                    <div
                      style={{
                        marginRight: "30px",
                      }}
                    >
                      <a style={{ color: primaryColor }}>Download</a>
                    </div>
                  );
                }}
                content={() => printFormRef.current}
              />
            )}
          </div>
          <Select
            placeholder="Select option"
            className="header-select"
            onChange={handleStructureChange}
            value={chartValue}
          >
            <Option value="fdi">FDI</Option>
            <Option value="universal">Universal</Option>
            <Option value="palmer">Palmer</Option>
          </Select>
        </div>
      </div>
    </>
  );

  const tabOptions = [
    {
      label: "IPR",
      value: "ipr",
    },
    {
      label: "Attachment",
      value: "attachment",
    },
  ];
  const getItems = () => [
    {
      key: "1",
      label: header,
      children: (
        <>
          <Radio.Group
            className="ipr-radio"
            options={tabOptions}
            onChange={onChangeRadio}
            value={value}
            optionType="button"
            buttonStyle="solid"
          />

          {value == "ipr" ? (
            <IprGuide
              upperLength={upperLength}
              lowerLength={lowerLength}
              upperAligners={upperAligners}
              setUpperAligners={setUpperAligners}
              lowerAligners={lowerAligners}
              setLowerAligners={setLowerAligners}
              showDownload={showDownload}
              chartValue={chartValue}
              updateAlignersErrorsArray={updateAlignersErrorsArray}
            />
          ) : (
            <Attachments
              attachmentProductList={attachmentProductList}
              setAttachmentProductList={setAttachmentProductList}
              upperLength={upperLength}
              lowerLength={lowerLength}
              upperAttachments={upperAttachments}
              setUpperAttachments={setUpperAttachments}
              lowerAttachments={lowerAttachments}
              setLowerAttachments={setLowerAttachments}
              chartValue={chartValue}
              updateAlignersErrorsArray={updateAlignersErrorsArray}
            />
          )}
        </>
      ),
    },
  ];

  const handleAttachmentChange = (value) => {
    // setDisableAttachment(value);
  };

  const handleSaveDraft = async () => {
    try {
      setDraftLoader(true);
      const values = form.getFieldsValue();
      const draftPayload = getPayload(values);
      delete draftPayload.removeIprFile;
      delete draftPayload.old_lab_treatment_id;

      const model = {
        num_of_upper_aligners: draftPayload.upperAlignersNo,
        num_of_lower_aligners: draftPayload.lowerAlignersNo,
        attachments: draftPayload.attachments,
        iprData: draftPayload.iprData,
        treatment_plan_link: draftPayload.planUrl,
        revision: draftPayload.revision,
        additionalDetails: draftPayload.details,
        labProductId: draftPayload.labProductId ? draftPayload.labProductId : null,
        quantity: draftPayload.quantity ? draftPayload.quantity : null,
        attachmentData: draftPayload.attachmentData,
        attachmentNames: draftPayload.attachmentNames,
        phasing: draftPayload.phasing,
        treatmentLabProductList: values.products,
      };

      await saveTreatmentDraftService({
        patientId,
        treatment: JSON.stringify(model),
      });
      setIsDraft(true);
      messageApi.success("Saved as Draft");
      if (iprFile) {
        messageApi.info("Files will not be retained in draft");
      }
    } catch (err) {
      console.log("err", err);
      messageApi.error("unable to save draft");
    } finally {
      setDraftLoader(false);
    }
  };

  const beforeUploadTreatment = (file) => {
    // console.log("beforeUploadTreatment", file)

    setTreatmentFile(file);
    return false;
  };

  const handleProductNameChange = (value, name, fieldKey) => {
    if(!value){
      form.setFields([
        {
          name: [`products`, name, "arches"],
          value: null,
        },
        {
          name: [`products`, name, "netPrice"],
          value: null,
        },
        {
          name: [`products`, name, "total"],
          value: null,
        },
        {
          name: [`products`, name, "quantity"],
          value: null,
        },
        {
          name: [`products`, name, "quantity"],
          value: null,
        },
      ]);
       calculateTotal();
      return;
    }
    const values = form.getFieldsValue().products || [];
    const isDuplicate = values.find(
      (item, index) => item?.labProductId === value && index !== name
    );
    if (isDuplicate) {
      message.warning("This product has already been added. Please select a different product.");
      form.setFields([
        {
          name: [`products`, name, "labProductId"],
          value: null,
        },
      ]);
      return;
    }
    const product = labProducts.find((item) => item.id === value);
    const quantity = product.type === "Package" ? 1 : 1;
    const total = product.price * quantity;
    form.setFields([
      {
        name: [`products`, name, "arches"],
        value: product.arches,
      },
      {
        name: [`products`, name, "netPrice"],
        value: product.price,
      },
      {
        name: [`products`, name, "price"],
        value: product.price,
      },
      {
        name: [`products`, name, "quantity"],
        value: quantity,
      },
      {
        name: [`products`, name, "total"],
        value: total,
      },
      {
        name: [`products`, name, "free"],
        value: product.free,
      },
      {
        name: [`products`, name, "quantityType"],
        value: product.quantityType,
      },
      {
        name: [`products`, name, "currency"],
        value: product.currency,
      },
      
    ]);
    setSelectedLabProductName(value);
    calculateTotal();
  };

  const onQuantityChange = (value, name) => {
    const netPricePerUnit = form.getFieldValue([`products`, name, `netPrice`]);
    const totalPrice = netPricePerUnit * value;
    form.setFields([
      {
        name: [`products`, name, "quantity"],
        value: value,
      },
      {
        name: [`products`, name, "total"],
        value: totalPrice,
      },
    ]);
    calculateTotal();
  };

  const showConfirmCancel = () => {
    modal.confirm({
      title: "Close add Treatment Form",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to cancel the treatment plan, all changes will be lost",
      okButtonProps: {
        className: styles["btn"],
      },
      cancelButtonProps: {
        className: styles["btn"],
      },
      onOk() {
        setShow(false);
        setTreatmentFile(null);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

 
  const onSaveDiscount = (name) => {
    const productDiscountPrice = form.getFieldValue(["products", name, "discountPrice"]);
   form.setFields([
     {
       name: [`products`, name, "netPrice"],
       value: productDiscountPrice,
     },
   ]);
   setOpenDropdownKey(null)
   calculateTotal()
 };

  return (
    <>
      {contextMessage}
      {contextModal}
      <Modal
        onCancel={handleCancel}
        className="treatment-setup-modal"
        width={1000}
        title={<Header isDraft={isDraft} refinementData={refinementData} treatment={treatment} />}
        open={showModal}
        footer={
          <Footer
            draftLoader={draftLoader}
            handleCancel={handleCancel}
            handleOk={handleOk}
            treatment={treatment}
            handleSaveDraft={handleSaveDraft}
          />
        }
      >
        <Spin spinning={loading}>
          {companyType === 5 ? (
            <SmileAlignPrescriptionDetails prescriptionObj={prescriptionObj} />
          ) : (
            <SmileGeniusPrescriptionDetails prescriptionObj={prescriptionObj} />
          )}
          <Form
            form={form}
            className="treatment-setup-form"
            name="treatment-setup"
            layout={"vertical"}
            onFinish={onFinish}
          >
            <Row gutter={[8, 8]}>
              <Col lg={10}>
                <Form.Item
                  label={`No. of upper ${
                    process.env.REACT_APP_COMPANY_TYPE == 4 ? "stages" : "aligners"
                  }`}
                  name="upperAlignersNo"
                  style={{
                    textAlign: "left",
                  }}
                  rules={[
                    {
                      validator: validateUpperAligners,
                    },
                    {
                      required: true,
                      message: "A valid number must be entered",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <InputNumber
                    onChange={handleUpperChange}
                    max={99}
                    style={{ width: "100%" }}
                    placeholder="Enter Number"
                  />
                </Form.Item>
              </Col>
              <Col lg={10}>
                <Form.Item
                  label={`No. of lower ${
                    process.env.REACT_APP_COMPANY_TYPE == 4 ? "stages" : "aligners"
                  }`}
                  name="lowerAlignersNo"
                  className="inline-form-right"
                  style={{
                    textAlign: "left",
                  }}
                  rules={[
                    {
                      validator: validateLowerAligners,
                    },
                    {
                      required: true,
                      message: "A valid number must be entered",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <InputNumber
                    onChange={handleLowerChange}
                    max={99}
                    style={{ width: "100%" }}
                    placeholder="Enter Number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="space-between" gutter={32}>
              <Col md={20}>
                {treatmentFile ? (
                  <div>
                    <label className="required">Treatment plan 3D model link</label>
                    <div className="treatment-file">
                      <span>
                        {treatmentFile?.name}
                        <CloseOutlined
                          style={{ marginLeft: "20px" }}
                          onClick={() => setTreatmentFile(null)}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <Form.Item
                    name="planUrl"
                    label="Treatment plan 3D model link"
                    style={{
                      textAlign: "left",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "A URL is required",
                        whitespace: true,
                      },
                      {
                        type: "url",
                        message:
                          "This field must be a valid url (start with https://) and end with a domain.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Model Link"></Input>
                  </Form.Item>
                )}
              </Col>
              <Col md={4}>
                <div style={{ marginTop: "12px" }}>
                  <TreatmentUpload
                    maxCount={1}
                    multiple={false}
                    beforeUpload={beforeUploadTreatment}
                    onChange={onChange}
                    treatmentFile={treatmentFile}
                    setTreatmentFile={setTreatmentFile}
                  />
                </div>
              </Col>
            </Row>
            {labProducts.length ? (
             <LabProductForm
             form={form}
             labProducts={labProducts}
             handleProductNameChange={handleProductNameChange}
             onQuantityChange={onQuantityChange}
             totalSum={totalSum}
             onSaveDiscount={onSaveDiscount}
             calculateTotal={calculateTotal}
             openDropdownKey={openDropdownKey}
             setOpenDropdownKey={setOpenDropdownKey}
             />
            ) : (
              <p style={{ fontWeight: "bold" }}>No Lab products Added</p>
            )}
            <div ref={printFormRef}>
              <Collapse
                className="treatment-collapse"
                collapsible="icon"
                bordered={false}
                header={header}
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <Button
                    className="add-guideBtn"
                    style={{ background: primaryColor }}
                    icon={
                      <DownOutlined
                        style={{
                          transform: !isActive ? "rotate(270deg)" : "rotate(360deg)",
                          transition: "all 0.3s",
                        }}
                      />
                    }
                  >
                    Add Treatment Guide
                  </Button>
                )}
                items={getItems()}
              ></Collapse>
            </div>
            {companyType === 5 && prescriptionObj?.phasing === "yes" && (
              <Row>
                <div style={{ marginTop: "20px", width: "100%" }}>
                  <label style={{ marginBottom: "4px" }}>Phasing</label>
                  <TextArea
                    placeholder="Enter phasing comments"
                    value={phasing}
                    onChange={(e) => {
                      setPhasing(e.target.value);
                    }}
                  />
                </div>
              </Row>
            )}
            <Row>
              <div style={{ marginTop: "20px", width: "100%" }}>
                <label style={{ marginBottom: "4px" }}>Additional Details</label>
                <TextEditor
                  placeholder="Enter additional details"
                  editorInput={detailText}
                  setEditorInput={setDetailText}
                  hideToolbar={true}
                />
              </div>
            </Row>
            <br />
            <label style={{ marginTop: "4px" }}>Upload Treatment Guide PDF</label>
            <IprUpload
              maxCount={1}
              multiple={false}
              beforeUpload={beforeUpload}
              onChange={onChange}
              fileList={fileList}
              setFileList={setFileList}
              setIprFile={setIprFile}
              setRemoveIprFile={setRemoveIprFile}
            />
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default TreatmentSetupModal;
