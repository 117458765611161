import api from "./api";
const baseUrl = process.env.REACT_APP_CLINIC_BASE_URL;

export const checkPatientEmail = (params) => {
  return api({
    method: "GET",
    url: "/patient/checkPatientEmail",
    params: params,
  });
};

export const createPatient = (data) => {
  return api({
    method: "POST",
    url: "/patient/createPatient",
    data: data,
  });
};

export const savePrescriptionAPI = (data) => {
  return api({
    method: "POST",
    url: "/clinic/prescription/add",
    data,
  });
};

export const uploadScanFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/add",
    data,
  });
};

export const confirmScanFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/upload/confirm",
    data,
  });
};

export const deleteScanFile = (data) => {
  return api({
    method: "POST",
    url: "patient/resource/delete",
    data,
  });
};

export const downloadS3File = (fileName) => {
  return baseUrl + "/clinic/progress-image/download/" + fileName;
};


export const getPatientCountryCodesService = () => {
  return api({
    method: "GET",
    url: "/patient/getAllCountries",
  });
};

export const addPatientAddressApi = (data) => {
  return api({
    method: "PUT",
    url: "/patient/editPatientAddress",
    data,
  });
};

export const getPatientDetails = (params) => {
  return api({
    method: "GET",
    url: "/lab/lab/patientlist/patientinfo",
    params: params,
  });
};

export const patientResourceImageConfirmApi = (data) => {
  return api({
    method: "POST",
    url: "/clinic/patient/resource/upload/confirm",
    data,
  });
};


export const patientResourceDeleteApi = (data) => {
  return api({
    method: "DELETE",
    url: "/clinic/patient/deleteResource",
    data,
  });
};