import { useState, useEffect, useRef } from "react";
import { Form, Modal, Button, message, Typography, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import AddResourceForm from "../ResourceForm/ResourceForm";
import { saveResource } from "api/resource";

const { Title } = Typography;
const { confirm } = Modal;

const AddResourceModal = ({
  showAddResourceModal,
  setShowAddResourceModal,
  lab,
  resources,
  SetResources,
}) => {
  const [addResourceForm] = Form.useForm();
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resourceFileList, setResourceFileList] = useState([]);

  const resourcesInitialMapper = (links) => {
    return {
      videoLinks: [{ resourceUrl: "" }],
    };
  };

  useEffect(() => {
    const formData = resourcesInitialMapper([]);
    addResourceForm.setFieldsValue(formData);
  }, []);

  const onResourceSubmit = async (values) => {
    const allUrls = values.videoLinks.map(obj=> obj.resourceUrl ? obj.resourceUrl : "")
    values.videoLinks = values.videoLinks.filter(obj=>obj.resourceUrl)
    if(!values.videoLinks.length &&  !resourceFileList.length){
      return message.error("Please add atleast one resource")
     }
  
    const hasEmptyString = allUrls.some(str => str === "");
    if (hasEmptyString && allUrls.length >1) {
      return message.error("Resource link can not be empty")
    }
    const isDuplicate = allUrls.length !== new Set(allUrls).size;
    if (isDuplicate && allUrls.length >1) {
      return message.error("Duplicate resource link")
    }   

    // console.log("resourceFileList", resourceFileList);
    setLoading(true);
    
    try {
      const formData = new FormData();
      console.log("resourceFileList",resourceFileList);
      for (let i = 0; i < resourceFileList.length; i++) {
        formData.append("files", resourceFileList[i]);
      }
      formData.append("name", values.name);
      formData.append("labId", lab.labId);
      formData.append("category", values.category);
      formData.append("description", values.description ? values.description : "");
     
      formData.append(
        "videoLinks",
        values.videoLinks.length ? JSON.stringify(values.videoLinks) : ""
      );
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const {data} = await saveResource(formData, config);
      const {id, name,shared,category,createdAt,description, files,allClinic} = data?.data
      const newAdded = {
        name: { resourceName: name, resourceId: id },
        shareList: {
          clinics: shared,
          resourceId: id,
          allClinic: allClinic,
        },
        category: category,
        uploaded: createdAt.split("T")[0],
        files: files.length ? files.filter((file) => file.mimeType !== "link") : [],
        id: id,
        description: description ? description: "",
        videoLinks: files.filter((file) => file.mimeType === "link"),
      }
       const prevResources = [...resources];
       prevResources.unshift(newAdded);
       SetResources(prevResources);
     message.success("Resource added successfully");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setShowAddResourceModal(false);
      // window.location.reload();
    }
  };

  const handleSubmit = () => {
    addResourceForm.submit();
  };

  const handleAddResourceCancel = () => {
    if (touched) {
      showConfirm();
    } else {
      setShowAddResourceModal(false);
      setResourceFileList([]);
    }
  };

  const showConfirm = () => {
    confirm({
      title: "Close add resource form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        setShowAddResourceModal(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  return (
    <div>
      <Modal
        className="addResourceModal"
        title={
          <div className="titleSpan">
            <Title level={5} style={{ margin: "0px" }}>
              Add New Resource
            </Title>
          </div>
        }
        width={800}
        open={showAddResourceModal}
        onCancel={handleAddResourceCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleAddResourceCancel}
            shape="round"
            style={{ width: "7rem", borderRadius: "30px" }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={handleSubmit}
            loading={loading}
            style={{ width: "7rem", borderRadius: "30px" }}
          >
            Save
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <AddResourceForm
            addResourceForm={addResourceForm}
            onResourceSubmit={onResourceSubmit}
            touched={touched}
            setTouched={setTouched}
            resourceFileList={resourceFileList}
            setResourceFileList={setResourceFileList}
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default AddResourceModal;
