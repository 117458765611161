import dayjs from "dayjs";

const swappedObject = {
  upper: "Upper",
  lower: "Lower",
  "Upper and Lower": "Upper and Lower",
  retainer: "Retainer",
  whitening: "Whitening tray",
  retainer_and_whitening: "Retainer And Whitening",
  no: "No Retainer And Whitening Tray",
  comment: "Comment",
  maintain: "Maintain",
  improve: "Improve",
  "as needed": "As Needed",
  anterior: "Anterior",
  posterior: "Posterior",
  yes: "Yes",
  gingival: "Givingal",
  incisal: "Incisal",
  expanding: "Expanding",
  proclining: "Proclining",
  between_centrals: "Between centrals",
  distal_to_laterals: "Distal to Laterals",
  distal_to_canines: "Distal to Canines",
  equally_around_laterals: "Equally around Laterals",
  thin: "Thin",
  thick: "Thick",
  normal: "Normal",
  left: "Left",
  right: "Right",
  both: "Both",
  class_one: "Class 1",
  class_two: "Class 2",
  class_three: "Class 3",
  division_one: "Division 1",
  division_two: "Division 2",
  division_three: "Division 3",
};

export function removeUnderscore(text) {
  if (!text || text === "") return "--";

  let objText;
  objText = swappedObject[text];
  
  if (objText) {
    return objText;
  } else {
    if (text === "Inlay_Onlay") {
      return "Inlay / Onlay";
    }
    const withoutUnderscore = text.replace(/_/g, " ");
    const capitalizedText = withoutUnderscore.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    return capitalizedText;
  }
}


export const findMaxPrescriptionVersion = (versions) => {
  if (versions && versions.length) {
    let maxVersion = Number(versions[0].value);
    versions.forEach((el) => {
      if (Number(el.value) > maxVersion) {
        maxVersion = Number(el.value);
      }
    });
    return maxVersion;
  }
  return 1;
};

export const prescriptionDataMapper = (formData, isDraft = false) => {
  //  console.log("prescriptionDataMapperprescriptionDataMapper", formData);
  return {
    treatmentTypes: formData?.treatmentTypes?.map((el) => {
      const aligner = {
        aligner: removeUnderscore(el.aligner),
        arches: removeUnderscore(el.arch_treated),
        whitening: removeUnderscore(el.retainer_and_whitening),
        whitening_arches: removeUnderscore(el.retainer_and_whitening_type),
        comment: el.retainer_and_whitening_comment,
      };
      return aligner;
    }),
    prescriptionPhasing: formData?.prescription_phasing,
    prescription_id: formData?.prescription_id,
    id: formData?.id,
    
    teethChart: formData?.teethChart || [],
    ipr: formData?.prescription_is_ipr ? formData?.prescription_is_ipr : "--",
    overjet: removeUnderscore(formData?.overjet) || "",
    overbite: removeUnderscore(formData?.overbite) || "",
    alignment: removeUnderscore(formData?.incisal_edge_alignment),
    bioType: formData?.periodontium_biotype ?formData?.periodontium_biotype : "--",
    recession: formData?.periodontium_recession_miller ? formData?.periodontium_recession_miller :"--",
    dentalClass: removeUnderscore(formData?.angle_dental_class),
    skeletalClass: removeUnderscore(formData?.angle_skeleton_class),
    skeletalDivision:  removeUnderscore(formData?.angle_skeleton_division),
    skeletalSide:  removeUnderscore(formData?.angle_skeleton_side),
    dentalDivision:  removeUnderscore(formData?.angle_dental_division),
    dentalSide:  removeUnderscore(formData?.angle_dental_side),
    parafunctionDetails: formData?.occlusion_angle_deviation ? formData.occlusion_angle_deviation : "--" ,
    midline: removeUnderscore(formData?.prescription_is_midline_correction) || "",
    duration: formData?.prescription_aligner_duration,
    prescriptionDetails: formData?.prescription_details || "--",
    labId: formData?.prescription_lab_id,
    upperArchUrl: formData?.prescription_upper_arch_file_url || "",
    lowerArchUrl: formData?.prescription_lower_arch_file_url || "",
    biteScanUrl: formData?.prescription_bite_scans_file_url || "",
    crowding: removeUnderscore(formData?.prescription_complaint_crowding_upper) || "",
    spacing: removeUnderscore(formData?.prescription_complaint_spacing_upper) || "",
    openbite: removeUnderscore(formData?.prescription_complaint_open_bite_anterior) || "",
    crossbite: removeUnderscore(formData?.prescription_complaint_cross_bite_anterior) || "",
    others: formData?.prescription_complaint_others || "",
    attachments: formData?.prescription_attachments_upper_lower|| "",
    prescriptionVersions: formData?.prescriptionVersions,
    version: formData?.prescription_version,
    openBiteValue: removeUnderscore(formData?.openBiteValue),
    crossBiteValue: removeUnderscore(formData?.crossBiteValue),
    caseId: formData?.case_id,
    editedDate: formData?.prescription_edited_date,
    phasing:formData?.phasing,
    labProductId:formData?.labProductId,
    smilelignTreatmentTypeId:formData?.smilelignTreatmentTypeId || null,
    smilelignArches: formData?.smilelignArches || "",
    smilelignWhitening: formData?.smilelignWhitening || null,
    smilelignTreatmentTypeData: formData?.smilelignTreatmentTypeData || null,
    createdAt : formData?.createdAt
  };
};
