import { Select, Button, Row, Col, Input, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { showTeethName } from "components/shared/TeethChart/data";
import { getAlignerNumberBackground} from "utils/ipr";
const { Option } = Select;

const AttachmentInfo = ({
  setUpperAttachments,
  attachmentsArray,
  attachmentType,
  setLowerAttachments,
  teethStructure,
  setTeethStructure,
  chartValue,
  attachmentProductList,
  setAttachmentProductList,
  updateAlignersErrorsArray
}) => {
  const handleAttachmentDelete = (index, teeth, valueIndex) => {
    let newData = [];
    newData = [...attachmentsArray];

    const emptyObj = {
      detail: "",
      attachmentType: "",
      teeth: null,
      value: false,
    };

    newData[index]["alignerValues"][valueIndex] = emptyObj;
    if (attachmentType === "upper") {
      setUpperAttachments(newData);
      updateAlignersErrorsArray('upperAlignersNo')
    } else {
      setLowerAttachments(newData);
      updateAlignersErrorsArray('lowerAlignersNo')
    }

    const structure = [...teethStructure];
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === Number(teeth)) {
        return {
          ...tooth,
          selected: false,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);
  };

  const handleAttachmentDetailChange = (index, value, valueIndex) => {
    let newData = [];
    newData = [...attachmentsArray];

    newData[index]["alignerValues"][valueIndex]["detail"] = value;

    if (attachmentType === "upper") {
      setUpperAttachments(newData);
    } else {
      setLowerAttachments(newData);
    }
  };

  const handleAttachmentTypeChange = (index, value, valueIndex) => {
    let newData = [];
    newData = [...attachmentsArray];
    newData[index]["alignerValues"][valueIndex]["attachmentType"] = value;
    if (attachmentType === "upper") {
      setUpperAttachments(newData);
    } else {
      setLowerAttachments(newData);
    }
  };
  return (
    <div className="attachment-values-container">
      {attachmentsArray?.map((attachment, attachmentIndex) => {
        return (
          <div key={attachmentIndex}>
            {attachment.alignerValues.map((attachmentValue, index) => {
              if (attachmentValue.value) {
                return (
                  <div key={index}>
                    <Row
                      gutter={[16, 16]}
                      align="middle"
                      style={{
                        marginTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col lg={3} sm={24}>
                        <p
                          style={{
                            background: `${getAlignerNumberBackground(
                              attachment.aligner_count
                            )} `,
                          }}
                          className={`toothName`}
                        >
                          {/* {attachmentValue.teeth} */}
                          {chartValue === "fdi"
                            ? attachmentValue.teeth
                            : showTeethName(attachmentValue.teeth, chartValue)}
                        </p>
                      </Col>
                      <Col lg={8}>
                        <Select
                          size="large"
                          value={attachmentValue.attachmentType}
                          onChange={(value) =>
                            handleAttachmentTypeChange(
                              attachmentIndex,
                              value,
                              index
                            )
                          }
                          options={attachmentProductList?.map((item, i) => {
                            return {
                              value: item.attachmentName,
                              label: (
                                <>
                                  <div className="lab-product-selectbox-item">
                                    {item.attachmentName}
                                  </div>
                                </>
                              ),
                            };
                          })}
                        >
                          <Option value="button">Button</Option>
                          <Option value="hook">Hook</Option>
                          <Option value="bracket">Bracket</Option>
                        </Select>
                      </Col>
                      <Col lg={11}>
                        <Input
                          placeholder="Detail"
                          size="large"
                          value={attachmentValue.detail}
                          onChange={(e) =>
                            handleAttachmentDetailChange(
                              attachmentIndex,
                              e.target.value,
                              index
                            )
                          }
                        />
                      </Col>
                      <Col lg={2}>
                        <Popconfirm
                          title="Delete"
                          description="Are you sure to delete this session?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() =>
                            handleAttachmentDelete(
                              attachmentIndex,
                              attachmentValue.teeth,
                              index
                            )
                          }
                        >
                          <Button
                            style={{ marginLeft: "23px" }}
                            className="delete-btn"
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return <div key={index}></div>;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentInfo;
