import {useState, useEffect} from "react"
import {  Spin } from "antd";

import "./prescriptionPDF.scss";
import PrescriptionHeader from "./Components/PrescriptionHeader";
import PatientInfo from "./Components/PatientInfo";
import PrescriptionTreatmentTypes from "./Components/PrescriptionTreatmentTypes";
import PrescriptionAttachments from "./Components/PrescriptionAttachments";
import PrescriptionAlignment from "./Components/PrescriptionAlignment";
import PrescriptionDuration from "./Components/PrescriptionDuration";
import PrescriptionOcclusion from "./Components/PrescriptionOcclusion";
import PrescriptionPeri from "./Components/PrescriptionPeri";
import PrescriptionToothChart from "./Components/PrescriptionToothChart";

import PrescriptionInstructions from "./Components/PrescriptionInstructions";
import { getPreliminaryImagesApi } from "api/prescription";
import PatientImages from "./Components/PatientImages";
import { companyType } from "colors-config";
import SmilePrescriptionTreatmentTypes from "./Components/SmilePrescriptionTreatmentTypes";
import { renderTreatmentTypes } from "utils/commonMethod";
// import { prescriptionPDFDataMapper } from "utils/prescriptionPDF";

const PrescriptionPDF = ({ prescriptionForm, chartValue, patientInfo, selectedVersion, patientId, treatmentId, clinicInfo, treatmentOptions }) => {
  const [prescriptionPDFObj, setPrescriptionObj] = useState({})
  const [loading,setLoading] = useState(false)
  const [preliminaryImages,setPreliminaryImages] = useState([])
  const [toothStatuses,setToothStatuses] = useState([])

  useEffect(() => {
    if (prescriptionForm) {
      // console.log("clinicInfoclinicInfoclinicInfo", clinicInfo)
      // console.log("prescriptionFormprescriptionFormprescriptionForm", prescriptionForm)

      if (prescriptionForm?.teethChart?.length > 0) {
        const mergedData = prescriptionForm?.teethChart.reduce((acc, currentItem) => {
          const existingItem = acc.find(
            (item) => item.tooth_no === currentItem.tooth_no
          );
          if (existingItem) {
            if (currentItem.isAdditional) {
              existingItem.addOns.push({
                ...currentItem,
                procedure: currentItem.toothProcedure,
              });
            } else {
              existingItem = { ...existingItem, ...currentItem, addOns: [] };
            }
          } else {

            acc.push({
              ...currentItem,
              procedure: currentItem.toothProcedure,
              toothNo: currentItem.tooth_no,
              addOns: currentItem.isAdditional
                ? [{ ...currentItem, procedure: currentItem.toothProcedure }]
                : [],
            });
          }

          return acc;
        }, []);
        //  console.log("mergedDatamergedDatamergedData", mergedData);
        setToothStatuses(mergedData);
      }
      setPrescriptionObj(prescriptionForm)
    }

  }, [prescriptionForm])

  useEffect(() => {
    (async() =>{
      try {
        setLoading(true)
        const request ={ 
            patientId,
            patientStage: "preliminary",
            alignerId:  100,
            labTreatmentId: treatmentId ? treatmentId : "",
          
        }
        const {data} = await getPreliminaryImagesApi(request)
        setPreliminaryImages(data?.body?.results?.patient_onboarding_images)
       } catch (error) {
        
       }
       finally{
        setLoading(false)
       }
    })()

  }, [treatmentId])
  
  // console.log("prescriptionForm", prescriptionForm, teethChartData);
  return (
    <div className="prescriptionPDF-container">
    <Spin spinning={loading}>
    <PrescriptionHeader prescriptionPDFObj={prescriptionPDFObj} selectedVersion={selectedVersion}/>
      <PatientInfo patientInfo={patientInfo} clinicInfo={clinicInfo}/>
      <PatientImages preliminaryImages={preliminaryImages}/>
      { 
      renderTreatmentTypes(companyType,prescriptionPDFObj?.createdAt).show ? <SmilePrescriptionTreatmentTypes  prescriptionPDFObj={prescriptionPDFObj} treatmentOptions={treatmentOptions}/> :  <PrescriptionTreatmentTypes prescriptionPDFObj={prescriptionPDFObj}/>
      }
     
      <PrescriptionInstructions prescriptionPDFObj={prescriptionPDFObj}/>
      <PrescriptionAttachments prescriptionPDFObj={prescriptionPDFObj}/>
      <PrescriptionAlignment prescriptionPDFObj={prescriptionPDFObj}/>
      <PrescriptionDuration prescriptionPDFObj={prescriptionPDFObj}/>
      <PrescriptionToothChart toothStatuses={toothStatuses} chartValue={chartValue}/>
      <PrescriptionOcclusion prescriptionPDFObj={prescriptionPDFObj}/>
      <PrescriptionPeri prescriptionPDFObj={prescriptionPDFObj}/>
    </Spin>

    </div>
  );
};

export default PrescriptionPDF;
