import { useState } from "react";
import { Row, Col, Modal, Divider, Radio } from "antd";
import { useRef } from "react";
import TeethType from "../../TeethType/TeethType";
import IprSessionList from "../IprSessionList/IprSessionList";
import IPRInfo from "../../IPRInfo/IPRInfo";
import AttachmentsInfo from "../../IPRInfo/AttachmentsInfo";
import FileList from ".././FileList/FileList";

// import AttachmentInfo from "../../AttachmentInfo";

import "./iprmodal.scss"

import AttachmentRow from "../AttachmentDetail/AttachmentRow";

import { companyType } from "colors-config";
import { checkTextEditorContent } from "utils/Lab";
import AttachmentShowInfo from "../AttachmentDetail/AttachmentShowInfo";


const IprModal = ({
  alignersLabel,
  chartValue,
  handleStructureChange,
  treatment,
  openIprGuideModal,
  setOpenIprGuideModal,
  lowerAligners,
  upperAligners,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
  attachmentsLabel,
  onAttachmentsLabelClicked,
  treatmentPlansState,
}) => {
  const [value, setValue] = useState("ipr");
  const modalRef = useRef();
  // console.log("treatment", treatment);
  const handleCancel = () => {
    setOpenIprGuideModal(false);
  };

  const detailOptions = [
    {
      label: "IPR",
      value: "ipr",
    },
    {
      label: "Attachment",
      value: "attachment",
    },
  ];

  const onChange4 = ({ target: { value } }) => {
    setValue(value);
  };

  const showAdditionalDetails = (additionalDetails = "") => {
    const hasContent = checkTextEditorContent(additionalDetails);

    return (
      <div>
        <Row>
          <p className="patient-question">Additional Details</p>
        </Row>
        <Row>
          <div
            className={`patient-response ${hasContent ? "detail-container" : ""}`}
            style={{ fontWeight: "400", textAlign: "justify" }}
          >
            {hasContent ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: additionalDetails,
                }}
              />
            ) : (
              "--"
            )}
          </div>
        </Row>
      </div>
    );
  };


  return (
    <Modal open={true} title="Preview" onCancel={handleCancel} footer={null} width={850}>
      <div
        className="ipr-modal-container aligner-component"
        // style={{ padding: "0 20px" }}
        ref={modalRef}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Radio.Group
            className="heading-radio"
            options={detailOptions}
            onChange={onChange4}
            value={value}
            optionType="button"
            buttonStyle="solid"
          />
          {/* <Row align="end" style={{ paddingBottom: "10px" }}>
            <ReactToPrint
              trigger={() => {
                return (
                  <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "flex-end",
                  //   marginRight: "10px",
                  //   marginBottom: "10px",
                  //   marginTop: "-20px",
                  // }}
                  >
                    <span
                      style={{
                        color: primaryColor,
                        cursor: "pointer",
                      }}
                    >
                      Download
                    </span>
                  </div>
                );
              }}
              content={() => modalRef.current}
            />
          </Row> */}
        </div>

        {companyType === 5 && (
          <div>
            <Row>
              <p className="patient-question">Phasing</p>
            </Row>
            <Row>
              <p
                className="patient-response"
                style={{
                  fontWeight: "400",
                  marginBottom: 0,
                  textAlign: "justify",
                }}
              >
                {treatment?.phasing.trim() ? treatment.phasing : "--"}
              </p>
            </Row>
          </div>
        )}

        {showAdditionalDetails(treatment?.additionalDetails)}

        {value == "ipr" ? (
          <>
            <div>
              {/* <p className="patient-question" style={{ textAlign: "left" }}>
                Treatment Guide PDF
              </p>
              <FileList
                iprFile={treatment?.iprDocument}
                // iprFile={treatmentPlansState?.body?.treatmentFile}
              /> */}
              <br/>
            </div>
            <Row align="top">
              <Col lg={10}>
                <div>
                  <Row>
                    <p className="patient-question" style={{ marginTop: "0px" }}>
                      No. of IPR sessions
                    </p>
                  </Row>
                  <Row>
                    <p className="patient-response">{alignersLabel.length}</p>
                  </Row>
                </div>
              </Col>
              <Col lg={14} className='ipr-session-list-labels'>
                <IprSessionList
                  alignersLabel={alignersLabel}
                  onAlignerLabelClicked={onAlignerLabelClicked}
                />
              </Col>
            </Row>
            <div className="chart-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <h1>Treatment Guide</h1> */}
              </div>
              <TeethType teethTypeName={chartValue} onTeethTypeChange={handleStructureChange} />
            </div>
            <Divider />
            <IPRInfo
              lowerAligners={lowerAligners}
              upperAligners={upperAligners}
              chartValue={chartValue}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
            />
          </>
        ) : (
          <>
            <div className="chart-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <h1>Treatment Guide</h1> */}
              </div>
              <TeethType teethTypeName={chartValue} onTeethTypeChange={handleStructureChange} />
            </div>
            <Divider />
            <AttachmentShowInfo
              alignersLabel={attachmentsLabel}
              onAlignerLabelClicked={onAttachmentsLabelClicked}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
            />
            <AttachmentsInfo
              lowerAligners={lowerAligners}
              upperAligners={upperAligners}
              chartValue={chartValue}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
            />

            <AttachmentRow
              alignersLabel={attachmentsLabel}
              onAlignerLabelClicked={onAttachmentsLabelClicked}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
              chartValue={chartValue}
            />
          </>
        )}

       
      </div>
    </Modal>
  );
};

export default IprModal;
