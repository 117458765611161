import { useState } from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import TextEditor from "components/shared/TextEditor/TextEditor";
import SendChat from "components/shared/Icon/SendChat";
import ChatInactive from "components/shared/Icon/ChatInactive";
import ChatActive from "components/shared/Icon/ChatActive";
import styles from "./editor.module.scss";

const ActivityEditor = ({ editorInput, setEditorInput, sendHandler, staffMembers }) => {
  const [hideToolbar, setHideToolBar] = useState(true);

  const handleToolBar = () => {
    setHideToolBar((prev) => !prev);
  };

  const Icon = hideToolbar ? ChatInactive : ChatActive;

  const users = staffMembers.map((el) => ({ id: el.id, value: el.label }));

  return (
    <div className={styles["comment-editor"]}>
      <div>
        <Avatar icon={<UserOutlined />} />
      </div>

      <TextEditor
        placeholder="Leave your comment here"
        editorInput={editorInput}
        setEditorInput={setEditorInput}
        hideToolbar={hideToolbar}
        mentionsEnabled={true}
        atValues={users}
      />
      <div className={styles["send-icons"]}>
        <div onClick={handleToolBar}>
          <Icon style={{ fontSize: "1.5rem" }} />
        </div>
        <div onClick={sendHandler}>
          <SendChat style={{ marginLeft: "10px", fontSize: "1.5rem" }} />
        </div>
      </div>
    </div>
  );
};

export default ActivityEditor;
