import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  Dropdown,
  DatePicker,
  Menu,
  Spin,
  Card,
  message
} from "antd";
import DashboardHeader from "components/private/DashboardComponents/Header.jsx";
import { connect } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { loadLab } from "services/redux/actions/labActions";
import { ReactComponent as FilterIcon } from "assets/images/FilterIcon.svg";
import { ReactComponent as DateFilter } from "assets/images/DateFilter.svg";
import dayjs from "dayjs";
import styles from "assets/styles/routes/dashboard.module.scss";
import StageChart from "components/private/DashboardComponents/StageChart";
import TaskTime from "components/private/DashboardComponents/TaskTime";
import { getDashboardAnalytics } from "api/lab";
import { formatDateRange, mapDashboardData } from "utils/Lab";
import OrdersTime from "components/private/DashboardComponents/OrdersTime";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Dashboard = ({ lab }) => {
  const [visible, setVisible] = useState(false);
  const [dates, setDates] = useState(() => {
    const storedDates = localStorage.getItem("dashboardFilter");
    return storedDates
      ? JSON.parse(storedDates).map((date) => dayjs(date))
      : null;
  });
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [applyFilter, setApplyFilter] = useState(false);

  useEffect(() => {
    if (lab.labId) {
      (async () => {
        try {
          let startDate;
          let endDate;
          setLoading(true);
          if (dates) {
            startDate = dayjs(dates[0]).format("YYYY-MM-DD");
            endDate = dayjs(dates[1]).format("YYYY-MM-DD");
            setApplyFilter(true)
          }

          const response = await getDashboardAnalytics(lab.labId, {
            startDate,
            endDate,
          });
          const finalResponse = mapDashboardData(response.data);
          //  console.log("finalResponsefinalResponse", finalResponse)
          setDashboardData(finalResponse);
        } catch (error) {
          console.log(error);
        } finally {
          setVisible(false);
          setLoading(false);
        }
      })();
    }
  }, [lab, applyFilter]);

  const handleRangeChange = (dates, dateStrings) => {
    localStorage.setItem("dashboardFilter", JSON.stringify(dates));
    setDates(dates);
  };

  const handleMenuClick = () => {
    setVisible(!visible);
  };

  const handleDateFilter = () => {
    if (!dates) {
    return  message.error("Please select date range")
    }
    setApplyFilter(!applyFilter);
    setVisible(!visible);
  };

  const handleReset = () => {
    setDates(null);
    setApplyFilter(false);
    localStorage.removeItem("dashboardFilter");
    // setVisible(!visible);
  };

  const disabledDate = (current) => {
    // Disable dates from today and onwards
    return current && current >= dayjs().endOf("day");
  };

  const menu = (
    <Menu className="conflict-menu">
      <Menu.Item key="1">
        <RangePicker
          format="DD-MM-YYYY"
          onChange={handleRangeChange}
          value={dates}
          disabledDate={disabledDate}
        />
        <div className={styles.buttons}>
          <Button
            shape="round"
            onClick={handleReset}
            className={styles.applyBtn}
          >
            Reset
          </Button>
          <Button
            shape="round"
            type="primary"
            className={styles.applyBtn}
            onClick={handleDateFilter}
          >
            Apply
          </Button>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.dashboardContainer}>
      <Spin spinning={loading}>
        <Row style={{ justifyContent: "space-between" }} align="middle">
          <Col style={{ textAlign: "start" }}>
            <Title level={3}>Dashboard</Title>
          </Col>
          <Col style={{ maxWidth: "35.5%" }}>
            <div className={styles.datesContainer}>
              {dates && applyFilter && (
                <div className={styles.dates}>
                  <span className={styles.dateSpan}>
                  {formatDateRange(dates[0], dates[1])}
                  </span>
                  <CloseOutlined
                    style={{ marginLeft: "5px" }}
                    onClick={handleReset}
                  />{" "}
                </div>
              )}
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                visible={visible}
                onVisibleChange={(flag) => setVisible(flag)}
              >
                <span className={styles.filterText} onClick={handleMenuClick}>
                  Filter by date {dates ? <DateFilter /> : <FilterIcon />}
                </span>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <DashboardHeader clinicData={dashboardData?.clinicData} />

        <Row gutter={[24, 24]} style={{ marginTop: "1rem" }}>
          <Col span={18} style={{ display: "flex" }}>
            <Card style={{ flex: 1 }} hoverable>
              <StageChart
                patientStageData={dashboardData?.patientStageData}
                patientStageArray={dashboardData?.patientStageArray}
              />
            </Card>
          </Col>
          <Col span={6} style={{ display: "flex" , flexDirection:"column"}}>
            <OrdersTime  orderData={dashboardData?.orders}/>
            <TaskTime
             treatmentTaskTimeData={dashboardData?.treatmentTaskTimeData}
            />
          
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps, { loadLab })(Dashboard);
