import React, { useState, useEffect } from "react";
import {
  Radio,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Select,
  Image,
  Card,
} from "antd";
import {
  companyType,
  primaryColor,
} from "colors-config";
import { labApiTasks } from "../../../models/lab";
import {DownloadOutlined} from '@ant-design/icons';
import { formatImageView, sortImagesByOrder } from "utils/progressImages";

const { Option } = Select;

function ProgressImageComponent({
  computedMatch,
  imageStageTypes,
  alignerStageDet,
  createImageStageTypes,
  labId,
  treatmentId,
  patientId,
  labType,
}) {
  const [selectedImageType, setSelectedImageType] = useState("");
  const [defaultImageType, setDefaultImageType] = useState(null);
  const [alignersList, setAlignersList] = useState();
  const [stageId, setStageId] = useState();
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState("No Progress Images Available to Show");
  const [urlCheck, setUrlCheck] = useState(true);
  const urlCount = Object.keys(imageStageTypes);
  useEffect(() => {
    const imageStageTypeKeys = Object.keys(imageStageTypes);
    renderAlignersList(alignerStageDet);
    if (imageStageTypeKeys.length) {
      // setUrlCheck(true);
      setDefaultImageType(imageStageTypeKeys[0]);
      const imagesData = sortImagesByOrder(imageStageTypes[imageStageTypeKeys[0]])
      createImages(imagesData);
    }
  }, [imageStageTypes]);

  useEffect(() => {
   
  if(labType!==1){
    alignerStageDet&&  _getAlignerImages((alignerStageDet.current_stage !==1 && alignerStageDet.current_stage !== 100)?alignerStageDet.current_stage-1:100)}

},[treatmentId])
async function downloadImage(imageSrc,name) {
  const image = await fetch(imageSrc)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.crossOrigin = "Anonymous";
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

  const createImages = (images) => {
    setImages(
      images.map((image) => {
        var withOutAccessKeyImg = image.imageUrl.indexOf("?");
        var processedURl = image.imageUrl.slice(0, withOutAccessKeyImg);
        return (
          <div
            className={
              companyType === 5
                ? "image-Card smileAlign-imageCard"
                : "image-Card"
            }
          >
            <p>
              <DownloadOutlined
                onClick={() =>
                  downloadImage(
                    image.imageUrl,
                   formatImageView(image?.imageViewType)
                  )
                }
                style={{
                  color: primaryColor,
                  marginRight: "1rem",
                  marginTop: "0.2rem",
                }}
              />
              {formatImageView(image?.imageViewType)}
            </p>

            <Image width={250} height={200} src={processedURl} />
          </div>
        );
      })
    );
  };
  const _getAlignerImages = async (info) => {
    try {
      const stageRes = await labApiTasks.getAll(
        "lab/patient/resource/getOnboardingImages",
        {
          patientId,
          patientStage: info === 100 ? "preliminary" : "aligner",
          alignerId: info,
          labTreatmentId: treatmentId ? treatmentId : "",
          labId,
        }
      );
      const imageDetails = stageRes.body.results.patient_onboarding_images;
      createImageStageTypes(imageDetails);
      stageRes.body.results.patient_onboarding_images.length > 0
        ? setUrlCheck(true)
        : setUrlCheck(false);
    } catch (error) {
      //   setIsProgressImagesSpinning(false);
      console.log(error);
    }
  };

  const onSelectImageStageTypeChange = (info) => {
    setStageId(info);
    setImages([]);
    _getAlignerImages(info);
  };
  const renderAlignersList = (list) => {
    const defaultAligner =
      list && list.current_stage ? list.current_stage : 100;
      console.log("LAB TYPE",labType)
      const lastAlignerStage=list &&list.last_aligner_image_uploaded;

    list &&
      list.patient_image_upload_stages &&
      setAlignersList(
        <Select
          style={{ width: 175 }}
          onChange={(info) => onSelectImageStageTypeChange(info)}
          defaultValue={
            lastAlignerStage===true?defaultAligner === 100
                    ? 100
                    : defaultAligner :(defaultAligner === 100
                        ? 100
                        : defaultAligner > 1
                        ? defaultAligner - 1
                        : 100) 
          }
        >
          
          {list.patient_image_upload_stages &&
            list.patient_image_upload_stages.map((item) => {
              return (
                <Option
                  key={item.patient_image_uploaded_stage_id}
                  value={item.patient_image_uploaded_stage_id}
                >
                 {item.patient_image_uploaded_stage_name}
                </Option>
              );
            })}
        </Select>
      );
  };
  const select = (
    <Select
      style={{ width: 175 }}
      onChange={onSelectImageStageTypeChange}
      defaultValue={defaultImageType}
    >
      {Object.keys(imageStageTypes).map((imageStageTypeId) => {
        return (
          <Option key={imageStageTypeId} value={imageStageTypeId}>
            {imageStageTypes[imageStageTypeId][0].imageStageName}
          </Option>
        );
      })}
    </Select>
  );

  return (
    <div>
      <Card
        title="Progress Images"
        // extra={defaultImageType && select}
        extra={alignersList}
        style={{
          minWidth: "45rem",
          maxWidth: "45%",
          textAlign: "left",
          boxShadow: "0px 10px 16px rgba(0, 0, 0, 0.02)",
          borderRadius: "8px",
        }}
      >
        <div
          className="progress-images-container"
        >
      
          {Object.keys(imageStageTypes).length
            ? urlCheck
              ? images
              : message
            : message}
        </div>
      </Card>
    </div>
  );
}

export default ProgressImageComponent;
