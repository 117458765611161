import { useState, useEffect } from "react";
import { Row, Button, Checkbox, Col, Input, List } from "antd";

import styles from "./filter.module.scss";
const { Search } = Input;

const ClinicNamesList = ({
  clearFilters,
  confirm,
  tableData,
  setPatientsFilterData,
  patientsFilterData,
  setTotalRecords
}) => {
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [clinicNames, setClinicNames] = useState([]);
  const [filteredClinicNames, setFilteredClinicNames] = useState([]);
  const [allChecked, setAllChecked] = useState(true);

  useEffect(() => {
    const names = [...new Set(tableData?.map((data) => data?.clinicianDetails?.clinicName))].sort();
    setClinicNames(names);
    setSelectedClinics(names);
    setFilteredClinicNames(names);
  }, [tableData]);

  const onSearch = (e) => {
    const { value } = e.target;
    const filteredNames = clinicNames.filter((clinic) =>
      clinic.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClinicNames(filteredNames);
  };

  const handleCheckboxChange = (clinicName) => {
    const updatedSelectedClinics = selectedClinics.includes(clinicName)
      ? selectedClinics.filter((name) => name !== clinicName)
      : [...selectedClinics, clinicName];
    if (updatedSelectedClinics.length === clinicNames.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setSelectedClinics(updatedSelectedClinics);
  };

  const handleFilter = (confirm) => {
    // console.log("filteredClinicNames", filteredClinicNames)
    // console.log("Selected Clinics:", selectedClinics);
    const patientsData = [...tableData];
    // console.log("patientsData", patientsData)
    const filteredClinics = patientsData.filter((patient) =>
      selectedClinics.includes(patient?.clinicianDetails?.clinicName)
    );
    // console.log("Filtered Data:", filteredClinics);
    setPatientsFilterData(filteredClinics);
    setTotalRecords(filteredClinics.length)
    confirm({ closeDropdown: true });
    // Handle the selected clinics as needed
  };

  const handleAllCheck = (e) => {
    const value = e.target.checked;
    if (value) {
      setSelectedClinics(clinicNames);
    } else {
      setSelectedClinics([]);
    }
    setAllChecked(value);
  };

  return (
    <div className={styles.filterContainer}>
      <Row gutter={[12, 12]} align="middle">
        <Col lg={2}>
          <Checkbox checked={allChecked} onChange={handleAllCheck}></Checkbox>
        </Col>
        <Col lg={22}>
          <Search
            placeholder="Search Clinic Name"
            onChange={onSearch}
            className={styles.filterSearch}
          />
        </Col>
      </Row>
      <div className={styles.filterList} style={{overflowY : filteredClinicNames.length > 5 ? "scroll" :"hidden"}}>
        <List
          dataSource={filteredClinicNames}
          renderItem={(clinic) => (
            <List.Item>
              <Checkbox
                checked={selectedClinics.includes(clinic)}
                onChange={() => handleCheckboxChange(clinic)}
              >
                {clinic}
              </Checkbox>
            </List.Item>
          )}
        />
      </div>

      <Button
        onClick={() => handleFilter(confirm)}
        shape="round"
        type="primary"
        className={styles.applyBtn}
      >
        Apply
      </Button>
    </div>
  );
};

export default ClinicNamesList;
