import { Row, Col } from "antd";
import AttachmentSessionList from "./AttachmentSessionList";
import { showTeethName } from "../../Attachments/data";


const AttachmentShowInfo = ({
  alignersLabel,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
  chartValue,
}) => {


  return (
    <div className="attachmentInfo-container">
      <Row align="top">
        <Col lg={10}>
          <div>
            <Row>
              <p className="patient-question" style={{ marginTop: "0px" }}>
                No. of Attachment Sessions
              </p>
            </Row>
            <Row>
              <p className="patient-response">{alignersLabel.length}</p>
            </Row>
          </div>
        </Col>
        <Col lg={14}>
          <AttachmentSessionList
            alignersLabel={alignersLabel}
            onAlignerLabelClicked={onAlignerLabelClicked}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AttachmentShowInfo;
