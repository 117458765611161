import { smileAlignPhasingInfo, smileGeniusPhasingInfo } from "./messages";
import { companyType } from "colors-config";


export const getPhasingInfo = () =>{
    if (companyType === 5) {
     return smileAlignPhasingInfo   
    }
    return smileGeniusPhasingInfo
}

  export const SCAN_REQUIRED_MESSAGE = "Are you sure you want to submit prescription without scan files?"