import { ReactComponent as EmptyTable } from "assets/images/EmptyTable.svg";
import { ReactComponent as EmptyTableSmilelign } from "assets/images/EmptyTableSmilelign.svg";
import { ReactComponent as EmptyTableIAS } from "assets/images/EmptyTableIAS.svg";
import { companyType } from "colors-config";
const EmptyTableDesign = () => {
  return (
    <div>
      {companyType === 5 ?
        <EmptyTableSmilelign /> :
        companyType === 6 ? <EmptyTableIAS /> :
          <EmptyTable />
      }


    </div>
  );
};

export default EmptyTableDesign;
