import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Modal,
  Button,
  message,
  Typography,
  Spin,
  notification,
} from "antd";
import axios from "axios";
import {
  checkPatientEmail,
  confirmScanFileAPI,
  createPatient,
  savePrescriptionAPI,
  uploadScanFileAPI,
} from "api/patient";
import {
  addPatientMapper,
  findMaxPrescriptionVersion,
  getScanFileCheck,
} from "utils/Patient";
import ConfirmationModal from "components/shared/modals/confirmationModal";
import { UserAddOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import PatientPrescriptionForm from "../PrescriptionModal/PatientPrescriptionForm";
import AddPatientForm from "../AddPatientForm/AddPatientForm";
import ViewScanFilesComponent from "../PrescriptionModal/components/ViewScanFilesComponent/ViewScanFilesComponent";
import { getAssociatedClinics } from "api/clinic";
import { primaryColor } from "colors-config";
import { useSelector } from "react-redux";
import { getLabSelector } from "services/redux/selectors/common";
import { scanFiles } from "../../../constants";
import { labApiTasks } from "models/lab";
import { companyType } from "colors-config";
import { SCAN_REQUIRED_MESSAGE } from "messages/messageController";
import { handleScanFilesModal } from "utils/prescription";

const { Title } = Typography;
const { confirm } = Modal;

const AddPatientModal = ({
  showAddPatientsModal,
  setShowAddPatientsModal,
  editPrescription,
  patientData,
  scanUrls,
  formData,
  setSelectedVersion,
  prescriptionFormRef,
  setTreatmentPrescription,
  setScanUrls,
}) => {
  const lab = useSelector(getLabSelector);
  const { labId, labName } = lab;

  const addPatientRef = useRef(null);
  const teethChartRef = useRef(null);
  const [addPatientForm] = Form.useForm();
  const [prescriptionForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [associatedClinics, setAssociatedClinics] = useState([]);
  const [showPrescriptionForm, setShowPrescriptionForm] = useState(false);
  const [touched, setTouched] = useState(false);
  const [clinicalFiles, setClinicalFiles] = useState([]);
  const [clinicalFilesArray, setClinicalFilesArray] = useState([]);
  const [scanMessage,setScanMessage] = useState(SCAN_REQUIRED_MESSAGE)
  const [prescriptionConfirmation, setPrescriptionConfirmation] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (editPrescription) {
      addPatientRef.current = {
        firstName: patientData.patient_first_name,
        lastName: patientData.patient_last_name,
        clinicId: patientData.clinic_id,
      };
      if (formData) {
        if (formData?.teethChart?.length > 0) {
          // console.log("formData?.teethChart?", formData?.teethChart)

          const mergedData = formData?.teethChart.reduce((acc, currentItem) => {
            const existingItem = acc.find(
              (item) => item.tooth_no === currentItem.tooth_no
            );
            if (existingItem) {
              if (currentItem.isAdditional) {
                existingItem.addOns.push({
                  ...currentItem,
                  procedure: currentItem.toothProcedure,
                });
              } else {
                existingItem = { ...existingItem, ...currentItem, addOns: [] };
              }
            } else {
              acc.push({
                ...currentItem,
                procedure: currentItem.toothProcedure,
                toothNo: currentItem.tooth_no,
                addOns: currentItem.isAdditional
                  ? [{ ...currentItem, procedure: currentItem.toothProcedure }]
                  : [],
              });
            }

            return acc;
          }, []);

          // console.log("mergedData", mergedData);
          teethChartRef.current = mergedData;
          // setTeethChartData(mergedData);
        }
        prescriptionForm.setFieldsValue(formData);
      }
    }
     setShowPrescriptionForm(editPrescription);
    getAssociatedClinics({
      status: "Partnered",
      labId,
      limit: 10000,
      page: 1,
      search: "",
    })
      .then((res) => {
        setAssociatedClinics(res.data.body.data);
      })
      .catch((err) => console.log(err));
  }, [labId, editPrescription]);

  const prescriptionDataMapper = (
    values,
    teethChartData,
    patientId,
    clinicId
  ) => {
    return {
      ...values,
      teethChart: teethChartData,
      patientId,
      clinicId,
      notify:false
    };
  };

  const getScanFiles = async (patientId) => {
    // setIsProgressImagesSpinning(true);
    try {
      const scanRes = await labApiTasks.getAll(
        "lab/patient/resource/getOnboardingImages",
        { patientId, patientStage: "scan" }
      );

      setScanUrls(scanRes.body.results.patient_onboarding_images);
    } catch (error) {
      //   setIsProgressImagesSpinning(false);

      console.log(error);
    }
  };

  const scanFileMapper = (fileObj, patientId) => {

    const { file } = fileObj;
    const fileNameArray = file.name.split(".");
    fileNameArray[0] = fileNameArray[0] + "-" +  file.uid;
    const fileName = fileNameArray.join(".");
    let fileType="stl";
    if(file.type.includes("obj")){
      fileType="obj";
    }
    else if(file.type.includes("stl")){
      fileType="stl";
    }
    else{
      fileType = file.type
    }
    const preSignRequestBody = {
      patient_id: patientId,
      file_name: fileName,
      resource_type: "file",
      file_type:fileType,
    };
    return preSignRequestBody;
  };
  const uploadService = async (preSignedResponse, fileObj) => {
    // console.log("preSignedResponse", preSignedResponse);
    const { file } = fileObj;
    let contentType="model/stl";
    if(file.type.includes("obj")){
      contentType="model/obj";
    }
    else if(file.type.includes("stl")){
      contentType="model/stl";
    }
    else{
      contentType = file.type;
    }

    const config = {
      headers: {
        "content-type": contentType,
      },
    };
    // const accessUrl = preSignedResponse?.data.body.success.accessUrl;
    const uploadUrl = preSignedResponse?.data?.body.success.uploadUrl;

    const response = await axios.put(
      uploadUrl,
      file.originFileObj,
      config
    );
    return response;
  };

  const confirmService = async (preSignedResponse, patientId, filename) => {
    const accessUrl = preSignedResponse?.data.body.success.accessUrl;
    const confirmRequestBody = {
      patient_id: patientId,
      file_url: accessUrl,
      patient_upload_stage: "scan",
      resource_type: "url",
      scan_type: filename,
      case_id: 1,
      notify: false,
    };
    await confirmScanFileAPI(confirmRequestBody);
  };

  const uploadSingleFile = async (clinicalFile, patient_Id, index) => {
    const preSignedRequestBody = scanFileMapper(clinicalFile, patient_Id);
    const preSignedResponse = await uploadScanFileAPI(preSignedRequestBody);
    await uploadService(preSignedResponse, clinicalFile);
    await confirmService(preSignedResponse, patient_Id, clinicalFile.filename);

    return {
      message: "scan images uploaded",
    };
  };

  const prescriptionFilesUploader = async (files, patient_Id) => {
    const promiseArray = [];

    files.forEach((file, index) => {
      promiseArray.push(uploadSingleFile(file, patient_Id, index));
    });

    await Promise.all(promiseArray.map((p) => p.catch((e) => e)));
  };

  const editPrescriptionHanlder = async (values) => {
    const formValues = {
      ...values,
      treatmentTypes: [],
      id: formData.id,
      patientId: patientData?.patient_id,
      prescription_id: formData.prescription_id,
    };

    const prescriptionPayload = prescriptionDataMapper(
      formValues,
      teethChartRef.current,
      patientData?.patient_id,
      patientData?.clinic_id
    );
    await prescriptionFilesUploader(
      clinicalFilesArray,
      patientData?.patient_id
    );
    const { data } = await savePrescriptionAPI(prescriptionPayload);

    if (data) {
      let newVersion = 1;
      const maxVersion = findMaxPrescriptionVersion(
        formData?.prescriptionVersions
      );
      const teethChartNewMapped = teethChartRef?.current?.map((item) => {
        if (item.id) {
          return { ...item };
        } else {
          return {
            ...item,
            tooth_no: item.toothNo,
            toothProcedure: item.procedure,
          };
        }
      });
      // console.log("teethChartRef.current", teethChartRef.current);
      // console.log("teethChartNewMapped",teethChartNewMapped)
      let updatedForm = {
        ...values,
        id: formData.id,
        teethChart: teethChartNewMapped,
      };
      if (
        maxVersion >= newVersion &&
        maxVersion >= formData?.prescriptionVersions?.length
      ) {
        newVersion = maxVersion + 1;
        updatedForm.prescriptionVersions = [
          ...formData?.prescriptionVersions,
          {
            value: newVersion,
            label: `Version ${newVersion.toFixed(1)}`,
          },
        ];
      } else {
        updatedForm.prescriptionVersions = [
          {
            value: newVersion,
            label: "Version 1.0",
          },
        ];
      }
      await getScanFiles(patientData?.patient_id);
      setTreatmentPrescription(updatedForm);
      // console.log("updatedFormupdatedFormupdatedForm", updatedForm)
      prescriptionFormRef.current = updatedForm;
      setSelectedVersion(newVersion);

      message.success("Prescription updated Successfully");
    }
  };

  const onPrescriptionSubmit = async (values) => {
    try {
      setLoading(true);
      if (editPrescription) {
        await editPrescriptionHanlder(values);
      } else {
        const addPatientPayload = addPatientMapper(
          addPatientRef.current,
          labId
        );
        addPatientPayload.companyType = process.env.REACT_APP_COMPANY_TYPE;

        addPatientPayload.username = true;
        const createPatientResponse = await createPatient(addPatientPayload);
        if (createPatientResponse) {
          const patient_Id = createPatientResponse?.data?.body?.patientId;

          const prescriptionPayload = prescriptionDataMapper(
            values,
            teethChartRef.current,
            patient_Id,
            addPatientRef.current.clinicId
          );

          const { data } = await savePrescriptionAPI(prescriptionPayload);

          await prescriptionFilesUploader(clinicalFilesArray, patient_Id);

          await getScanFiles(patient_Id);
          notification.success({
            duration: 6,
            message: createPatientResponse?.data?.body?.msg,
            description: <a>View Profile</a>,
            className: "patient-created",
            onClick: () => {
              history.push("/lab/patient/" + patient_Id);
              window.location.reload();
            },
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setShowPrescriptionForm(false);
      setShowAddPatientsModal(false);
      setClinicalFilesArray([]);
    }
  };

  const prescriptionSubmit = () =>{
    const formValues  = prescriptionForm.getFieldsValue();
    let arches = {};
    if (formValues.treatmentTypes && formValues.treatmentTypes[0]) {
      arches = formValues.treatmentTypes[0].arches || {};
    }
    if (companyType === 5) {
      arches = formValues.smilelignArches || {};
    }
    const res = handleScanFilesModal(clinicalFilesArray, scanUrls, arches)
    setScanMessage(res.message)
    if (res.test) {
      setPrescriptionConfirmation(true)
    }
    else{
      prescriptionForm.submit();
    }


    // prescriptionForm.submit()
  }

  const onPatientSubmit = async (values) => {
    const patientValues = {
      ...values,
      clinicId: values.clinicId.value
    }
    try {
      const checkPatientDetails = {
        email: values.Email,
        patientId: values.patientId,
        checkPatientId: values.patientId ? true : false,
      };

      const checkPatientResponse = await checkPatientEmail(checkPatientDetails);
      if (checkPatientResponse?.data?.data?.emailExists) {
        message.error("Patient email already exists.");
        return;
      }

      if (checkPatientResponse?.data?.data?.patientIdExists) {
        message.error("Patient ID already exists.");
        return;
      }
      addPatientRef.current = patientValues;
      setShowPrescriptionForm(true);
    } catch (err) {
      message.error("Some error occured");
    }
  };

  const handleSubmit = () => {
    if (showPrescriptionForm) {
       prescriptionSubmit()
    } else {
      addPatientForm.submit();
      setClinicalFilesArray([]);
    }
  };

  const handleAddPatientCancel = (event) => {
    if (touched) {
      showConfirm();
    } else {
      setShowAddPatientsModal(false);
    }
  };

  const showConfirm = () => {
    confirm({
      title: "Close add patient form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
        },
      },
      onOk() {
        setShowAddPatientsModal(false);
        setShowPrescriptionForm(false);
        setClinicalFilesArray([]);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const modalStyles = {
    top: 20,
  };

  const contentStyles = {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "auto",
  };
  const onConfirmationOk=()=>{
    prescriptionForm.submit();
  }

  return (
    <div>
      <Modal
        style={modalStyles}
        className="addPatientModal"
        title={
          <div className="titleSpan">
            <UserAddOutlined style={{ fontSize: "35px" }} />{" "}
            <Title level={5} style={{ margin: "0px" }}>
              {showPrescriptionForm
                ? `${addPatientRef?.current?.firstName} ${addPatientRef?.current?.lastName} Prescription`
                : "NEW PATIENT"}
            </Title>
          </div>
        }
        width={showPrescriptionForm ? 1500 : 800}
        open={showAddPatientsModal}
        onCancel={handleAddPatientCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleAddPatientCancel}
            shape="round"
            style={{ width: "7rem", borderRadius: "30px" }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={handleSubmit}
            loading={loading}
            style={{ width: "7rem", borderRadius: "30px" }}
          >
            {showPrescriptionForm ? "Submit" : "Next"}
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          {showPrescriptionForm ? (
            <div className="prescription-modal-container" style={contentStyles}>
              <ViewScanFilesComponent
                setClinicalFilesArray={setClinicalFilesArray}
                clinicalFilesArray={clinicalFilesArray}
                clinicalFiles={clinicalFiles}
                scanUrls={scanUrls}
                setScanUrls={setScanUrls}
                // patientId={patientId}
                treatmentOriginId={3}
                activeCaseId={1}
                // getImg={_getImagesAndQuestionnaire}
                // status={statusesState}
                // dropdownValues={dropdownValuesState}
                // setStatusesState={setStatusesState}
                // updateTreatmentOrigin={updateTreatmentOrigin}
              />
              <PatientPrescriptionForm
                prescriptionForm={prescriptionForm}
                addPatientRef={addPatientRef}
                clinicId={
                  patientData?.clinic_id || addPatientRef.current?.clinicId
                }
                labName={labName}
                labId={labId}
                teethChartRef={teethChartRef}
                onPrescriptionSubmit={onPrescriptionSubmit}
              />
            </div>
          ) : (
            <AddPatientForm
              addPatientForm={addPatientForm}
              onPatientSubmit={onPatientSubmit}
              associatedClinics={associatedClinics}
              touched={touched}
              setTouched={setTouched}
            />
          )}
        </Spin>
      </Modal>


      {prescriptionConfirmation &&
        <ConfirmationModal
          title="Are you sure you want to submit without relevant scans?"
          content={scanMessage}
          showModal={prescriptionConfirmation}
          setShow={setPrescriptionConfirmation}
          onConfirmation={onConfirmationOk}
          okText="Submit"
        ></ConfirmationModal>
      }
    </div>
  );
};

export default AddPatientModal;
