import { columnTitleElement } from "utils/commonMethod";
import { Tag, Row, Col, Tooltip, Button } from "antd";
import {
  DownOutlined,
  FileOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import FilterDataRangePicker from "components/shared/filterDataRangePicker";
import { companyType } from "colors-config";

export const getFirstTableColumns = (handleDateFilter, showPaymentModal, setOrderInfo,setShowInvoiceModal, onDownloadAllClick, fromDate, endDate) => {
  const columns =  [
    {
      title: columnTitleElement("INVOICE NO"),
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width: 170,
      render: (invoiceNo) => {
        return (
          <div style={{ color: "#223032", display: "flex" }}>
            <FileOutlined style={{ marginRight: "5px", color: "#77888B" }} />
            {invoiceNo}
          </div>
        );
      },
    },
    {
      title: columnTitleElement("INVOICE DATE"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      filtered: (fromDate && endDate),
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDataRangePicker
          clearFilters={clearFilters}
          confirm={confirm}
          handleDateFilter={handleDateFilter}
          fromDate={fromDate}
          endingDate={endDate}
        />
      ),
    },
    {
      title: columnTitleElement("CLINIC NAME"),
      dataIndex: "clinicName",
      key: "clinicName",
    },
    {
      title: columnTitleElement("PATIENT ID"),
      dataIndex: "patientId",
      key: "patientId",
    },
    {
      title: columnTitleElement(
        companyType === 5 || companyType === 1
          ? "LAB PRODUCT / SERVICE NAME"
          : "PRODUCT/SERVICE"
      ),
      dataIndex: "productServiceName",
      key: "productServiceName",
      render: (productName, record) => {
        return (
          <Tooltip title={record.productServiceNames}>
          {productName}
        </Tooltip>
        );
      },
    },
    {
      title: columnTitleElement("PAYMENT STATUS"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: (paymentStatus, record) => {
        return (
          <>
            <Tag
              style={{
                borderRadius: "10px",
                paddingTop: "3px",
                paddingBottom: "3px",
                width: "130px",
                position: "relative",
              }}
              key={paymentStatus}
              onClick={() => showPaymentModal(record)}
            >
              {paymentStatus}
              <DownOutlined
                style={{
                  float: "right",
                  marginTop: "0.25rem",
                  position: "relative",
                  pointerEvents: "none",
                }}
              />
            </Tag>
          </>
        );
      },
    },
    {
      title: columnTitleElement("ORDER INVOICE"),
      dataIndex: "orderInvoice",
      key: "orderInvoice",
      render: (invoice, record) => {
        return (
          <>
            <Row style={{ marginTop: "1rem" }}>
              <Col>
                {record?.shippingFiles?.length ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="View">
                      <Button
                        onClick={() => {
                          setOrderInfo(record);
                          setShowInvoiceModal(true);
                        }}
                        className="invoice-viewBtn"
                        icon={
                          <EyeOutlined
                            style={{ fontSize: "17px", cursor: "pointer" }}
                          />
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip title="Download">
                      <Button
                        onClick={() =>
                          onDownloadAllClick(record?.shippingFiles)
                        }
                        className="invoice-viewBtn"
                        icon={
                          <DownloadOutlined
                            style={{ fontSize: "17px", cursor: "pointer" }}
                          />
                        }
                      ></Button>
                    </Tooltip>
                  </div>
                ) : (
                  "--"
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
  ]
  if (
    companyType === 5 ||
    companyType === 1
  ) {
    columns.splice(5, 0, {
      title: columnTitleElement("QUANTITY"),
      dataIndex: "quantity",
      key: "quantity",
       align:"end",
       width:120
    });
    columns.splice(7, 0, {
      title: columnTitleElement("LAB TOTAL PRICE"),
      dataIndex: "labProductTotalPrice",
      key: "labProductTotalPrice",
      // align:"end"
    });
  }
  return columns
  
};
