import UploadFile from "components/shared/UploadFile";
import { primaryColor } from "colors-config";
import { forwardRef, useState, useImperativeHandle, useEffect } from "react";

const ClinicalFiles = forwardRef((props, ref) => {
  const { setIsChanged } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (files.length) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [files]);

  const onChange = ({ file, fileList }) => {
    // cons

    setFiles(fileList);
  };


  useImperativeHandle(ref, () => ({
    clinicalFiles: files,
  }));

  return (
    <>
      <div>
        <b>Xray/ Clinical Files</b>
      </div>
      <UploadFile
        //   beforeUpload={beforeUpload}
        onChange={onChange}
        multiple={false}
        fileList={files}
        uploadFiles={({ file, onSuccess, onError }) => {
          onSuccess(true);
        }}
        style={{ borderColor: primaryColor }}
      />
    </>
  );
});

export default ClinicalFiles;
