import api from "./api";

export const getCountriesCodes = () => {
  return api({
    method: "GET",
    url: `/clinic/getCountryCodes`,
  });
};

export const getCountries = () => {
  return api({
    method: "GET",
    url: "/lab//getCountries",
  });
};


export const createStripePaymentIntentAPI = (data) => {
  return api({
    method: "POST",
    url: `/patient/stripe/createStripePaymentIntent`,
    data: data,
  }).then((res) => res.data);
};