import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const TitleComponent = ({ titleText }) => {
  return (
    <div>
      <Title
        level={3}
        style={{
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        {titleText}
      </Title>
    </div>
  );
};

export default TitleComponent;
