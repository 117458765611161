import { useEffect, useState } from "react";

import { message } from "antd";
import { uploadChatFile, socket as labChatSocket } from "api/chat";
import LabChatEditor from "./LabChatEditor";
import DisplayChat from "./DisplayChat";
import { checkTextEditorContent, removeTrailingEmptyTagsFromString } from "utils/Lab.js";
import { CHAT_FILE_SIZE } from "../../../../../constants";

const LabChat = ({
  tab,
  chatInfo,
  subUser,
  labMessages,
  chatSpinner,
  labChatScrollRef,
  scrollToBottom,
  labInfo,
  clinicInfo,
  editorInput,
  setEditorInput
}) => {

  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
    scrollToBottom()
  },[scrollToBottom])

  const sendMessage = (text) => {
    const hasInnerText = checkTextEditorContent(text);
    text = removeTrailingEmptyTagsFromString(text);
    if (hasInnerText) {
      labChatSocket.emit(
        "chatMessage",
        {
          meta: {
            receiver_id: chatInfo.clinic_id,
            sender_id: chatInfo.lab_id,
            patient_id: chatInfo.patient_id,
            module: "lab",
            staffId: subUser.id,
          },

          message: text,
        },
        (response) => {
          if (response && response?.status === "ok") {
            setEditorInput("");
            scrollToBottom();
          } else {
            message.error("server not responding! try again");
          }
        }
      );
    }
  };

  const sendHandler = () => {
    const stringText = editorInput.toString();
    if (!stringText) {
      return;
    }
    sendMessage(stringText);
  };

  const onFileChange = async (file) => {
    if (file?.size > CHAT_FILE_SIZE) {
      return message.error("One or more files should not be greater than 150MB.");
    }
    const data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    data.append("sender", "lab");
    data.append("lab_id", chatInfo.lab_id);
    data.append("clinic_id", chatInfo.clinic_id);
    data.append("patient_id", chatInfo.patient_id);

    data.append("receiver", "clinic");
    try {
      setIsLoading(true);
      await uploadChatFile(data);
    } catch (err) {
      console.log("err");
      message.error("failed to send file");
    } finally {
      setIsLoading(false);
    }

    return false;
  };

  return (
    <>
      <DisplayChat
        labChatScrollRef={labChatScrollRef}
        isLoading={isLoading || chatSpinner}
        labMessages={labMessages}
        labName={labInfo.labName}
        clinicName={clinicInfo.clinic_name}
      />
      <LabChatEditor
        onFileChange={onFileChange}
        sendHandler={sendHandler}
        editorInput={editorInput}
        setEditorInput={setEditorInput}
      />
    </>
  );
};

export default LabChat;
