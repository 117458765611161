import React, { useEffect } from "react";
import { Modal, Button } from "antd";
import dayjs from "dayjs";
import DispatchDateForm from "../DispatchDateForm/DispatchDateForm";

const UpdateDispatchDateModal = ({
  showEditDispatchModal,
  setShowEditDispatchModal,
  onConfirmation,
  loading,
  printForm,
  orderDetails,
}) => {
  const handleOk = () => {
    printForm.submit();
  };

  const handleCancel = () => {
    setShowEditDispatchModal(false);
  };
  useEffect(() => {
    if (orderDetails?.est_date) {
      const estimatedDate = dayjs(orderDetails?.est_date);
      printForm.setFieldsValue({
        estDate: estimatedDate,
      });
    }
  }, [orderDetails, printForm]);

  return (
    <Modal
      title="Edit Est. Dispatch Date"
      okText="Update"
      cancelText="Cancel"
      open={showEditDispatchModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
          shape="round"
          style={{ width: "7rem" }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          onClick={handleOk}
          loading={loading}
          style={{ width: "7rem" }}
        >
          Update
        </Button>,
      ]}
    >
      <DispatchDateForm onConfirmation={onConfirmation} printForm={printForm} />
    </Modal>
  );
};

export default UpdateDispatchDateModal;
