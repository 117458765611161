// import { ReactComponent as Teeth18 } from "../../../assets/images/teeths/teeth18.svg";
// import { ReactComponent as Teeth17 } from "../../../assets/images/teeths/teeth17.svg";
// import { ReactComponent as Teeth16 } from "../../../assets/images/teeths/teeth16.svg";
// import { ReactComponent as Teeth15 } from "../../../assets/images/teeths/teeth15.svg";
// import { ReactComponent as Teeth14 } from "../../../assets/images/teeths/teeth14.svg";
// import { ReactComponent as Teeth13 } from "../../../assets/images/teeths/teeth13.svg";
// import { ReactComponent as Teeth12 } from "../../../assets/images/teeths/teeth12.svg";
// import { ReactComponent as Teeth11 } from "../../../assets/images/teeths/teeth11.svg";

// import { ReactComponent as Teeth21 } from "../../../assets/images/teeths/teeth21.svg";
// import { ReactComponent as Teeth22 } from "../../../assets/images/teeths/teeth22.svg";
// import { ReactComponent as Teeth23 } from "../../../assets/images/teeths/teeth23.svg";
// import { ReactComponent as Teeth24 } from "../../../assets/images/teeths/teeth24.svg";
// import { ReactComponent as Teeth25 } from "../../../assets/images/teeths/teeth25.svg";
// import { ReactComponent as Teeth26 } from "../../../assets/images/teeths/teeth26.svg";
// import { ReactComponent as Teeth27 } from "../../../assets/images/teeths/teeth27.svg";
// import { ReactComponent as Teeth28 } from "../../../assets/images/teeths/teeth28.svg";

// import { ReactComponent as Teeth48 } from "../../../assets/images/teeths/teeth48.svg";
// import { ReactComponent as Teeth47 } from "../../../assets/images/teeths/teeth47.svg";
// import { ReactComponent as Teeth46 } from "../../../assets/images/teeths/teeth46.svg";
// import { ReactComponent as Teeth45 } from "../../../assets/images/teeths/teeth45.svg";
// import { ReactComponent as Teeth44 } from "../../../assets/images/teeths/teeth44.svg";
// import { ReactComponent as Teeth43 } from "../../../assets/images/teeths/teeth43.svg";
// import { ReactComponent as Teeth42 } from "../../../assets/images/teeths/teeth42.svg";
// import { ReactComponent as Teeth41 } from "../../../assets/images/teeths/teeth41.svg";

// import { ReactComponent as Teeth31 } from "../../../assets/images/teeths/teeth31.svg";
// import { ReactComponent as Teeth32 } from "../../../assets/images/teeths/teeth32.svg";
// import { ReactComponent as Teeth33 } from "../../../assets/images/teeths/teeth33.svg";
// import { ReactComponent as Teeth34 } from "../../../assets/images/teeths/teeth34.svg";
// import { ReactComponent as Teeth35 } from "../../../assets/images/teeths/teeth35.svg";
// import { ReactComponent as Teeth36 } from "../../../assets/images/teeths/teeth36.svg";
// import { ReactComponent as Teeth37 } from "../../../assets/images/teeths/teeth37.svg";
// import { ReactComponent as Teeth38 } from "../../../assets/images/teeths/teeth38.svg";


export const ALIGNER_OPTIONS = [
    {
        label:"Aligner 1",
        key:"aligner-1"
    },
    {
        label:"Aligner 2",
        key:"aligner-2"
    },
    {
        label:"Aligner 3",
        key:"aligner-3"
    },
    {
        label:"Aligner 4",
        key:"aligner-4"
    },
    {
        label:"Aligner 5",
        key:"aligner-5"
    },
    {
        label:"Aligner 6",
        key:"aligner-6"
    },
  ]

  export const ALIGNER_VALUES = [
    {
        label:"0.1",
        key:"0.1"
    },
    {
        label:"0.2",
        key:"0.2"
    },
    {
        label:"0.3",
        key:"0.3"
    },
    {
        label:"0.4",
        key:"0.4"
    },
    {
        label:"0.5",
        key:"0.5"
    },

    {
        label:"0.6",
        key:"0.6"
    },
    {
      label:"none",
      key:"none"
  },
  ]
  export const LOWER_JAW = [
   
      {
        name: "48",
        universalName: "17",
        palmerName: "LR8",
        showName: "48",
        selection: true,
    
        id: 48,
        visible: false,
      },
      {
        name: "47",
        universalName: "18",
        palmerName: "LR7",
        showName: "47",
        selection: true,
    
        id: 47,
        visible: false,
      },
      {
        name: "46",
        universalName: "36",
        palmerName: "LR6",
        showName: "46",
        selection: true,
    
        id: 46,
        visible: false,
      },
      {
        name: "45",
        universalName: "35",
        palmerName: "LR5",
        showName: "45",
        selection: true,
    
        id: 45,
        visible: false,
      },
      {
        name: "44",
        universalName: "34",
        palmerName: "LR4",
        showName: "44",
        selection: true,
        id: 44,
        visible: false,
      },
      {
        name: "43",
        universalName: "33",
        palmerName: "LR3",
        showName: "43",
        selection: true,
        id: 43,
        visible: false,
      },
      {
        name: "42",
        universalName: "32",
        palmerName: "LR2",
        showName: "42",
        selection: true,
        id: 42,
        visible: false,
      },
      {
        name: "41",
        universalName: "31",
        palmerName: "LR1",
        showName: "41",
        selection: true,
    
        id: 41,
        visible: false,
      },
      {
        name: "31",
        universalName: "30",
        palmerName: "LL1",
        showName: "31",
        selection: true,
    
        id: 31,
        visible: false,
      },
      {
        name: "32",
        universalName: "29",
        palmerName: "LL2",
        showName: "32",
        selection: true,
    
        id: 32,
        visible: false,
      },
      {
        name: "33",
        universalName: "28",
        palmerName: "LL3",
        showName: "33",
        selection: true,
    
        id: 33,
        visible: false,
      },
      {
        name: "34",
        universalName: "27",
        palmerName: "LL4",
        showName: "34",
        selection: true,
    
        id: 34,
        visible: false,
      },
      {
        name: "35",
        universalName: "26",
        palmerName: "LL5",
        showName: "35",
        selection: true,
    
        id: 35,
        visible: false,
      },
      {
        name: "36",
        universalName: "25",
        palmerName: "LL6",
        showName: "36",
        selection: true,
    
        id: 36,
        visible: false,
      },
      {
        name: "37",
        universalName: "24",
        palmerName: "LL7",
        showName: "37",
        selection: true,
        id: 37,
        visible: false,
      },
      {
        name: "38",
        universalName: "23",
        palmerName: "LL8",
        showName: "38",
        selection: false,
        id: 38,
        visible: false,
      },
  ]

  export const UPPER_JAW = [
    {
        name: "18",
        universalName: "1",
        palmerName: "UR8",
        showName: "18",
        selection: true,
        id: 18,
        visible: false,
      },
      {
        name: "17",
        universalName: "2",
        palmerName: "UR7",
        showName: "17",
        selection: true,
        id: 17,
        visible: false,
      },
      {
        name: "16",
        universalName: "3",
        palmerName: "UR6",
        showName: "16",
        selection: true,
        id: 16,
        visible: false,
      },
      {
        name: "15",
        universalName: "4",
        palmerName: "UR5",
        showName: "15",
        selection: true,
    
        id: 15,
        visible: false,
      },
      {
        name: "14",
        universalName: "5",
        palmerName: "UR4",
        showName: "14",
        selection: true,
    
        id: 14,
        visible: false,
      },
      {
        name: "13",
        universalName: "6",
        palmerName: "UR3",
        showName: "13",
        selection: true,
    
        id: 13,
        visible: false,
      },
      {
        name: "12",
        universalName: "7",
        palmerName: "UR2",
        showName: "12",
        selection: true,
    
        id: 12,
        visible: false,
      },
      {
        name: "11",
        universalName: "8",
        palmerName: "UR1",
        showName: "11",
        selection: true,
    
        id: 11,
        visible: false,
      },
      {
        name: "21",
        universalName: "9",
        palmerName: "UL1",
        showName: "21",
        selection: true,
    
        id: 21,
        visible: false,
      },
      {
        name: "22",
        universalName: "10",
        palmerName: "UL2",
        showName: "22",
        selection: true,
    
        id: 22,
        visible: false,
      },
      {
        name: "23",
        universalName: "11",
        palmerName: "UL3",
        showName: "23",
        selection: true,
    
        id: 23,
        visible: false,
      },
      {
        name: "24",
        universalName: "12",
        palmerName: "UL4",
        showName: "24",
        selection: true,
    
        id: 24,
        visible: false,
      },
      {
        name: "25",
        universalName: "13",
        palmerName: "UL5",
        showName: "25",
        selection: true,
    
        id: 25,
        visible: false,
      },
      {
        name: "26",
        universalName: "14",
        palmerName: "UL6",
        showName: "26",
        selection: true,
        id: 26,
        visible: false,
      },
      {
        name: "27",
        universalName: "15",
        palmerName: "UL7",
        showName: "27",
        selection: true,
    
        id: 27,
        visible: false,
      },
      {
        name: "28",
        universalName: "16",
        palmerName: "UL8",
        showName: "28",
        selection: false,
        id: 28,
        visible: false,
      },
  ]




