import { Form, DatePicker } from "antd";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import { disablePastDates } from "utils/commonMethod";
import styles from "./dispatchForm.module.scss"

const DispatchDateForm = ({ printForm, onConfirmation }) => {
  return (
    <Form
      style={{
        width: "100%",
        marginTop:"10px"
      }}
      form={printForm}
      name="complex-form"
      layout={"vertical"}
      onFinish={onConfirmation}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
    >
      <div className={styles["dispatch-form-container"]}>
        <Form.Item>
          <Form.Item
            label={
              <span>
                Est. Dispatch Date&nbsp;
                <AppToolTip
                  title={"Est. Dispatch Date"}
                />
              </span>
            }
            rules={[
                { required: true, message: "Date is required" },
              ]}
            name="estDate"
          >
            <DatePicker
              style={{ width: "50%" }}
              disabledDate={disablePastDates}
            />
          </Form.Item>
        </Form.Item>
      </div>
    </Form>
  );
};

export default DispatchDateForm;
