import { Form, Select, Button, Row, Radio, Col, Input } from "antd";
import { memo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
// import { ReactComponent as DeleteIcon } from "../../../../assets/images/teeths/deleteIcon.svg";
import { FORM_CONSTANTS } from "../../constants";
import { primaryColor } from "colors-config";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";
import { getPhasingInfo } from "messages/messageController";
import App from "App";
import AppInfoIcon from "components/shared/AppInfoIcon/AppInfoIcon";

const { Option } = Select;
const { TextArea } = Input;

const Aligners = ({
  productList,
  onFormChange,
  disabled,
  prescriptionForm,
  alignerComments,
  setAlignerComments,
  formDataObjectRef,
  labProducts,
}) => {
  // const [showCommentArray, setShowCommentArray] = useState([true]);

  const handleRetainerChange = (value, index) => {
    const updatedShowCommentArray = [...alignerComments];
    updatedShowCommentArray[index] =
      value !== `${FORM_CONSTANTS.NO_RETAINER_AND_WHITENING_TRAY}`;
    setAlignerComments(updatedShowCommentArray);
  };

  const handleChange = (value, field, rowIndex) => {
    // console.log(" formDataObjectRef.current", formDataObjectRef.current)
    // console.log(value, field, rowIndex);
    //  formDataObjectRef.current.treatmentTypes[rowIndex][field] = value
    // console.log(formDataObjectRef.current)
  };

  return (
    <>
      <h1 className="form-heading" style={{ margin: "0px 0px 10px 0px" }}>
        1. Treatment Type
      </h1>
      <div className="treatment-row aligner-row">
        <Form.List name="treatmentTypes" disabled={disabled}>
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, fieldKey, ...restField }, index) => (
                <div key={key} style={{ position: "relative" }}>
                  {fields.length > 1 && (
                    <div
                      className="num-btn"
                      style={{ background: primaryColor }}
                    >
                      {index + 1}
                    </div>
                  )}

                  <Row gutter={[8, 8]} align="middle">
                    <Col lg={8}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "aligner"]}
                        name={[name, "aligner"]}
                        label="Aligner"
                        rules={[
                          {
                            required: true,
                            message: "Please select a type",
                          },
                        ]}
                      >
                        <Select
                          disabled={disabled}
                          size="large"
                          className="form-select"
                          placeholder="Select Aligners"
                          notFoundContent={
                            <p>No treatment type added in the clinic</p>
                          }
                          onChange={(value) =>
                            handleChange(value, "aligner", index)
                          }
                        >
                          {productList.map((item) => {
                            return (
                              <Option value={item.product_name}>
                                {item.product_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={15}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "arches"]}
                        name={[name, "arches"]}
                        label="Arches treated"
                        rules={[
                          {
                            required: true,
                            message: "Please select an Option",
                          },
                        ]}
                      >
                        <Radio.Group
                          disabled={disabled}
                          onChange={(e) =>
                            handleChange(e.target.value, "arches", index)
                          }
                        >
                          <Radio value={FORM_CONSTANTS.UPPER_AND_LOWER}>
                            Upper and Lower
                          </Radio>
                          <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
                          <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col lg={1}>
                      {fields.length > 1 && (
                        <Button
                          className="delete-btn"
                          disabled={disabled}
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            remove(name);
                          }}
                        ></Button>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col lg={8}>
                      <Form.Item
                        {...restField}
                        fieldKey={[fieldKey, "whitening"]}
                        name={[name, "whitening"]}
                        // label="Aligner"
                        rules={[
                          {
                            required: true,
                            message: "Please select a type",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          className="form-select"
                          placeholder="Select whitening"
                          onChange={(value) =>
                            handleRetainerChange(value, index)
                          }
                          notFoundContent={
                            <p>No treatment type added in the clinic</p>
                          }
                          disabled={disabled}
                        >
                          <Option value={FORM_CONSTANTS.RETAINER}>
                            Retainer{" "}
                          </Option>
                          <Option value={FORM_CONSTANTS.WHITENING_TRAY}>
                            Whitening tray
                          </Option>
                          <Option
                            value={FORM_CONSTANTS.RETAINER_AND_WHITENING_TRAY}
                          >
                            Retainer & Whitening Tray{" "}
                          </Option>
                          <Option
                            value={
                              FORM_CONSTANTS.NO_RETAINER_AND_WHITENING_TRAY
                            }
                          >
                            No Retainer & Whitening Tray
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {alignerComments[index] && (
                      <Col lg={15}>
                        <Form.Item
                          {...restField}
                          fieldKey={[fieldKey, "whitening_arches"]}
                          name={[name, "whitening_arches"]}
                          label=""
                          rules={[
                            {
                              required: true,
                              message: "Please select an Option",
                            },
                          ]}
                        >
                          <Radio.Group
                            disabled={disabled}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "whitening_arches",
                                index
                              )
                            }
                          >
                            <Radio value={FORM_CONSTANTS.UPPER_AND_LOWER}>
                              Upper and Lower
                            </Radio>
                            <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
                            <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  {alignerComments[index] && (
                    <Row gutter={[16, 16]}>
                      <Col lg={24}>
                        <Form.Item
                          {...restField}
                          fieldKey={[fieldKey, "comment"]}
                          name={[name, "comment"]}
                          // label="Aligner"
                        >
                          <TextArea
                            onChange={(e) =>
                              handleChange(e.target.value, "comment", index)
                            }
                            placeholder="Comment"
                            rows={2}
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </div>
              ))}
              <Button
                icon={<AddIcon style={{ fill: primaryColor }} />}
                className="addOn-btn"
                onClick={() => {
                  add();
                }}
                disabled={disabled}
              >
                Add Another Treatment
              </Button>
            </>
          )}
        </Form.List>
        {process.env.REACT_APP_COMPANY_TYPE == 5 ? (
          <>
            <Row gutter={[8, 8]} align="bottom" style={{ marginTop: "15px" }}>
              <Col lg={7}>
                <Form.Item name="labProductId" label="Select Product From Lab">
                  <Select
                    placeholder="Product Name"
                    size="large"
                    onChange={(val) => onFormChange("labProductId", val)}
                    disabled={disabled}
                  >
                    <Option value="">NIL</Option>
                    {labProducts?.map((labProduct, index) => {
                      return (
                        <Option value={labProduct.id} key={index}>
                          {labProduct.productName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={7}>
                <Form.Item
                  name="labProductPrice"
                  className="prescriptionProductPrice"
                  label="Product Price"
                >
                  <Input
                    placeholder="Product Price"
                    size="large"
                    // value={selectedLabProduct?.price ? `${selectedLabProduct.price} ${selectedLabProduct.currency}` : ""}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="phasing-row" style={{ paddingTop: "30px" }}>
              <Row gutter={[16, 16]}>
                <Col lg={7}>
                  <div className="label">
                    <label>
                      Phasing
                      <AppToolTip
                        title={getPhasingInfo()}
                        iconStyles={{ marginLeft: "5px" }}
                      >
                      {/* <AppInfoIcon /> */}
                      </AppToolTip>
                    </label>
                  </div>
                </Col>
                <Col lg={16}>
                  <Form.Item name="phasing" label="">
                    <Radio.Group
                      onChange={(e) => onFormChange("phasing", e.target.value)}
                      disabled={disabled}
                    >
                      <Radio value={FORM_CONSTANTS.YES}>Yes</Radio>
                      <Radio value={FORM_CONSTANTS.NO}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default memo(Aligners);
