import React, { useState, useEffect } from "react";
import { Modal } from "antd";

const DeleteConfirmationModel = ({
  title,
  content,
  onConfirmation,
  showModal,
  setShow,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  const handleOk = () => {
    setIsModalVisible(false);
    setShow(false);
    onConfirmation();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
  };

  return (
    <>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          danger: true,
          //   size: "large",
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        <p>Are you sure you want to remove {content}</p>
      </Modal>
    </>
  );
};

export default DeleteConfirmationModel;
