import axios from "axios";
import {
    confirmScanFileAPI,
    uploadScanFileAPI,
  } from "api/patient";
import { SCAN_FILE_LOWER, SCAN_FILE_LOWER_ARCH, SCAN_FILE_UPPER_ARCH  } from "constants";
import { SCAN_FILE_UPPER } from "constants";


const scanFileMapper = (fileObj, patientId) => {

    const { file } = fileObj;
    const fileNameArray = file.name.split(".");
    fileNameArray[0] = fileNameArray[0] + "-" + file.uid;
    const fileName = fileNameArray.join(".");
    let fileType="stl";
    if(file.type.includes("obj")){
      fileType="obj";
    }
    else if(file.type.includes("stl")){
      fileType="stl";
    }
    else{
      fileType = file.type
    }
    const preSignRequestBody = {
        patient_id: patientId,
        file_name: fileName,
        resource_type: "file",
        file_type: fileType,
    };
    return preSignRequestBody;
};
const uploadService = async (preSignedResponse, fileObj) => {
    const { file } = fileObj;
    let contentType="model/stl";
    if(file.type.includes("obj")){
      contentType="model/obj";
    }
    else if(file.type.includes("stl")){
      contentType="model/stl";
    }
    else{
      contentType = file.type;
    }
    const config = {
        headers: {
            "content-type": contentType,
        },
    };
    // const accessUrl = preSignedResponse?.data.body.success.accessUrl;
    const uploadUrl = preSignedResponse?.data?.body.success.uploadUrl;

    const response = await axios.put(
        uploadUrl,
        file.originFileObj,
        config
    );
    return response;
};

const confirmService = async (preSignedResponse, patientId, filename) => {
    try {
        const accessUrl = preSignedResponse?.data.body.success.accessUrl;
        const confirmRequestBody = {
            patient_id: patientId,
            file_url: accessUrl,
            patient_upload_stage: "scan",
            resource_type: "url",
            scan_type: filename,
            case_id: 1,
            notify: false,
        };
        await confirmScanFileAPI(confirmRequestBody);
    } catch (err) {
        throw `${filename} file upload failed.`
    }
};

const uploadSingleFile = async (clinicalFile, patient_Id, index) => {
    try {
        const preSignedRequestBody = scanFileMapper(clinicalFile, patient_Id);
        const preSignedResponse = await uploadScanFileAPI(preSignedRequestBody);
        await uploadService(preSignedResponse, clinicalFile);
        await confirmService(preSignedResponse, patient_Id, clinicalFile.filename);

        return {
            message: "scan images uploaded",
        };
    }
    catch (err) {
        throw err
    }
};

export const prescriptionFilesUploader = async (files, patient_Id) => {
    try {
        const promiseArray = [];

        files.forEach((file, index) => {
            promiseArray.push(uploadSingleFile(file, patient_Id, index));
        });

        await Promise.all(promiseArray.map((p) => p.catch((e) => e)));
    } catch (err) {
        throw err
    }
};

const scanFilesNames = (arch) =>{
    let files = []
  if (arch === SCAN_FILE_UPPER) {
    files = [SCAN_FILE_UPPER_ARCH ]
  }
  else if(arch === SCAN_FILE_LOWER) {
  files = [SCAN_FILE_LOWER_ARCH]
  }
  else{
    files = [SCAN_FILE_UPPER_ARCH, SCAN_FILE_LOWER_ARCH]
  }
  return files
  }
 
  export const handleScanFilesModal = (
    clinicalFilesArray,
    scanUrls,
    arches
  ) => {
    const scanUrlAvailableFiles = scanUrls
      ? scanUrls?.map((scan) => scan?.patient_image_view)
      : [];
    const clinicalAvailableFiles = clinicalFilesArray?.map(
      (scan) => scan?.filename
    );
    const allFiles = [...scanUrlAvailableFiles, ...clinicalAvailableFiles];
    const requiredFiles = scanFilesNames(arches);
    const missingFiles = requiredFiles.filter(
      (file) => !allFiles.includes(file)
    );
    const attachedFilesMessage = allFiles.length
      ? `attached only ${allFiles.join(", ")} Scan File`
      : "attached no Scan Files";
    return {
      test: missingFiles.length ? true : false,
      message: `It looks like you have asked for ${arches} arches in your prescription but you have ${attachedFilesMessage}. Consider attaching the ${missingFiles.join(
        ", "
      )} scan before proceeding.`,
    };
  };