import { useState, useEffect, useCallback } from "react";
import { Spin } from "antd";
import DisplayActivity from "./DisplayActivity";
import ActivityEditor from "./ActivityEditor";

import styles from "./activity.module.scss";

const Activities = ({ activities, activityScrollRef, activityLoader }) => {
  return (
    <Spin tip="loading activities" spinning={activityLoader}>
      <div
        ref={activityScrollRef}
        className={styles["activity-container"]}
        data-id="activity-container"
      >
        {activities.map((content) => (
          <DisplayActivity key={content.id} content={content} />
        ))}
      </div>
    </Spin>
  );
};

const Activity = ({
  patientId,
  activitySocket,
  subUser,
  activities,
  staffMembers,
  activityScrollRef,
  activityLoadMore,
  loadMoreActivities,
  activityLoader,
  setEditorInput,
  editorInput
}) => {


  const handleScroll = useCallback(() => {
    if (activityScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = activityScrollRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 5 && activityLoadMore) {
        loadMoreActivities(patientId, activities.length);
        // console.log("reached");
      }
    }
  }, [activityScrollRef, activityLoadMore, loadMoreActivities, patientId, activities.length]);

  useEffect(() => {
    const container = activityScrollRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [activityScrollRef, handleScroll]);

  const sendHandler = () => {
    if (editorInput.toString()) {
      activitySocket.emit(
        "lab:activity",
        { staffId: subUser.id, activity: editorInput.toString(), patientId },
        (response) => {
          if (response.status === "success") {
            console.log("data", response.data);

            setEditorInput("");
          }
        }
      );
    }
  };
  return (
    <>
      <ActivityEditor
        staffMembers={staffMembers}
        sendHandler={sendHandler}
        editorInput={editorInput}
        setEditorInput={setEditorInput}
      />
      <Activities
        activityLoader={activityLoader}
        activityScrollRef={activityScrollRef}
        activities={activities}
      />
    </>
  );
};

export default Activity;
