import { Row, Col, Card } from "antd";
import { removeUnderscore } from "../utils";
import { FORM_CONSTANTS } from "../constants";
import { companyType } from "colors-config";

const SmileTreatment = ({
  smilelignTreatmentTypeId,
  smilelignArches,
  phasing,
  smilelignWhitening,
  prescriptionPhasing,
  smilelignTreatmentTypeData,
}) => {
  // console.log("treatmentTypes", treatmentTypes);


  return (
    <>
      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Treatment Type</h1>

        <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
          <Col lg={14} className="border-right">
            <h2 className="caption">Treatment Type</h2>
            {/* <p className="content">{treatmentObj[smilelignTreatmentTypeId]}</p> */}
            <p className="content">{smilelignTreatmentTypeData?.treatmentName}</p>
          </Col>
          <Col lg={10}>
            <div className="paddingLeft">
              <h2 className="caption">Retainer Arches</h2>
              <p className="content">{removeUnderscore(smilelignArches)}</p>
            </div>
          </Col>
        </Row>

        {companyType === 5 ?
          <>
            <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
              <Col lg={10}>
                <div className="">
                  <h2 className="caption">Phasing</h2>
                  <p className="content" style={{ textTransform: "capitalize" }}>
                    {phasing}
                  </p>
                </div>
              </Col>
            </Row>
            {phasing === FORM_CONSTANTS.YES && (
              <Row gutter={[16, 16]} align="bottom">
                <Col lg={24}>
                  <p className="treatment-label"></p>
                  <p
                    className="treatment-info"
                    style={{ textTransform: "capitalize" }}
                  >
                    {prescriptionPhasing ? prescriptionPhasing : "--"}
                  </p>
                </Col>
              </Row>
            )}
          </> : null
        }
      </Card>
    </>
  );
};

export default SmileTreatment;
