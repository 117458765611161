import {Button} from "antd"
import { primaryColor } from "colors-config";

import { ReactComponent as Pdf } from "assets/images/Pdf.svg";
import { ReactComponent as Image } from "assets/images/Image.svg";
import AppButton from "components/shared/AppButton/AppButton";

const ResourceDetail = ({ resource , handleResourceEdit}) => {
  return (
    <div className="resource-detail-container">
    <div className="resource-editBtn">
      <AppButton type="primary" shape="round" style={{width:"100px"}} onClick={handleResourceEdit}>Edit</AppButton>
    </div>
      <div className="resource-detail">
        <label>File Name</label>
        <p>{resource.name?.resourceName}</p>
      </div>
      <div className="resource-detail">
        <label>Category</label>
        <p>{resource.category}</p>
      </div>
      <div className="resource-detail">
        <label>Description</label>
        <p>{resource.description?resource.description:"--"}</p>
      </div>
      <div className="resource-detail">
        <label>Documents and Images</label>
        <div className="resources-container">
        {resource.files.map((file,i) =>{
          return(
            <a href={file.url} target="_blank" rel="noreferrer" key={i}>
            <div key={i} className="resource">
            {file.mimeType === "image/jpeg" || "image/png" ? <Image/> : <Pdf/>}
              <p>{file.name}</p>  
            </div>
            </a>  
        
          )
        })}
         {resource.files.length==0 ?"No files uploaded":""}
        </div>
       
     
      </div>
      <div className="resource-detail">
        <label>Video Links</label>
        <p>{resource.videoLinks?.map((resource, i) =>{
          return(
         <a href={resource.url} target="_blank" rel="noreferrer" key={i} style={{color: primaryColor}}>{resource.name} </a>
          )
        })}
        {resource.videoLinks.length ?"":"No Links uploaded"}
        </p>
      </div>
    </div>
  );
};

export default ResourceDetail;
