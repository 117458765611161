import { useState, useEffect, useCallback } from "react";

import { getLabStaff } from "api/staff";

const roleMapper = {
  "super-admin": "Super Admin",
  admin: "Admin",
  user: "User",
};

const statusMapper = {
  "email-sent": "Email Sent",
  active: "Active",
};

const useGetStaff = (labId) => {
  const [loading, setLoading] = useState(true);
  const [staffData, setStaffData] = useState([]);
  const [error, setError] = useState(false);
  const [rawData, setRawData] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getLabStaff(labId);

      setRawData(response?.data?.data);

      let mappedData = response?.data?.data.map((staff) => {
        const displayRole = roleMapper[staff.role];
        return {
          ...staff,
          displayRole,
          displayStatus: statusMapper[staff.status],
        };
      });

      mappedData = mappedData.sort((a, b) => {
        if (a.role === "super-admin") return -1;
        if (b.role === "super-admin") return 1;
        return a.name.localeCompare(b.name);
      });

      setStaffData(mappedData);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [labId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [loading, staffData, rawData, error, fetchData];
};

export default useGetStaff;
