import { TREATMENT_ORDER_KEY, TREATMENT_ORDER, APPLIANCES_ORDER_KEY, APPLIANCES_ORDER } from "constants";
export const placeOrderItems = (handleOnClick) => {
    return [
      {
        key: TREATMENT_ORDER_KEY,
        label: "Treatment",
        onClick: () => handleOnClick(TREATMENT_ORDER_KEY),
      },
      {
        key: APPLIANCES_ORDER_KEY,
        label: "Appliances",
        onClick: () => handleOnClick(APPLIANCES_ORDER_KEY),
      },
    ];
  };


  export   const handlePrintingStatusMessage = (order_delivery_status_id, patient_order_id) => {
    let  msg = `Are you sure you want to update this printing (Order : ${patient_order_id})`;
    if (order_delivery_status_id === 6) {
      msg = `Are you sure you want to start this printing (Order : ${patient_order_id})`;
    } else if (order_delivery_status_id === 7) {
      msg = `Are you sure you want to pause this printing (Order : ${patient_order_id})`;
    } else if (order_delivery_status_id === 9) {
      msg = `Are you sure you want to restart this printing (Order : ${patient_order_id})`;
    }
    return msg
  };