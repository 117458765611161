import { apiProvider } from "./provider";

import { Auth } from "aws-amplify";
import store from "../../redux/store";
import { setToken, logout } from "../../redux/actions/authActions";
const tenantId = process.env.REACT_APP_SMILE_TENENT_ID;

export default class ApiCore {
  constructor(options, axiosInstance) {
    if (options.getAll) {
      this.getAll = (url, query) => {
        const config = {
          params: query,
        };
        return apiProvider.getAll(
          url,
          config,
          axiosInstance,
          options.isAuthRequired
        );
      };
    }

    if (options.getSingle) {
      this.getSingle = (url, query) => {
        const config = {
          params: query,
        };
        return apiProvider.getSingle(
          url,
          config,
          axiosInstance,
          options.isAuthRequired
        );
      };
    }

    if (options.getClinic) {
      this.getClinic = (url, query) => {
        const config = {
          params: query,
        };
        return apiProvider.getSingle(
          url,
          config,
          axiosInstance,
          options.isAuthRequired
        );
      };
    }

    if (options.post) {
      this.post = (url, model, config) => {
        return apiProvider.post(
          url,
          model,
          axiosInstance,
          options.isAuthRequired,
          config
        );
      };
    }

    if (options.put) {
      this.put = (url, model, config) => {
        return apiProvider.put(
          url,
          model,
          axiosInstance,
          options.isAuthRequired
        );
      };
    }

    if (options.patch) {
      this.patch = (url, model) => {
        return apiProvider.patch(
          url,
          model,
          axiosInstance,
          options.isAuthRequired
        );
      };
    }

    if (options.delete) {
      this.remove = (url, requestObject) => {
        return apiProvider.remove(
          url,
          requestObject,
          axiosInstance,
          options.isAuthRequired
        );
      };
    }
    if (options.isAuthRequired) {
      axiosInstance.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem("idToken");
          if (token) {
            config.headers["Authorization"] = "Bearer " + token;
          }
          if(tenantId){
            config.headers["tenantId"] =tenantId;
          }

          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );
    }

    
    axiosInstance.interceptors.request.use(
      (config) => {
        if(tenantId){
          config.headers["tenantId"] =tenantId;
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );


    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401) {
          try {
            const data = await Auth.currentSession();
            console.log(data, "cloud");
            // throw "err"

            const token = data.getIdToken().getJwtToken();
            axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;

            await store.dispatch(setToken(token));
            return axiosInstance(originalRequest);
          } catch (error) {
            store.dispatch(logout());
            console.log(error);
          }
        }
        return Promise.reject(error);
      }
    );
  }
}
