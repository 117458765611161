import api from "./api";

export const getPreliminaryImagesApi = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patient/resource/getOnboardingImages`,
    params: params,
  });
};

export const deleteScanService = (data) => {
  return api({
    method: "POST",
    url: `/patient/resource/delete`,
    data,
  });
};

// await axios
// .post(`${process.env.REACT_APP_CLINIC_BASE_URL}/patient/resource/delete`, deleteData)
// .then(async (res) => {
//   const scanRes = await axios.get(
//     `${process.env.REACT_APP_CLINIC_BASE_URL}/clinic/patient/resource/getOnboardingImages?patientId=${patient_id}&patientStage=scan&caseId=${case_id}`
//   );
//   setScanUrls(scanRes?.data?.body?.results?.patient_onboarding_images);
//   message.success("Resource Deleted Successfully");
// });
