import dayjs from "dayjs";
import { Row, Col, Divider } from "antd";
import {ReactComponent as UserPdf} from "assets/images/UserPdf.svg";
const PatientInfo = ({ patientInfo, clinicInfo }) => {
  //  console.log("clinicInfoclinicInfo", clinicInfo)
  return (
    <div className="prescriptionPDF-patientContainer">
      <Row align="top" justify="space-between">
        <Col lg={2}>
         <UserPdf/>
        </Col>
        <Col lg={11}>
          <div className="prescriptionPDF-patientRow">
            <label>Patient Name</label>
            <b>
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_first_name
                : ""}{" "}
              {patientInfo?.patient_first_name
                ? patientInfo?.patient_last_name
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Gender</label>
            <b>
              {"--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Date of Birth</label>
            <b>
              {patientInfo?.patient_date_of_birth
                ? dayjs(patientInfo?.patient_date_of_birth).format("DD-MM-YYYY")
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Phone Number</label>
            <b>
              {patientInfo?.patient_phone_number
                ? patientInfo?.patient_phone_number
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Address</label>
            <b>
                {patientInfo?.patient_address
                ? `${patientInfo?.patient_address},`
                : "--"}
                <br/>
                {patientInfo?.patient_city
                ? `${patientInfo?.patient_city},`
                : ""}
                {patientInfo?.patient_county
                ? `${patientInfo?.patient_county},`
                : ""}
                <br/>
                {patientInfo?.patient_country
                ? `${patientInfo?.patient_country},`
                : ""}
                {patientInfo?.patient_postal_code
                ? `${patientInfo?.patient_postal_code}`
                : ""}
            </b>
          </div>
        </Col>
        <Col lg={11}>
          <div className="prescriptionPDF-patientRow">
            <label>Clinic Name</label>
            <b>
              {clinicInfo?.clinic_name
                ? clinicInfo?.clinic_name
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Doctor Name</label>
            <b>
            {clinicInfo?.doctor_name
                ? clinicInfo?.doctor_name
                : "--"}
            </b>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Email </label>
            <span>
              {" "}
              {clinicInfo?.clinic_email
                ? clinicInfo?.clinic_email
                : "--"}
            </span>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Phone Number</label>
            <span>
            {clinicInfo?.country_code_name
                ? clinicInfo?.country_code_name
                : "--"}
              -
              {clinicInfo?.clinic_contact
                ? clinicInfo?.clinic_contact
                : "--"}
            </span>
          </div>
          <div className="prescriptionPDF-patientRow">
            <label>Address</label>
            <span>{clinicInfo?.clinic_address
                ? `${clinicInfo?.clinic_address},`
                : "--"}
                <br/>
                {clinicInfo?.clinic_city
                ? `${clinicInfo?.clinic_city},`
                : ""}
                {clinicInfo?.clinic_county
                ? `${clinicInfo?.clinic_county},`
                : ""}
                <br/>
                {clinicInfo?.clinic_country
                ? `${clinicInfo?.clinic_country},`
                : ""}
                {clinicInfo?.clinic_zipcode
                ? `${clinicInfo?.clinic_zipcode}`
                : ""}</span>
          </div>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default PatientInfo;
