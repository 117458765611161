import InvoiceListComponent from "components/private/invoiceListComponent";

const AllInvoices = ({
  onSearch,
  handleTableChange,
  handleDateFilter,
  totalRecords,
  paymentStatusList,
  paginationLimit,
  invoiceList,
  paginationPage,
  confirmUpdatePaymentStatus,
  fromDate,
  endDate
}) => {
  return (
    <div>
      <div style={{ marginLeft: "1rem" }}>
        <InvoiceListComponent
          paymentStatusList={paymentStatusList}
          paginationLimit={paginationLimit}
          invoiceList={invoiceList}
          paginationPage={paginationPage}
          confirmUpdatePaymentStatus={confirmUpdatePaymentStatus}
          onSearch={onSearch}
          onPagination={handleTableChange}
          handleDateFilter={handleDateFilter}
          totalRecords={totalRecords}
          fromDate={fromDate}
          endDate={endDate}
        ></InvoiceListComponent>
      </div>
    </div>
  );
};

export default AllInvoices;
