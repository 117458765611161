import React, { useEffect, useState } from "react";
// import NotificationsComponent from "../../components/private/notificationsComponent";
import NotificationsComponent from "components/private/Notifications/Notifications";
import { Row, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useSelector } from "react-redux";
import { labApiTasks } from "../../models/lab";
import { changeNotificationState } from "../../services/redux/actions/labActions";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { primaryColor } from "colors-config";

const Notifications = ({ lab, changeNotificationState ,location}) => {
  const [isNotificationsSpinning, setIsNotificationsSpinning] = useState(true);
  const [secondaryFilter, setSecondaryFilter] = useState([]);
  const labState = useSelector((state) => state?.lab);
  const [pageState, setPageState] = useState({
    prevPage: 0,
    nextPage: 1,
    notificationsArray: [],
  });
  const history = useHistory();
  const PAGE_LIMIT = 20;
  const [filterVal, setFilterVal] = useState("ALL");
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [clinicIds, setClinicIds] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const [staffId, setStaffId] = useState();
  useEffect(() => {
    if (queryParams) {
      const typeParams=queryParams.get("type") ;
      if(typeParams=='comment'){
        setSecondaryFilter(["comment"]);
      }
    }
  }, []);
  useEffect(() => {
    if(lab?.labId){
      setStaffId(getStaffId());
      _getPartnered();
    }
   
  }, [lab.labId]);
  const _getPartnered = async () => {
    try {
      const partneredRes = await labApiTasks.getAll("lab/clinicList", {
        limit: 1000,
        page: 1,
        labId: lab.labId,
        status: "Partnered",
      });
      setClinicList(partneredRes.body.data);
    } catch (error) {}
  };

  const getStaffId=()=>{
    const userStaffId = localStorage.getItem("userStaffId") || 0;
    if (!staffId) {
      const userStaffId = lab?.subUser?.id || 0;
      return userStaffId;
    }
    return userStaffId;
  }
  
  const getNotifications = async (pageNo = 1) => {
    let staffId = getStaffId();
    console.log("staffId",staffId);
    
    if (pageNo !== 0 && labState.labId !== null) {
      try {
        setIsNotificationsSpinning(true);
        const notifyUrl = process.env.REACT_APP_NOTIFICATION_URL;
        let notificationApiUrl = `${
          notifyUrl
        }notification/get?user_id=${
          labState.labId
        }&limit=${PAGE_LIMIT}&page=${pageNo}&secondaryFilter=${secondaryFilter?.join(
          ","
        )}`;

        if (filterVal == "Clinic" && clinicIds.length == clinicList.length) {
          notificationApiUrl = notificationApiUrl + "&key=" + filterVal;
        } else if (clinicIds.length && clinicIds.length != clinicList.length) {
          notificationApiUrl =
            notificationApiUrl + "&clinicIds=" + clinicIds.toString();
        }
        else if(filterVal=="MYCASES"){
          notificationApiUrl = notificationApiUrl + "&key=" + filterVal+"&staffId="+staffId;
        }
        else {
          notificationApiUrl = notificationApiUrl;
        }
        const getNotifications = await axios.get(notificationApiUrl);
        setTotalNotifications(getNotifications.data.body.unreadDataCount);
        changeNotificationState(false);
        let tempNotification = [];

        if (pageNo === 1) {
          tempNotification = getNotifications?.data.body?.userData;
        } else {
          tempNotification = [
            ...pageState.notificationsArray,
            ...getNotifications?.data?.body?.userData,
          ];
        }
        pageState.notificationsArray = tempNotification;
        setIsNotificationsSpinning(false);
        pageState.nextPage = getNotifications?.body?.nextPage;
        setPageState({
          ...pageState,
          notificationsArray: tempNotification,
          nextPage: getNotifications?.data?.body?.nextPage,
        });
      } catch (error) {
        console.log("err", error);
        setIsNotificationsSpinning(false);
      }
    }
  };

  useEffect(() => {
    if (lab.labId !== null) {
      getNotifications().then(() => {});
    }
  }, [lab.labId, filterVal, clinicIds, secondaryFilter]);

  return (
    <div>
      <Row style={{ width: "100%" }}>
        <PageHeader
          className="site-page-header"
          onBack={() => history.goBack()}
          title="Back"
          // breadcrumb={{ routes, itemRender }}
        />
      </Row>
      <Spin style={{ width: "100%" }} spinning={isNotificationsSpinning}>
        <InfiniteScroll
          dataLength={pageState?.notificationsArray?.length || 0} //This is important field to render the next data
          next={() => getNotifications(pageState.nextPage)}
          loader={
            pageState.notificationsArray.length > 0 && <h4>Loading...</h4>
          }
          hasMore={pageState.nextPage !== 0}
          endMessage={
            <p style={{ textAlign: "center", color: primaryColor, marginTop:"20px" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Row>
            <NotificationsComponent
              totalNotifications={totalNotifications}
              notificationsArray={pageState.notificationsArray}
              filterVal={filterVal}
              setFilterVal={setFilterVal}
              getNotifications={getNotifications}
              clinicIds={clinicIds}
              setClinicIds={setClinicIds}
              clinicList={clinicList}
              setSecondaryFilter={setSecondaryFilter}
            />
          </Row>
        </InfiniteScroll>
      </Spin>
    </div>
  );
};
const mapStateToProps = (state) => ({
  lab: state.lab,
});
export default connect(mapStateToProps, { changeNotificationState })(
  Notifications
);
