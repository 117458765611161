import { Row, Col, Card } from "antd";
import { showTeethName } from "./data";
import "./teethChart.scss";
import { removeUnderscore } from "components/private/patient-detail/newPrescriptionForm/utils/index";
import { primaryColor } from "colors-config";

const TeethInfo = ({ toothStatuses, chartValue }) => {
  return (
    <div>
      <Card
        className="prescription-card"
        hoverable
        style={{ margin: "30px 0px 20px 0px" }}
      >
        <h1 className="form-heading">Status</h1>
        {toothStatuses ?  toothStatuses?.map((tooth, i) => {
          return (
            <div key={i} style={{ marginTop: "15px" }}>
              <Row gutter={[8, 8]} align="bottom">
                <Col lg={6}>
                  <h2 className="caption toothName" style={{backgroundColor: primaryColor}}>
                    {chartValue === "fdi"
                      ? tooth.toothNo
                      : showTeethName(tooth.toothNo, chartValue)}
                  </h2>
                  <p className="content">{removeUnderscore(tooth.procedure)}</p>
                </Col>
                <Col lg={18}>
                  <div className="paddingLeft">
                    <h2 className="caption">Details</h2>
                    <p className="content">
                      {tooth.detail === "" ? "--" : tooth.detail}
                    </p>
                  </div>
                </Col>
              </Row>

              {tooth.addOns.map((addOn, i) => {
                return (
                  <div key={i} style={{ marginTop: "20px" }}>
                    <h1 className="form-heading">Additional Status</h1>
                    <Row gutter={[8, 8]} align="bottom">
                      <Col lg={6}>
                        <h2 className="caption">Procedure</h2>
                        <p className="content">
                          {removeUnderscore(addOn.procedure)}
                        </p>
                      </Col>
                      <Col lg={18}>
                        <div className="paddingLeft">
                          <h2 className="caption">Details</h2>
                          <p className="content">
                            {addOn.detail === "" ? "--" : addOn.detail}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          );
        }) : ""}
      </Card>
    </div>
  );
};

export default TeethInfo;
