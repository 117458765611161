import { useState, useEffect, useRef } from "react";
import { Form, Spin, message, Row, Col } from "antd";
import { connect } from "react-redux";
import TeethChart from "components/shared/TeethChart/TeethChart";
import Occlusion from "./Occlusion/Occlusion";
import Periodontium from "./Periodontium/Periodontium";
// import { ReactComponent as AddIcon } from "../../../assets/images/teeths/addIcon.svg";
// import { ReactComponent as SelectIcon } from "../../../assets/images/selectIcon.svg";

import IPRAlignment from "./IPRAlignment/IPRAlignment";
import Duration from "./Duration/Duration";
import Treatment from "./Treatment/Treatment";
import Footer from "./FormFooter/Footer";
import TreatmentType from "./TreatmentTypes/TreatmentTypes";
import "./prescriptionForm.scss";
import { getProducts } from "api/lab";
import SmileTreatment from "./SmileTreatment/SmileTreatment";
import { companyType } from "colors-config";
import { renderTreatmentTypes } from "utils/commonMethod";

const PrescriptionForm = ({
  clinic,
  formDataObjectRef,
  formData,
  disabled,
  selectedVersion,
  teethChartRef,
  printFormRef,
  lab,
  chartValue,
  setChartValue 
}) => {
  const [loading, setLoading] = useState(false);
  const [alignerComments, setAlignerComments] = useState([true]);
  const [teethChartData, setTeethChartData] = useState([]);
  const [labProducts,setLabProducts] = useState([]);
  const [labProductDetails,setLabProductDetails] = useState();


  useEffect(()=>{
    if(lab.labId){
      getAllLabProducts(lab.labId);
    }
  },[lab])

  const getAllLabProducts=async (labId)=>{
    try {
     
      const labProductsRes = await getProducts(labId);
      if(labProductsRes?.data?.labProducts?.length){
        setLabProducts(labProductsRes.data.labProducts);
      }
      
    } catch (error) {
      message.error("Some Error occured!")
      console.error(error);
    }
  }
  useEffect(()=>{
    if(labProducts.length && formData?.labProductId){
      const findProduct=labProducts.find(obj=>obj.id==formData?.labProductId)
        if(findProduct){
          setLabProductDetails(<span>{findProduct.productName} {findProduct.currency} {findProduct.price}</span>)
        }
      }
    
  },[labProducts,formData])

  useEffect(() => {
    if (formData) {
      // console.log("formDataformDataformDataformData", formData);
      if (formData?.teethChart?.length > 0) {
        const mergedData = formData?.teethChart.reduce((acc, currentItem) => {
          const existingItem = acc.find(
            (item) => item.tooth_no === currentItem.tooth_no
          );
          if (existingItem) {
            if (currentItem.isAdditional) {
              existingItem.addOns.push({
                ...currentItem,
                procedure: currentItem.toothProcedure,
              });
            } else {
              existingItem = { ...existingItem, ...currentItem, addOns: [] };
            }
          } else {

            acc.push({
              ...currentItem,
              procedure: currentItem.toothProcedure,
              toothNo: currentItem.tooth_no,
              addOns: currentItem.isAdditional
                ? [{ ...currentItem, procedure: currentItem.toothProcedure }]
                : [],
            });
          }

          return acc;
        }, []);
        //  console.log("mergedDatamergedDatamergedData", mergedData);
        setTeethChartData(mergedData);
      }
    }
  }, [formData, selectedVersion]);
  return (
    <div
      className="prescription-form-container"
      ref={printFormRef}
    >
      <Spin spinning={loading}>
        <Row gutter={[12, 12]}>
          <Col lg={12}>
            <div
              style={{
                background: "#fff",
                borderRadius: "8px",
                padding: "24px 20px 16px 20px",
              }}
            >
              {renderTreatmentTypes(companyType, formData?.createdAt).show ? (
                <SmileTreatment
                  smilelignTreatmentTypeData={
                    formData?.smilelignTreatmentTypeData
                  }
                  smilelignArches={formData?.smilelignArches}
                  smilelignWhitening={formData?.smilelignWhitening}
                  phasing={formData?.phasing}
                  smilelignTreatmentTypeId={formData?.smilelignTreatmentTypeId}
                  prescriptionPhasing={formData?.prescriptionPhasing}
                />
              ) : (
                <TreatmentType
                  treatmentTypes={formData?.treatmentTypes}
                  alignerComments={alignerComments}
                  formDataObjectRef={formDataObjectRef}
                  phasing={formData?.phasing}
                  labProductDetails={labProductDetails}
                />
              )}

              <Treatment
                openbite={formData?.openbite}
                overjet={formData?.overjet}
                overbite={formData?.overbite}
                crossBite={formData?.crossbite}
                midline={formData?.midline}
                openBiteValue={formData?.openBiteValue}
                crossBiteValue={formData?.crossBiteValue}
              />
              <IPRAlignment
                ipr={formData?.ipr}
                attachments={formData?.attachments}
                crowding={formData?.crowding}
                incisal={formData?.alignment}
                spacing={formData?.spacing}
              />
              <Duration
                duration={formData?.duration}
                detail={formData?.prescriptionDetails}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div
              className="col"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div style={{ flex: "0 0 auto",}}>
              <TeethChart
                teethChartData={teethChartData}
                ref={teethChartRef}
                country={clinic?.country}
                disabled={disabled}
                chartValue={chartValue}
                setChartValue={setChartValue}
              />
              </div>
              <div
                style={{
                  marginTop: "15px",
                  padding: "24px 20px 16px",
                  backgroundColor: "rgb(255, 255, 255)",
                  borderRadius: "8px",
                  height:"100%",
                  flexGrow: 1,
                }}
              >
                <Occlusion
                  dentalClass={formData?.dentalClass}
                  dentalDivision={formData?.dentalDivision}
                  dentalSide={formData?.dentalSide}
                  skeletalClass={formData?.skeletalClass}
                  skeletalDivision={formData?.skeletalDivision}
                  skeletalSide={formData?.skeletalSide}
                  parafunctionDetails={formData?.parafunctionDetails}
                />
                <Periodontium
                  bioType={formData?.bioType}
                  recession={formData?.recession}
                />
              </div>
              </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
  lab: state.lab
});

export default connect(mapStateToProps)(PrescriptionForm);
