export const TABLE_ACTION_TYPES = {
  RESET_PASSWORD: "reset-password",
  RESEND_INVITE: "resend",
  DELETE_INVITE: "delete",
};

export const staffRolesOptions = [
  { label: "Admin", value: "admin" },
  { label: "User", value: "user" },
];

export const STAFF_STATUS = {
  EMAIL_SENT: "email-sent",
  ACTIVE: "active",
};
