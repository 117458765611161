import { createSelector } from "reselect";
import { capitalizeFirstLetter } from "utils/commonMethod";
import { subUserSelector } from "./lab.selectors";
const getStaff = (state) => state.staff;

export const staffMembersSelectors = createSelector(getStaff, (staff) => staff.members);

export const staffDropDowSelector = createSelector(
  [staffMembersSelectors, subUserSelector],
  (staff, currentUser) => {
    const mappedStaff = staff.map((el) => {
      let label = `${capitalizeFirstLetter(el?.firstName || "")} ${
        el.role === "super-admin" ? "" : capitalizeFirstLetter(el?.lastName || "")
      }`;

      const name = label;
      if (currentUser.id === el.id) {
        label = label + " (Assign to me)";
      }
      return {
        ...el,
        value: el.id,
        label,
        name,
      };
    });

    return mappedStaff.sort((a, b) => a.name.localeCompare(b.name));
  }
);
