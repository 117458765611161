import { returnErrors } from "./errorActions";

import { labApiTasks } from "../../../models/lab";
import firebase from "firebase/app";
import { getLabStaff } from "api/staff";
import { capitalizeFirstLetter } from "utils/commonMethod";
import { getFirebaseToken } from "../../../firebase";
import errorModal from "../../../components/shared/modals/errorModal";
import { autoPopulateLabDetailsOnFirstLogin } from "api/lab";
import axios from "axios";

import {
  AUTH_ERROR,
  LAB_LOADED,
  GET_LOV,
  CLEAR_LAB,
  NOTIFICATION_STATE_CHANGE,
  UPDATE_COMMENT_BOX_STATE,
  GET_COUNTRIES_CODES,
  UPDATE_LAB_DATA,
  LAB_NOTIFICATION_LOADED,
  LAB_NOTIFICATION_READ,
  PATIENT_COUNTRY_CODES,
  LOAD_STAFF_MEMBERS,
} from "./types";
import { getPatientCountryCodesService } from "api/patient";

export const tokenExpired = () => async (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
  });
};

export const fetchLOV = () => async (dispatch, getState) => {
  try {
    const response = await labApiTasks.getAll("lab/patient/statusFilter", {
      labId: getState().lab.labId,
    });
    await dispatch({
      type: GET_LOV,
      payload: response.body,
    });
  } catch (error) {
    console.log(error);
    errorModal(error.message);
    await dispatch(returnErrors(error, error.code));
  }
};

export const staffMembers = (payload) => {
  return {
    type: LOAD_STAFF_MEMBERS,
    payload,
  };
};
// Check token & load user
export const loadLab = () => async (dispatch, getState) => {
  try {
    const res = await autoPopulateLabDetailsOnFirstLogin({
      labEmail: getState().auth.user.attributes.email,
    });

    localStorage.setItem("userStaffId", res?.data?.data?.subUser?.id || 0);
    const staff = await getLabStaff(res.data.data.lab_id);

    const mappedStaff =
      staff?.data?.data?.map((el) => ({
        ...el,
        value: el.id,
        label: `${capitalizeFirstLetter(el?.firstName || "")} ${
          el.role === "super-admin" ? "" : capitalizeFirstLetter(el?.lastName || "")
        }`,
      })) || [];

    await dispatch(staffMembers(mappedStaff));

    if (firebase.messaging.isSupported()) {
      getFirebaseToken()
        .then(async (token) => {
          try {
            await labApiTasks.post("deviceToken/add", {
              user_id: res.data.data.lab_id,
              device_token: token,
            });

            localStorage.setItem("device_token", token);
            dispatch(loadLabNotificationCount());
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => console.log("failed: ", err));
    }

    await dispatch({
      type: LAB_LOADED,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);

    // errorModal(error.message);
    await dispatch(returnErrors(error, error.code));
  }
};

export const clearLab = () => async (dispatch, getState) => {
  await dispatch({
    type: CLEAR_LAB,
    payload: null,
  });
};

export const changeNotificationState = (isActive) => async (dispatch, getState) => {
  await dispatch({
    type: NOTIFICATION_STATE_CHANGE,
    payload: { isActive },
  });
};
export const changeCommentBoxState = (payload) => async (dispatch) => {
  await dispatch({
    type: UPDATE_COMMENT_BOX_STATE,
    payload,
  });
};

export const countriesAndCodes = (payload) => {
  return {
    type: GET_COUNTRIES_CODES,
    payload,
  };
};

export const updateLabData = (payload) => {
  return {
    type: UPDATE_LAB_DATA,
    payload,
  };
};

// Logout User

export const loadLabNotificationCount = () => async (dispatch, getState) => {
  try {
    const labId = getState().lab.labId;
    if (labId) {
      let notificationApiUrl = `${process.env.REACT_APP_NOTIFICATION_URL}notification/get?user_id=${labId}&limit=1&page=1&secondaryFilter=`;

      const getNotifications = await axios.get(notificationApiUrl);

      await dispatch({
        type: LAB_NOTIFICATION_LOADED,
        payload: getNotifications?.data?.body?.unreadDataCount
          ? getNotifications?.data?.body?.unreadDataCount
          : 0,
      });
    }
  } catch (error) {
    console.log(error);

    // errorModal(error.message);
    await dispatch(returnErrors(error, error.code));
  }
};

export const updateReadCount = (count) => async (dispatch, getState) => {
  let notificationCount = count == 0 ? 0 : getState().lab.notificationCount - 1;
  if (notificationCount < 1) {
    notificationCount = 0;
  }
  await dispatch({
    type: LAB_NOTIFICATION_READ,
    payload: notificationCount,
  });
};

export const addReadCount = () => async (dispatch, getState) => {
  let notificationCount = getState().lab.notificationCount + 1;
  await dispatch({
    type: LAB_NOTIFICATION_READ,
    payload: notificationCount,
  });
};

export const getAllCountriesAndCodes = () => async (dispatch, getState) => {
  try {
    const { data } = await getPatientCountryCodesService();
    await dispatch({
      type: PATIENT_COUNTRY_CODES,
      payload: data?.data?.data,
    });
  } catch (error) {
    console.error(error);
  }
};
