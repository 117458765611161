import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import styles from "./appModal.module.scss";

const AppModal = ({
  title,
  open,
  onOk,
  onCancel,
  children,
  modalFooter,
  confirmLoading,
  okText,
  cancelText,
  showFooter,
  width,
  ...rest
}) => {
  const renderFooter = () => {
    if (showFooter === null) {
      return showFooter;
    }
    if (showFooter) {
      return modalFooter;
    }
    return (
      <div>
        <Button className={`${styles["footer-btn"]} ${styles["cancel-btn"]}`} shape="round" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button
          className={styles["footer-btn"]}
          loading={confirmLoading}
          type="primary"
          shape="round"
          onClick={onOk}
        >
          {okText}
        </Button>
      </div>
    );
  };
  return (
    <Modal
      width={width}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={renderFooter()}
      {...rest}
    >
      {children}
    </Modal>
  );
};

// PropTypes for validation
AppModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  modalFooter: PropTypes.node,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  showFooter: PropTypes.oneOf([true, null, false]),
  width: PropTypes.number,
};

// Default props
AppModal.defaultProps = {
  title: "Title",
  onOk: () => {},
  onCancel: () => {},
  open: false,
  modalFooter: null,
  okText: "Ok",
  cancelText: "Cancel",
  showFooter: false,
};

export default AppModal;
