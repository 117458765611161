import React, { useState, useEffect } from "react";

import { message, Button, Typography, Row, Spin, Col, Form } from "antd";

import { connect, useSelector } from "react-redux";
import axios from "axios";
import { labApiTasks } from "models/lab";
import { loadLab } from "services/redux/actions/labActions";
import { saveProducts, getCurrencies, getProducts } from "api/lab";
import "../../../assets/styles/routes/onboarding.scss";
import errorModal from "components/shared/modals/errorModal";
import Settings from "components/private/Settings/index";
import { labFormResources } from "api/lab";
import {
  getProfileButtonText,
  getUniquePrescriptionProducts,
  productServicesFormInitialValues,
  productsServiceMapper,
} from "utils/Lab";
import {
  confirmWhiteLabelFileAPI,
  getWhiteLabelling,
  saveWhiteLabelling,
  uploadWhiteLabelFileAPI,
} from "api/clinic";
import AppButton from "components/shared/AppButton/AppButton";
import { userRoleSelector } from "services/redux/selectors/lab.selectors";
import { isSuperAdmin } from "utils/auth";
const { Title } = Typography;

function Profile({ lab, loadLab }) {
  const userRole = useSelector(userRoleSelector);
  const [loading, setLoading] = useState(false);
  const [isButtonSpinning, setIsButtonSpinning] = useState(false);
  const [labDetails, setLabDetails] = useState({});
  const [fileListTermsAndConditions, setFileListTermsAndConditions] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [productsAndServices, setProductAndServices] = useState([]);
  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] = useState([]);

  const [fileListForm, setFileListForm] = useState([]);
  const [mode, setMode] = useState("view");
  const [productList, setProductList] = useState([]);
  const [prescriptionProductList, setPrescriptionProductList] = useState([]);

  const [profileForm] = Form.useForm();
  const [productAndServicesForm] = Form.useForm();
  const [productFormData, setProductFormData] = useState([]);
  const [whiteLabelData, setWhiteLabelData] = useState(null);
  const [loginImageFile, setLoginImageFile] = useState([]);
  const [splashImageFile, setSplashImageFile] = useState([]);
  const [treatmentImageFile, setTreatmentImageFile] = useState([]);

  const [whiteLabelForm] = Form.useForm();
  const parseFiles = (files) => {
    let termsAndConditionFiles = [];
    let formFiles = [];
    files.forEach((file) => {
      if (file.lab_document_type === "Lab Terms & Conditions") {
        termsAndConditionFiles.push({
          name: file.file_name,
          status: "done",
          type: file.file_type,

          url: file.resource_url,
          documentType: file.lab_document_type,
        });
      } else if (file.lab_document_type === "Data Acquisition Form")
        formFiles.push({
          name: file.file_name,
          status: "done",
          type: file.file_type,

          url: file.resource_url,
          documentType: file.lab_document_type,
        });
    });

    return [termsAndConditionFiles, formFiles];
  };

  useEffect(() => {
    const fetchLabProfile = async () => {
      try {
        setLoading(true);

        const currencyRes = await getCurrencies();
        setProductOrServiceCurrencyOptions(currencyRes?.data?.data);

        productServicesValueSetter(null, currencyRes?.data?.data, null);
        const res = await labApiTasks.getAll("lab/getLabDetailsByLabId", {
          labId: lab.labId,
        });

        const labDetailsFromDB = res.body.labServices[0];
        const labDetailsObject = {
          labName: labDetailsFromDB.lab_name,
          labAdminName: labDetailsFromDB.lab_admin_name,
          labServiceID: labDetailsFromDB.lab_service_id,
          businessRegistrationNo: labDetailsFromDB.lab_registration_number,
          email: labDetailsFromDB.lab_email,
          county: labDetailsFromDB.lab_county,
          country: labDetailsFromDB.lab_country,
          city: labDetailsFromDB.lab_city,
          address: labDetailsFromDB.lab_address,
          postalCode: labDetailsFromDB.lab_postal_code,
          phone: labDetailsFromDB.lab_phone_number,
          prefix: labDetailsFromDB.lab_country_code_name,
        };
        setLabDetails(labDetailsObject);

        await getLabProducts(currencyRes?.data?.data, labDetailsFromDB.refinementSeries);

        const resourceRes = await labFormResources(lab.labId);
// console.log(resourceRes)
        // labApiTasks.getAll("lab/getResource", {
        //   labId: lab.labId,
        // });
        // const [termsAndConditionFiles, formFiles] = parseFiles(resourceRes.body.lab_resources);
        if(resourceRes?.data){
          setFileListForm(resourceRes.data);
        }
     
        // setFileListTermsAndConditions(termsAndConditionFiles);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (lab.labId) {
      fetchLabProfile();
    }
  }, [lab.labId]);

  useEffect(() => {
    const fetchLabWhiteLabel = async () => {
      try {
        setLoading(true);
        const { data } = await getWhiteLabelling(lab.labId);
        setWhiteLabelData(data?.data?.settings);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (lab.labId) {
      fetchLabWhiteLabel();
    }
  }, [lab.labId]);

  const handleSubmit = () => {
    if (activeTab === "1") {
      profileForm.submit();
    }
    if (activeTab === "2") {
      productAndServicesForm.submit();
    } else {
      whiteLabelForm.submit();
    }
  };

  const productServicesValueSetter = (ps, def, pres, refinementSeries = 1) => {
    let formData;
    if (ps?.length > 0) {
      formData = productsServiceMapper(ps);
    } else {
      formData = productServicesFormInitialValues(def[0]?.currency_id);
    }

    productAndServicesForm.setFieldsValue({
      ...formData,
      prescriptionProducts: pres,
      refinementSeries,
    });

    setProductFormData(formData.productsServices);
  };

  const onProductsFormSubmit = async (values) => {
    const finalPrescriptionProducts = values.prescriptionProducts;
    const result = getUniquePrescriptionProducts(finalPrescriptionProducts);
    if (!result) {
      return message.error("Duplicate prescription products and services");
    }
    setIsButtonSpinning(true);
    setLoading(true);
    const finalValues = values.productsServices.map((product, index) => {
      const matchedOption = productOrServiceCurrencyOptions.find(
        (p) => p.currency_id == product.currencyId
      );
      return {
        ...product,
        product_order: index + 1,
        currency: matchedOption
          ? matchedOption.currency_name
          : productOrServiceCurrencyOptions[0]?.currency_name,
      };
    });
    if (!finalValues.every((i) => i.productName && (i.price || i.free))) {
      message.error("All fields are required");
      setIsButtonSpinning(false);
      return false;
    }

    if (
      productDuplicateValidate(
        finalValues.map((obj) => {
          return {
            productName: obj.productName,
            arches: obj.arches,
            quantityType: obj.quantityType,
          };
        })
      )
    ) {
      message.error("Remove duplicate products");
      setIsButtonSpinning(false);
      setLoading(false);
      return false;
    }

    try {
      const productData = await saveProducts(
        {
          productsServices: finalValues,
          productNames: productList,
          prescriptionProducts: finalPrescriptionProducts,
          refinementSeries: values.refinementSeries,
        },
        lab.labId
      );
      // console.log('productData',productData)
      setProductAndServices(productData.data.labProducts);
      setPrescriptionProductList(productData?.data?.prescriptionPrducts);
      productServicesValueSetter(
        productData.data.labProducts,
        productOrServiceCurrencyOptions[0]?.currency_id,
        productData?.data?.prescriptionPrducts,
        values.refinementSeries
      );
      message.success("Lab products updated");
    } catch (error) {
      console.log(error);
      message.error("One or more Products are in used");
      await getLabProducts();
    } finally {
      setLoading(false);
      setIsButtonSpinning(false);
      setMode("view");
    }
  };

  const getLabProducts = async (tempProductServices, refinementSeries) => {
    const productsRes = await getProducts(lab.labId);
    setProductAndServices(productsRes?.data?.labProducts);
    setPrescriptionProductList(productsRes?.data?.prescriptionPrducts);

    productServicesValueSetter(
      productsRes?.data?.labProducts,
      tempProductServices ? tempProductServices : productOrServiceCurrencyOptions[0]?.currency_id,
      productsRes?.data?.prescriptionPrducts,
      refinementSeries
    );
  };

  const productDuplicateValidate = (list) => {
    const map = new Map();
    const duplicates = [];

    list.forEach((obj) => {
      const key = JSON.stringify(obj);
      if (map.has(key)) {
        duplicates.push(obj);
      } else {
        map.set(key, true);
      }
    });
    return duplicates.length ? true : false;
  };

  const onProfileFormSubmit = async (labDetails) => {
    // setLoading(true);

    const addLabBody = {
      labName: labDetails.labName,
      labAdminName: labDetails.labAdminName,
      labServiceID: labDetails.labServiceID,
      labRegistrationNumber: labDetails.businessRegistrationNo,
      labID: lab.labId,
      labCountry: labDetails.country,
      labCity: labDetails.city,
      labAddress: labDetails.address,
      labCounty: labDetails.county ? labDetails.county : "",
      labZipcode: labDetails.postalCode,
      labPhoneNumber: labDetails.phone,
      labCountryCode: labDetails.prefix,
    };

    try {
      setIsButtonSpinning(true);
      await labApiTasks.post("lab/addLab", addLabBody);
    } catch (error) {
      errorModal(error.response.data.err.message);

      console.log(error);
    } finally {
      message.success("Lab details updated.");
      setMode("view");
      loadLab();
      // setLoading(false);
      setIsButtonSpinning(false);
    }
  };

  const onChange = (key) => {
    setActiveTab(key);
  };
  const onCancelProductClick = async () => {
    setMode("view");
    await getLabProducts();
  };

  const uploadService = async (preSignedResponse, file) => {
    // console.log("preSignedResponse", preSignedResponse, file);
    const config = {
      headers: {
        "content-type": file.type,
      },
    };
    // const accessUrl = preSignedResponse?.data.body.success.accessUrl;
    const uploadUrl = preSignedResponse?.data?.uploadUrl;
    const response = await axios.put(uploadUrl, file, config);
    return response;
  };

  const confirmService = async (preSignedResponse, labId, filename) => {
    const { url, fileName } = preSignedResponse?.data;
    const confirmRequestBody = {
      file_url: url,
      resource_type: "file",
      scan_type: fileName,
      case_id: 1,
      notify: false,
    };
    await confirmWhiteLabelFileAPI(confirmRequestBody);
  };

  const whiteLabelFileMapper = (fileObj, labId) => {
    const { name, type, imageType } = fileObj;
    const preSignRequestBody = {
      fileName: name,
      mimeType: fileObj.type,
    };
    return preSignRequestBody;
  };

  const uploadSingleFile = async (clinicalFile, labId, index) => {
    const preSignedRequestBody = whiteLabelFileMapper(clinicalFile, labId);
    // console.log("preSignedRequestBody", preSignedRequestBody);
    const preSignedResponse = await uploadWhiteLabelFileAPI(preSignedRequestBody, labId);
    await uploadService(preSignedResponse, clinicalFile);
    await confirmService(preSignedResponse, clinicalFile.filename);

    return {
      uploadedFile: preSignedResponse?.data.fileName,
      imageType: clinicalFile.imageType,
    };
  };

  const whiteLabelFilesUploader = async (files, labId) => {
    const promiseArray = [];
    files.forEach((file, index) => {
      promiseArray.push(uploadSingleFile(file, labId, index));
    });
    const finalRes = await Promise.all(promiseArray.map((p) => p.catch((e) => e)));
    // console.log("finalRes", finalRes);
    return finalRes;
  };

  const onWhiteLabelSubmit = async (values) => {
    //  console.log("onWhiteLabelSubmit values ",values )
    let filesArray = [];
    let fileUrls = [];
    filesArray = [...loginImageFile, ...splashImageFile, ...treatmentImageFile];
    //  console.log("filesArray", filesArray)
    // if (!whiteLabelData.fileUrls.length && !filesArray.length) {
    //   return message.error("Please upload App Images");
    // }

    fileUrls = await whiteLabelFilesUploader(filesArray, lab?.labId);

    const transformArrayToObject = (array) => {
      return array.reduce((acc, item) => {
        acc[item.imageType] = item;
        return acc;
      }, {});
    };

    const outputObject = transformArrayToObject(fileUrls);

    setLoading(true);
    try {
      const whiteLabelPayload = {
        labId: lab.labId,
        settings: {
          ...values,
          loginImage: outputObject.loginImage ? outputObject.loginImage : whiteLabelData.loginImage,
          splashImage: outputObject.splashImage
            ? outputObject.splashImage
            : whiteLabelData.splashImage,
          treatmentImage: outputObject.treatmentImage
            ? outputObject.treatmentImage
            : whiteLabelData.treatmentImage,
          //  fileUrls: fileUrls.length ? fileUrls : whiteLabelData.fileUrls,
        },
      };
      const { data } = await saveWhiteLabelling(whiteLabelPayload);
      console.log("data", data);
      message.success("WhiteLabel settings saved successfully");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setWhiteLabelData({
        ...values,
        loginImage: outputObject.loginImage ? outputObject.loginImage : whiteLabelData.loginImage,
        splashImage: outputObject.splashImage
          ? outputObject.splashImage
          : whiteLabelData.splashImage,
        treatmentImage: outputObject.treatmentImage
          ? outputObject.treatmentImage
          : whiteLabelData.treatmentImage,
      });
      setMode("view");
    }
  };
  return (
    <Spin spinning={loading}>
      <div className="onboarding-container">
        <Row justify="space-between" align="middle" className="header-row">
          <Title
            level={3}
            style={{
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Profile & Settings
          </Title>{" "}
          {isSuperAdmin(userRole) && (
            <>
              {" "}
              {activeTab !== "3" && (
                <>
                  {mode === "view" ? (
                    <AppButton type="primary" onClick={() => setMode("edit")} style={{width:"100px"}}>
                      {mode === "view" && "Edit"}
                    </AppButton>
                  ) : null}
                </>
              )}
            </>
          )}
        </Row>
        <Settings
          activeKey={activeTab}
          onChange={onChange}
          onProfileFormSubmit={onProfileFormSubmit}
          labDetails={labDetails}
          fileListTermsAndConditions={fileListTermsAndConditions}
          fileListForm={fileListForm}
          mode={mode}
          setMode={setMode}
          profileForm={profileForm}
          productAndServicesForm={productAndServicesForm}
          onProductsFormSubmit={onProductsFormSubmit}
          productsAndServices={productsAndServices}
          productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
          defaultCurrency={productOrServiceCurrencyOptions[0]?.currency_id}
          lab={lab}
          productList={productList}
          setProductList={setProductList}
          setPrescriptionProductList={setPrescriptionProductList}
          prescriptionProductList={prescriptionProductList}
          setProductFormData={setProductFormData}
          productFormData={productFormData}
          whiteLabelForm={whiteLabelForm}
          whiteLabelData={whiteLabelData}
          onWhiteLabelSubmit={onWhiteLabelSubmit}
          loginImageFile={loginImageFile}
          setLoginImageFile={setLoginImageFile}
          splashImageFile={splashImageFile}
          setSplashImageFile={setSplashImageFile}
          treatmentImageFile={treatmentImageFile}
          setTreatmentImageFile={setTreatmentImageFile}
        />

        {mode !== "view" && (
          <Row justify="end">
            <Col>
              <div className="footer-buttons">
                {mode === "edit" && (
                  <AppButton
                    shape="round"
                    onClick={onCancelProductClick}
                    className="footer-btn cancel-btn"
                  >
                    Cancel
                  </AppButton>
                )}
                <AppButton
                  type="primary"
                  shape="round"
                  // htmlType="submit"
                  loading={isButtonSpinning}
                  onClick={handleSubmit}
                  className="footer-btn save-btn"
                >
                  {getProfileButtonText(lab?.isFirstLogin, activeTab)}
                </AppButton>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps, { loadLab })(Profile);
