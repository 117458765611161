import { useEffect, useState } from "react";
import { Spin } from "antd";
import ProductServicesForm from "./ProductServicesForm/ProductServicesForm";
import {
  productServicesFormInitialValues,
  productsServiceMapper,
} from "utils/Lab";

import { getProductsName } from "api/lab";

const ProductServicesContainer = ({
  mode,
  productAndServicesForm,
  onProductsFormSubmit,
  productsAndServices,
  productOrServiceCurrencyOptions,
  defaultCurrency,
  lab,
  productList,
  setProductList,
  setProductFormData,
  productFormData,
  setPrescriptionProductList,
  prescriptionProductList,

  
}) => {
  const [productsHide, setProductsHide] = useState([false]);
  const [prescriptionProductsHide, setPrescriptionProductsHide] = useState([false]);

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log("productsAndServices",productsAndServices)
  //   let formData;
  //   if (productsAndServices?.length > 0) {
  //     formData = productsServiceMapper(productsAndServices);
  //   } else {
  //     formData = productServicesFormInitialValues(defaultCurrency);
  //   }
  //   // setProductFormData(formData.productsServices);
  //   productAndServicesForm.setFieldsValue(formData);
  // }, [productsAndServices]);

  useEffect(() => {
    (async () => {
      let finalNames = [];
      try {
        const productRes = await getProductsName(lab.labId);
        if (productRes.data.data) {
          finalNames = productRes?.data?.data;
        } else {
          finalNames = [];
        }
      } catch (error) {
      } finally {
        setProductList(finalNames);
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  return (
    <div className="product-services-container">
      <Spin spinning={loading}>
     
        <ProductServicesForm
          mode={mode}
          productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
          productAndServicesForm={productAndServicesForm}
          onProductsFormSubmit={onProductsFormSubmit}
          setProductsHide={setProductsHide}
          productsHide={productsHide}
          defaultCurrency={defaultCurrency}
          lab={lab}
          productList={productList}
          setProductList={setProductList}
          setPrescriptionProductList={setPrescriptionProductList}
          prescriptionProductList={prescriptionProductList}
          productFormData={productFormData}
          setProductFormData={setProductFormData}
          prescriptionProductsHide={prescriptionProductsHide}
          setPrescriptionProductsHide={setPrescriptionProductsHide}
        />
      </Spin>
    </div>
  );
};

export default ProductServicesContainer;
