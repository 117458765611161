import { Spin, Image } from "antd";
import { FileOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import PlaceholderChatIcon from "components/shared/Icon/PlaceholderChat";
// import React from "react";
import PropTypes from "prop-types";
import styles from "./labChat.module.scss";

function urlify(text) {
  const urlRegex = /https?:\/\/[^\s<>"']+(?![^<>]*>)/g;
  return text.replace(urlRegex, function (url, b, c) {
    var url2 = c == "www." ? "http://" + url : url;
    return '<a class="hyperlinksChat" href="' + url2 + '" target="_blank">' + url + "</a>";
  });
}

const replaceNewLine = (comment_description) => {
  if (comment_description) {
    comment_description = comment_description.replaceAll("\n", "<br>");
  }
  return comment_description;
};

const Message = ({ isSender, name, content, date, primaryColor }) => {
  return (
    <div
      className={`${styles["chat__message"]} ${
        isSender ? styles["chat__sender__comment"] : styles["chat__receiver__comment"]
      } `}
    >
      <div
        className={styles["chat-message__content"]}
        style={{
          backgroundColor: isSender ? primaryColor : "#f0f0f0",
          color: isSender ? "#fff" : "",
        }} // Customize the background color
      >
        <span className={styles["chat-message__name"]}>{name}</span>
        <div className={styles["chat-message__text"]}>{content}</div>
        <span style={{ color: isSender ? "#fff" : "" }} className={styles["chat-message__date"]}>
          {date}
        </span>
      </div>
    </div>
  );
};

Message.propTypes = {
  isSender: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  primaryColor: PropTypes.string,
};

const DisplayChat = ({ labMessages, isLoading, labChatScrollRef, clinicName, labName }) => {
  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    link.click();
    link.remove();
  };
  const showContent = (commentData) => {
    if (commentData?.type === "image") {
      return <Image width={200} src={commentData.comment_description} />;
    }

    if (commentData?.type === "file") {
      return (
        <div
          title={commentData.metaData.name}
          onClick={() => handleDownload(commentData.comment_description, commentData.metaData.name)}
          className="file-container"
        >
          <FileOutlined style={{ fontSize: "25px" }} />
          <span className="file-name">{commentData.metaData.name}</span>
        </div>
      );
    }

    return (
      <div
        className="no-margin"
        dangerouslySetInnerHTML={{
          __html: urlify(replaceNewLine(commentData.comment_description)),
        }}
      />
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div ref={labChatScrollRef} className={styles["lab-chat__container"]}>
        {labMessages?.length ? (
          labMessages.map((data) => {
            const isSender = data.sender_id?.includes("LAB") || false;
            const name = isSender ? labName : clinicName;

            return (
              <Message
                key={data.clinic_comments_tbl_id}
                isSender={isSender}
                name={name}
                content={showContent(data)}
                date={data.created_date}
                primaryColor={primaryColor}
              />
            );
          })
        ) : (
          <div className={styles["chat-container__empty"]}>
            <PlaceholderChatIcon style={{ fontSize: "150px", color: primaryColor }} />
            <p className={styles["chat-container__empty-text"]}>No Chat with Clinic</p>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default DisplayChat;
