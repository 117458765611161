import { LAB_USER_ROLES } from "../constants";

export const isAdmin = (role) => {
  if (!role || typeof role !== "string") {
    return false;
  }
  return LAB_USER_ROLES.SUPER_ADMIN === role || LAB_USER_ROLES.ADMIN === role;
};

export const isSuperAdmin = (role) => {
  if (!role) {
    return false;
  }
  return LAB_USER_ROLES.SUPER_ADMIN === role;
};
