import { createSelector } from "reselect";

const getLab = (state) => state.lab;

export const getLabSelector = createSelector([getLab], (lab) => lab);

export const getCountriesSelector = createSelector(
  [getLabSelector],
  (lab) => lab.countries // Modify the logic as needed
);

export const getLabId = createSelector(
  [getLabSelector],
  (lab) => lab.labId // Modify the logic as needed
);

export const getCodesSelector = createSelector(
  [getLabSelector],
  (lab) => lab.codes // Modify the logic as needed
);

export const getIsFirstLogin = createSelector(
  [getLabSelector],
  (lab) => lab.isFirstLogin // Modify the logic as needed
);


export const getPatientCountryCodesList = createSelector(
  [getLabSelector],
  (lab) => lab.patientCountryCodesList
);