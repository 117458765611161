import React from "react";
// import EmailIcon from "assets/images/envelope.png";
import "../../assets/styles/components/patientsDetail.scss";
import { ReactComponent as AddressIcon } from "assets/images/address_icon.svg";
import UserIcon from "../../assets/images/user_icon.svg";
// import EmailIcon from "../../assets/images/envelope.png"
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar_icon.svg";
import { ReactComponent as SmartphoneIcon } from "../../assets/images/smartphone_icon.svg";
import { ReactComponent as EmailIcon } from "assets/images/envelope.svg";

import moment from "moment";
import { primaryColor } from "colors-config";

const ClinicInformation = ({ clinicInfo, clinicDetails }) => {
  const getClinicTime = () => {
    const dummyDate = "2017-03-13";
    if (clinicDetails?.clinic_start_time) {
      return moment(dummyDate + " " + clinicDetails?.clinic_start_time).format(
        "HH:mm"
      ) + " - " + moment(dummyDate + " " + clinicDetails?.clinic_end_time).format(
        "HH:mm"
      )
    }
    return "Not available";

  }
  return (
    <div style={{ width: "100%" }}>
      <div className="patient-detail-container-clinicInfo">
        <div className="patient-detail-topRow">
          <div className="patient-detail-topRow-profile">
            <div className="profile-icon">
              <img src={UserIcon} className="user-icon-image" alt="Edit" />
            </div>
            <div className="patient-name">
              <div className="subheading">
                {clinicInfo?.clinic_name}
              </div>
              <div className="caption">
                {clinicInfo?.clinic_id}
              </div>
            </div>
          </div>
        </div>
        <div className="patient-detail-bottomRow" style={{ marginTop: "30px" }}>
          <div className="patient-info">
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "5px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="caption" style={{ marginLeft: "-84px" }}>
                Registration No:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "12px",
                }}
              >
                <div className="content" style={{ marginLeft: "8px" }}>
                  {clinicInfo?.clinic_regno}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "300px",
                marginTop: "5px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="caption" style={{ marginLeft: "-210px" }}>
                Total Orders
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "12px",
                }}
              >

                <EmailIcon
                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                  alt="email"
                  fill={primaryColor}
                />
                <div className="content" style={{ marginLeft: "8px" }}>
                  {clinicInfo?.lab_order_count}
                </div>
              </div>
            </div>

            <div
              style={{
                height: "80%",
                width: "450px",
                marginTop: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >

              <div
                className="caption"
                style={{ textAlign: "left", marginLeft: "20px" }}
              >
                Address
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "18px",
                }}
              >
                <AddressIcon style={{ fill: primaryColor }} />
                {/* <img src={AddressIcon} alt="Edit" /> */}
                <div className="content" style={{ marginLeft: "8px" }}>
                  {clinicInfo?.clinic_address},
                  {clinicInfo?.clinic_city},
                  {clinicInfo?.clinic_county},
                  {clinicInfo?.clinic_zipcode}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="patient-detail-bottomRow">
          <div className="patient-info">

            <div
              style={{
                height: "80%",
                width: "300px",
                marginTop: "5px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="caption" style={{ marginLeft: "-230px" }}>
                Email
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "12px",
                }}
              >
                {/* <img  style={{height:"20px",width:"20px",marginLeft:"5px"}} src={EmailIcon} alt="Edit" /> */}

                <EmailIcon
                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                  alt="email"
                  fill={primaryColor}
                />
                <div className="content" style={{ marginLeft: "8px" }}>
                  {clinicDetails?.clinic_email
                    ? clinicDetails?.clinic_email
                    : "Not available"}
                </div>
              </div>
            </div>


            <div
              style={{
                height: "80%",
                width: "300px",
                marginTop: "5px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="caption" style={{ marginLeft: "-195px" }}>
                Mobile No
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "12px",
                }}
              >
                {/* <img  style={{height:"20px",width:"20px",marginLeft:"5px"}} src={SmartphoneIcon} alt="Edit" /> */}
                <SmartphoneIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "8px" }}>
                  {clinicDetails?.clinic_contact
                    ? clinicDetails.country_code_name + " " + clinicDetails?.clinic_contact
                    : "Not available"}
                </div>
              </div>
            </div>

            <div
              style={{
                height: "80%",
                width: "450px",
                marginTop: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >

              <div
                className="caption"
                style={{ textAlign: "left", marginLeft: "20px" }}
              >
                Hours
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "18px",
                }}
              >
                {/* <img src={CalendarIcon} alt="Edit" /> */}
                <CalendarIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "8px" }}>
                  {getClinicTime()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ClinicInformation;
