import React, { useState, useEffect } from "react";
import { Spin, Alert } from "antd";

import { Redirect, Route } from "react-router";
import { connect, useSelector } from "react-redux";
import { Mixpanel } from '../mixPanel';
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";

import LayoutComponent from "../components/private/layoutComponent";
import { checkLastActiveStatus } from "utils/commonMethod";
import { logout } from "services/redux/actions/authActions";



// const AuthRoute = (props) => {
//   const { type } = props;
//   //   const isAuthenticated =  getAuthenticationStatus();
//   const isAuthenticated = true;

//   console.log(isAuthenticated, "isAuthenticated");

//   if (type === "public" && isAuthenticated) return <Redirect to="/private" />;
//   else if (type === "private" && !isAuthenticated) return <Redirect to="/" />;

//   return <Route {...props} />;
// };

// export default AuthRoute;

const PrivateRoute = (props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { component: Component, isAuthenticated, isLoading, ...rest } = props;
  // console.log(props);

  useEffect(() => {
    setLoading(false);
  }, []);
  const state = useSelector(s => s)

  //Mix Panel
  useEffect(() => {
    // Mixpanel.identify(state.lab.labId);
    Mixpanel.track(state.lab.labName);
    Mixpanel.people.set(state.lab.labEmail, {
      $labName: state.lab.labName,
      $labEmail: state.lab.labEmail,
    });
  }, [window.location.href])

  // Google Gtag
  useEffect(() => {
    if (state.lab.labName && state.lab.labId) {
      ReactGA.initialize(process.env.REACT_APP_G_TAG, {
        gaOptions: {
          name: state.lab.labName,
          userId: state.lab.labId
        }
      });
      ReactGA.send("pageview");
    }
    else {
      ReactGA.initialize(process.env.REACT_APP_G_TAG);
    }

  }, [state.lab])

  const renderRoute = () => {

    let redirectCheck = false;
    if (isAuthenticated) {
      if (checkLastActiveStatus()) {
        dispatch(logout());
        redirectCheck = true;
      }
      return (
        <LayoutComponent>
          {props.lab?.labId &&
            !props.lab?.labName &&
            !props.lab?.isFirstLogin && (
              <div style={{ marginBottom: "0.5rem" }}>
                <Alert
                  message="Please complete your profile"
                  type="info"
                />
              </div>
            )}
          <Component {...props} />
        </LayoutComponent>
      );
    }
    else {
      redirectCheck = true;
    }

    if (redirectCheck) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }
  }

  return (
    <div>
      {isLoading && (
        <div>
          <Spin></Spin>
        </div>
      )}
      {!isLoading && (
        <Route
          {...rest}
          render={renderRoute}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ auth, lab }) => ({
  isAuthenticated: auth.isAuthenticated,
  isLoading: auth.isLoading,
  lab,
});

export default connect(mapStateToProps)(PrivateRoute);
