import { companyType } from "colors-config";
import dayjs from "dayjs";

function checkUndefined(val) {
  return val ? val : "";
}

export const addPatientMapper = (values, labId) => {
  const defaultCode = "+353";
  // const phone_number = (values?.prefix || defaultCode) + (values?.phone || "");
  return {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.Email ? values?.Email : "",
    isUnderAge: false,
    phoneNumber: values?.phone ? values?.phone : "",
    clinicId: values.clinicId,
    countryCode: values.prefix ? values.prefix : defaultCode,
    Address: checkUndefined(values?.Address),
    county: checkUndefined(values?.county),
    Zipcode: checkUndefined(values?.Zipcode),
    city: checkUndefined(values?.city),
    country: checkUndefined(values?.country),
    dob: values?.dob ? dayjs(values.dob).toISOString() : "",
    createdFrom: "lab",
    labId: labId,
    patientId: values.patientId,
    addressId: values.addressId
  };
};

export const findMaxPrescriptionVersion = (versions) => {
  if (versions && versions.length) {
    let maxVersion = Number(versions[0].value);
    versions.forEach((el) => {
      if (Number(el.value) > maxVersion) {
        maxVersion = Number(el.value);
      }
    });
    return maxVersion;
  }
  return 1;
};

export const getScanFileCheck = (scanFiles) => {
  return {
    [scanFiles.UpperArch]: false,
    [scanFiles.LowerArch]: false,
  };
};

export const makePatientAddress = (patientData) => {
  const patientAddress = `${patientData?.patient_address || ""}${
    patientData?.patient_address && patientData?.patient_city ? "," : ""
  } ${patientData?.patient_city || ""}${
    patientData?.patient_county && patientData?.patient_city ? "," : ""
  } ${patientData?.patient_county || ""}${
    patientData?.patient_county && patientData?.patient_postal_code ? "," : ""
  } ${patientData?.patient_postal_code || ""}${
    patientData?.patient_country && patientData?.patient_postal_code ? "," : ""
  } ${patientData?.patient_country || ""}`;

  return patientAddress;
};

export const patientDetailBreadcrumb = [
  {
    path: "/lab/patients",
    breadcrumbName: "Patients List",
  },
  {
    breadcrumbName: "Patient Detail",
  },
];

export const scansArray = [
  {
    name: "upperArch",
    label: "Upper Arch",
    id: 1,
  },
  {
    name: "lowerArch",
    label: "Lower Arch",
    id: 2,
  },
  {
    name: "biteScan1",
    label: "Bite Scan 1",
    id: 3,
  },
  {
    name: "biteScan2",
    label: "Bite Scan 2",
    id: 4,
  },
];

