import { useState } from "react";
import { Button, message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { companyType } from "colors-config";
import "./UploadFile";

const { Dragger } = Upload;

const UploadFile = ({
  fileList,
  setFileList,
  multiple = false,
  maxCount = 5,
  uploadFiles,
}) => {
  const [uploading, setUploading] = useState(false);
  const props = {
    className: `custom-file-dragger ${
      companyType == 5 ? "custom-file-dragger-smile" : ""
    }`,
    name: "file",
    multiple: false,
    maxCount: 1,
    // accept: ".csv",
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    customRequest: uploadFiles,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove: (file) => {
      console.log("remove");
    },
  };
  return (
    <div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          <strong>Click</strong> or <strong>drag</strong> .csv or .xlxs file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single  upload.</p>
      </Dragger>
    </div>
  );
};

export default UploadFile;
