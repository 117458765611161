import apiInstance from "./instance";

export const getCommentsAPI = (params) => {
  return apiInstance({
    method: "GET",
    url: "/comment/get",
    params:params,
  });
};


export const addCommentsAPI = (data) => {
    return apiInstance({
      method: "POST",
      url: "/comment/add",
      data:data,
    });
  };
  