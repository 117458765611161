import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FloatButton } from "antd";
import ChatIcon from "components/shared/Icon/ChatIcon";
import { CloseOutlined } from "@ant-design/icons";
import { socket, activitySocket } from "api/chat";
import { subUserSelector } from "services/redux/selectors/lab.selectors";
import { primaryColor } from "colors-config";
import styles from "./chatWindow.module.scss";
// import "./chat.scss";

import ChatDrawer from "./ChatDrawer";

const ChatWindow = ({ openCommentBox, lab, patientId, clinicInfo }) => {
  const labInfo = { labId: lab.labId, labName: lab.labName };
  const [open, setOpen] = useState(openCommentBox ?? false);
  const [unreadCount, setUnreadCount] = useState(0);
  const subUser = useSelector(subUserSelector);

  useEffect(() => {
    socket.connect();
    activitySocket.connect();
    // console.log("socket", socket);
    return () => {
      socket.disconnect();
      activitySocket.disconnect();
    };
  }, []);

  const showDrawer = () => {
    setOpen((prev) => !prev);
    setUnreadCount(0);
  };

  return (
    <>
      <FloatButton
        trigger="click"
        onClick={showDrawer}
        type="primary"
        className={styles["chat-btn"]}
        badge={{ count: unreadCount }}
        icon={
          open ? <CloseOutlined /> : <ChatIcon style={{ fontSize: "40px" }} fill={primaryColor} />
        }
      />
      {/* <AppButton
        className="chat-window-btn"
        type="primary"
        icon={
          <Badge count={unreadCount}>
            <ChatIcon style={{ fontSize: "20px!important" }} className="chat-icon" />
          </Badge>
        }
        onClick={showDrawer}
      /> */}
      {clinicInfo && patientId && lab && (
        <ChatDrawer
          open={open}
          setOpen={setOpen}
          subUser={subUser}
          labInfo={labInfo}
          patientId={patientId}
          setUnreadCount={setUnreadCount}
          // partneredLabs={partneredLabs}
          // patientName={patientName}
          // openPatientChatTab={openPatientChatTab}
          // disableCommentBox={disableCommentBox}
          clinicInfo={clinicInfo}
        />
      )}
    </>
  );
};

export default ChatWindow;
